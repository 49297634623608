import React from 'react';
import { createRoot } from 'react-dom/client';

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";

// ************************************  ADMIN Components ***********************************
import Dashboard from "./Admin/Dashboard";
import Industry from "./Admin/Industry";
import Company from "./Admin/Company";
import Companyjobs from "./Admin/Companyjobs";
import Department from "./Admin/Department";
import Login from "./Admin/Login";
import Jobs from "./Admin/Jobs";
import Title from "./Admin/Titles";
import Skill from "./Admin/Skill";
import Category from "./Admin/Category";
import Users from "./Admin/User";
import Topic from "./Admin/Topic";
import Question from "./Admin/Question";
import Reportuser from "./Admin/Reportuser";

// ************************************  seller Components ***********************************
import Seller from "./Seller";
import SellerLogin from "./Seller/Login";
import SellerRegister from "./Seller/Register";
import SellerDashboard from "./Seller/Dashboard";
import SellerJobs from "./Seller/Jobs";
import Profile from "./Seller/Profile";
import Applyjoblist from "./Seller/Applyjoblist";
import Claimaccount from "./Seller/Claimaccount";
import Createpassword from "./Seller/Createpassword";
import Forgotpassword from "./Seller/Forgotpassword";
import Restpassword from "./Seller/Restpassword";

import Faq from "./Faq";
import Header from "./Header";
import Footer from "./Footer";
// import Privacy from "./Privacy";
import ContactUs from "./ContactUs";
import UserDashboard from "./User/Dashboard";
import UserJobs from "./User/Job";
import OnBoarding from "./User/Onboarding";
import UserCompany from "./User/Company";
import SingleCompany from "./User/Singlecompany";
import SingleForum from "./User/ForumDetail";
import UserForum from './User/Forum';
import SingleJob from "./User/Singlejob";
import UserSetting from "./User/Setting";
import Customnotifications from "./Admin/Customnotifications";
import Customerchats from "./Admin/Customerchats";
import Home from "./Home";
import ArabicHome from "./ArabicHome";
function App() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 mt-5">
                    <div className="card p-3 ">
                        <div className="card-header">App Component</div>
                        <div className="card-body">You should not be here.</div>
                        <Link to="/home">Back to Home</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Privacy() {
    return <>

        <Header />
        <section id="home">
            <div class="container">
                <div class="banner-text row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="heading-text" id="privacy-text">
                                <h4>privacy Policy</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="frequently-asked-quetions" id="faq-sec-maine">
            <div class="container">
                <div class="row">
                    <div class="privacy-text">

                        <p><b>We are at Kaziom will</b> explain in this Privacy and Policy how we collect, process and or use information that we receive via our website, emails we send, and mobile applications that link to this Policy, as amended from time to time. This Policy describes how Kaziom collects, processes, uses, shares and secures the personal information you provide. It also describes your choices regarding use, access, and correction of your personal information. For purposes of this Policy, personal information shall mean any information relating to an identified or identifiable natural person.</p>
                        <p>When you fill out registration forms, submit your resume, or provide us with other personal information actively, we collect that information for processing and use in accordance with this Policy. Because we change our offerings and features from time to time, the options you have to provide us with personal information also may change, but here are some examples of situations in which you will have the opportunity to provide personal information to us:</p>

                        <ul class="list-section">
                            <li> Creating an account;</li>
                            <li> Signing up for email alerts;</li>
                            <li>Updating your profile;</li>
                            <li>Contacting us for technical support or customer service.</li>
                        </ul>


                        <p>Depending on how you interact with our Platform, the personal information we collect from you may vary. For example, to create an account we may ask only for an email address and password. In other circumstances, such as when you complete a form related to a particular service offering, we may ask you to provide other information, which may include your name, phone number, and/or postal address. We also collect and store certain information associated with actions you take. For example, when you search for jobs, we store the titles, locations and general salary range (if available) of jobs you search for and click on. We also store information about the job postings that you apply to, the time and duration of your activity on our Platform, and other information about your behavior on our Platform.</p>

                        <p>If you connect to the Platform using Facebook, Gmail , or LinkedIn, we will receive information that you authorize the Social Networking Site to share with us, which may include public profile information, name and email address. When we receive this information, it becomes Kaziom account information for purposes of your use of the Platform. This information also constitutes personal information and is therefore subject to this Policy. Any information that we collect from your Social Networking Site account may depend on the privacy settings you have set with the Social Networking Site, so please consult the Social Networking Site's privacy and data practices. You have the ability to disconnect your Social Networking Site account from your Kaziom account by adapting the privacy settings in your Social Networking Site account.</p >


                        <div class="text-bottom-sec">
                            <p>When you use the Platform, your device is automatically providing information to us so we can respond and customize our response to you. The type of information we collect by automated means generally includes technical information about your computer, such as your IP address or other device identifier, the type of device you use, and operating system version. The information we collect also may include usage information and statistics about your interaction with the Platform. That information may include the URLs of our web pages that you visited, URLs of referring and exiting pages, page views, time spent on a page, number of clicks, platform type, location data (if you have enabled access to your location on your mobile device), and other information about how you used the Platform. </p>
                        </div>

                        <h4>How We Use Information</h4>
                        <p>We use the information we collect to provide our services. In order for us to best provide our services (and to help make it feasible for us to do so), it is essential that we are able to collect and use the information as described in this Policy. So it is largely necessary for fulfilling the relationship we have with you, and, where that is not the case, we have a legitimate interest in using the information we collect, including personal information, for these purposes:</p>
                        <ul>
                            <li>to provide you with personalized content, services and offers of services offered by Kaziom, as well as other promotional content and services (for example, Kaziom and its affiliates may use your previous search and browsing history (e.g. the jobs you click on), or your resume or Profile information, to determine relevant search results and provide you with recommended jobs on Kaziom or on sites of Kaziom affiliates, or Kaziom may reach out to you about jobs or companies you may be interested in; we also use your IP address and mobile device for these purposes); For the purposes of this Policy, "affiliates" means any entity that directly or indirectly, controls, is controlled by, or is under common control of or with Kaziom, Inc., now or in the future. "Control" for these purposes means having a majority of shares or the right and ability to direct management.</li>
                            <li>to moderate and display the employer reviews, interview reviews, salary reports and other content you have submitted for the benefit of our other users;</li>
                            <li>to customize and improve the features, performance, and support of the Kaziom site </li>
                            <li> to provide relevant advertising, including interest-based advertising from us and third parties, which may mean that we share non-personally identifiable information, such as your job title, to third-party advertisers;</li>
                            <li> to transmit your application and save your application materials;</li>
                            <li> to share your saved Profile or resume with employers, subject to visibility settings you control;</li>
                            <li>to allow Kaziom to process your personal data for the purpose of improving for internal operations, including detecting and preventing fraud and spam, troubleshooting, data analysis, testing, research, and service improvement (this includes use of your IP address and mobile device information to help diagnose problems with our service and to administer the Platform);</li>

                        </ul>


                        <p>If we detect fraudulent activity, we remove the job posting from the Platform. However, fraudulent job postings can sometimes appear and we urge you to exercise caution in your job search. Please consider the following tips when conducting your search:</p>
                        <ul>
                            <li> Do not send money to a prospective employer.</li>
                            <li>Do not give out personal or financial account information to a new employer via email.</li>
                            <li> Do not cash checks or transfer funds for your employer.</li>
                            <li>Beware of job postings that ask you to send your resume to a free email service address (e.g., gmail, yahoo, etc).</li>
                            <li> Do not include sensitive information like your National Identification number in your resume.</li>
                            <li> Beware of job listings with spelling errors and bad grammar.</li>
                            <li> Beware of requests for interviews in suspicious places and get consultation from others.</li>
                        </ul>
                        <h4>Updates to Our Privacy Policy</h4>
                        <p>We may revise this Policy from time to time by posting an updated version on the Platform. The revised Policy will be effective immediately for unregistered users and users registering accounts or otherwise acknowledging the Policy on or after the revision date. For other users who registered accounts before the revision date, it will also become effective immediately and they will be notified by email.
                        </p>
                    </div>
                </div>
            </div>

        </section>
        <Footer />
    </>;
}


function Term() {
    return <>
        <Header />
        <section id="home">
            <div class="container">
                <div class="banner-text row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="heading-text">
                                <h4>Terms & Conditions</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="frequently-asked-quetions" id="terms-condition">
            <div class="container">
                <div class="row">
                    <div class="privacy-text">
                        <h3>Terms of Use</h3>
                        <h4>Signing up to Kaziom website:</h4>
                        <p><b>Social Sign Up and Sign In. </b> Currently: You may be able to register an account and subsequently access Kaziom through a social networking site, such as Facebook("Social Networking Site"). If you access Kaziom through a Social Networking Site you agree that we may access, make available through Kaziom, and store (if applicable) any information, data, text, messages, tags, and/or other materials that you have provided to and stored and made accessible in your Social Networking Site account so that it is available on and through Kaziom via your account and your Profile page. Subject to the privacy settings that you have set with the Social Networking Site account you use to access Kaziom, personally identifiable information that you post to that Social Networking Site may be displayed on Kaziom. </p>
                        <h4>Using Kaziom website and applications:</h4>
                        <p><b>Third-Party Content on Kaziom.</b> Content from other users, advertisers, and other third parties is made available to you through Kaziom. "Content" means any work of authorship or information, including salaries, company reviews, interview reviews, company photos, logos, employer responses, job ads, employer profile information, advertisements, comments, opinions, postings, resumes, messages, text, files, images, photos, works of authorship, e-mail, data or other materials you find on Kaziom. Because we do not control such Content, you understand and agree that: (1) we are not responsible for, and do not endorse, any such Content, including advertising and information about third-party products and services, job ads, or the employer, interview and salary-related information provided by other users; (2) we make no guarantees about the accuracy, currency, suitability, reliability or quality of the information in such Content; and (3) we assume no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful Content made available by users, advertisers, and third parties.</p>
                        <p><b>House Rules.</b> You represent and warrant that you will use Kaziom solely for lawful purposes in a manner consistent with these Terms and any and all applicable laws, regulations, or other legally enforceable obligations (including contractual obligations) you may have towards us and any third parties. You are solely responsible for any and all Content that is posted through your account on Kaziom ("Your Content"). You agree that by submitting or authorizing Your Content for use on Kaziom, you have reviewed and understood our Community Guidelines. You understand that you may expose yourself to liability if Your Content or other use of Kaziom violates applicable law or any third-party right.</p>
                        <div class="list-sec">
                            <h5>You agree that you will not:</h5>
                            <ul>
                                <li> Impersonate another person, or his or her email address, or misrepresent your current or former affiliation with an employer;</li>
                                <li>  Create user accounts under false or fraudulent pretenses; create or use an account for anyone other than yourself; or create multiple active user accounts to post multiple reviews for the same company</li>
                                <li> Post Content that you do not own or have the right to post in accordance with the license set forth in these Terms;</li>
                                <li>Violate these Terms, the terms of your agreements with us, explicit restrictions set forth in our Community Guidelines, or any applicable law, rule or regulation;</li>
                                <li> Post Content that is defamatory, libelous, or fraudulent; that you know to be false or misleading; or that does not reflect your honest opinion and experience;</li>
                                <li> Act in a manner that is harassing, threatening, abusive, racist or bigoted, is otherwise objectionable (as determined by Kaziom);</li>
                                <li> Promote, endorse or further illegal activities;</li>
                                <li> Disclose information in violation of any legally enforceable confidentiality, non-disclosure or other contractual restrictions or rights of any third party, including any current or former employers or potential employers;</li>
                                <li> Violate the privacy, publicity, copyright, patent, trademark, trade secret, or other intellectual property or proprietary rights of any third-party;</li>
                                <li>  Post anything pornographic or sexually explicit in nature, or engage in the exploitation of persons in a sexual or violent manner;</li>
                                <li>  Solicit personally identifying information from minors;</li>
                                <li>  Except as expressly approved by us, and subject to applicable laws, use Kaziom for commercial activities and/or promotions such as contests, sweepstakes, barter, pyramid schemes, advertising, affiliate links, and other forms of solicitation;</li>
                                <li> Imply a Kaziom endorsement or partnership of any kind without our express written permission;</li>
                                <li>  Send spamming  messages </li>
                                <li>  Introduce software or automated agents to Kaziom, or access Kaziom so as to produce multiple accounts, generate automated messages, or to scrape, strip or mine data from Kaziom without our express written permission;</li>
                                <li>  "Frame" or "mirror" or otherwise incorporate part of Kaziom into any website, or "deep-link" to any portion of Kaziom without our express written permission.</li>
                                <li>  Copy, modify or create derivative works of Kaziom or any Content (excluding Your Content) without our express written permission);</li>
                                <li>  Copy or use the information, Content (excluding Your Content), or data on Kaziom in connection with a competitive service, as determined by Kaziom;</li>
                                <li> Sell, resell, rent, lease, loan, trade or otherwise monetize access to Kaziom or any Content (excluding Your Content) without our express written permission;</li>
                                <li>  Interfere with, disrupt, modify, reverse engineer, or decompile any data or functionality of Kaziom;</li>
                                <li> Interfere with, disrupt, or create an undue burden on Kaziom or the networks or services connected to Kaziom;</li>
                                <li> Introduce any viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or similar software to Kaziom; or Attempt to circumvent any security feature of Kaziom;</li>
                            </ul>
                            <p><b>Links to Third-Party Websites.</b> Kaziom may contain links to third-party websites placed by us as a service to those interested in this information, or posted by other users. Your use of all such links to third-party websites is at your own risk. We do not monitor or have any control over, and make no claim or representation regarding third-party websites. To the extent such links are provided by us, they are provided only as a convenience, and a link to a third-party website does not imply our endorsement, adoption or sponsorship of, or affiliation with, such third-party website. When you leave Kaziom, our terms and policies do not govern your use of third-party websites.</p>
                            <h5>Special Provisions Applicable To Employers</h5>
                            <ul>
                                <h6>1. Posting Jobs on Kaziom. You may not post any job ad that:</h6>
                                <li> Does not comply with the applicable laws or regulations of Oman where the job is to be performed, including laws relating to labor and employment, equal employment opportunity and employment eligibility requirements, data privacy, data access and use and intellectual property;</li>
                                <li> Contains false information or solicits employees by intentional misrepresentation, such as, misrepresentation of the terms of employment, the hiring entity, or the identity of the poster;</li>
                                <li> Requires an application fee or up-front or periodic payments; requires recruitments of others; resembles a multi-level marketing scheme, franchise, pyramid scheme, "club membership", distributorship or sales representative agency arrangement; or only pays commissions (except where the listing makes clear that the available job pays commission only and clearly describes the product or service that the job seeker would be selling); or resembles a multi-level marketing scheme </li>
                                <li> Involves any screening requirement where such screening requirement is not an actual and legal requirement of the advertised position;</li>
                                <li> Contains any logo or brands, or link to website, other than your own or those of any entity for which you are authorized to submit job ads;</li>
                                <li>  Contains multiple job openings in a single job ad (unless you've purchased a service that permits this);</li>
                                <li>   Discriminates against applicants on the basis of gender, race, religion, sexual orientation, age, disability, or any other ground(s) prohibited by applicable law, in each case as determined in Kaziom's reasonable discretion.</li>
                            </ul>
                            <h5>You agree that Kaziom may, at its sole discretion, remove or prevent the posting of any job ad for any or no reason.</h5>
                            <p>Kaziom assumes no responsibility, and disclaims all liability for the content, accuracy, completeness, legality, reliability, or availability of any job posting, career page, job solicitation, screener question, answer to screener question, resume information, or message you post, send or receive through Kaziom or the Kaziom Messaging Service.</p>
                            <p>You are solely responsible for your interactions with advertisers and other users and we are not responsible for the activities, omissions, or other conduct, whether online or offline, of any advertiser or user of Kaziom. We are not responsible for any incorrect, inaccurate, or unlawful Content (including any information in profiles) posted on Kaziom, whether caused by users or by any of the equipment or programming associated with or utilized in Kaziom. We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any communication with advertisers or other users. We are not responsible for any problems or technical malfunction of any hardware and software due to technical problems on the Internet or on Kaziom or combination thereof, including any injury or damage to users or to any person's computer related to or resulting from participation or downloading materials in connection with Kaziom. Under no circumstances shall we be responsible for any loss or damage resulting from the use of Kaziom or from any Content posted on Kaziom or transmitted to users, or any interactions between users of Kaziom, whether online or offline.</p>
                            <h5>Changes to Terms</h5>
                            <p>We may revise these Terms from time to time by posting an updated version on Kaziom. The revised Terms will be effective immediately for unregistered users and users registering accounts on or after the revision date. For users who registered accounts before the revision date, they will become effective thirty (30) days after the revision date and they will be notified by email.</p>
                        </div>


                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>;
}

export default Privacy;


const hist = createBrowserHistory();

// setTimeout(() => {
if (document.getElementById('root')) {
    const root = createRoot(document.getElementById('root'));

    if (sessionStorage.getItem("language") === 'english') {
       
        root.render(<Router history={hist}>
            <Switch>
                <Route path="/main" render={props => <App {...props} />} />
                <Route path="/privacy-policy" render={props => <Privacy {...props} />} />
                <Route path="/terms-conditions" render={props => <Term {...props} />} />
                <Route path="/" render={props => <Home {...props} />} />
                <Route path="/faq" render={props => <Faq {...props} />} />
                <Route path="/contact-us" render={props => <ContactUs {...props} />} />

                <Redirect from="/" to="/main" />
            </Switch>
        </Router>);
    } else {
        root.render(<Router history={hist}>
            <Switch>
                <Route path="/main" render={props => <App {...props} />} />
                <Route path="/privacy-policy" render={props => <Privacy {...props} />} />
                <Route path="/terms-conditions" render={props => <Term {...props} />} />
                <Route path="/" render={props => <ArabicHome {...props} />} />
                <Route path="/faq" render={props => <Faq {...props} />} />
                <Route path="/contact-us" render={props => <ContactUs {...props} />} />



                <Redirect from="/" to="/main" />
            </Switch>
        </Router>);
    }
}


// }, 100);

if (document.getElementById('adminroot')) {
    const adminroot = createRoot(document.getElementById('adminroot'));

    adminroot.render(<Router history={hist}>
        <Switch>
            <Route path="/admin/dashboard" render={props => <Dashboard {...props} />} />
            <Route path="/admin/users" render={props => <Users {...props} />} />
            <Route path="/admin/industry" render={props => <Industry {...props} />} />
            <Route path="/admin/department" render={props => <Department {...props} />} />
            <Route path="/admin/title" render={props => <Title {...props} />} />
            <Route path="/admin/category" render={props => <Category {...props} />} />
            <Route path="/admin/skill" render={props => <Skill {...props} />} />
            <Route path="/admin/company" render={props => <Company {...props} />} />
            <Route path="/admin/companyjobs" render={props => <Companyjobs {...props} />} />
            <Route path="/admin/jobs" render={props => <Jobs {...props} />} />
            <Route path="/admin/topic" render={props => <Topic {...props} />} />
            <Route path="/admin/post" render={props => <Question {...props} />} />
            <Route path="/admin/custom-notifications" render={props => <Customnotifications {...props} />} />
            <Route path="/admin/customer-chats" render={props => <Customerchats {...props} />} />
            <Route path="/admin/report-user" render={props => <Reportuser {...props} />} />
            <Route path="/admin/login" render={props => <Login {...props} />} />
            <Redirect from="/admin" to="/admin/login" />
            <Redirect from="/" to="/main" />
        </Switch>
    </Router>);
}
if (document.getElementById('employerroot')) {
    const employerroot = createRoot(document.getElementById('employerroot'));

    employerroot.render(<Router history={hist}>
        <Switch>
            <Route path="/employer/home" render={props => <Seller {...props} />} />
            <Route path="/employer/claim-account" render={props => <Claimaccount {...props} />} />
            <Route path="/employer/create-password" render={props => <Createpassword {...props} />} />
            <Route path="/employer/forgot-password" render={props => <Forgotpassword {...props} />} />
            <Route path="/employer/reset-password" render={props => <Restpassword {...props} />} />

            <Route path="/employer/login" render={props => <SellerLogin {...props} />} />
            <Route path="/employer/register" render={props => <SellerRegister {...props} />} />
            <Route path="/employer/dashboard" render={props => <SellerDashboard {...props} />} />
            <Route path="/employer/jobs" render={props => <SellerJobs {...props} />} />
            <Route path="/employer/profile" render={props => <Profile {...props} />} />
            <Route path="/employer/apply-candidates-list" render={props => <Applyjoblist {...props} />} />

            <Redirect from="/" to="/main" />
        </Switch>
    </Router>);
}

if (document.getElementById('userroot')) {
    const userroot = createRoot(document.getElementById('userroot'));
    userroot.render(<Router history={hist}>
        <Switch>
            <Route path="/user/onboarding" render={props => <OnBoarding {...props} />} />
            <Route path="/user/dashboard" render={props => <UserDashboard {...props} />} />
            <Route path="/user/jobs" render={props => <UserJobs {...props} />} />
            <Route path="/user/forums" render={props => <UserForum {...props} />} />
            <Route path="/user/companies" render={props => <UserCompany {...props} />} />
            <Route path="/user/company/:id" render={props => <SingleCompany {...props} />} />
            <Route path="/user/forum/:id" render={props => <SingleForum {...props} />} />
            <Route path="/user/job/:id" render={props => <SingleJob {...props} />} />
            <Route path="/user/settings" render={props => <UserSetting {...props} />} />
            <Redirect from="/" to="/main" />
        </Switch>
    </Router>);
}


import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
class Headerseller extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA",
            language:sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "english"
        }
    }

    componentDidMount() {
      this.checkLanguage();
    }
    changeLanguage = (e, language) => {
       this.setState({
          "language":language
       })
        sessionStorage.setItem("language",language)
      window.location.reload()
    }

  checkLanguage() {
      this.setState({
         "language":sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "arabic"
      })
  }

    render() {
        return <>
     <header class="page_header" >
            <div class="mhead_sec" >
               <div class="containr">
               <div class="head_menu">
                  <div class="logo_img"> 
                  <a class="navbar-logo"><img src="../sellers/img/kaziom-logo.png" alt="kaziom logo" /></a> 
                  </div>
                  <div class="nav_bar">
                     <div class="login_sec">
                        <a class="semibold-small-text" href="login">Login</a> |  
                        <a class="semibold-small-text" href="login" target="_blank"> Employee</a>
                        </div>

                        <div class="nav-wrapper">
                                 <div class="sl-nav">
                                    <ul>
                                       <li>
                                          {this.state.language === 'english' && <> <img src="../sellers/img/flag1.png" class="selectimage" alt=""/> <i class="fa fa-angle-down" aria-hidden="true"></i>
                                          <div class="triangle"></div> </>}
                                          {this.state.language === 'arabic' && <> <img src="../sellers/img/flag2.png" class="selectimage" alt=""/> <i class="fa fa-angle-down" aria-hidden="true"></i>
                                          <div class="triangle"></div> </>}
                                          <ul>
                                             <li onClick={(e) => { this.changeLanguage(e,'english') }}><img src="../sellers/img/flag1.png" class="flag-icons" alt=""/><span class="active"> English</span></li>
                                             <li onClick={(e) => { this.changeLanguage(e,'arabic') }}><img src="../sellers/img/flag2.png" class="flag-icons" alt=""/><span> عربي</span></li>
                                          </ul>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                     {/* <div class="nav-wrapper">
                     <div class="sl-nav">
                        <ul>
                           <li> 
                              <img src="../sellers/img/flag1.png" class="selectimage" alt="" />
                               <i class="fa fa-angle-down" aria-hidden="true"></i>
                           <div class="triangle"></div>
                           <ul>
                              <li><img src="../sellers/img/flag1.png" class="flag-icons" alt="" /><span class="active"> English</span></li>
                              <li><img src="../sellers/img/flag2.png" class="flag-icons" alt="" /><span> عربي</span></li>
                           </ul>
                           </li>
                        </ul>
                     </div>
                     </div> */}
                  </div>
               </div>
               </div>
            </div>
         </header>
    
        </>;
    }
}

export default Headerseller;
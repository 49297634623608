import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";

import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import * as moment from "moment";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import BarChart from 'react-bar-chart';
import Autocomplete from "react-google-autocomplete";
import Header from "./Header";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Slider from "react-slick";
import ReactStars from "react-rating-stars-component";

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.MIX_REACT_API_URL,
            show: false,
            allData: [],
            show_modal_review: false,
            show_modal_salary: false,
            show_modal_job: false,
            apply_job_id: "",
            company_id: "",
            JobTitle: [],
            JobType: [],
            avgRating: 0,
            Type: [],
            intExp: "",
            rating: "",
            company: "",
            jobs: [],
            pros: "",
            location: "",
            cons: "",
            job_title_id: "",
            job_title: "",
            employer_type: "",
            reviewTitle: "",
            Review: [],
            interviewQuestion: [],
            salary: "",
            salary_type: "",
            experience: "",
            CompanySalary: [],
            allResumes: [],
            allJobs: [],
            allCompany: [],
            image: "",
            name: "",
            email: "",
            phone: "",
            resume: "",
            imgUrl: "",
            resume_id: "",
            company_Logo: '',

        }
        this.onFormSubmitJob = this.onFormSubmitJob.bind(this);
        this.onChangeJob = this.onChangeJob.bind(this);
    }
    componentDidMount = (e) => {
        var company_id = window.location.href.split("/").pop()
        this.setState({
            "userDetail": JSON.parse(sessionStorage.getItem("userDetail")),
            company_id: window.location.href.split("/").pop()
        })

        setTimeout(() => {
            this.setState({
                designation: this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
                show: true
            })
            console.log("sidebar", this.state.userDetail.image)
        }, 100);
        this.getSingleCompany(company_id)
        this.getTitle()
        this.getJobType()
        this.getSingleCompanySalary(company_id)
        this.getAllJobs()
        this.getAllCompany()
    }

    getAllCompany = (page = 1) => {
        var dd = [];
        Axios.post(`${this.state.url}/api/get/all/company`, page, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allCompany: res.data.data,
            })
            if (this.state.allCompany) {
                for (let k of this.state.allCompany) {
                    dd.push({ "id": k.id, "name": k.company_name });
                }
                this.setState({
                    CompanyName: dd
                })
            }
            console.log("CompanyName", this.state.CompanyName)
        });
    }


    getAllJobs = (page = 1) => {
        Axios.post(`${this.state.url}/api/get/all/job`, page, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allJobs: res.data.data,
            })
            console.log("homedata", this.state.allJobs)
        });
    }

    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    getResume = () => {
        Axios.get(`${this.state.url}/api/get-resumes`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allResumes: res.data.resume
            })
            console.log("resumes", this.state.allResumes)
        });
    }
    handleInput(e) {

        this.setState({ [e.target.name]: e.target.value })
    }

    getSingleCompany = (id) => {
        Axios.post(`${this.state.url}/api/single/company`, { id: id }, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            this.setState({
                allData: res.data.data,
                company: res.data.data.company,
                intExp: res.data.data.interview_experience,
                jobs: res.data.data.jobs,
                interviewQuestion: res.data.data.interview_question,
                Review: res.data.data.review ? res.data.data.review[0] : [],
                avgRating: res.data.data.avg_rating.toFixed(1),
                company_Logo: res.data.data.company.logo,
            })
            console.log(res.data.data, 'data124')
        });
    }

    ReviewModal = (e) => {
        if (e) e.preventDefault();
        this.setState({
            show_modal_review: true
        })
    }
    showModalReview = e => {
        this.setState({
            show_modal_review: false,
        })
    }

    SalaryModal = (e) => {
        if (e) e.preventDefault();
        this.setState({
            show_modal_salary: true
        })
    }
    showModalSalary = e => {
        this.setState({
            show_modal_salary: false,
        })
    }


    getSingleCompanySalary = (id) => {
        Axios.post(`${this.state.url}/api/single/company/salary`, { id: id }, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                CompanySalary: res.data.data,

            })

        });
    }

    getTitle = (page = 1) => {
        var dd = [];
        Axios.post(`${this.state.url}/api/title`, page, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                JobTitle: res.data.data,
            })
            if (this.state.JobTitle) {
                for (let k of this.state.JobTitle) {
                    dd.push({ "id": k.id, "name": k.name });
                }
                this.setState({
                    Title: dd
                })
            }
            console.log("CompanyName", this.state.Title)
        });
    }

    getJobType = (page = 1) => {
        var dd = [];
        Axios.post(`${this.state.url}/api/type`, page, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                JobType: res.data.data,
            })
            if (this.state.JobType) {
                for (let k of this.state.JobType) {
                    dd.push({ "id": k.id, "name": k.name });
                }
                this.setState({
                    Type: dd
                })
            }
        });
    }


    AddReview = () => {
        if (this.state.rating == "") {
            NotificationManager.error("error", "Please choose rating.");
            return false;
        }

        if (this.state.job_title_id == "") {
            NotificationManager.error("error", "Please choose job title.");
            return false;
        }

        if (this.state.job_type_id == "") {
            NotificationManager.error("error", "Please choose job type.");
            return false;
        }

        if (this.state.employer_type == "") {
            NotificationManager.error("error", "Please choose employer type.");
            return false;
        }

        if (this.state.reviewTitle == "") {
            NotificationManager.error("error", "Please enter review title.");
            return false;
        }

        if (this.state.pros == "") {
            NotificationManager.error("error", "Please enter pros.");
            return false;
        }

        if (this.state.cons == "") {
            NotificationManager.error("error", "Please enter cons.");
            return false;
        }


        var data = {
            rating: this.state.rating,
            job_title_id: this.state.job_title_id,
            employer_type: this.state.employer_type,
            title: this.state.reviewTitle,
            job_type_id: this.state.job_type_id,
            cons: this.state.cons,
            pros: this.state.pros,
            company_id: this.state.company.id,
        }

        Axios.post(`${this.state.url}/api/company/rating`, data, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            this.setState({
                reviewStep: 1,
                rating: "",
                pros: "",
                job_title_id: "",
                title: "",
                employer_type: "",
                job_type_id: "",
                cons: "",
                show_modal_review: false
            })
            NotificationManager.success("success", "Review Added Successfully");
        });
    }

    AddSalary = () => {
        if (this.state.salary == "") {
            NotificationManager.error("error", "Please enter salary.");
            return false;
        }
        if (this.state.salary_type == "") {
            NotificationManager.error("error", "Please choose salary type.");
            return false;
        }
        if (this.state.job_title_id == "") {
            NotificationManager.error("error", "Please choose job title.");
            return false;
        }
        if (this.state.location == "") {
            NotificationManager.error("error", "Please enter location.");
            return false;
        }
        if (this.state.employer_type == "") {
            NotificationManager.error("error", "Please choose employer type.");
            return false;
        }
        if (this.state.job_type_id == "") {
            NotificationManager.error("error", "Please choose job type.");
            return false;
        }

        var data = {
            salary: this.state.salary,
            salary_type: this.state.salary_type,
            job_title_id: this.state.job_title_id,
            location: this.state.location,
            employer_type: this.state.employer_type,
            job_type_id: this.state.job_type_id,
            experience: this.state.experience,
            company_id: this.state.company.id,
        }

        Axios.post(`${this.state.url}/api/company/addSalary`, data, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                salary: "",
                salary_type: "",
                job_title_id: "",
                location: "",
                employer_type: "",
                job_type_id: "",
                experience: "",
                show_modal_salary: false
            })
            NotificationManager.success("success", "Salary Added Successfully");
        });
    }

    ApplyJob = (e, id) => {
        e.preventDefault()
        var aa = []
        aa.push(id)
        this.getResume()
        this.setState({
            apply_job_id: aa,
            show_modal_job: true
        })


    }
    showModalJob = e => {
        this.setState({
            show_modal_job: false,
            topicStep: 1
        })
    }

    RemoveImage = e => {
        this.setState({
            image: "",
            resume: "",
            imgUrl: "",
            resume_id: ""
        })
    }

    NewResume = e => {
        this.setState({
            resume_id: ""
        })
    }

    onFormSubmitJob = (e) => {
        e.preventDefault();
        if (this.state.name === "") {
            NotificationManager.error("error", "Please enter name.");
            return false;
        }
        if (this.state.email === "") {
            NotificationManager.error("error", "Please enter email.");
            return false;
        }
        if (this.state.phone === "") {
            NotificationManager.error("error", "Please enter phone.");
            return false;
        }
        if (this.state.resume_id) {
            this.applyNewJob(this.state.resume_id, this.state.name, this.state.email, this.state.phone);
        } else {
            if (this.state.resume === "") {
                NotificationManager.error("error", "Please choose image.");
                return false;
            }
            const formData = new FormData();
            formData.append('name', this.state.name);
            formData.append('resume', this.state.resume);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    "Authorization": "Bearer " + sessionStorage.getItem('_access')
                }
            };
            Axios.post(`${this.state.url}/api/upload-resume`, formData, config, {
                // headers: {
                //    "Authorization": "Bearer "+sessionStorage.getItem('_access')
                //  }
            }).then(res => {
                this.applyNewJob(res.data.resume.id, this.state.name, this.state.email, this.state.phone);
            });
        }

    }


    applyNewJob = (resume_id, name, email, phone) => {
        var data = {
            resume_id: resume_id,
            name: name,
            email: email,
            phone: phone,
            job_id: this.state.apply_job_id
        }
        // console.log(data); return false;
        Axios.post(`${this.state.url}/api/apply-job`, data, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            NotificationManager.success("success", "Job Applied Successfully");
            $('.type_def_chk').prop("checked", false);
            this.setState({
                name: "",
                resume: "",
                imgUrl: "",
                image: "",
                apply_job_id: "",
                resume_id: "",
                aa: "",
                show_modal_job: false

            })

        });
    }

    onChangeJob(e) {

        const filesFormats = [".doc", ".docx", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];

        if (e.target.files) {
            let file = e.target.files[0];
            console.log(file.type)
            const isRightFormat = filesFormats.includes(file.type);
            if (!isRightFormat) {
                NotificationManager.error("error", "Please enter valid document only pdf or doc.");
                return;
            }
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState({
                    imgUrl: file.type == "application/pdf" ? "/admin/dist/images/pdf.jpeg" : "/admin/dist/images/doc.png",  //[reader.result]
                    //   image : [reader.result]
                    resume: file
                })
            }.bind(this);
            setTimeout(() => {
                console.log(this.state.image[0])
            }, 200);

        }
    }

    SingleJob = (e, id) => {
        window.location.href = "/user/job/" + id
    }

    render() {

        const data = [
            { text: 'Positive', value: this.state.intExp.positive },
            { text: 'Neutral', value: this.state.intExp.neutral },
            { text: 'Negative', value: this.state.intExp.negative }
        ];
        const margin = { top: 20, right: 20, bottom: 30, left: 40 };

        const ratingChanged = (newRating) => {
            this.setState({
                rating: newRating
            })
        };

        const handleOnSelectTitle = (item) => {
            var tt = []
            for (let k of this.state.JobTitle) {
                if (k.id === item.id) {
                    tt = k;
                }
            }
            setTimeout(() => {
                this.setState({
                    job_title_id: item.id
                })
                console.log('this.state.CompanyInfo', this.state.job_title_id)
            }, 100);
            // the item selected

        }

        const handleOnSearch = (string, results) => {
            // onSearch will have as the first callback parameter
            // the string searched and for the second the results.
            console.log(string, results)
        }

        const handleOnHover = (result) => {
            // the item hovered
            console.log(result)
        }

        const handleOnSelectType = (item) => {
            var tt = []
            for (let k of this.state.JobType) {
                if (k.id === item.id) {
                    tt = k;
                }
            }
            setTimeout(() => {
                this.setState({
                    job_type_id: item.id
                })
                console.log('this.state.CompanyInfo', this.state.job_type_id)
            }, 100);
            // the item selected

        }

        const onPlaceSelected = (place) => {
            console.log(place)
            for (let ko of place.address_components) {
                for (let l of ko.types) {
                    if (l === "administrative_area_level_2") {
                        console.log(ko.long_name)
                        this.setState({
                            location: ko.long_name
                        })
                    }
                }
            }
        }

        var settings = {
            className: "slider coupon",
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            dots: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }

            ]
        };
        return (
            <>
                <NotificationContainer />
                <Header />
                <Navbar />
                <section class="sidebar_menu">
                    <div id="page-content-wrapper" class="top-job-page">
                        <div class="container-fluid">
                            <div class="col-md-12 pd0">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="company-banner">
                                            <img src={this.state.url + '/uploads/' + this.state.company.banner} />
                                        </div>
                                        <div class="company-details">
                                            <div class="row">
                                                <div class="col-md-7">
                                                    <div class="comp-profile-img">
                                                        <img src={this.state.url + '/uploads/' + this.state.company.logo} />
                                                    </div>
                                                    <div class="job-details-dsp">
                                                        <h4>{this.state.company.company_name} <span class="follows">50k Follower</span></h4>
                                                        <p>{this.state.company.headqarter}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="post-date">
                                                        <a href="javascript:void(0)" onClick={(e) => { this.ReviewModal(e) }} class="apply-job"><img src="/admin/dist/images/star.png" />{this.state.avgRating} | Give Review</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <div class="post-fiels-sec">
                                                        <a href="javascript:void(0)">{this.state.company.industry}</a>
                                                        <a href="javascript:void(0)">{this.state.jobs.length} Job Opening</a>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    {/* <div class="save-list">
                                    <span>Add To Watchlist <img src="/admin/dist/images/save.png"/></span>
                                 </div> */}
                                                </div>
                                            </div>
                                            <div class="tabs_details">
                                                <ul class="nav nav-tabs">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" data-toggle="tab" href="#home">Overview</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#menu1">Saleries</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#menu2">Jobs</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#interview">Interview</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#review">Review</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content job-tabs">
                                                    <div id="home" class="tab-pane active">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <br></br>
                                                                <p className="overview_text">
                                                                    <p className="overview_paragraph" dangerouslySetInnerHTML={{ __html: this.state.company.description }} ></p>
                                                                </p>
                                                                <div className='row'>
                                                                    <div className="col-md-12 col-lg-6">
                                                                        <div className="row overview_section">
                                                                            <h3 className="col-md-12 col-lg-4">Industry</h3>
                                                                            <p className="col-md-12 col-lg-8">{this.state.company.industry}</p>
                                                                        </div>
                                                                        <div className="row overview_section">
                                                                            <h3 className="col-md-12 col-lg-4">Company</h3>
                                                                            <p className="col-md-12 col-lg-8" >{this.state.company.company_name}</p>
                                                                        </div>
                                                                        <div className="row overview_section">
                                                                            <h3 className="col-md-12 col-lg-4">Website</h3>
                                                                            <p className="col-md-12 col-lg-8">{this.state.company.website}</p>
                                                                        </div>
                                                                        <div className="row overview_section">
                                                                            <h3 className="col-md-12 col-lg-4">Size</h3>
                                                                            <p className="col-md-12 col-lg-8">{this.state.company.size}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 col-lg-6">
                                                                        <div className=" row overview_section">
                                                                            <h3 className="col-md-12 col-lg-5">Headquarters</h3>
                                                                            <p className="col-md-12 col-lg-7">{this.state.company.headqarter}</p>
                                                                        </div>
                                                                        <div className="row overview_section">
                                                                            <h3 className="col-md-12 col-lg-5">Founded</h3>
                                                                            <p className="col-md-12 col-lg-7">{this.state.company.founded}</p>
                                                                        </div>
                                                                        <div className="row overview_section">
                                                                            <h3 className="col-md-12 col-lg-5">Email Address</h3>
                                                                            <p className="col-md-12 col-lg-7">{this.state.company.company_email}</p>
                                                                        </div>
                                                                        <div className="row overview_section">
                                                                            <h3 className="col-md-12 col-lg-5">Status</h3>
                                                                            <p className="col-md-12 col-lg-7">{this.state.company.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="menu1" class="tab-pane empolyee-salaries">
                                                        <div className="salary_section">
                                                            <h3>Employee Salary</h3>
                                                            <a href="javascript:void(0)" onClick={(e) => { this.SalaryModal(e) }}>Add Salary</a>
                                                        </div>
                                                        <div className="row m-0 justify-content-between">
                                                            {this.state.CompanySalary && this.state.CompanySalary.length > 0 && <>
                                                                {this.state.CompanySalary.map((cr, index) => {
                                                                    return (
                                                                        <div class="col-xl-5 col-lg-10 my-3">
                                                                            <div className=" row">
                                                                                <div className="exp_sec col">
                                                                                    <div>
                                                                                        <img src={this.state.url + '/uploads/' + this.state.company.logo} style={{ padding: 0 }} className="img-thumbnail" alt="" />
                                                                                    </div>
                                                                                    <div className="col-6" style={{ padding: '0 5px' }}>
                                                                                        <h3 className="salary_title">{cr.title}</h3>
                                                                                        <p className="salary_type">Experience Min: {cr.experience} Years</p>
                                                                                    </div>
                                                                                    <p className="col-4 m-0 p-0 salary_salary">{cr.salary}/{cr.salary_type}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>}
                                                        </div>
                                                    </div>
                                                    <div id='menu2' className="tab-pane employee_jobs">
                                                        <h3>Jobs</h3>
                                                        {this.state.jobs.length > 0 ?
                                                            <p>There are <b> {this.state.jobs.length} </b> Jobs Available</p>
                                                            : ""}
                                                        <div className="row justify-content-between suggestion-sec" style={{ width: '100%', margin: 'auto', border: 'none' }}>
                                                            {this.state.jobs.map((slide, index) => {
                                                                return (
                                                                    <div class="card col-lg-5 col-xl-3 p-0 suggested">
                                                                        <div class="card-body suggested_body">
                                                                            <div class="card-content suggested">
                                                                                <div class="card-text suggested_text">
                                                                                    <div onClick={(e) => { this.SingleJob(e, slide.id) }}>
                                                                                        <img src={this.state.url + '/uploads/' + slide.banner} alt=""></img>
                                                                                        <h5>{slide.job_title}</h5>
                                                                                        <p><span class="sales-img"><img src={this.state.url + '/uploads/' + this.state.company_Logo} alt='' /></span>{slide.department}</p>
                                                                                        <p id="suggested_tab"> {slide.address}
                                                                                        </p>
                                                                                        <p>
                                                                                            ({slide.job_type})
                                                                                        </p>
                                                                                        <p>{moment.utc(slide.created_at).local().startOf('seconds').fromNow()}</p>
                                                                                    </div>
                                                                                    <a href="javascript:void(0)" onClick={(e) => { this.ApplyJob(e, slide.id) }} class="apply_btn">Apply</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div id='interview' className="tab-pane employee_interview">
                                                        <div class="interviewq addqu row">
                                                            <h3>Interview Question</h3>
                                                            <div class="row ml-1 justify-content-between">
                                                                {this.state.interviewQuestion && this.state.interviewQuestion.length > 0 && <>
                                                                    {this.state.interviewQuestion.map((cr, index) => {
                                                                        return (
                                                                            <div className="col-lg-5 my-3 col-sm-12 interview_card" key={index}>
                                                                                <h3 className="card-title">{cr.job_titile}</h3>
                                                                                <div className="interview_question overview_section">
                                                                                    <h3 className="col-4 px-0" >Question</h3>
                                                                                    <p className="col-8 px-0">{cr.question}</p>
                                                                                </div>
                                                                                <div className="interview_answer overview_section">
                                                                                    <h3 className="col-4 px-0">Answer</h3>
                                                                                    <p className="col-8 px-0">{cr.answer}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id='review' className="tab-pane employee_review">
                                                        <a href="javascript:void()" onClick={(e) => { this.ReviewModal(e) }}>Add Review</a>
                                                        <div className="row mt-3">
                                                            <div className="col-md-12 col-lg-6">
                                                                <h3>Reviews {this.state.avgRating}</h3>
                                                                <ReactStars
                                                                    count={3.5}
                                                                    isHalf={true}
                                                                    size={24}
                                                                />
                                                                {this.state.Review && <>
                                                                    <h3>Review Title</h3>
                                                                    <p>{this.state.Review.title}</p>
                                                                    <h3>Cons</h3>
                                                                    <p>{this.state.Review.cons}</p>
                                                                    <h3>Pros</h3>
                                                                    <p>{this.state.Review.pros}</p>
                                                                </>}
                                                            </div>
                                                            <div class="col-md-12 col-lg-6">
                                                                <div class="graph-se">
                                                                    <div>
                                                                        <BarChart xlabel='Quantity'
                                                                            width={250}
                                                                            height={250}
                                                                            margin={margin}
                                                                            data={data}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="suggestion-sec">
                                            <div class="suggested-sec">
                                                <h3>Suggested Jobs</h3>
                                                <Link to={`/user/jobs`} activeClassName="active">
                                                    <a>View All</a>
                                                </Link>
                                            </div>
                                            <div class="suggestedjob-slider">
                                                <Slider {...settings}>
                                                    {this.state.jobs.map((slide, index) => {
                                                        return (
                                                            <div class="card suggested">
                                                                <div class="card-body suggested_body">
                                                                    <div class="card-content suggested">
                                                                        <div class="card-text suggested_text">
                                                                            <div onClick={(e) => { this.SingleJob(e, slide.id) }}>
                                                                                <img src={this.state.url + '/uploads/' + slide.banner} alt=""></img>
                                                                                <h5>{slide.job_title}</h5>
                                                                                <p><span class="sales-img"><img src={this.state.url + '/uploads/' + this.state.company_Logo} alt='' /></span>{slide.department}</p>
                                                                                <p id="suggested_tab"> {slide.address}
                                                                                </p>
                                                                                <p>
                                                                                    ({slide.job_type})
                                                                                </p>
                                                                                <p>{moment.utc(slide.created_at).local().startOf('seconds').fromNow()}</p>
                                                                            </div>
                                                                            <a href="javascript:void(0)" onClick={(e) => { this.ApplyJob(e, slide.id) }} class="apply_btn">Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Slider>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 company">
                                        <div class="top-job">
                                            <h3>Related Jobs </h3>
                                            <Link to={`/user/jobs`} activeClassName="active">View All</Link>
                                            {this.state.allJobs && this.state.allJobs.length > 0 && <>
                                                {this.state.allJobs.map((cr, index) => {
                                                    if (index <= 4) {
                                                        return <div class="job-details-block">
                                                            <div class="row">
                                                                <div class="col-md-8 pr-0">
                                                                    <Link to={`/user/job/` + cr.id} activeClassName="active ">
                                                                        <div className="related_job">
                                                                            <div class="job-profile">
                                                                                {cr.banner && <> <img src={this.state.url + '/uploads/' + cr.banner} alt="" /> </>}
                                                                                {cr.banner == null && <> <img src="/admin/dist/images/ui.png" alt="" /> </>}
                                                                            </div>
                                                                            <div class="job-details">
                                                                                <h4>{cr.department}</h4>
                                                                                <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt="" /></span>{cr.company_name}</p>
                                                                                <p id="suggested_tab">{cr.address}</p>
                                                                                <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="apply_btn"><a onClick={(e) => { this.ApplyJob(e, cr.id) }} href="javascript:void(0)">Apply</a></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                })}
                                            </>}
                                        </div>
                                        <div class="top-companies" id="sticky">
                                            <h3>Top Companies</h3>
                                            <Link to={`/user/companies`} activeClassName="active">View All</Link>
                                            {this.state.allCompany && this.state.allCompany.length > 0 && <>
                                                {this.state.allCompany.map((cr, index) => {
                                                    if (index <= 4) {
                                                        return <Link to={`/user/company/` + cr.id} activeClassName="active">
                                                            <div class="companies-details-block">
                                                                <div class="company-profile">
                                                                    {cr.logo && <> <img src={this.state.url + '/uploads/' + cr.logo} alt="" /> </>}
                                                                    {cr.logo == null && <> <img src="/admin/dist/images/google.png" alt="" /> </>}
                                                                </div>
                                                                <div class="job-details">
                                                                    <h4>{cr.company_name}</h4>
                                                                    <p><span class="sales-img"><img src="/admin/dist/images/location.png" alt="" /></span>{cr.headqarter}</p>
                                                                    <p><span class="sales-img"><img src="/admin/dist/images/bag.png" alt="" /></span>{cr.jobs.length} Job Open</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    }
                                                })}
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                <Modal
                    show={this.state.show_modal_job}
                    onHide={() => this.showModalJob(false)}
                    dialogClassName="modal-90w"
                    //  size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <h4 class="title-headingsec">Apply Job</h4>
                    </Modal.Header>

                    <Modal.Body>
                        <h2>Upload resume</h2>
                        <form onSubmit={this.onFormSubmitJob}>
                            <div class="form-group">
                                <input type="text" class="form-control" id="name" onChange={e => { this.handleInput(e) }} name="name" placeholder="Enter Name" />
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" id="email" onChange={e => { this.handleInput(e) }} name="email" placeholder="Enter email" />
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" id="phone" onChange={e => { this.handleInput(e) }} name="phone" placeholder="phone" />
                            </div>
                            {this.state.resume_id && <>
                                <a href="javascript:void()" onClick={(e) => { this.NewResume(e) }}>Upload New Resume</a>
                            </>}
                            {!this.state.resume_id && <>
                                <div class="form-group">
                                    {this.state.imgUrl && <>
                                        <img src={this.state.imgUrl} />
                                        <a href="javascript:void()" onClick={(e) => { this.RemoveImage(e) }}>Remove</a>
                                    </>}
                                    {this.state.imgUrl == "" && <> <div class="uploadresume">

                                        <input type="file" id="myfile" name="image" onChange={this.onChangeJob} />

                                        <img src="/admin/dist/images/uploadfile.png" />
                                    </div>
                                    </>}

                                    <p>Please select a CV/Resume. CV Must be.PDF
                                        or .DOC file.</p>
                                </div>
                            </>}

                            <div class="upload-btn">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                        <div class="my-resume-sec">
                            <h2>My Resume</h2>
                            <div class="resume_maine">
                                {this.state.allResumes && this.state.allResumes.length > 0 && <>
                                    {this.state.allResumes.map((cr, index) => {
                                        return <div class="resume-innersec">
                                            <div class="row">
                                                <input type="radio" disabled={this.state.imgUrl} name="resume_id" onChange={e => { this.handleInput(e) }} value={cr.id} />
                                                <div class="col-md-10">
                                                    <div class="tabs-data">
                                                        <div class="left-side-sec">
                                                            <img src="/admin/dist/images/resumes.png" />
                                                        </div>
                                                        <div class="right-side-sec toppadd">
                                                            <h4>{cr.name}</h4>
                                                            <p>{moment(cr.created_at).format('DD-MMM-YYYY')}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    })}
                                </>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.show_modal_salary}
                    onHide={() => this.showModalSalary(false)}
                    dialogClassName="modal-90w"
                    //  size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <h4 class="title-headingsec">Add Salary</h4>
                    </Modal.Header>

                    <Modal.Body>

                        <div class="img-salary">
                            <img src={this.state.url + '/uploads/' + this.state.company.banner} />
                        </div>
                        <p>{this.state.company.company_name}</p>
                        <lable>
                            Enter Base Pay
                        </lable>
                        <input type="text" name="salary" onChange={e => { this.handleInput(e) }} class="form-control" />

                        <div class="radio-btns">
                            <lable>
                                Choose Salary Type
                            </lable>
                            <input type="radio" onChange={e => { this.handleInput(e) }} name="salary_type" value="yearly" />Yearly
                            <input type="radio" onChange={e => { this.handleInput(e) }} name="salary_type" value="hourly" />Hourly
                            <input type="radio" onChange={e => { this.handleInput(e) }} name="salary_type" value="monthly" />Monthly
                        </div>
                        <div>
                            <lable>
                                Job Title
                            </lable>
                            <ReactSearchAutocomplete
                                items={this.state.Title}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelectTitle}
                                // onFocus={handleOnFocus}
                                autoFocus
                            // formatResult={formatResult}
                            />
                        </div>
                        <div class="enterlocation">
                            <lable>
                                Enter Location
                            </lable>
                            <Autocomplete
                                apiKey="AIzaSyABHQkemy2kHyDvto_e0IW4hNRafNlsoQw"
                                onPlaceSelected={onPlaceSelected}
                            />
                        </div>

                        <div class="radio-btns">
                            <lable>
                                Choose Employee Type
                            </lable>
                            <input type="radio" onChange={e => { this.handleInput(e) }} name="employer_type" value="currently" />Currently Working
                            <input type="radio" onChange={e => { this.handleInput(e) }} name="employer_type" value="old" /> Former Employee
                        </div>
                        <div>
                            <lable>
                                Job Type
                            </lable>
                            <ReactSearchAutocomplete
                                items={this.state.Type}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelectType}
                                // onFocus={handleOnFocus}
                                autoFocus
                            // formatResult={formatResult}
                            />
                        </div>
                        <div>
                            <lable>Experience</lable>

                            <select onChange={e => { this.handleInput(e) }} class="form-control" name="experience">{[...Array(51)].map((e, i) => {
                                return <option value={i + 1}>{i + 1}</option>
                                // <li key={i}>{i}</li>
                            })}
                            </select>
                        </div>
                        <div class="post-salary">
                            <a href="javascript:void(0)" onClick={(e) => { this.AddSalary(e) }}>Add</a>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.show_modal_review}
                    onHide={() => this.showModalReview(false)}
                    dialogClassName="modal-90w"
                    //  size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <h4 class="title-headingsec">Add Review</h4>
                    </Modal.Header>

                    <Modal.Body>
                        <div class="img-salary">
                            <img src={this.state.url + '/uploads/' + this.state.company.banner} />
                        </div>
                        <p>{this.state.company.company_name}</p>
                        <lable>
                            Choose Rating
                        </lable>
                        <ReactStars
                            count={5}
                            isHalf={true}
                            onChange={ratingChanged}
                            size={24}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                        />

                        <div>
                            <lable>
                                Job Title
                            </lable>
                            <ReactSearchAutocomplete
                                items={this.state.Title}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelectTitle}
                                // onFocus={handleOnFocus}
                                autoFocus
                            // formatResult={formatResult}
                            />
                        </div>

                        <div>
                            <lable>
                                Job Type
                            </lable>
                            <ReactSearchAutocomplete
                                items={this.state.Type}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelectType}
                                // onFocus={handleOnFocus}
                                autoFocus
                            // formatResult={formatResult}
                            />
                        </div>


                        <div class="radio-btns">
                            <lable>
                                Choose Employee Type
                            </lable>
                            <input type="radio" onChange={e => { this.handleInput(e) }} name="employer_type" value="currently" />Currently Working
                            <input type="radio" onChange={e => { this.handleInput(e) }} name="employer_type" value="old" /> Former Employee
                        </div>

                        <div>
                            <lable>Review Title</lable>
                            <input type="text" name="reviewTitle" class="form-control" onChange={e => { this.handleInput(e) }} />
                        </div>

                        <div>
                            <lable>Pros</lable>
                            <textarea cols="10" onChange={e => { this.handleInput(e) }} name="pros" rows="5"></textarea>
                        </div>

                        <div>
                            <lable>Cons</lable>
                            <textarea cols="10" onChange={e => { this.handleInput(e) }} name="cons" rows="5"></textarea>
                        </div>
                        <div class="post-salary">
                            <a href="javascript:void(0)" onClick={(e) => { this.AddReview(e) }}>Add</a>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
                    </Modal.Footer>
                </Modal>


            </>
        )
    }
}

export default Company;
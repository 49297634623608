import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import readXlsxFile from 'read-excel-file';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Breadcrumb, Boxes } from "./Parts";
import CKEditor from "react-ckeditor-component";
import Pagination from "../Common/Pagination";
import { CSVLink } from "react-csv";
import * as moment from "moment";
const input = document.getElementById('input');

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            first_name: "",
            last_name: "",
            company_email: "",
            url: process.env.MIX_REACT_API_URL,
            company_name: "",
            company_title: "",
            description: "",
            banner: "",
            logo: "",
            keyword: "",
            keyword1: "",
            industry_id: "",
            add_new: false,
            contact_number: "",
            show_back: false,
            size: "",
            upload_data: [],
            company_status: "",
            website: "",
            founded: "",
            headqarter: "",
            edit_mode: false,
            allCompany: [],
            allIndustry: [],
            sugIndustry: [],
            pagination_data: {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": null,
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "next_page_url": null,
                "path": "",
                "per_page": 0,
                "prev_page_url": null,
                "to": 0,
                "total": 0
            },
            excel_data: [],
            excel_headers: [
                { label: "Company", key: "company_name" },
                { label: "Title", key: "company_title" },
                { label: "Email", key: "company_email" },
                { label: "Website", key: "website" },
                // { label: "CEO Name", key: "first_name" },
                { label: "Headqarter", key: "headqarter" },
                { label: "Founded", key: "founded" },
                { label: "Size", key: "size" },
                { label: "Status", key: "status" }
            ]
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        // this.onChange = this.onChange2.bind(this);
    }

    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
        this.getCompany();
        this.getIndustry()
    }
    onChange1(evt) {

        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            description: newContent
        })
    }

    getExcelData = () => {
        var param = new URLSearchParams(this.props.location.search).get("status");
        Axios.get(`${this.state.url}/get/excel/company`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {

            if (res.data.data.length > 0) {

                var x = [];
                res.data.data.forEach(val => {

                    var c = {
                        company_name: val.company_name,
                        company_title: val.company_title,
                        company_email: val.company_email,
                        website: val.website,
                        // first_name: val.first_name,
                        headqarter: val.headqarter,
                        size: val.size,
                        founded: val.founded,
                        status: val.status
                    }
                    c.created_at = moment(val.created_at).format("YYYY-MM-DD");

                    x.push(c);
                })

                this.setState({
                    excel_data: x
                })
                // setTimeout(() => {
                console.log("res.data.data.data", res.data.data)
                // }, 500);

            }

        });
    }

    searchRecords = e => {
        var val = e.target.value;
        console.log(val, e.keyCode)
        if (val.length > 2 || (e.keyCode === 8 && val.length === 0) || e.keyCode === 13) {
            this.setState({
                keyword: val,
                page: 1
            })
            setTimeout(() => {
                console.log(this.state.keyword)
                this.getCompany();
            }, 100);
        }
    }
    searchIndustry = (e) => {
        Axios.post(`${this.state.url}/admin/search-industry`, { keyword: this.state.keyword1 }, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                sugIndustry: res.data.data
            })
            //  console.log("this.state.allDepartment", this.state.allDepartment);
        });
    }
    getIndustry = (e) => {
        Axios.get(`${this.state.url}/admin/get/industry`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allIndustry: res.data.data
            })
        });
    }
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === "keyword1") {
            this.setState({
                keyword1: e.target.value
            })
            //console.log("message",this.state.keyword);
            this.searchIndustry();
        }
    }

    onChange(e) {
        if (e.target.name === 'image') {
            this.setState({
                "logo": e.target.files[0],
            });
        } else {
            this.setState({
                "banner": e.target.files[0],
            });
        }
    }

    onChange2(e) {
        this.setState({
            "banner": e.target.files[0],
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        if (this.state.industry_id === "") {
            NotificationManager.error("error", "Please select Industry .");
            return false;
        }
        if (this.state.company_name === "") {
            NotificationManager.error("error", "Please enter Company Name.");
            return false;
        }
        
        // if (this.state.first_name === "") {
        //     NotificationManager.error("error", "Please enter First Name.");
        //     return false;
        // }

        // if (this.state.last_name === "") {
        //     NotificationManager.error("error", "Please enter Last Name.");
        //     return false;
        // }

        if (this.state.website === "") {
            NotificationManager.error("error", "Please enter Company Website.");
            return false;
        }
        if (this.state.size === "") {
            NotificationManager.error("error", "Please enter Company Size.");
            return false;
        }
        if (this.state.headqarter === "") {
            NotificationManager.error("error", "Please enter Company Headquarter.");
            return false;
        }
        if (this.state.founded === "") {
            NotificationManager.error("error", "Please select founded.");
            return false;
        }
        // if (this.state.company_title === "") {
        //     NotificationManager.error("error", "Please enter Company Title.");
        //     return false;
        // }
        // if (this.state.company_email === "") {
        //     NotificationManager.error("error", "Please enter Company Email.");
        //     return false;
        // }
        if (this.state.contact_number === "") {
            NotificationManager.error("error", "Please enter Contact Number.");
            return false;
        }
        if (this.state.logo === "") {
            NotificationManager.error("error", "Please Choose company logo.");
            return false;
        }
        if (this.state.banner === "") {
            NotificationManager.error("error", "Please Choose company banner.");
            return false;
        }
        if (this.state.description === "") {
            NotificationManager.error("error", "Please Enter Description.");
            return false;
        }
        if (this.state.company_status === "") {
            NotificationManager.error("error", "Please Select Company Status.");
            return false;
        }
        const formData = new FormData();
        // formData.append('first_name', this.state.first_name);
        formData.append('logo', this.state.logo);
        formData.append('banner', this.state.banner);
        // formData.append('last_name', this.state.last_name);
        formData.append('company_name', this.state.company_name);
        formData.append('contact_number', this.state.contact_number);
        formData.append('company_title', this.state.company_title);
        formData.append('company_email', this.state.company_email);
        formData.append('website', this.state.website);
        formData.append('size', this.state.size);
        formData.append('founded', this.state.founded);
        formData.append('headqarter', this.state.headqarter);
        formData.append('industry_id', this.state.industry_id);
        formData.append('company_status', this.state.company_status);
        formData.append('status', this.state.status);
        formData.append('description', this.state.description);
        formData.append("id", this.state.id ? this.state.id : "");
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        if (!this.state.edit_mode) {
            Axios.post(`${this.state.url}/add/company`, formData, config, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false
                    })
                    NotificationManager.success("success", "Company successfully added");
                    this.getCompany()
                } else {
                    NotificationManager.error("error", "Email Already Exist");
                }
            })
        } else {
            Axios.post(`${this.state.url}/update/company`, formData, config, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false,
                        edit_mode: false,
                        show_back: false
                    })
                    NotificationManager.success("success", "Company updated successfully");
                    this.getCompany()
                } else {
                    NotificationManager.error("error", res.data.message);
                }
            })
        }
    }

    getCompany = (page = 1) => {
        Axios.get(`${this.state.url}/get/company?page=${page}&keyword=${this.state.keyword}`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allCompany: res.data.data.data,
                pagination_data: res.data.data
            })
            this.getExcelData()
            console.log("this.state.allCompany", this.state.allCompany);
        });
    }


    AddNew = (e) => {
        setTimeout(() => {
            this.setState({
                show_back: true,
                add_new: true,
                edit_mode: false,
                status: "",
                first_name: "",
                last_name: "",
                company_email: "",
                description: "",
                contact_number: "",
                company_name: "",
                company_title: "",
                company_status: "",
                industry_id: "",
                size: "",
                website: "",
                founded: "",
                headqarter: "",
                keyword1: ""
            })
            console.log(this.state.show_back);
        }, 100);

    }

    cancelForm = (e) => {
        this.setState({
            show_back: false,
            add_new: false,
            edit_mode: false,
            status: "",
            first_name: "",
            description: "",
            last_name: "",
            company_email: "",
            industry_id: "",
            company_name: "",
            contact_number: "",
            company_title: "",
            company_status: "",
            size: "",
            website: "",
            founded: "",
            headqarter: "",
            keyword1: ""
        })
    }

    editType = (e, index, id) => {
        e.preventDefault();
        var data = [];
        var selected = this.state.allCompany[index];

        this.setState({
            show_back: true,
            id: selected.id,
            first_name: selected.first_name,
            last_name: selected.last_name,
            company_email: selected.company_email,
            logo: selected.logo,

            keyword1: selected.industry ? selected.industry.name : "",
            banner: selected.banner,
            company_status: selected.company_status,
            company_name: selected.company_name,
            description: selected.description,
            contact_number: selected.contact_number,
            company_title: selected.company_title,
            website: selected.website,
            headqarter: selected.headqarter,
            founded: selected.founded,
            size: selected.size,
            industry_id: selected.industry_id,
            status: selected.status,
            edit_mode: true,
            show_list: false
        })
        console.log("this.state.edit_mode", selected);
    }

    UploadFile = (e) => {
        var data = []
        readXlsxFile(e.target.files[0]).then((rows) => {
            console.log(rows)
            if (rows) {
                for (let k of rows) {
                    data.push({ "industry_id": k[0], "company_name": k[1], "first_name": k[2], "last_name": k[3], "website": k[4], "size": k[5], "headqarter": k[6], "founded": k[7], "company_title": k[8], "company_email": k[9], "contact_number": k[10], "status": "inactive", "description": k[12], "company_status": k[13] })
                    // data.push({"company_name":k[1]})
                }
            }
            this.setState({
                upload_data: data.slice(1)
            })

            console.log(this.state.upload_data)
        })
    }
    SendData = (e) => {
        Axios.post(`${this.state.url}/add/company/excel`, { data: this.state.upload_data }, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            NotificationManager.success("success", "Company successfully added");
        })
    }
    handleInput1 = (e = null, data) => {
        this.setState({
            industry_id: data.id,
            keyword1: data.name,
            sugIndustry: []
        })
    }
    render() {
        return (<>
            <NotificationContainer />
            <Navbar page="Admin Dashboard" />
            <Sidebar />

            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-3">
                                <h1 class="m-0">Company</h1>
                                {/* <input type="search" className="form-control"></input> */}
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="search-bar">
                                            <form class="form-inline search-datesec">
                                                <div class="search-form">
                                                    <div class="form-group">
                                                        <input type="text" onKeyUp={(e) => { this.searchRecords(e) }} name="search" id="myInputTextField" placeholder="Search Record" class="form-control" />
                                                        <span>
                                                            <i class="fa fa-search" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row my-3">
                            <div className="col-md-6 mt-3">
                                {!this.state.show_back && <> <div style={{ textAlign: 'left' }} class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.AddNew(e) }}>Add New</a></div> </>}
                                {this.state.show_back && <> <div style={{ textAlign: 'left' }} class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>}
                            </div>
                            <div class={`col-md-6 mt-3 d-flex align-items-center`}>
                                <input type="file" className="col-sm-5" id="input" onChange={(e) => { this.UploadFile(e) }} />
                                <div className="col-sm-2 ml-2 px-0"> Upload File </div>
                                <div style={{ display: 'flex', alignItems: 'center' }} className='col-sm-5'>
                                    <button type="button" style={{ marginRight: '5px', backgroundColor: '#047768', borderRadius: '2rem' }} class="btn btn-block text-white admin_upload_btn" onClick={(e) => { this.SendData(e) }}>Upload</button>
                                    <button type="button" style={{ marginLeft: '5px', backgroundColor: '#047768', borderRadius: '2rem' }} class="btn btn-block mt-0 admin_upload_btn">
                                        <CSVLink data={this.state.excel_data} headers={this.state.excel_headers} id="typesTableExport" filename={"Company-list-" + moment().format('YYYY-MM-DD-h-mm-ss-a') + ".csv"} asyncOnClick={true} onClick={(event) => { this.getExcelData() }}>
                                            <div style={{ color: '#fff' }}>
                                                Excel
                                            </div>
                                        </CSVLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.edit_mode || this.state.add_new) && <> <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card card-primary">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <h3 class="card-title">Add New Company</h3>
                                            </div>
                                            <div class="col-md-3">

                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={this.onFormSubmit}>

                                        <div class="card-body">
                                            <div class="row">

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="last_name">Industry</label>

                                                        <input value={this.state.keyword1} autoComplete="off" className="custom-select" placeholder="Search Industry" name="keyword1" onChange={(e) => { this.handleInput(e) }} />


                                                        {this.state.sugIndustry.length > 0 && <>
                                                            {this.state.sugIndustry.map((temp, key) => {

                                                                //  return <p key={key} className="chat-template"  >{temp.company_name}</p>

                                                                return <option key={key} onClick={(e) => { this.handleInput1(e, temp) }} >{temp.name}</option>
                                                            })
                                                            }

                                                        </>}


                                                    </div>
                                                </div>
                                                {/* <div class="col-md-6">
                                <div class="form-group">
                                    <label for="last_name">Industry</label>
                                    <select value={this.state.industry_id} className="custom-select" name="industry_id" onChange={(e) => { this.handleInput(e) }}>
                                          <option value="">Select Industry</option>
                                          {this.state.allIndustry.length > 0 && <>
                                             {this.state.allIndustry.map((cr, index) => {
                                                return <option value={cr.id} data={index}>{cr.name}</option>;
                                             })}
                                          </>}
                                       </select>
                                </div>
                                </div> */}
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_name">Company</label>
                                                        <input type="text" value={this.state.company_name} class="form-control" name="company_name" onChange={e => { this.handleInput(e) }} id="company_name" placeholder="Enter Company Name" />
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">CEO First Name</label>
                                                        <input type="text" value={this.state.first_name} class="form-control" name="first_name" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter First Name" />
                                                    </div>
                                                </div> */}
                                                {/* <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="last_name">CEO Last Name</label>
                                                        <input type="text" value={this.state.last_name} class="form-control" name="last_name" onChange={e => { this.handleInput(e) }} id="last_name" placeholder="Enter Last Name" />
                                                    </div>
                                                </div> */}
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="website">Company Website</label>
                                                        <input type="text" value={this.state.website} class="form-control" name="website" onChange={e => { this.handleInput(e) }} id="website" placeholder="Enter Website" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="size">Company Size</label>
                                                        <select class="custom-select" value={this.state.size} onChange={(e) => { this.handleInput(e) }} name="size">
                                                            <option value="">Select Size</option>
                                                            <option selected={this.state.size === '0-50'} value="0-50">0-50</option>
                                                            <option selected={this.state.size === '51-100'} value="51-100">51-100</option>
                                                            <option selected={this.state.size === '101-150'} value="101-150">101-150</option>
                                                            <option selected={this.state.size === '151-250'} value="151-250">151-250</option>
                                                            <option selected={this.state.size === '251-500'} value="251-500">251-500</option>
                                                            <option selected={this.state.size === '501-1000'} value="501-1000">501-1000</option>
                                                            <option selected={this.state.size === '1001-5000'} value="1001-5000">1001-5000</option>
                                                            <option selected={this.state.size === '5001-10000'} value="5001-10000">5001-10000</option>
                                                        </select>


                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="headqarter">Company Headquarters</label>
                                                        <input type="text" value={this.state.headqarter} class="form-control" name="headqarter" onChange={e => { this.handleInput(e) }} id="headqarter" placeholder="Enter Headquarters" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="headqarter">Founded</label>
                                                        <select class="custom-select" value={this.state.founded} onChange={(e) => { this.handleInput(e) }} name="founded">
                                                            <option>- Year -</option>
                                                            <option selected={this.state.founded === '2021'} value="2021">2021</option>
                                                            <option selected={this.state.founded === '2020'} value="2020">2020</option>
                                                            <option selected={this.state.founded === '2019'} value="2019">2019</option>
                                                            <option selected={this.state.founded === '2018'} value="2018">2018</option>
                                                            <option selected={this.state.founded === '2017'} value="2017">2017</option>
                                                            <option selected={this.state.founded === '2016'} value="2016">2016</option>
                                                            <option selected={this.state.founded === '2015'} value="2015">2015</option>
                                                            <option selected={this.state.founded === '2014'} value="2014">2014</option>
                                                            <option selected={this.state.founded === '2013'} value="2013">2013</option>
                                                            <option selected={this.state.founded === '2012'} value="2012">2012</option>
                                                            <option selected={this.state.founded === '2011'} value="2011">2011</option>
                                                            <option selected={this.state.founded === '2010'} value="2010">2010</option>
                                                            <option selected={this.state.founded === '2009'} value="2009">2009</option>
                                                            <option selected={this.state.founded === '2008'} value="2008">2008</option>
                                                            <option selected={this.state.founded === '2007'} value="2007">2007</option>
                                                            <option selected={this.state.founded === '2006'} value="2006">2006</option>
                                                            <option selected={this.state.founded === '2005'} value="2005">2005</option>
                                                            <option selected={this.state.founded === '2004'} value="2004">2004</option>
                                                            <option selected={this.state.founded === '2003'} value="2003">2003</option>
                                                            <option selected={this.state.founded === '2002'} value="2002">2002</option>
                                                            <option selected={this.state.founded === '2001'} value="2001">2001</option>
                                                            <option selected={this.state.founded === '2000'} value="2000">2000</option>
                                                            <option selected={this.state.founded === '1999'} value="1999">1999</option>
                                                            <option selected={this.state.founded === '1998'} value="1998">1998</option>
                                                            <option selected={this.state.founded === '1997'} value="1997">1997</option>
                                                            <option selected={this.state.founded === '1996'} value="1996">1996</option>
                                                            <option selected={this.state.founded === '1995'} value="1995">1995</option>
                                                            <option selected={this.state.founded === '1994'} value="1994">1994</option>
                                                            <option selected={this.state.founded === '1993'} value="1993">1993</option>
                                                            <option selected={this.state.founded === '1992'} value="1992">1992</option>
                                                            <option selected={this.state.founded === '1991'} value="1991">1991</option>
                                                            <option selected={this.state.founded === '1990'} value="1990">1990</option>
                                                            <option selected={this.state.founded === '1989'} value="1989">1989</option>
                                                            <option selected={this.state.founded === '1988'} value="1988">1988</option>
                                                            <option selected={this.state.founded === '1987'} value="1987">1987</option>
                                                            <option selected={this.state.founded === '1986'} value="1986">1986</option>
                                                            <option selected={this.state.founded === '1985'} value="1985">1985</option>
                                                            <option selected={this.state.founded === '1984'} value="1984">1984</option>
                                                            <option selected={this.state.founded === '1983'} value="1983">1983</option>
                                                            <option selected={this.state.founded === '1982'} value="1982">1982</option>
                                                            <option selected={this.state.founded === '1981'} value="1981">1981</option>
                                                            <option selected={this.state.founded === '1980'} value="1980">1980</option>
                                                            <option selected={this.state.founded === '1979'} value="1979">1979</option>
                                                            <option selected={this.state.founded === '1978'} value="1978">1978</option>
                                                            <option selected={this.state.founded === '1977'} value="1977">1977</option>
                                                            <option selected={this.state.founded === '1976'} value="1976">1976</option>
                                                            <option selected={this.state.founded === '1975'} value="1975">1975</option>
                                                            <option selected={this.state.founded === '1974'} value="1974">1974</option>
                                                            <option selected={this.state.founded === '1973'} value="1973">1973</option>
                                                            <option selected={this.state.founded === '1972'} value="1972">1972</option>
                                                            <option selected={this.state.founded === '1971'} value="1971">1971</option>
                                                            <option selected={this.state.founded === '1970'} value="1970">1970</option>
                                                            <option selected={this.state.founded === '1969'} value="1969">1969</option>
                                                            <option selected={this.state.founded === '1968'} value="1968">1968</option>
                                                            <option selected={this.state.founded === '1967'} value="1967">1967</option>
                                                            <option selected={this.state.founded === '1966'} value="1966">1966</option>
                                                            <option selected={this.state.founded === '1965'} value="1965">1965</option>
                                                            <option selected={this.state.founded === '1964'} value="1964">1964</option>
                                                            <option selected={this.state.founded === '1963'} value="1963">1963</option>
                                                            <option selected={this.state.founded === '1962'} value="1962">1962</option>
                                                            <option selected={this.state.founded === '1961'} value="1961">1961</option>
                                                            <option selected={this.state.founded === '1960'} value="1960">1960</option>
                                                            <option selected={this.state.founded === '1959'} value="1959">1959</option>
                                                            <option selected={this.state.founded === '1958'} value="1958">1958</option>
                                                            <option selected={this.state.founded === '1957'} value="1957">1957</option>
                                                            <option selected={this.state.founded === '1956'} value="1956">1956</option>
                                                            <option selected={this.state.founded === '1955'} value="1955">1955</option>
                                                            <option selected={this.state.founded === '1954'} value="1954">1954</option>
                                                            <option selected={this.state.founded === '1953'} value="1953">1953</option>
                                                            <option selected={this.state.founded === '1952'} value="1952">1952</option>
                                                            <option selected={this.state.founded === '1951'} value="1951">1951</option>
                                                            <option selected={this.state.founded === '1950'} value="1950">1950</option>
                                                            <option selected={this.state.founded === '1949'} value="1949">1949</option>
                                                            <option selected={this.state.founded === '1948'} value="1948">1948</option>
                                                            <option selected={this.state.founded === '1947'} value="1947">1947</option>
                                                            <option selected={this.state.founded === '1946'} value="1946">1946</option>
                                                            <option selected={this.state.founded === '1945'} value="1945">1945</option>
                                                            <option selected={this.state.founded === '1944'} value="1944">1944</option>
                                                            <option selected={this.state.founded === '1943'} value="1943">1943</option>
                                                            <option selected={this.state.founded === '1942'} value="1942">1942</option>
                                                            <option selected={this.state.founded === '1941'} value="1941">1941</option>
                                                            <option selected={this.state.founded === '1940'} value="1940">1940</option>
                                                            <option selected={this.state.founded === '1939'} value="1939">1939</option>
                                                            <option selected={this.state.founded === '1938'} value="1938">1938</option>
                                                            <option selected={this.state.founded === '1937'} value="1937">1937</option>
                                                            <option selected={this.state.founded === '1936'} value="1936">1936</option>
                                                            <option selected={this.state.founded === '1935'} value="1935">1935</option>
                                                            <option selected={this.state.founded === '1934'} value="1934">1934</option>
                                                            <option selected={this.state.founded === '1933'} value="1933">1933</option>
                                                            <option selected={this.state.founded === '1932'} value="1932">1932</option>
                                                            <option selected={this.state.founded === '1931'} value="1931">1931</option>
                                                            <option selected={this.state.founded === '1930'} value="1930">1930</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_title">Company Title</label>
                                                        <input type="text" value={this.state.company_title} class="form-control" name="company_title" onChange={e => { this.handleInput(e) }} id="company_title" placeholder="Enter Company Title" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_title">Work Email Address</label>
                                                        <input type="text" value={this.state.company_email} class="form-control" name="company_email" onChange={e => { this.handleInput(e) }} id="company_email" placeholder="Enter Company Email" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_title">Contact Number</label>
                                                        <input type="text" value={this.state.contact_number} class="form-control" name="contact_number" onChange={e => { this.handleInput(e) }} id="contact_number" placeholder="Enter Company Contact Number" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Status</label>
                                                        <select class="custom-select" value={this.state.status} onChange={(e) => { this.handleInput(e) }} name="status">
                                                            <option value="">Select Status</option>
                                                            <option selected={this.state.status === 'active' || this.state.status === 'active'} value="active">Active</option>
                                                            <option selected={this.state.status === 'inactive' || this.state.status === 'inactive'} value="inactive">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group logogorm">
                                                        <label for="company_title">Company Logo</label>
                                                        <input type="file" name="image" onChange={this.onChange} class="form-control"></input>
                                                        {this.state.edit_mode === true &&
                                                            <img height="30px" width="30px" src={this.state.url + '/uploads/' + this.state.logo} alt=""></img>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_title">Banner Image</label>
                                                        <input type="file" name="banner" onChange={this.onChange} class="form-control"></input>
                                                        {this.state.edit_mode === true &&
                                                            <img src={this.state.url + '/uploads/' + this.state.banner} class="bannerimage" alt=""></img>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Description</label>
                                                        <CKEditor
                                                            activeClass="p10"
                                                            content={this.state.description}
                                                            events={{
                                                                "blur": this.onBlur,
                                                                "afterPaste": this.afterPaste,
                                                                "change": this.onChange1
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Company Status</label>
                                                        <select class="custom-select" value={this.state.company_status} onChange={(e) => { this.handleInput(e) }} name="company_status">
                                                            <option value="">Select Company Status</option>
                                                            <option selected={this.state.company_status === 'open'} value="open">Open</option>
                                                            <option selected={this.state.company_status === 'close'} value="close">Close</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-6">
                                    <div class="add-images-sec">
                                        <label>Image</label>
                                        <input type="file" name="image" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Radio</label>
                                         <input type="radio" class="radio-sec" name="type" class="" value="radio1" />  Radio 1
                                        <input type="radio" class="radio-sec" name="type" class="rediopadding" value="radio2" />  Radio 2
                                    </div>
                               </div>
                                <div class="col-md-6">
                                    <div class="form-group description-sec">
                                        <label >Description</label>
                                        <input type="text" class="form-control"  id="exampleInputEmail1" placeholder="Description"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group check-sec">
                                <label for="myCheck">Checkbox:</label> 
                                <input type="checkbox" id="myCheck" onclick="myFunction()" />Option 1
                               
                                </div>
                                </div> */}
                                            </div>
                                        </div>
                                        <div class="card-footer submit-btn">
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                            <button type="button" onClick={e => { this.cancelForm(e) }} class="btn btn-primary">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}

                {!this.state.add_new && !this.state.edit_mode && <> <section class="table-sec">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <div class="start-table-sec">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>Company Name</th>
                                                <th>Company Title</th>
                                                <th>Company Email</th>
                                                {/* <th>CEO First Name</th>
                                                <th>CEO Last Name</th> */}
                                                <th>Website</th>
                                                <th>Headquarter</th>
                                                <th>Logo</th>
                                                <th>Company Status</th>
                                                <th>Created By</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.allCompany.length > 0 && <>
                                                {this.state.allCompany.map((list, index) => {
                                                    return <tr>
                                                        <td>{(this.state.pagination_data.per_page * (this.state.pagination_data.current_page - 1)) + index + 1}</td>
                                                        <td>{list.company_name}</td>
                                                        <td>{list.company_title}</td>
                                                        <td>{list.company_email}</td>
                                                        {/* <td>{list.first_name}</td>
                                                        <td>{list.last_name}</td> */}
                                                        <td>{list.website}</td>
                                                        <td>{list.headqarter}</td>
                                                        <td><img width="20px;" height="20px" src={this.state.url + '/uploads/' + list.logo}></img></td>
                                                        <td>{list.company_status}</td>
                                                        <td>{list.created_by}</td>
                                                        <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                                                        <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editType(e, index, list._id) }}></i></a></td>
                                                    </tr>
                                                })}
                                            </>}

                                        </tbody>
                                        <Pagination data={this.state.pagination_data} getData={this.getCompany} />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}
            </div>
        </>

        );
    }
}
export default Company;
import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import ArabicHeader from "./ArabicHeader";
import ArabicFooter from "./ArabicFooter";
class ArabicHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA"
        }
    }

    componentDidMount() {
        this.checkLanguage();
    }
    checkLanguage() {
        this.setState({
           "language":sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "arabic"
        })
    }

    render() {
        return <>
            <ArabicHeader/>
            <section id="home">
            <div class="container">
            <div class="banner-text row">
               <div class="col-md-12 chanhe-position">
                  <div class="row">
               <div class="col-md-4 order1">
                     <div class="banner_mobile">
                        <img src="admin/dist/img/arabic-forum.png" alt=""/>
                     </div>
                  </div>
                  <div class="col-md-8 order2">
                     <div class="inner-banner-text text-position-ch">
                  
                       <h1>رواتب. مراجعات. وظائف <br></br>اعثر على ما يناسبك</h1> 
                        <p>نشر المراجعات الوظيفية على بعد ضغطة<br></br> زر. اشترك في بوابتنا وشارك خبراتك في العمل مع العالم بأسره.</p>
                        <div class="play-store-btn">
                           <a href="https://play.google.com/store/apps/details?id=com.app.kaziom" target="_blank"><img src="admin/dist/img/available-soon-apps-arabic.png" alt=""/></a>
                           <a href="https://apps.apple.com/us/app/kaziom/id1562348010" target="_blank"><img src="admin/dist/img/play_store_fr2.png" alt=""/></a>
                        </div>
                     </div>
                  </div>
                  </div>
               </div>
            </div>
         </div>
         </section>
            <section class="about-us" id="about_us">
         <div class="container scroll">
            <div class="row">
               <div class="col-md-12">
                  <div class="inner-aboutus text-about-sec-add">
                        <h2>نبذة عنا</h2>
                        <img src="admin/dist/img/line.png" alt=""></img>
                   <p>
                   نحن نساعد الناس على مشاركة آرائهم حول العمل الذي يقومون به. في عالم المراقبة الرقمية ، نتأكد من أنه يمكن<br></br>
                   نشر المراجعات بشكل مجهول لتجنب أي تعارضات. شعارنا بسيط ، كل فرد لديه وجهات نظر مختلفة حول نفس الوظيفة.<br></br>
                   وبالتالي ، فهم بحاجة إلى منصة لمشاركته بلا خوف وإعلام الناس بإيجابيات وسلبيات الشركة أو الملف الشخصي للوظيفة<br></br>
                   بدءًا من أسئلة المقابلة ، إلى الرواتب ، ونظام الترقيات ، والمزايا ، وثقافة العمل ، وأساليب الإدارة ؛ سنكون صوت الرجل<br></br>
                   .العادي الذي يكدح ليلاً ونهارًا. نطرح أسئلة محددة حتى تأتي المراجعات الأكثر صدقًا في مقدمة صفحاتنا<br></br>
                   في وظائف السلطنة ، نسمح أيضًا بإعلانات الوظائف وصور المكتب ومراجعات المزايا ومتوسط ​​الرواتب ومراجعات الشركة<br></br>
                   لمساعدة الطامحين للوظائف في الحصول على النوع الصحيح من المعلومات على الإنترنت. لا توجد مراجعات وتصنيفات<br></br>
                   مزيفة أو حتى حملات تشهير. على عكس أي مواقع عمل أخرى هنا ، ستحصل على جميع المعلومات من قبل أولئك الذين<br></br>
                   يعرفون الشركة أفضل من  أي شخص كالموظفين. مع وجود ملايين الشركات والموظفين حول العالم ، حان الوقت للحصول<br></br>
                   على جميع المعلومات قبل بدء حياتك المهنية.
                   </p>

                     <hr class="line"/>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="services" id="features_sec">
         <div class="container scroll">
            <div class="">
               <div class="col-md-12">
                  <div class="inner-aboutus">
                     <h3>المميزات</h3>
                     <img src="admin/dist/img/line.png" alt=""/>
                     <p>تحقق من جميع المعطيات حيث يمكنك مراجعة الشركة ومشاركة أفكارك. رأيك سيغير مستقبل الملايين.</p>
                  </div>
                  <div class="row ser-inner">
                     <div class="col-md-3">
                        <div class="main-blok-sec">
                           <div class="overlay-image">
                              <img src="admin/dist/img/shape.png" class="shape-img" alt=""/>
                              <div class="services-block">
                                 <div class="inner-ser-block text-position-change">
                                    <img src="admin/dist/img/company.png" alt=""/>
                                    <h3>الشركات</h3>
                                   <p>انشر حول إيجابيات وسلبيات الشركة التي تعمل بها,  ولا تنس ذكر الراتب.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-3">
                        <div class="main-blok-sec">
                           <div class="overlay-image">
                              <img src="admin/dist/img/shape.png" class="shape-img" alt=""/>
                              <div class="services-block">
                                 <div class="inner-ser-block text-position-change">
                                    <img src="admin/dist/img/job.png" alt=""/>
                                    <h3>الوظائف</h3>
                                    <p>مملة ؟ مجهدة ؟ ممتعة ؟ أخبرنا المزيد عن الوظيفة التي تقوم بها.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-3">
                        <div class="main-blok-sec">
                           <div class="overlay-image">
                              <img src="admin/dist/img/shape.png" class="shape-img" alt=""/>
                              <div class="services-block">
                                 <div class="inner-ser-block text-position-change">
                                    <img src="admin/dist/img/salary.png" alt=""/>
                                    <h3>الرواتب</h3>
                                    <p>اكتب حول راتبك في شركتك الحالية
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-3">
                        <div class="main-blok-sec">
                           <div class="overlay-image">
                              <img src="admin/dist/img/shape.png" class="shape-img" alt=""/>
                              <div class="services-block">
                                 <div class="inner-ser-block text-position-change">
                                    <img src="admin/dist/img/interview.png" alt=""/>
                                    <h3>المقابلات</h3>
                                    <p>شارك طبيعة المقابلات التي قمت بها
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="list-sec">
         <div class="container">
            <div class="row chanhe-position">
               
               <div class="col-md-7 order1">
                  <div class="text-right-side text-why-us-chnage">
                     <h3>لماذا نحن ؟ </h3>
                     <img src="admin/dist/img/line2.png" alt=""/>
                     <ul class="list-secinner">
                        <li><span>إبدأ مسيرتك المهنية مع أفضل الشركات</span></li>
                        <li><span>ستجد الوظيفة الأنسب لك معنا</span></li>
                        <li><span>تعرف على ما يجري في الشركات التي تحلم بها</span></li>
                        <li><span>تعرف على ما يقوله زملاؤك في العمل</span></li>
                     </ul>
                     <div class="play-btna-sec">
                     <a href="https://play.google.com/store/apps/details?id=com.app.kaziom" target="_blank"><img src="admin/dist/img/availables-soon-apps_arabic.png" alt=""/></a>
                     <a href="https://apps.apple.com/us/app/kaziom/id1562348010" target="_blank"><img src="admin/dist/img/play_store_fr.png" alt=""/></a> 
                  </div>
                  </div>
               </div>
               <div class="col-md-5 order2">
                  <div class="image-left">
                     <img src="admin/dist/img/home_arabic.png" alt=""/>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <section class="google-paly-sec">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
               </div>
               <div class="col-md-7">
                  <div class="play-btna-sec">
                     <a href="javascript:void(0)"><img src="admin/dist/img/google-play.png" alt=""/></a>
                     <a href="javascript:void(0)"><img src="admin/dist/img/play-store.png" alt=""/></a> 
                  </div>
               </div>
            </div>
         </div>
      </section> */}
      {/* <section class="testimonial-sec" id="testimonial_sec">
         <div class="container">
            <div class="">
               <div class="col-md-12">
                  <div class="inner-aboutus">
                     <h3>Testimonial</h3>   
                     <img src="admin/dist/img/line.png" alt=""/>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                     </p>
                  </div>
                  <div class="your-class">
                     <div class="col-md-4">
                        <div class="user-list-details">
                           <img src="admin/dist/img/client.png" alt=""/>
                           <p>Lorem Ipsum is simply dummy text of the printing and is typesetting industry.</p>
                           <h3>Amelia</h3>
                           <span>Sr. UI Designer</span>
                        </div>
                     </div>
                     <div class="col-md-4">
                        <div class="user-list-details">
                           <img src="admin/dist/img/client.png" alt=""/>
                           <p>Lorem Ipsum is simply dummy text of the printing and is typesetting industry.</p>
                           <h3>Amelia</h3>
                           <span>Sr. UI Designer</span>
                        </div>
                     </div>
                     <div class="col-md-4">
                        <div class="user-list-details">
                           <img src="admin/dist/img/client.png" alt=""/>
                           <p>Lorem Ipsum is simply dummy text of the printing and is typesetting industry.</p>
                           <h3>Amelia</h3>
                           <span>Sr. UI Designer</span>
                        </div>
                     </div>
                    
                  </div>
               </div>
            </div>
         </div>
      </section> */}
      {/* <section class="frequently-asked-quetions" id="faq_sec">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="inner-aboutus">
                     <h3>Frequently Asked Quetions</h3>
                     <img src="admin/dist/img/line2.png" alt=""/>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                     </p>
                  </div>
                  <div class="">
                     <div class="row">
                        <div class="col-md-9 mx-auto">
                           <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingOne">
                                    <h4 class="panel-title">
                                       <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.le VHS.</div>
                                 </div>
                              </div>
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingTwo">
                                    <h4 class="panel-title">
                                       <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</div>
                                 </div>
                              </div>
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingThree">
                                    <h4 class="panel-title">
                                       <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                 </div>
                              </div>
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingFour">
                                    <h4 class="panel-title">
                                       <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section> */}
      {/* <section class="blog-sec" id="blog_sec">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="inner-aboutus">
                     <h3>Blog</h3>
                     <img src="admin/dist/img/line.png" alt=""/>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                     </p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-4">
                  <div class="bolg-sec">
                     <div class="blog-imge">
                        <img src="admin/dist/img/blog-image.png" alt=""/>
                     </div>
                     <div class="blog-text">
                        <h4>Lorum</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                        <span><i class="fa fa-user" aria-hidden="true"></i> Amelia</span>
                        <span class="calender-sec"><i class="fa fa-calendar" aria-hidden="true"></i> 25 Dec-2020</span>
                     </div>
                  </div>
               </div>
               <div class="col-md-4">
                  <div class="bolg-sec">
                     <div class="blog-imge">
                        <img src="admin/dist/img/blog-image.png" alt=""/>
                     </div>
                     <div class="blog-text">
                        <h4>Lorum</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                        <span><i class="fa fa-user" aria-hidden="true"></i> Amelia</span>
                        <span class="calender-sec"><i class="fa fa-calendar" aria-hidden="true"></i> 25 Dec-2020</span>
                     </div>
                  </div>
               </div>
               <div class="col-md-4">
                  <div class="bolg-sec">
                     <div class="blog-imge">
                        <img src="admin/dist/img/blog-image.png" alt=""/>
                     </div>
                     <div class="blog-text">
                        <h4>Lorum</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                        <span><i class="fa fa-user" aria-hidden="true"></i> Amelia</span>
                        <span class="calender-sec"><i class="fa fa-calendar" aria-hidden="true"></i> 25 Dec-2020</span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="read-more-btn">
               <a href="javascript:void(0)">Read More</a>
            </div>
         </div>
      </section> */}
      <ArabicFooter/>
        </>;
    }
}

export default ArabicHome;

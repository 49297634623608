import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import ReactTextareaAutocomplete from 'react-textarea-autocomplete'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import { NotificationContainer, NotificationManager } from 'react-notifications';
class ForumDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.MIX_REACT_API_URL,
            show: false,
            search: '',
            latestTopics: [],
        }
        this.searchHandle = this.searchHandle.bind(this);
    }

    searchHandle(e) {
        this.setState({
            search: e.target.value,
        })
    }

    componentDidMount = (e) => {
        this.setState({
            "userDetail": JSON.parse(sessionStorage.getItem("userDetail"))
        })

        setTimeout(() => {
            this.setState({
                designation: this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
                show: true
            })
            console.log("sidebar", this.state.userDetail.image)
        }, 100);
        this.getLatestTopics()
    }

    getLatestTopics = () => {
        Axios.get(`${this.state.url}/api/get/topic`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res, 55)
            this.setState({
                latestTopics: res.data.data
            })
            console.log("resumes", this.state.latestTopics)
        });
    }
    render() {
        return (
            <>
                <NotificationContainer />
                <Header />
                <Navbar />
                <section class="sidebar_menu">
                    <div id="page-content-wrapper" class="top-job-page">
                        <div class="container-fluid">
                            <div class="col-md-12 pd0">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div className="row">
                                            <div class="col-md-3 forums">
                                                <div className="tabs_details mt-0">
                                                    <ul class="nav nav-tabs">
                                                        <li class="nav-item">
                                                            <a class="nav-link active" data-toggle="tab" href="#explore">Explore Topics</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" data-toggle="tab" href="#mytopic">My Topics</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" data-toggle="tab" href="#myanswer">My Answers</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9 tab-content">
                                                <div id="explore" class="tab-pane active">
                                                    <div class="desp-details forum_text">
                                                        <div class="img-pro">
                                                            <img src="/uploads/1608634769238season.jpg" alt="" />
                                                        </div>
                                                        <div class="name-details-time">
                                                            <h4>C## In A Nutshell</h4>
                                                            <p>Ankit Mehra <span class="dot-point">22h Ago</span></p>
                                                        </div>
                                                        <p class="des_forum">So, i've been using c## for a whole decade now, if you guys know how to break the boring feelin,
                                                            please let me know! ps: here's some memes of the day xoxo</p>
                                                    </div>
                                                    {/* <div className="new_forum">
                                                        <div className="trending">
                                                            <div className="img">
                                                                <img src="/uploads/1608634769238season.jpg" alt="post" />
                                                                <div className="img_text">
                                                                    C## In A Nutshell
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="description">
                                                            So, i've been using c## for a whole decade now, if you guys know how to break the boring feelin,
                                                            please let me know! ps: here's some memes of the day xoxo
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div id="mytopic" class="tab-pane">
                                                    My Topics
                                                </div>
                                                <div id="myanswer" class="tab-pane">
                                                    My Answers
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-md-none d-lg-block  col-lg-3">
                                        <div class="forum-sec">
                                            <h3>Latest Topics</h3>
                                            <Link to={`/user/forums`} activeClassName="active">View All</Link>
                                            {this.state.latestTopics && this.state.latestTopics.length > 0 && <>
                                                {this.state.latestTopics.map((cr, index) => {
                                                    return <div class="desp-details">
                                                        <div class="img-pro">
                                                            <Link to={`/user/forum/` + cr.id} activeClassName="active">
                                                                <img src='/uploads/1608634769238season.jpg' alt="" />
                                                            </Link>
                                                        </div>
                                                        <div class="name-details-time">
                                                            <h4>{cr.topic}</h4>
                                                            <p>
                                                                Ankit Mehra
                                                                {/* {cr.user.name} */}
                                                                <span class="dot-points pl-2">{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</span>
                                                            </p>
                                                        </div>
                                                        <p class="des_forum">{cr.description}</p>
                                                    </div>
                                                })}
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ForumDetail;   
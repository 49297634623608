import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import CKEditor from "react-ckeditor-component";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import ModernDatepicker from 'react-modern-datepicker';
import Jobform from "../Common/Jobform";
import * as moment from "moment";
import Pagination from "../Common/Pagination";
import { CSVLink } from "react-csv";
const regex = /(<([^>]+)>)/ig;
// const $ = window.jQuery;
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            first_name: "",
            last_name:"",
            company_email:"",
            url: process.env.MIX_REACT_API_URL,
            company_name:"",
            company_title:"",
            description:"",
            banner:"",
            logo:"",
            keyword:"",
            keyword1:"",
            industry_id:"",
            add_new:false,
            contact_number:"",
            show_back:false,
            size:"",
            upload_data:[],
            company_status:"",
            website:"",
            founded:"",
            headqarter:"",
            company_id: "",
            company_details:null,
            keyword1:"",
            show_fixed: false,
            allIndustry: [],
            allCompany: [],
            sugIndustry:[],
            old_password:"",
            new_password:"",
            confirm_password:"",
            edit_mode:false
           
         
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.updatepassword = this.updatepassword.bind(this);
        
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
    }

    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
     
   
        this.getCompany();
        this.getIndustry();
      
    }

    getIndustry = (e) => {
      Axios.get(`${this.state.url}/admin/get/industry`, {
          headers: {
              token: sessionStorage.getItem('_access')
          }
      }).then(res => {
          console.log(res)
          this.setState({
              allIndustry:res.data.data
          })
      });
  }
  searchIndustry = (e) => {
    Axios.post(`${this.state.url}/admin/search-industry` ,{ keyword: this.state.keyword1 }, {
        headers: {
            token: sessionStorage.getItem('_access')
        }
    }).then(res => {
        console.log(res)
        this.setState({
            sugIndustry: res.data.data
        })
      //  console.log("this.state.allDepartment", this.state.allDepartment);
    });
  }
  onSelect = (e) => {
        console.log("address", e);
        setTimeout(() => {
            var lastEl = e.value.terms.slice(-2)[0];
            this.setState({
                address: e.value.description,
                city: lastEl.value
            })
            console.log("asdfsdf", this.state.city)
            this.getLatLng(this.state.address)
        }, 100);

    }
    
    edit_mode = (e) => {
   
      this.setState({ edit_mode : true });
  };
  cancel_mode = (e) => {
   
    this.setState({ edit_mode : false });
};
  
    handleChange = address => {
        console.log("Adsdf")
        this.setState({ address });
    };
    handleInput(e) {
        console.log(e.target.name)
        // setTimeout(() => {
        

        this.setState({ [e.target.name]: e.target.value })

        if (e.target.name === "keyword1") {
          this.setState({
              keyword1: e.target.value
          })
          //console.log("message",this.state.keyword);
          this.searchIndustry();
      }
        
    }
    
    updatepassword = (e) => {
      e.preventDefault();
      
      if (this.state.old_password === "") {
          NotificationManager.error("error", "Please Enter Old Password.");
          return false;
      }
      if (this.state.new_password === "") {
          NotificationManager.error("error", "Please Enter New Password.");
          return false;
      }
      if (this.state.new_password !== this.state.confirm_password) {
          NotificationManager.error("error", "Please New Password And Confirm password Same.");
          return false;
      }
      
      const formData = new FormData();
      formData.append('old_password',this.state.old_password);
      formData.append('new_password',this.state.new_password);
      

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              'Authorization': "Bearer " + sessionStorage.getItem('_access')
          }
      };
     
          Axios.post(`${this.state.url}/api/update-company-password`, formData,config, {
                  headers: {
                      token: sessionStorage.getItem('_access')
                  }
              }).then(res => {
              console.log(res.data)
              if(res.data.status == true){
        
              
                  NotificationManager.success("success", res.data.message);
                  this.getCompany()
              }else{
                  NotificationManager.error("error", res.data.message);
              }
          })
    
  }
   onFormSubmit = (e) => {
      e.preventDefault();
      
      if (this.state.industry_id === "" || this.state.industry_id === null) {
          NotificationManager.error("error", "Please select Industry .");
          return true;
      }
      if (this.state.company_name === "" || this.state.company_name === null) {
          NotificationManager.error("error", "Please enter Company Name.");
          return true;
      }
      if (this.state.first_name === "" || this.state.first_name === null) {
          NotificationManager.error("error", "Please enter First Name.");
          return true;
      }
      if (this.state.last_name === "" || this.state.last_name === null) {
          NotificationManager.error("error", "Please enter Last Name.");
          return true;
      }
      if (this.state.website === "" || this.state.website === null) {
          NotificationManager.error("error", "Please enter Company Website.");
          return true;
      }
      if (this.state.size === "" || this.state.size === null) {
          NotificationManager.error("error", "Please enter Company Size.");
          return true;
      }
      if (this.state.headqarter === "" || this.state.headqarter === null) {
          NotificationManager.error("error", "Please enter Company Headquarter.");
          return true;
      }
      if (this.state.founded === "" || this.state.founded === null) {
          NotificationManager.error("error", "Please select founded.");
          return true;
      }
      if (this.state.company_title === "" || this.state.company_title === null) {
          NotificationManager.error("error", "Please enter Company Title.");
          return true;
      }
      if (this.state.company_email === "" || this.state.company_email === null) {
          NotificationManager.error("error", "Please enter Company Email.");
          return true;
      }
      if (this.state.contact_number === "" || this.state.contact_number === null) {
          NotificationManager.error("error", "Please enter Contact Number.");
          return true;
      }
      if (this.state.logo === "" || this.state.logo === null) {
          NotificationManager.error("error", "Please Choose company logo.");
          return true;
      }
      if (this.state.banner === "" || this.state.banner === null) {
          NotificationManager.error("error", "Please Choose company banner.");
          return true;
      }
      if (this.state.description === "" || this.state.description === null) {
          NotificationManager.error("error", "Please Enter Description.");
          return true;
      }
      if (this.state.company_status === "" || this.state.company_status === null) {
          NotificationManager.error("error", "Please Select Company Status.");
          return true;
      }
      const formData = new FormData();
      formData.append('first_name',this.state.first_name);
      formData.append('logo',this.state.logo);
      formData.append('banner',this.state.banner);
      formData.append('last_name',this.state.last_name);
      formData.append('company_name',this.state.company_name);
      formData.append('contact_number',this.state.contact_number);
      formData.append('company_title',this.state.company_title);
      formData.append('company_email',this.state.company_email);
      formData.append('website',this.state.website);
      formData.append('size',this.state.size);
      formData.append('founded',this.state.founded);
      formData.append('headqarter',this.state.headqarter);
      formData.append('industry_id',this.state.industry_id);
      formData.append('company_status',this.state.company_status);
      formData.append('status',this.state.status);
      formData.append('description',this.state.description);
    //  formData.append("id",this.state.id ? this.state.id : "");
      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              'Authorization': "Bearer " + sessionStorage.getItem('_access')
          }
      };
     
          Axios.post(`${this.state.url}/api/update-company-profile`, formData,config, {
                  headers: {
                      token: sessionStorage.getItem('_access')
                  }
              }).then(res => {
              console.log(res.data)
              if(res.data.status == true){
                sessionStorage.setItem("data",JSON.stringify(res.data.user_data))
                sessionStorage.setItem("comapny_data",JSON.stringify(res.data.data))
                  this.setState({
                      company_details:res.data.data,
                      add_new:false,
                      edit_mode:false,
                      show_back:false
                  })
                  NotificationManager.success("success", res.data.message);
                  this.getCompany()
              }else{
                  NotificationManager.error("error", res.data.message);
              }
          })
    
  }
    handleOnSubmit = (term) => {
        setTimeout(() => {
            this.setState({
                job_title: term
            })
        }, 100);

    }

    editLocation = (e) => {
        this.setState({
            edit_location:true
        })
    }
    
    getCompany = (e) => {
        Axios.get(`${this.state.url}/api/get/company/details`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
              
            this.setState({
                company_details: res.data.data,
                first_name:res.data.data.first_name,
                last_name:res.data.data.last_name,
                company_name:res.data.data.company_name,
                company_title:res.data.data.company_title,
                company_email:res.data.data.company_email,
                industry_id:res.data.data.industry_id,
                keyword1: res.data.data.industry ? res.data.data.industry.name : null,
                banner:res.data.data.banner,
                logo:res.data.data.logo,
                size:res.data.data.size,
                founded:res.data.data.founded,
                website:res.data.data.website,
                headqarter:res.data.data.headqarter,
                company_status:res.data.data.company_status,
                description:res.data.data.description,
                contact_number:res.data.data.contact_number,
                
                
            })
            console.log("this.state.allCompany", res.data.data);
        });
    }
    
    

   
  
 
    onChange(e) {
        if(e.target.name === 'image'){
            this.setState({
                "logo":e.target.files[0],
            });
        }else{
            this.setState({
                "banner":e.target.files[0],
            });
        }
    }
    onChange1(evt){
      
      console.log("onChange fired with event info: ", evt);
      var newContent = evt.editor.getData();
      console.log(newContent)
      this.setState({
          description:newContent
      }) 
    }
  
    handelDate = (date) => {
        this.setState({
            expiry_date: date
        })
    }
   
    handleInput1 = (e = null, data) => {
      this.setState({
          industry_id: data.id,
          keyword1:data.name,
          sugIndustry: []
      })
  }
  
    
    
    render() {
        const recentSearches = this.state.filterData;
        const placeholder = 'Search Job Type...';
        const job_type = "job_type"
        const inputPosition = 'center';
        return (<>
            <NotificationContainer />
            <Navbar page="Seller Jobs" />
            
            <div class="dashboard_content" >
   <Sidebar />
   <section class="home-section" > 
    <div class="home-content">
      <div class="company_page">
        <div class="company_name com_page">
          <div class="company_img">
            <div class="avatar-upload">
              {/* <div class="avatar-edit">
                <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" />
                <label for="imageUpload" ></label>
              </div> */}
              <div class="avatar-preview">
              {this.state.company_details && 
                <div id="imagePreview" style={{ backgroundImage: "url(" + this.state.url+"/uploads/"+this.state.logo + ")"}}  > 
</div>
               }
              {!this.state.company_details && <> 
                <div id="imagePreview" style={{ backgroundImage: "url(" + "../sellers/img/dummy-user.png" + ")"}}  > 
                </div>
              </> }
       

              </div>
            </div>
          </div>
          <div class="name_proccess">
            <div class="name_rating">
              <h2>{this.state.company_details ? this.state.company_details.company_name : null }  </h2>
              {/* <div class="rating">
                <input type="radio" name="rating" value="5" id="5" />
                <label for="5">☆</label>
                <input type="radio" name="rating" value="4" id="4" />
                <label for="4">☆</label>
                <input type="radio" name="rating" value="3" id="3" />
                <label for="3">☆</label>
                <input type="radio" name="rating" value="2" id="2" />
                <label for="2">☆</label>
                <input type="radio" name="rating" value="1" id="1" />
                <label for="1">☆</label>
              </div> */}
            </div>
            <h4>Profile Completion: <span>30%</span></h4>
            <div class="progress">
              <div class="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="company_details">
          <div class="row"> 
            <div class="col-md-9">
            {!this.state.edit_mode && <>
              
            <div class="row">
                <div class="col-md-12">
                  <div class="about_cmpny">
                    <div class="bg_white">
                      <div class="head_sec">
                        <h4>Overviews</h4>
                        <p>Basic information of the company</p>
                        <span class="edit_icon"><i class="fa fa-pencil" onClick={(e) => { this.edit_mode(e) }}  aria-hidden="true"></i> </span> </div>
                        <div class="form_inputs">
                         <form onSubmit={this.onFormSubmit}>
                          <div class="row">
                          <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>First Name</label>
                                 <span class="add_value">{this.state.first_name}</span>
                             </div>
                            </div> 
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Last Name</label>
                                <span class="add_value">{this.state.last_name}</span>
                             </div>
                            </div> 
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Title</label>
                                <span class="add_value">{this.state.company_title}</span>
                             </div>
                            </div>
                            
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Name</label>
                                <span class="add_value">{this.state.company_name}</span> 
                             </div>
                            </div>  
                           
                          
                           <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company E-mail</label>
                                <span class="add_value">{this.state.company_email}</span> 
                             </div>
                            </div>  
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Contact Number</label>
                                <span class="add_value">{this.state.contact_number}</span> 
                             </div>
                            </div>   
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Website</label>
                                <span class="add_value">{this.state.website}</span> 
                            </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Founded</label>
                                <span class="add_value">{this.state.founded}</span> 
                             
                            </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Headqarter</label>
                                <span class="add_value">{this.state.headqarter}</span> 
                            </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Size</label>
                                <span class="add_value">{this.state.size}</span> 
                              
                               
                              </div>
                            </div>
                            <div class="col-md-6">
                                                <div class="form-group form-outputs">
                                                    <label for="last_name">Industry</label>
                                                    <span class="add_value">{this.state.keyword1}</span> 
                              
                                                </div>
                                            </div>
                                <div class="col-md-6">
                                   <div class="form-group form-outputs">
                                      <label for="exampleInputPassword1">Company Status</label>
                                      <span class="add_value">{this.state.company_status}</span> 
                                     
                                </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group logogorm">
                                    <label for="company_title">Company Logo</label>
                                    {this.state.logo &&
                                        <img height="100px" style={{width: "100px"}} width="100px" src ={this.state.url+'/uploads/'+this.state.logo} alt=""></img>
                                    }
                                </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="company_title">Banner Image</label>
                                   {this.state.banner  &&
                                        <img src ={this.state.url+'/uploads/'+this.state.banner}  style={{width: "100px"}} class="bannerimage" alt=""></img>
                                    }
                                </div>
                                </div>
                          </div>
                          <div class="row">
                          <div class="col-md-12">
                           <div class="form-group form-outputs">
                              <label for="exampleInputPassword1">About the company</label>
                              {this.state.description && <> 
                                <span class="add_value"> {this.state.description.replace(regex, '')}</span> 
                              
                              </>}
                             
                           </div>
                        </div>
                         
                          </div>
                        
                          <div class="sub_btn">
                            {/* <button class="save_btn page-primary-btn">Save</button>
                            <a href="#" class="cel_btn">Cancel</a> */}
                             </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
               </> }
              {this.state.edit_mode && <>
              
              
              
              <div class="row">
                <div class="col-md-12">
                  <div class="about_cmpny">
                    <div class="bg_white">
                      <div class="head_sec">
                        <h4>Overviews</h4>
                        <p>Basic information of the company</p>
                        <span class="edit_icon"><i class="fa fa-times" onClick={(e) => { this.cancel_mode(e) }} aria-hidden="true"></i> </span> </div>
                        <div class="form_inputs">
                         <form onSubmit={this.onFormSubmit}>
                          <div class="row">
                          <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>First Name</label>
                               
                                <input type="text" class="form-control" placeholder="Enter First Name" value={this.state.first_name} onChange={(e) => { this.handleInput(e) }}  name="first_name" />
                              </div>
                            </div> 
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Last Name</label>
                               
                                <input type="text" class="form-control" placeholder="Enter Last Name" value={this.state.last_name} onChange={(e) => { this.handleInput(e) }}  name="last_name" />
                              </div>
                            </div> 
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Title</label>
                               
                                <input type="text" class="form-control" placeholder="" value={this.state.company_title} onChange={(e) => { this.handleInput(e) }}  name="company_title"  />
                              </div>
                            </div>
                            
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Name</label>
                               
                                <input type="text"  name="company_name" class="form-control" placeholder="" value={this.state.company_name} onChange={(e) => { this.handleInput(e) }}   />
                              </div>
                            </div>  
                           
                          
                           <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company E-mail</label>
                               
                                <input type="text"  name="company_email" class="form-control" placeholder="" value={this.state.company_email} onChange={(e) => { this.handleInput(e) }}   />
                              </div>
                            </div>  
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Contact Number</label>
                               
                                <input type="text"  name="contact_number" class="form-control" placeholder="Enter Contact Number" value={this.state.contact_number} onChange={(e) => { this.handleInput(e) }}   />
                              </div>
                            </div>   
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Website</label>
                               
                                <input type="text" class="form-control" placeholder="" value={this.state.website} name="website" onChange={e => { this.handleInput(e) }} />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Founded</label>
                               
                                    <select class="custom-select form-control" value={this.state.founded} onChange={(e) => { this.handleInput(e) }} name="founded">
                                    <option>- Year -</option>
                                    <option selected={this.state.founded === '2021'} value="2021">2021</option>
                                    <option selected={this.state.founded === '2020'} value="2020">2020</option>
                                    <option selected={this.state.founded === '2019'} value="2019">2019</option>
                                    <option selected={this.state.founded === '2018'} value="2018">2018</option>
                                    <option selected={this.state.founded === '2017'} value="2017">2017</option>
                                    <option selected={this.state.founded === '2016'} value="2016">2016</option>
                                    <option selected={this.state.founded === '2015'} value="2015">2015</option>
                                    <option selected={this.state.founded === '2014'} value="2014">2014</option>
                                    <option selected={this.state.founded === '2013'} value="2013">2013</option>
                                    <option selected={this.state.founded === '2012'} value="2012">2012</option>
                                    <option selected={this.state.founded === '2011'} value="2011">2011</option>
                                    <option selected={this.state.founded === '2010'} value="2010">2010</option>
                                    <option selected={this.state.founded === '2009'} value="2009">2009</option>
                                    <option selected={this.state.founded === '2008'} value="2008">2008</option>
                                    <option selected={this.state.founded === '2007'} value="2007">2007</option>
                                    <option selected={this.state.founded === '2006'} value="2006">2006</option>
                                    <option selected={this.state.founded === '2005'} value="2005">2005</option>
                                    <option selected={this.state.founded === '2004'} value="2004">2004</option>
                                    <option selected={this.state.founded === '2003'} value="2003">2003</option>
                                    <option selected={this.state.founded === '2002'} value="2002">2002</option>
                                    <option selected={this.state.founded === '2001'} value="2001">2001</option>
                                    <option selected={this.state.founded === '2000'} value="2000">2000</option>
                                    <option selected={this.state.founded === '1999'} value="1999">1999</option>
                                    <option selected={this.state.founded === '1998'} value="1998">1998</option>
                                    <option selected={this.state.founded === '1997'} value="1997">1997</option>
                                    <option selected={this.state.founded === '1996'} value="1996">1996</option>
                                    <option selected={this.state.founded === '1995'} value="1995">1995</option>
                                    <option selected={this.state.founded === '1994'} value="1994">1994</option>
                                    <option selected={this.state.founded === '1993'} value="1993">1993</option>
                                    <option selected={this.state.founded === '1992'} value="1992">1992</option>
                                    <option selected={this.state.founded === '1991'} value="1991">1991</option>
                                    <option selected={this.state.founded === '1990'} value="1990">1990</option>
                                    <option selected={this.state.founded === '1989'} value="1989">1989</option>
                                    <option selected={this.state.founded === '1988'} value="1988">1988</option>
                                    <option selected={this.state.founded === '1987'} value="1987">1987</option>
                                    <option selected={this.state.founded === '1986'} value="1986">1986</option>
                                    <option selected={this.state.founded === '1985'} value="1985">1985</option>
                                    <option selected={this.state.founded === '1984'} value="1984">1984</option>
                                    <option selected={this.state.founded === '1983'} value="1983">1983</option>
                                    <option selected={this.state.founded === '1982'} value="1982">1982</option>
                                    <option selected={this.state.founded === '1981'} value="1981">1981</option>
                                    <option selected={this.state.founded === '1980'} value="1980">1980</option>
                                    <option selected={this.state.founded === '1979'} value="1979">1979</option>
                                    <option selected={this.state.founded === '1978'} value="1978">1978</option>
                                    <option selected={this.state.founded === '1977'} value="1977">1977</option>
                                    <option selected={this.state.founded === '1976'} value="1976">1976</option>
                                    <option selected={this.state.founded === '1975'} value="1975">1975</option>
                                    <option selected={this.state.founded === '1974'} value="1974">1974</option>
                                    <option selected={this.state.founded === '1973'} value="1973">1973</option>
                                    <option selected={this.state.founded === '1972'} value="1972">1972</option>
                                    <option selected={this.state.founded === '1971'} value="1971">1971</option>
                                    <option selected={this.state.founded === '1970'} value="1970">1970</option>
                                    <option selected={this.state.founded === '1969'} value="1969">1969</option>
                                    <option selected={this.state.founded === '1968'} value="1968">1968</option>
                                    <option selected={this.state.founded === '1967'} value="1967">1967</option>
                                    <option selected={this.state.founded === '1966'} value="1966">1966</option>
                                    <option selected={this.state.founded === '1965'} value="1965">1965</option>
                                    <option selected={this.state.founded === '1964'} value="1964">1964</option>
                                    <option selected={this.state.founded === '1963'} value="1963">1963</option>
                                    <option selected={this.state.founded === '1962'} value="1962">1962</option>
                                    <option selected={this.state.founded === '1961'} value="1961">1961</option>
                                    <option selected={this.state.founded === '1960'} value="1960">1960</option>
                                    <option selected={this.state.founded === '1959'} value="1959">1959</option>
                                    <option selected={this.state.founded === '1958'} value="1958">1958</option>
                                    <option selected={this.state.founded === '1957'} value="1957">1957</option>
                                    <option selected={this.state.founded === '1956'} value="1956">1956</option>
                                    <option selected={this.state.founded === '1955'} value="1955">1955</option>
                                    <option selected={this.state.founded === '1954'} value="1954">1954</option>
                                    <option selected={this.state.founded === '1953'} value="1953">1953</option>
                                    <option selected={this.state.founded === '1952'} value="1952">1952</option>
                                    <option selected={this.state.founded === '1951'} value="1951">1951</option>
                                    <option selected={this.state.founded === '1950'} value="1950">1950</option>
                                    <option selected={this.state.founded === '1949'} value="1949">1949</option>
                                    <option selected={this.state.founded === '1948'} value="1948">1948</option>
                                    <option selected={this.state.founded === '1947'} value="1947">1947</option>
                                    <option selected={this.state.founded === '1946'} value="1946">1946</option>
                                    <option selected={this.state.founded === '1945'} value="1945">1945</option>
                                    <option selected={this.state.founded === '1944'} value="1944">1944</option>
                                    <option selected={this.state.founded === '1943'} value="1943">1943</option>
                                    <option selected={this.state.founded === '1942'} value="1942">1942</option>
                                    <option selected={this.state.founded === '1941'} value="1941">1941</option>
                                    <option selected={this.state.founded === '1940'} value="1940">1940</option>
                                    <option selected={this.state.founded === '1939'} value="1939">1939</option>
                                    <option selected={this.state.founded === '1938'} value="1938">1938</option>
                                    <option selected={this.state.founded === '1937'} value="1937">1937</option>
                                    <option selected={this.state.founded === '1936'} value="1936">1936</option>
                                    <option selected={this.state.founded === '1935'} value="1935">1935</option>
                                    <option selected={this.state.founded === '1934'} value="1934">1934</option>
                                    <option selected={this.state.founded === '1933'} value="1933">1933</option>
                                    <option selected={this.state.founded === '1932'} value="1932">1932</option>
                                    <option selected={this.state.founded === '1931'} value="1931">1931</option>
                                    <option selected={this.state.founded === '1930'} value="1930">1930</option>
                                    </select>
                            </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Headqarter</label>
                                
                                <input type="text" class="form-control" placeholder="" value={this.state.headqarter} onChange={(e) => { this.handleInput(e) }}  name="headqarter" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Company Size</label>
                            
                                <select class="custom-select form-control" value={this.state.size} onChange={(e) => { this.handleInput(e) }} name="size">
                                        <option value="">Select Size</option>
                                            <option selected={this.state.size === '0-50'} value="0-50">0-50</option>
                                            <option selected={this.state.size === '51-100'} value="51-100">51-100</option>
                                            <option selected={this.state.size === '101-150'} value="101-150">101-150</option>
                                            <option selected={this.state.size === '151-250'} value="151-250">151-250</option>
                                            <option selected={this.state.size === '251-500'} value="251-500">251-500</option>
                                            <option selected={this.state.size === '501-1000'} value="501-1000">501-1000</option>
                                            <option selected={this.state.size === '1001-5000'} value="1001-5000">1001-5000</option>
                                            <option selected={this.state.size === '5001-10000'} value="5001-10000">5001-10000</option>
                                        </select>
                               
                              </div>
                            </div>
                            <div class="col-md-6">
                                                <div class="form-group form-outputs">
                                                    <label for="last_name">Industry</label>

                                                    <input value={this.state.keyword1} autoComplete="off" className="custom-select form-control" placeholder="Search Industry" name="keyword1" onChange={(e) => { this.handleInput(e) }} />
                                                      

                                                        {this.state.sugIndustry.length > 0 && <>
                                                            {this.state.sugIndustry.map((temp, key) => {
                                                                  
                                                            
                                                                return  <option key={key} onClick={(e) => { this.handleInput1(e, temp) }} >{temp.name}</option>
                                                            })
                                                            }
                                                          
                                                            </>}
                                                      
                                                
                                                </div>
                                            </div>
                                <div class="col-md-6">
                                   <div class="form-group form-outputs">
                                      <label for="exampleInputPassword1">Company Status</label>
                                       <select class="custom-select form-control" value={this.state.company_status} onChange={(e) => { this.handleInput(e) }} name="company_status">
                                            <option value="">Select Company Status</option>
                                            <option selected={this.state.company_status === 'open'} value="open">Open</option>
                                            <option selected={this.state.company_status === 'close'} value="close">Close</option>
                                        </select>
                                </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group logogorm">
                                    <label for="company_title">Company Logo</label>
                                    <input type="file" name="image" onChange= {this.onChange} class="form-control"></input>
                                    {this.state.logo &&
                                        <img height="30px" style={{width: "33px"}} width="30px" src ={this.state.url+'/uploads/'+this.state.logo} alt=""></img>
                                    }
                                </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="company_title">Banner Image</label>
                                    <input type="file" name="banner" onChange= {this.onChange} class="form-control"></input>
                                    {this.state.banner  &&
                                        <img src ={this.state.url+'/uploads/'+this.state.banner}  style={{width: "33px"}} class="bannerimage" alt=""></img>
                                    }
                                </div>
                                </div>
                          </div>
                          <div class="row">
                          <div class="col-md-12">
                           <div class="form-group form-outputs">
                              <label for="exampleInputPassword1">About the company</label>
                              <CKEditor
                              activeClass="p10"
                              content={this.state.description}
                              events={{
                              "blur": this.onBlur,
                              "afterPaste": this.afterPaste,
                              "change": this.onChange1
                              }}
                              />
                           </div>
                        </div>
                         
                          </div>
                        
                          <div class="sub_btn">
                            <button class="save_btn page-primary-btn">Save</button>
                            <a href="#" class="cancel_btn page-primary-btn">Cancel</a> </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="about_cmpny">
                    <div class="bg_white">
                      <div class="head_sec">
                     
                        <p>Update Password</p>
                        <span class="edit_icon"><i class="fa fa-pencil" aria-hidden="true"></i> </span> </div>
                        <div class="form_inputs">
                         <form onSubmit={this.updatepassword}>
                          <div class="row">
                          <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Old Password</label>
                               
                                <input type="password" class="form-control" placeholder="Enter old password" value={this.state.old_password} onChange={(e) => { this.handleInput(e) }}  name="old_password" />
                              </div>
                            </div> 
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>New Password</label>
                               
                                <input type="password" class="form-control" placeholder="Enter new password" value={this.state.new_password} onChange={(e) => { this.handleInput(e) }}  name="new_password" />
                              </div>
                            </div> 
                            <div class="col-md-6">
                              <div class="form-group form-outputs">
                                <label>Confirm Password</label>
                               
                                <input type="password" class="form-control" placeholder="Enter confirm password" value={this.state.confirm_password} onChange={(e) => { this.handleInput(e) }}  name="confirm_password"  />
                              </div>
                            </div>
                            
                            
                     
                           
                          </div>
                         
                        
                          <div class="sub_btn">
                            <button class="save_btn page-primary-btn">Save</button>
              </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              </>
              
              
            }
            </div>
            <div class="col-md-3"> </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

       </>

        );
    }
}
export default Profile;
import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import ReactTextareaAutocomplete from 'react-textarea-autocomplete'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { ReactTinyLink } from 'react-tiny-link'
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Autocomplete from "react-google-autocomplete";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReactStars from "react-rating-stars-component";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Slider from "react-slick";

class Dashboard extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         url: process.env.MIX_REACT_API_URL,
         show_modal: false,
         show_modal_project: false,
         show_modal_salary: false,
         show_modal_review: false,
         show_modal_interview: false,
         show_modal_topic: false,
         show_modal_job: false,
         showComment: false,
         current: false,
         former: false,
         yearly: false,
         monthly: false,
         hourly: false,
         message: "AAAA",
         image: "",
         topic_id: "",
         apply_job_id: "",
         textarea: "",
         project_text: "",
         userDetail: [],
         allHastag: [],
         CommentData: [],
         TopicPosts: [],
         SinglePostData: [],
         JobPosts: [],
         options: [],
         CompanyInfo: "",
         Has: [],
         skills: [],
         obj: [],
         people: "",
         previewUrl: "",
         HasTagValue: [],
         JobTopic: [],
         Topic: [],
         allCategory: [],
         allSkill: [],
         alldatatext: [],
         step: 1,
         job_type_id: "",
         project_price: "",
         project_title: "",
         designation: "",
         min_price: "",
         location: "",
         budget: "",
         level: "",
         max_price: "",
         category_id: "",
         topicName: "",
         skill_id: "",
         job_title_id: "",
         company_id: "",
         allHomeData: [],
         allJobs: [],
         allCompany: [],
         CompanyName: [],
         salaryStep: 1,
         reviewStep: 1,
         topicStep: 1,
         interviewStep: 1,
         JobTitle: [],
         Title: [],
         salary: "",
         salary_type: "",
         employer_type: "",
         employment_status: "",
         experience: "",
         JobType: [],
         Type: [],
         rating: "",
         reviewTitle: "",
         allResumes: [],
         pros: "",
         cons: "",
         interview_outcome: "",
         type: "",
         description: "",
         topic_description: "",
         topic_title: "",
         topic_image: "",
         comment: "",
         imgUrl: "",
         resume: "",
         name: "",
         resume_id: "",
         phone: "",
         email: "",
         idcomment: 0,
         file: [null],
         fileObj: [],
         fileArray: [],
         imagesfile: [],
         formValues: [{ question: "", answer: "" }],
         language: sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "english"
      }
      this.handleRequestOptions = this.handleRequestOptions.bind(this);

      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.onFormSubmitComment = this.onFormSubmitComment.bind(this);
      this.onFormSubmitJob = this.onFormSubmitJob.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onChangeJob = this.onChangeJob.bind(this);
      this.commentdata = this.commentdata.bind(this);
   }

   componentDidMount() {
      this.setState({
         "userDetail": JSON.parse(sessionStorage.getItem("userDetail"))
      })
      setTimeout(() => {
         this.setState({
            designation: this.state.userDetail.designation ? this.state.userDetail.designation.name : ""
         })
         console.log("this.state.userDetail", this.state.url)
      }, 100);
      this.getHasTag()
      this.getCategory()
      this.getHomeData();
      this.getAllJobs()
      this.getAllCompany()
      this.getTitle()
      this.getJobType()
      this.getTopic()
      this.getPosts()
   }

   commentdata(index) {
      this.setState({
         idcomment: index
      })
      this.setState({
         showComment: !this.state.showComment,
      });
   }

   onFormSubmitJob = (e) => {
      e.preventDefault();
      if (this.state.name === "") {
         NotificationManager.error("error", "Please enter name.");
         return false;
      }
      if (this.state.email === "") {
         NotificationManager.error("error", "Please enter email.");
         return false;
      }
      if (this.state.phone === "") {
         NotificationManager.error("error", "Please enter phone.");
         return false;
      }
      if (this.state.resume_id) {
         this.applyNewJob(this.state.resume_id, this.state.name, this.state.email, this.state.phone);
      } else {
         if (this.state.resume === "") {
            NotificationManager.error("error", "Please choose image.");
            return false;
         }
         const formData = new FormData();
         formData.append('name', this.state.name);
         formData.append('resume', this.state.resume);
         const config = {
            headers: {
               'content-type': 'multipart/form-data',
               "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
         };
         Axios.post(`${this.state.url}/api/upload-resume`, formData, config, {
            // headers: {
            //    "Authorization": "Bearer "+sessionStorage.getItem('_access')
            //  }
         }).then(res => {
            this.applyNewJob(res.data.resume.id, this.state.name, this.state.email, this.state.phone);
         });
      }

   }


   applyNewJob = (resume_id, name, email, phone) => {
      var data = {
         resume_id: resume_id,
         name: name,
         email: email,
         phone: phone,
         job_id: this.state.apply_job_id
      }
      // console.log(data); return false;
      Axios.post(`${this.state.url}/api/apply-job`, data, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         NotificationManager.success("success", "Job Applied Successfully");
         $('.type_def_chk').prop("checked", false);
         this.setState({
            name: "",
            resume: "",
            imgUrl: "",
            image: "",
            apply_job_id: "",
            resume_id: "",
            aa: "",
            show_modal_job: false

         })

      });
   }

   getResume = () => {
      Axios.get(`${this.state.url}/api/get-resumes`, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allResumes: res.data.resume
         })
         console.log("resumes", this.state.allResumes)
      });
   }
   handleInput(e) {
      // if (this.state.current || this.state.former || this.state.yearly || this.state.monthly || this.state.hourly) {
      this.setState({ [e.target.name]: e.target.value }, console.log(e.target.name, e.target.value))
      // } else {
      //    this.setState({ [e.target.name]: '' })
      // }
      console.log(
         this.state.current, 'c',
         this.state.former, 'f',
         this.state.monthly, 'm',
         this.state.hourly, 'h',
         this.state.yearly, 'y',
         this.state.salary_type, 's',
         this.state.employer_type, 'e',
         542
      );
   }


   addFormFields() {
      for (let k of this.state.formValues) {
         if (k.question == "") {
            NotificationManager.error("error", "Please enter question.");
            return false;
         }
      }
      this.setState(({
         formValues: [...this.state.formValues, { question: "", answer: "" }]
      }))

      console.log("this.staet.formvalue", this.state.formValues)
   }

   removeFormFields(i) {
      let formValues = this.state.formValues;
      formValues.splice(i, 1);
      this.setState({ formValues });
   }


   handleChange(i, e) {
      let formValues = this.state.formValues;
      formValues[i][e.target.name] = e.target.value;
      this.setState({ formValues });
   }



   getJobType = (page = 1) => {
      var dd = [];
      Axios.post(`${this.state.url}/api/type`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            JobType: res.data.data,

         })
         if (this.state.JobType) {
            for (let k of this.state.JobType) {
               dd.push({ "id": k.id, "name": k.name });
            }
            this.setState({
               Type: dd
            })
         }
      });
   }

   ApplyJob = (e, id) => {
      e.preventDefault()
      var aa = []
      aa.push(id)
      this.getResume()
      this.setState({
         apply_job_id: aa,
         show_modal_job: true
      })
   }

   getTitle = (page = 1) => {
      var dd = [];
      Axios.post(`${this.state.url}/api/title`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            JobTitle: res.data.data,

         })
         if (this.state.JobTitle) {
            for (let k of this.state.JobTitle) {
               dd.push({ "id": k.id, "name": k.name });
            }
            this.setState({
               Title: dd
            })
         }
         console.log("CompanyName", this.state.Title)
      });
   }


   getTopic = (page = 1) => {
      var dd = [];
      Axios.get(`${this.state.url}/api/get/topic`, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            JobTopic: res.data.data,

         })
         if (this.state.JobTopic) {
            for (let k of this.state.JobTopic) {
               dd.push({ "id": k.id, "name": k.topic, "image": k.image, "topic": k.topic });
            }
            this.setState({
               Topic: dd
            })
         }
         console.log("JobTopic", this.state.JobTopic)
      });
   }

   getPosts = (page = 1) => {
      var dd = [];
      Axios.post(`${this.state.url}/api/get/posts`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         setTimeout(() => {
            this.setState({
               JobPosts: res.data.data.posts.data,
            })
            console.log("JobPosts1212", this.state.JobPosts)
         }, 200);

      });
   }



   getAllCompany = (page = 1) => {
      var dd = [];
      Axios.post(`${this.state.url}/api/get/all/company`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allCompany: res.data.data,
         })
         if (this.state.allCompany) {
            for (let k of this.state.allCompany) {
               dd.push({ "id": k.id, "name": k.company_name });
            }
            this.setState({
               CompanyName: dd
            })
         }
         console.log("CompanyName", this.state.CompanyName)
      });
   }


   getAllJobs = (page = 1) => {
      Axios.post(`${this.state.url}/api/get/all/job`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allJobs: res.data.data,
         })
         console.log("homedata", this.state.allJobs)
      });
   }

   LikeForumPost = (e, id) => {
      e.preventDefault()
      Axios.post(`${this.state.url}/api/like/post`, { post_id: id }, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         NotificationManager.success("success", "Post Like Successfully");
      });
   }

   getHomeData = (page = 1) => {
      Axios.get(`${this.state.url}/api/new/home`, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allHomeData: res.data.data,
            alldatatext: res.data.data.map((item) => { return item.text }),
         })
         console.log("homedata", this.state.allHomeData, 440)
      });
   }

   handleInput(e) {
      if (e.target.name === 'category_id') {
         this.getSkill(e.target.value)
      }
      this.setState({ [e.target.name]: e.target.value })
   }

   handleCheckboxChange = (event) => {
      var dayArr = [...this.state.skills];
      const value = parseInt(event.target.value)
      const index = dayArr.findIndex(day => day === value);
      if (index > -1) {
         dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
      } else {
         dayArr.push(value);
      }
      this.setState({ skills: dayArr });
      setTimeout(() => {
         console.log(this.state.skills)
      }, 100);
   }


   handleCheckboxChangeJob = (event) => {
      var dayArr = [...this.state.apply_job_id];
      const value = parseInt(event.target.value)
      const index = dayArr.findIndex(day => day === value);
      if (index > -1) {
         dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
      } else {
         dayArr.push(value);
      }
      this.setState({ apply_job_id: dayArr });
      setTimeout(() => {
         console.log(this.state.apply_job_id)
      }, 100);
   }

   AddSalary = () => {
      if (this.state.salary == "") {
         NotificationManager.error("error", "Please enter salary.");
         return false;
      }
      if (this.state.salary_type == "") {
         NotificationManager.error("error", "Please choose salary type.");
         return false;
      }
      if (this.state.job_title_id == "") {
         NotificationManager.error("error", "Please choose job title.");
         return false;
      }
      if (this.state.location == "") {
         NotificationManager.error("error", "Please enter location.");
         return false;
      }
      if (this.state.employer_type == "") {
         NotificationManager.error("error", "Please choose employer type.");
         return false;
      }
      if (this.state.job_type_id == "") {
         NotificationManager.error("error", "Please choose job type.");
         return false;
      }

      var data = {
         salary: this.state.salary,
         salary_type: this.state.salary_type,
         job_title_id: this.state.job_title_id,
         location: this.state.location,
         employer_type: this.state.employer_type,
         job_type_id: this.state.job_type_id,
         experience: this.state.experience,
         company_id: this.state.company_id,
      }

      Axios.post(`${this.state.url}/api/company/addSalary`, data, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.getHomeData();
         console.log(res, 542);
         this.setState({
            salaryStep: 1,
            salary: "",
            salary_type: "",
            job_title_id: "",
            location: "",
            employer_type: "",
            job_type_id: "",
            experience: "",
            company_id: "",
            show_modal_salary: false,
            current: false,
            former: false,
            yearly: false,
            monthly: false,
            hourly: false,
         })
         NotificationManager.success("success", "Salary Added Successfully");
      });
   }




   AddReview = () => {
      if (this.state.rating == "") {
         NotificationManager.error("error", "Please choose rating.");
         return false;
      }

      if (this.state.job_title_id == "") {
         NotificationManager.error("error", "Please choose job title.");
         return false;
      }

      if (this.state.job_type_id == "") {
         NotificationManager.error("error", "Please choose job type.");
         return false;
      }

      if (this.state.employer_type == "") {
         NotificationManager.error("error", "Please choose employer type.");
         return false;
      }

      if (this.state.reviewTitle == "") {
         NotificationManager.error("error", "Please enter review title.");
         return false;
      }

      if (this.state.pros == "") {
         NotificationManager.error("error", "Please enter pros.");
         return false;
      }

      if (this.state.cons == "") {
         NotificationManager.error("error", "Please enter cons.");
         return false;
      }
      var data = {
         rating: this.state.rating,
         job_title_id: this.state.job_title_id,
         employer_type: this.state.employer_type,
         title: this.state.reviewTitle,
         job_type_id: this.state.job_type_id,
         cons: this.state.cons,
         pros: this.state.pros,
         company_id: this.state.company_id,
      }
      Axios.post(`${this.state.url}/api/company/rating`, data, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.getHomeData();
         console.log(res)
         this.setState({
            reviewStep: 1,
            rating: "",
            pros: "",
            job_title_id: "",
            title: "",
            employer_type: "",
            job_type_id: "",
            cons: "",
            company_id: "",
            show_modal_review: false,
            current: false,
            former: false,
         }, console.log(res, 617))
         NotificationManager.success("success", "Review Added Successfully");
      });
   }



   AddInterview = () => {
      if (this.state.type == "") {
         NotificationManager.error("error", "Please choose type.");
         return false;
      }

      if (this.state.job_title_id == "") {
         NotificationManager.error("error", "Please choose job title.");
         return false;
      }

      if (this.state.description == "") {
         NotificationManager.error("error", "Please enter hiring process.");
         return false;
      }

      if (this.state.interview_outcome == "") {
         NotificationManager.error("error", "Please enter interview outcome.");
         return false;
      }

      if (this.state.formValues[0].question == "") {
         NotificationManager.error("error", "Please enter interview question.");
         return false;
      }

      var data = {
         type: this.state.type,
         job_title_id: this.state.job_title_id,
         description: this.state.description,
         interview_outcome: this.state.interview_outcome,
         question: this.state.formValues,
         company_id: this.state.company_id,
      }

      Axios.post(`${this.state.url}/api/company/addInterview`, data, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.getHomeData();
         console.log(res)
         this.setState({
            interviewStep: 1,
            type: "",
            description: "",
            interview_outcome: "",
            formValues: [{ question: "", answer: "" }],
            company_id: "",
            show_modal_interview: false
         })
         NotificationManager.success("success", "Review Added Successfully");
      });
   }

   NextStep(step) {
      if (this.state.step == 1) {
         if (this.state.project_title === "") {
            NotificationManager.error("error", "Please enter project title.");
            return false;
         }
         if (this.state.category_id === "") {
            NotificationManager.error("error", "Please choose category.");
            return false;
         }
         if (this.state.skills.length == 0) {
            NotificationManager.error("error", "Please choose skills.");
            return false;
         }
      }
      if (this.state.step == 2) {
         if (this.state.level === "") {
            NotificationManager.error("error", "Please choose level of experience.");
            return false;
         }

         if (this.state.budget === "") {
            NotificationManager.error("error", "Please choose project budget type.");
            return false;
         }
         if (this.state.budget === "hourly_based") {
            if (this.state.min_price == "") {
               NotificationManager.error("error", "Please enter minimum price.");
               return false;
            }
            if (this.state.max_price == "") {
               NotificationManager.error("error", "Please enter maximum price.");
               return false;
            }
         }

         if (this.state.budget === "project_based") {
            if (this.state.project_price == "") {
               NotificationManager.error("error", "Please choose project price.");
               return false;
            }
         }
      }
      if (this.state.step == 3) {
         if (this.state.project_text == "") {
            NotificationManager.error("error", "Please enter project Description.");
            return false;
         }
      }
      this.setState({
         step: this.state.step + 1
      })
   }

   BackStep(step) {
      this.setState({
         step: this.state.step - 1,
         budget: "",
         min_price: "",
         max_price: "",
         project_price: ""
      })
   }

   PostProject = () => {
      if (this.state.project_text == "") {
         NotificationManager.error("error", "Please enter project Description.");
         return false;
      }
      var data = {
         project_price: this.state.project_price,
         min_price: this.state.min_price,
         max_price: this.state.max_price,
         budget: this.state.budget,
         project_title: this.state.project_title,
         category_id: this.state.category_id,
         skill_id: this.state.skills,
         hastag: this.state.Has,
         description: this.state.project_text,
         level: this.state.level
      }
      Axios.post(`${this.state.url}/api/project/add`, data, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.getHomeData();
         console.log(res)
         this.setState({
            step: 1,
            level: "",
            hastag: [],
            image: "",
            url: "",
            postData: "",
            show_modal_project: false,
            min_price: "",
            max_price: "",
            description: "",
            skill_id: "",
            skills: [],
            category_id: "",
            budget: "",
            project_price: "",
            project_title: ""
         })
         NotificationManager.success("success", "Project Created Successfully");
      })
         .catch((e) => console.log(e, 777));
   }

   getCategory = (page = 1) => {
      Axios.get(`${this.state.url}/get/category?page=${page}`, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allCategory: res.data.data.data,

         })
         console.log("dddd", this.state.allCategory)
      });
   }

   getSkill = (category_id) => {
      var data = {
         category_id: category_id
      }
      Axios.post(`${this.state.url}/api/get/skill`, data, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allSkill: res.data.data,

         })
         console.log("dddd", this.state.allSkill)
      });
   }

   getHasTag = () => {
      Axios.get(`${this.state.url}/api/hastag`, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         var dd = [];
         if (res.data.data.length > 0) {
            this.setState({
               allHastag: res.data.data,
            })
            for (let k of this.state.allHastag) {
               dd.push(k.hastag);
            }
            this.setState({
               options: dd
            })
         }

         console.log("this.state.allTitle", dd);
      });
   }

   changeLanguage = (e, language) => {
      this.setState({
         "language": language
      })
      sessionStorage.setItem("language", language)
      window.location.reload()
   }

   PostModal = (e) => {
      if (e) e.preventDefault();
      this.setState({
         show_modal: true
      })
   }
   showModal = e => {
      this.setState({
         show_modal: false
      })
   }


   ProjectModal = (e) => {
      if (e) e.preventDefault();
      this.setState({
         show_modal_project: true
      })
   }
   showModalProject = e => {
      this.setState({
         show_modal_project: false
      })
   }


   SalaryModal = (e) => {
      if (e) e.preventDefault();
      this.setState({
         show_modal_salary: true
      })
   }
   showModalSalary = e => {
      this.setState({
         show_modal_salary: false,
         salaryStep: 1,
         current: false,
         former: false,
         yearly: false,
         monthly: false,
         hourly: false,
      })
   }

   ReviewModal = (e) => {
      if (e) e.preventDefault();
      this.setState({
         show_modal_review: true
      })
   }
   showModalReview = e => {
      this.setState({
         show_modal_review: false,
         reviewStep: 1,
         current: false,
         former: false
      })
   }

   InterviewModal = (e) => {
      if (e) e.preventDefault();
      this.setState({
         show_modal_interview: true
      })
   }
   showModalInterview = e => {
      this.setState({
         show_modal_interview: false,
         interviewStep: 1
      })
   }

   TopicModal = (e) => {
      if (e) e.preventDefault();
      this.setState({
         show_modal_topic: true
      })
   }
   showModalTopic = e => {
      this.setState({
         show_modal_topic: false,
         topicStep: 1
      })
   }

   ApplyJobModal = (e) => {
      if (e) e.preventDefault();
      if (this.state.apply_job_id === "") {
         NotificationManager.error("error", "Please choose atleast one job");
         return;
      }
      this.getResume()
      this.setState({
         show_modal_job: true
      })
   }
   showModalJob = e => {
      this.setState({
         show_modal_job: false,
         topicStep: 1
      })
   }

   TopicNext(step) {
      this.setState({
         topicStep: 2
      })
   }
   BackTopic() {
      this.setState({
         topicStep: 1
      })
   }

   SingleTopic(page = 1, topic_id, name) {
      Axios.post(`${this.state.url}/api/get/posts`, { page: page, topic_id: topic_id }, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         setTimeout(() => {
            this.setState({
               TopicPosts: res.data.data.posts.data,

            })
            console.log("JobPosts1212", this.state.JobPosts)
         }, 200);

      });
      this.setState({
         topicStep: 3,
         topicName: name
      })
   }

   SinglePost(e, id) {
      e.preventDefault()
      Axios.post(`${this.state.url}/api/single/post`, { post_id: id }, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         setTimeout(() => {
            this.setState({
               SinglePostData: res.data.data.post,

            })
            console.log("singlePost", this.state.SinglePostData)
         }, 200);

      });
      this.getCommentList(id)
      this.setState({
         topicStep: 4,
         post_id: id
      })
   }

   getCommentList(id) {
      Axios.post(`${this.state.url}/api/comment/list`, { post_id: id }, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         setTimeout(() => {
            this.setState({
               CommentData: res.data.data.comments.data,

            })
            console.log("singlePost", this.state.CommentData)
         }, 200);

      });
      this.setState({
         topicStep: 4,
      })
   }


   RemoveImage = (e, indeximage) => {
      const newarray = this.state.fileArray.filter((item, index) => {
         return index !== indeximage
      });
      this.setState({
         image: "",
         resume: "",
         imgUrl: "",
         resume_id: "",
         previewUrl: "",
         fileArray: newarray
      })
   }

   NewResume = e => {
      this.setState({
         resume_id: ""
      })
   }


   onChangeJob(e) {

      const filesFormats = [".doc", ".docx", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];

      if (e.target.files) {
         let file = e.target.files[0];
         console.log(file.type)
         const isRightFormat = filesFormats.includes(file.type);
         if (!isRightFormat) {
            NotificationManager.error("error", "Please enter valid document only pdf or doc.");
            return;
         }
         var reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onloadend = function (e) {
            this.setState({
               imgUrl: file.type == "application/pdf" ? "/admin/dist/images/pdf.jpeg" : "/admin/dist/images/doc.png",  //[reader.result]
               //   image : [reader.result]
               resume: file
            })
         }.bind(this);
         setTimeout(() => {
            console.log(this.state.image[0])
         }, 200);

      }
   }

   onChange(e) {
      this.state.fileObj.push(e.target.files)
      for (let i = 0; i < this.state.fileObj[0].length; i++) {
         this.state.fileArray.push(URL.createObjectURL(this.state.fileObj[0][i]))
      }
      this.setState({ file: this.state.fileArray })
      //    this.setState({
      //       "image": evt.target.files[0],
      //   });
      if (e.target.files) {
         let file = e.target.files[0];
         var reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onloadend = function (e) {
            this.setState({
               image: [reader.result],
               "topic_image": file,
               "comment_image": file
            })
         }.bind(this);
         setTimeout(() => {
            console.log(this.state.image[0])
         }, 200);
      }
   }

   handleRequestOptions(part) {
      console.log("part");          // -> "ap", which is part after trigger "@"
      // this.setState({ options: SOME_NEW_OPTION_ARRAY });
   }

   updateInputValue(evt) {
      var has = this.state.obj;
      var newArr = [];
      const val = evt;
      console.log(val);
      // ...
      this.setState({
         project_text: val,
         HasTagValue: ""
      });
      setTimeout(() => {
         var ss = this.state.project_text.split(" ");
         // console.log(ss);
         for (let k of ss) {
            if (k.indexOf("https://") != -1) {
               this.setState({
                  previewUrl: k
               })
            }
            if (k.indexOf("#") != -1 && k.length > 3) {
               this.setState({
                  HasTagValue: [...this.state.HasTagValue, k]
               }, console.log(this.state.HasTagValue, this.state.Has, 1118))
            }
         }

         var deduped = Array.from(new Set(this.state.HasTagValue));
         this.setState({
            Has: deduped
         })
         console.log(this.state.project_text, "aaaa")
      }, 200);


   }

   onChangeImage(e) {
      if (e.target.files) {
         this.setState({
            topic_image: e.target.files[0]
         })
         console.log(e)
      }
   }

   onFormSubmitComment = (e) => {
      e.preventDefault()
      if (this.state.comment == "") {
         NotificationManager.error("error", "Please enter comment.");
         return false;
      }
      const formData = new FormData();
      formData.append('message', this.state.comment);
      formData.append('post_id', this.state.post_id);
      if (this.state.comment_image != "") {
         formData.append('image', this.state.comment_image);
      }
      const config = {
         headers: {
            'content-type': 'multipart/form-data',
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      };
      Axios.post(`${this.state.url}/api/create/comment`, formData, config, {
      }).then(res => {
         this.getCommentList(this.state.post_id)
         this.setState({
            comment: "",
            comment_image: ""
         })
         NotificationManager.success("success", "Comment Added Successfully");
      })
   }


   onFormSubmit = (e) => {
      e.preventDefault();
      if (this.state.topic_id == "") {
         NotificationManager.error("error", "Please choose topic.");
         return false;
      }
      if (this.state.company_id == "") {
         NotificationManager.error("error", "Please choose company.");
         return false;
      }
      if (this.state.job_title_id == "") {
         NotificationManager.error("error", "Please choose title.");
         return false;
      }
      if (this.state.topic_title == "") {
         NotificationManager.error("error", "Please enter title.");
         return false;
      }
      if (this.state.topic_description == "") {
         NotificationManager.error("error", "Please enter description.");
         return false;
      }
      if (this.state.topic_image == "") {
         NotificationManager.error("error", "Please choose image.");
         return false;
      }
      const formData = new FormData();
      formData.append('description', this.state.topic_description);
      formData.append('title', this.state.topic_title);
      formData.append('company_id', this.state.company_id);
      formData.append('topic_id', this.state.topic_id);
      formData.append('title_id', this.state.job_title_id);
      formData.append('image', this.state.topic_image);
      const config = {
         headers: {
            'content-type': 'multipart/form-data',
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      };
      Axios.post(`${this.state.url}/api/create/post`, formData, config, {
      }).then(res => {
         this.setState({
            topic_id: "",
            title_id: "",
            topic_image: "",
            topic_description: "",
            company_id: "",
            topicStep: 1
         })
         NotificationManager.success("success", "Post Created Successfully");
      })
   }

   AddPost = () => {
      if (this.state.project_text == "") {
         NotificationManager.error("error", "Please enter post Description.");
         return false;
      }
      const formData = new FormData();
      for (var i = 0; i < this.state.HasTagValue.length; i++) {
         formData.append('hastage[]', this.state.HasTagValue[i]);
      }
      // formData.append('hastage', this.state.Has);
      formData.append('url', this.state.previewUrl);
      formData.append('postData', this.state.project_text);
      // formData.append("images[]", document.getElementById('fileToUpload').files);
      var ins = document.getElementById('fileToUpload').files.length;
      for (var x = 0; x < ins; x++) {
         formData.append("images[]", document.getElementById('fileToUpload').files[x]);
      }
      // var data = {
      //    hastag: this.state.Has,
      //    image: this.state.image[0],
      //    images: this.state.fileArray,
      //    url: this.state.previewUrl,
      //    postData: this.state.project_text
      // }
      Axios.post(`${this.state.url}/api/userpost/add`, formData, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.getHomeData();
         this.setState({
            HasTagValue: [],
            hastag: [],
            image: "",
            url: "",
            postData: "",
            previewUrl: "",
            project_text: '',
            show_modal: false,
            fileArray: [],
         })
         NotificationManager.success("success", "Post Created Successfully");
      }).catch((e) => console.log(e.message));
   }

   //  AddHashProject = (e) => {
   //    this.setState({
   //       project_text:"[...this.state.project_text, "#"]"
   //    })


   // }

   render() {
      const fruts = [
         'tacos',
         'blackberry',
         'watermelon',
         'kiwi',
         'chili',
         'lemon',
         'orange',
         'pineaple'
      ]
      const Loading = ({ data }) => <div>Loading</div>;

      const handleOnSearch = (string, results) => {
         // onSearch will have as the first callback parameter
         // the string searched and for the second the results.
         console.log(string, results)
      }

      const handleOnHover = (result) => {
         // the item hovered
         console.log(result)
      }

      const handleOnSelect = (item) => {
         var company = []
         for (let k of this.state.allCompany) {
            if (k.id === item.id) {
               company = k;
            }
         }
         setTimeout(() => {
            if (this.state.show_modal_salary) {
               this.setState({
                  salaryStep: 2,
                  CompanyInfo: company,
                  company_id: item.id
               })
            } else if (this.state.show_modal_review) {
               this.setState({
                  reviewStep: 2,
                  CompanyInfo: company,
                  company_id: item.id
               })
            } else {
               this.setState({
                  interviewStep: 2,
                  CompanyInfo: company,
                  company_id: item.id
               })
            }
         }, 100);
         // the item selected
         console.log('this.state.CompanyInfo', this.state.company)
      }

      const handleOnSelectTitle = (item) => {
         var tt = []
         for (let k of this.state.JobTitle) {
            if (k.id === item.id) {
               tt = k;
            }
         }
         setTimeout(() => {
            this.setState({
               job_title_id: item.id
            })
            console.log('this.state.CompanyInfo', this.state.job_title_id)
         }, 100);
         // the item selected
      }

      const handleOnSearchTopic = (string, results) => {
         if (results.length > 0) {
            this.setState({
               JobTopic: results
            })
         } else {
            this.getTopic()
         }
         // onSearch will have as the first callback parameter
         // the string searched and for the second the results.
         console.log(string, this.state.JobTopic)
      }

      const handleOnSelectTopic = (item) => {
         console.log("hello")
         var tt = []
         for (let k of this.state.JobTopic) {
            if (k.id === item.id) {
               tt = k;
            }
         }
         setTimeout(() => {
            this.setState({
               topic_id: item.id
            })
         }, 100);
         // the item selected

      }


      const handleOnSelectType = (item) => {
         var tt = []
         for (let k of this.state.JobType) {
            if (k.id === item.id) {
               tt = k;
            }
         }
         setTimeout(() => {
            this.setState({
               job_type_id: item.id
            })
            console.log('this.state.CompanyInfo', this.state.job_type_id)
         }, 100);
         // the item selected

      }

      const handleOnFocus = () => {
         console.log('Focused')
      }

      const onPlaceSelected = (place) => {
         console.log(place)
         for (let ko of place.address_components) {
            for (let l of ko.types) {
               if (l === "administrative_area_level_2") {
                  console.log(ko.long_name, 1413);
                  this.setState({
                     location: ko.long_name
                  })
               }
            }
         }
      }

      const ratingChanged = (newRating) => {
         this.setState({
            rating: newRating
         })
      };

      var settings = {
         className: "slider coupon",
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1,
         autoplay: false,
         autoplaySpeed: 2000,
         dots: false,
         variableWidth: true,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
               }
            },
            {
               breakpoint: 992,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
               }
            },
            {
               breakpoint: 767,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
               }
            },
            {
               breakpoint: 576,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 320,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }

         ]
      };

      const arrayoption = [
         {
            value: "Experience",
         },
         {
            value: 0,
         },
         {
            value: 1,
         },
         {
            value: 2,
         },
         {
            value: 3,
         },
         {
            value: 4,
         },
         {
            value: 5,
         },
         {
            value: 6,
         },
         {
            value: 7,
         },
         {
            value: 8,
         },
         {
            value: 9,
         },
         {
            value: 10,
         },
      ]

      return (
         <>
            <NotificationContainer />
            <Header />


            <section class="sidebar_menu">

               <Navbar />

               <div id="page-content-wrapper" class="dashboard-bg">
                  <div class="container-fluid">
                     <div class="col-md-12 pd0">
                        <div class="row">
                           <div class="col-lg-3 col-md-4">
                              <div id="stickyjob">
                                 <div class="forum-sec">
                                    <h3>Forum</h3>
                                    <Link to={`/user/forums`} activeClassName="active">View All</Link>
                                    {this.state.JobPosts && this.state.JobPosts.length > 0 && <>
                                       {this.state.JobPosts.map((cr, index) => {
                                          if (index <= 4) {
                                             return <div class="desp-details">
                                                <div class="img-pro">
                                                   <img src={this.state.url + cr.image} alt="" />
                                                </div>
                                                <div class="name-details-time">
                                                   <h4>{cr.title}</h4>
                                                   <p>{cr.user.name} <span class="dot-point">{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</span></p>
                                                </div>
                                                <p class="des_forum">{cr.description}</p>
                                             </div>
                                          }
                                       })}
                                    </>}

                                 </div>
                                 <div class="top-job">
                                    <h3>Top Jobs </h3>
                                    <Link to={`/user/jobs`} activeClassName="active">View All</Link>
                                    <div class="maine-apply">
                                       <a href="javascript:void(0)" onClick={(e) => { this.ApplyJobModal(e) }} class="applyall">Apply All</a>
                                    </div>
                                    {/* <a href="javascript:void(0)" class="job_all" >View All</a> */}
                                    {this.state.allJobs && this.state.allJobs.length > 0 && <>
                                       {this.state.allJobs.map((cr, index) => {
                                          if (index <= 4) {
                                             return <div class="job-details-block">
                                                <div class="row">
                                                   <div class="col-md-1 padd-right0">
                                                      <input type="checkbox" class="type_def_chk" name="apply_job_id" onChange={e => { this.handleCheckboxChangeJob(e) }} value={cr.id} />
                                                   </div>
                                                   <div class="col-md-7 padd-0">
                                                      <Link to={`/user/job/` + cr.id} activeClassName="active">
                                                         <div class="job-profile">
                                                            {cr.banner && <> <img src={this.state.url + '/uploads/' + cr.banner} alt="" /> </>}
                                                            {cr.banner == null && <> <img src="/admin/dist/images/ui.png" alt="" /> </>}
                                                         </div>
                                                         <div class="job-details">
                                                            <h4>{cr.department}</h4>
                                                            <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt="" /></span>{cr.company_name}</p>
                                                            <p>{cr.address} ({cr.job_type})</p>
                                                            <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                         </div>
                                                      </Link>
                                                   </div>
                                                   <div class="col-md-4">
                                                      <div class="apply_btn"><a onClick={(e) => { this.ApplyJob(e, cr.id) }} href="javascript:void(0)">Apply</a></div>
                                                   </div>
                                                </div>
                                             </div>
                                          }
                                       })}
                                    </>}
                                 </div>
                                 <div id="sticky-anchor"></div>
                                 <div class="top-companies" id="sticky">
                                    <h3>Top Companies</h3>
                                    <Link to={`/user/companies`} activeClassName="active">View All</Link>
                                    {this.state.allCompany && this.state.allCompany.length > 0 && <>
                                       {this.state.allCompany.map((cr, index) => {
                                          if (index <= 4) {
                                             return <Link to={`/user/company/` + cr.id} activeClassName="active">
                                                <div class="companies-details-block">
                                                   <div class="company-profile">
                                                      {cr.logo && <> <img src={this.state.url + '/uploads/' + cr.logo} alt="" /> </>}
                                                      {cr.logo == null && <> <img src="/admin/dist/images/google.png" alt="" /> </>}
                                                   </div>
                                                   <div class="job-details">
                                                      <h4>{cr.company_name}</h4>
                                                      <p><span class="sales-img"><img src="/admin/dist/images/location.png" alt="" /></span>{cr.headqarter}</p>
                                                      <p><span class="sales-img"><img src="/admin/dist/images/bag.png" alt="" /></span>{cr.jobs.length} Job Open</p>
                                                   </div>
                                                </div>
                                             </Link>
                                          }
                                       })}
                                    </>}

                                 </div>
                                 <footer id="footer">
                                    <ul>
                                       <li><a href="javascript:void(0)">Terms and Coditions</a></li>
                                       <li><a href="javascript:void(0)">About us</a></li>
                                       <li><a href="javascript:void(0)">Contact us</a></li>
                                       <li><a href="javascript:void(0)">Privacy Policy</a></li>
                                       <li><a href="javascript:void(0)">Site Map</a></li>
                                    </ul>
                                 </footer>
                              </div>

                           </div>
                           <div class="col-lg-6 col-md-8">
                              <div class="items_sec">
                                 <a href="javascript:void(0)" onClick={(e) => { this.PostModal(e) }}><img src="/admin/dist/images/create-post.png" alt="" />Create Post</a>
                                 <a href="javascript:void(0)" onClick={(e) => { this.ProjectModal(e) }}><img src="/admin/dist/images/project.png" alt="" />Create Project</a>
                                 <a href="javascript:void(0)" onClick={(e) => { this.SalaryModal(e) }}><img src="/admin/dist/images/add-ralary.png" alt="" />Add Salary</a>
                                 <a href="javascript:void(0)" onClick={(e) => { this.ReviewModal(e) }}><img src="/admin/dist/images/reviews.png" alt="" />Add Review</a>
                                 <a href="javascript:void(0)" onClick={(e) => { this.InterviewModal(e) }}><img src="/admin/dist/images/interviews.png" alt="" />Add Interview</a>
                                 <a href="javascript:void(0)" onClick={(e) => { this.TopicModal(e) }}><img src="/admin/dist/images/topics.png" alt="" />Add Topics</a>
                              </div>
                              {this.state.allHomeData && this.state.allHomeData.length > 0 && <>
                                 {this.state.allHomeData.map((cr, index) => {
                                    if (cr.type == 'post') {
                                       return <div class="user-profile-sec">
                                          {console.log(cr, 1690)}
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="user-img">
                                                   {cr.user.image && <> <img src={cr.user.image} alt="" /> </>}
                                                   {cr.user.image == null && <> <img src="/admin/dist/images/pro.png" alt="" /> </>}
                                                </div>
                                                <div class="user-details-sec">
                                                   <h5>{cr.user.name}</h5>
                                                   <p>{cr.user.designation != null ? cr.user.designation.name : ""}</p>
                                                   <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="dotted-sec"><a href="javascript:void(0)"><img src="/admin/dist/images/dots.png" alt="" /></a></div>
                                             </div>
                                          </div>
                                          <div class="">
                                             <div class="">
                                                <div class="desp-urse" >
                                                   {cr.link === null ?
                                                      <div dangerouslySetInnerHTML={{ __html: cr.text }}></div>
                                                      : ''}
                                                </div>
                                                {cr.link !== null &&
                                                   <div className="link_post">
                                                      <a href={`${cr.link}`} target="_blank">{cr.link}</a>
                                                   </div>
                                                }
                                                {cr.hashtags.map((item) => {
                                                   return (
                                                      <span className="green">
                                                         {item}
                                                      </span>
                                                   )
                                                })}
                                                <div class="likes-sec">
                                                   <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt="" /> <span>You and 20 Others</span></div>
                                                   <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                                   <hr></hr>
                                                   <div class="like-share-sec">
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/like.png" alt="" /> Like</a></span>
                                                      <span onClick={() => this.commentdata(cr.id)}>
                                                         <img src="/admin/dist/images/comment.png" alt="" /> Comment
                                                      </span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt="" /> Share</a></span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt="" /> Send</a></span>
                                                   </div>
                                                   {this.state.showComment ?
                                                      cr.id === this.state.idcomment &&
                                                      <div className="d-grid comment_div">
                                                         <textarea className="comment_textarea form-control" id="exampleFormControlTextarea1" rows="3" cols="30" ></textarea>
                                                         <button onClick={() => this.setState({ showComment: false })} className="comment_btn btn">Add Comment</button>
                                                      </div>
                                                      : ''
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    }
                                    if (cr.type == 'images') {
                                       return <div class="user-profile-sec">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="user-img">
                                                   {cr.user.image && <> <img src={cr.user.image} alt="" /> </>}
                                                   {cr.user.image == null && <> <img src="/admin/dist/images/pro.png" alt="" /> </>}
                                                </div>
                                                <div class="user-details-sec">
                                                   <h5>{cr.user.name}</h5>
                                                   <p>{cr.user.designation != null ? cr.user.designation.name : ""}</p>
                                                   <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="dotted-sec"><a href="javascript:void(0)"><img src="/admin/dist/images/dots.png" alt="" /></a></div>
                                             </div>
                                          </div>
                                          <div class="">
                                             <div class="">
                                                <div class="desp-urse" >
                                                   {cr.link === null ?
                                                      <div dangerouslySetInnerHTML={{ __html: cr.text }}></div>
                                                      : ''}
                                                   {cr.images !== null && <> <div class="row">
                                                      <div class="post-image">
                                                         {cr.images.length > 1
                                                            ? <Slider {...settings}>
                                                               {cr.images.map((url, index) => {
                                                                  return (
                                                                     <img class="img-post-popup_img" src={url.image} alt="..." />
                                                                  )
                                                               })}
                                                            </Slider>
                                                            : cr.images.map((img) => {
                                                               return <img src={img.image} alt="postimage" />
                                                            })
                                                         }
                                                      </div>
                                                   </div> </>}
                                                </div>
                                                {cr.link !== null &&
                                                   <div className="link_post">
                                                      <a href={`${cr.link}`} target="_blank">{cr.link}</a>
                                                   </div>
                                                }
                                                {/* {cr.hashtags !== null &&
                                                   <div className="green">
                                                      {cr.hashtags.map((item) => {
                                                         item
                                                      })}
                                                   </div>
                                                } */}
                                                <div class="likes-sec">
                                                   <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt="" /> <span>You and 20 Others</span></div>
                                                   <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                                   <hr></hr>
                                                   <div class="like-share-sec">
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/like.png" alt="" /> Like</a></span>
                                                      <span onClick={() => this.commentdata(cr.id)}>
                                                         <img src="/admin/dist/images/comment.png" alt="" /> Comment
                                                      </span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt="" /> Share</a></span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt="" /> Send</a></span>
                                                   </div>
                                                   {this.state.showComment ?
                                                      cr.id === this.state.idcomment &&
                                                      <div className="d-grid comment_div">
                                                         <textarea className="comment_textarea form-control" id="exampleFormControlTextarea1" rows="3" cols="30" ></textarea>
                                                         <button onClick={() => this.setState({ showComment: false })} className="comment_btn btn">Add Comment</button>
                                                      </div>
                                                      : ''
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    }
                                    {/* <div class="suggestion-sec">
                           <div class="suggested-sec">
                              <h3>Suggested Jobs</h3>
                              <a href="javascript:void(0)">View All</a>
                           </div>
                           <div class="suggested-slider">
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                                    {/* <div class="follow-hiring-sec">
                           <div class="row">
                              <div class="col-md-9">
                                 <div class="user-img">
                                    <img src="/admin/dist/images/pro.png" alt=""/>
                                 </div>
                                 <div class="user-details-sec">
                                    <h5>Alina Johnsan</h5>
                                    <p>Sr. UX Designer</p>
                                    <p>10h Ago</p>
                                 </div>
                              </div>
                              <div class="col-md-3">
                                 <div class="follow-sec"><a href="javascript:void(0)">Follow</a></div>
                              </div>
                              <div class="col-md-12">
                                 <div id="summary">
                                    <p class="collapse" id="collapseSummary">
                                       Want to design solutions to innovate and work with the smartest technologists for large...Want to design solutions to innovate and work with the smartest technologists for large.
                                    </p>
                                    <a class="collapsed" data-toggle="collapse" href="#collapseSummary" aria-expanded="false" aria-controls="collapseSummary"></a>
                                 </div>
                                 <div class="hiringpost-slider">
                                    <div>
                                       <div class="main_post block_blue">
                                          <div class="hiring-data">
                                             <h5>WE'RE HIRING</h5>
                                             <h3>Security Architects
                                                and Engineers
                                             </h3>
                                             <p>Protect all information relating to
                                                ccaregivers and affiliates, as well as
                                                confidential business information.
                                             </p>
                                          </div>
                                       </div>
                                       <div class="join_sec">
                                          <p>Join Our Team</p>
                                       </div>
                                    </div>
                                    <div>
                                       <div class="main_post block_green">
                                          <div class="hiring-data">
                                             <h5>WE'RE HIRING</h5>
                                             <h3>Principal, Lead and
                                                Sernior Software
                                                Engineers
                                             </h3>
                                             <p>Protect all information relating to
                                                ccaregivers and affiliates, as well as
                                                confidential business information.
                                             </p>
                                          </div>
                                       </div>
                                       <div class="join_sec">
                                          <p>Hiring in Hyderabad</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="likes-sec">
                                    <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt=""/> <span>You and 20 Others</span></div>
                                    <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                  <hr></hr>
                                    <div class="like-share-sec">
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/like.png" alt=""/> Like</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/comment.png" alt=""/> Comment</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt=""/> Share</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt=""/> Send</a></span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                                    {/* <div class="job-profile-sec">
                           <div class="row">
                              <div class="col-md-9">
                                 <div class="user-img">
                                    <img src="/admin/dist/images/porfile-img.png" alt=""/>
                                 </div>
                                 <div class="user-details-sec">
                                    <h5>UI Designer</h5>
                                    <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span> Salesforce Masters</p>
                                    <p>India (Remote)</p>
                                    <p>22h Ago</p>
                                 </div>
                              </div>
                              <div class="col-md-3">
                                 <div class="follow-sec"><a href="javascript:void(0)">Apply this job</a></div>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-7">
                                 <div class="matches_job">
                                    <img src="/admin/dist/images/profile-img.png" alt=""/><span class="check-icon"><img src="/admin/dist/images/check.png" alt=""/></span>Your profile matches this job
                                 </div>
                              </div>
                              <div class="col-md-5"></div>
                           </div>
                           <div class="col-md-12">
                              <div class="row">
                                 <div class="likes-sec">
                                    <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt=""/> <span>You and 20 Others</span></div>
                                    <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                  <hr></hr>
                                    <div class="like-share-sec">
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/like.png" alt=""/> Like</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/comment.png" alt=""/> Comment</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt=""/> Share</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt=""/> Send</a></span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                                    {/* <div class="weare-hiring-sec">
                           <div class="row">
                              <div class="col-md-9">
                                 <div class="user-img">
                                    <img src="/admin/dist/images/pro.png" alt=""/>
                                 </div>
                                 <div class="user-details-sec">
                                    <h5>Alina Johnsan</h5>
                                    <p>Sr. UX Designer</p>
                                    <p>10h Ago</p>
                                 </div>
                              </div>
                              <div class="col-md-3">
                                 <div class="dotted-sec"><a href="javascript:void(0)"><img src="/admin/dist/images/dots.png" alt=""/></a></div>
                              </div>

                           </div>
                           <div class="row">
                              <div class="images-hiring">
                                 <img src="/admin/dist/images/hiring-post.png" alt=""/>
                              </div>
                           </div>
                           <div class="col-md-12">
                              <div class="row">
                                 <div class="likes-sec">
                                    <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt=""/> <span>You and 20 Others</span></div>
                                    <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                 <hr></hr>
                                    <div class="like-share-sec">
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/like.png" alt=""/> Like</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/comment.png" alt=""/> Comment</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt=""/> Share</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt=""/> Send</a></span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                                    {/* <div class="register-post-sec">
                           <div class="row">
                              <div class="col-md-9">
                                 <div class="user-img">
                                    <img src="/admin/dist/images/pro.png" alt=""/>
                                 </div>
                                 <div class="user-details-sec">
                                    <h5>Alina Johnsan</h5>
                                    <p>Sr. UX Designer</p>
                                    <p>10h Ago</p>
                                 </div>
                              </div>
                              <div class="col-md-3">
                                 <div class="follow-sec"><a href="javascript:void(0)">Follow</a></div>
                              </div>

                           </div>
                           <div class="">
                              <div id="summary2">
                                 <p class="collapse" id="collapseSummary2" >
                                    Want to design solutions to innovate and work with the smartest technologists for large...Want to design solutions to innovate and work with the smartest technologists for large.
                                 </p>
                                 <a class="collapsed" data-toggle="collapse" href="#collapseSummary2" aria-expanded="false" aria-controls="collapseSummary2"></a>
                              </div>
                           </div>
                           <div class="row">
                              <div class="images-hiring">
                                 <img src="/admin/dist/images/register-post.png" alt=""/>
                              </div>
                              <div class="register-now">
                                 <p>REGISTER NOW - Systems Engineering Management 5-Day Course | PPI - <span>social.ppi-int.com</span></p>
                              </div>
                           </div>
                           <div class="col-md-12">
                              <div class="row">
                                 <div class="likes-sec">
                                    <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt=""/> <span>You and 20 Others</span></div>
                                    <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                 <hr></hr>
                                    <div class="like-share-sec">
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/like.png" alt=""/> Like</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/comment.png" alt=""/> Comment</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt=""/> Share</a></span>
                                       <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt=""/> Send</a></span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                                    {/* <div class="suggestion-sec">
                           <div class="suggested-sec">
                              <h3>Apply Your Designation Releted Jobs</h3>
                              <a href="javascript:void(0)">Suggested Jobs</a>
                           </div>
                           <div class="related-slider">
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <div class="card">
                                    <div class="card-body">
                                       <div class="card-content">
                                          <div class="card-text">
                                             <img src="/admin/dist/images/ui.png" alt=""/>
                                             <h5>UI Design</h5>
                                             <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt=""/></span>Salesforce Masters</p>
                                             <p>New York (Remote)</p>
                                             <p>22h Ago</p>
                                             <a href="javascript:void(0)">Apply</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </div> */}
                                    if (cr.type == 'project') {
                                       return <div class="userprofile-post-sec">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="user-img">
                                                   {cr.user.image && <> <img src={cr.user.image} alt="" /> </>}
                                                   {cr.user.image == null && <> <img src="/admin/dist/images/pro.png" alt="" /> </>}
                                                </div>
                                                <div class="user-details-sec">
                                                   <h5>{cr.user.name}</h5>
                                                   <p>{cr.user.designation != null ? cr.user.designation.name : ""}</p>
                                                   <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="dotted-sec"><a href="javascript:void(0)"><img src="/admin/dist/images/dots.png" alt="" /></a></div>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="col-md-12">
                                                <div class="post-user">
                                                   <h5>{cr.title} </h5>
                                                   {cr.budget == 'project_based' && <> <p>Fixed-price - {cr.level} - Est. Budget: ${cr.project_price}</p> </>}
                                                   {cr.budget == 'hourly_based' && <> <p>Price - {cr.level} - Min ${cr.min_price} - Max ${cr.max_price}/Hour</p> </>}
                                                </div>
                                             </div>
                                          </div>
                                          <div id="summary4">
                                             <p class="collapse" id="collapseSummary4" >
                                                <div dangerouslySetInnerHTML={{ __html: cr.description }}></div>
                                             </p>
                                             <a class="collapsed" data-toggle="collapse" href="#collapseSummary4" aria-expanded="false" aria-controls="collapseSummary4"></a>
                                          </div>
                                          {/* <div class="tags">
                                    <span>  #uidesign #webdesign #job</span>
                                    </div> */}
                                          <div class="proposal-user">
                                             <div class="row">
                                                <div class="col-md-8"><span class="proposals-sec">Proposals: <b>10 to 15</b></span></div>
                                                <div class="col-md-4"><span class="submit-proposal">Submit a Proposal</span></div>
                                             </div>
                                          </div>

                                          <div class="col-md-12">
                                             <div class="row">
                                                <div class="likes-sec">
                                                   <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt="" /> <span>You and 20 Others</span></div>
                                                   <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                                   <hr></hr>
                                                   <div class="like-share-sec">
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/save-grey.png" alt="" /> Save</a></span>
                                                      <span
                                                         onClick={() => this.commentdata(cr.id)} >
                                                         <img src="/admin/dist/images/comment.png" alt="" /> Comment</span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt="" /> Share</a></span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt="" /> Send</a></span>
                                                   </div>
                                                   {this.state.showComment ?
                                                      cr.id === this.state.idcomment &&
                                                      <div className="d-grid comment_div">
                                                         <textarea className="comment_textarea form-control" id="exampleFormControlTextarea1" rows="3" cols="30" ></textarea>
                                                         <button onClick={() => this.setState({ showComment: false })} className="comment_btn btn">Add Comment</button>
                                                      </div>
                                                      : ''
                                                   }
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                    }
                                    if (cr.type === 'forum_post') {
                                       return <div class="register-post-sec">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="user-img">
                                                   {cr.user.image && <> <img src={cr.user.image} alt="" /> </>}
                                                   {cr.user.image == null && <> <img src="/admin/dist/images/pro.png" alt="" /> </>}
                                                </div>
                                                <div class="user-details-sec">
                                                   <h5>{cr.user.name}</h5>
                                                   <p>{cr.user.designation != null ? cr.user.designation.name : ""}</p>
                                                   <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="dotted-sec"><a href="javascript:void(0)"><img src="/admin/dist/images/dots.png" alt="" /></a></div>
                                             </div>
                                          </div>
                                          <div id="summary3">
                                             <h5>{cr.title}</h5>
                                             <p class="collapse" id="collapseSummary3" >
                                                {cr.description}
                                             </p>
                                             <a class="collapsed" data-toggle="collapse" href="#collapseSummary3" aria-expanded="false" aria-controls="collapseSummary3"></a>
                                          </div>
                                          <div class="like-dislike-sec">
                                             {cr.likes && cr.likes.length === 0 && <> <a href="javascript:void(0)" class="like" onClick={(e) => { this.LikeForumPost(e, cr.id) }}><img src="/admin/dist/images/like.png" class="like-img" />Like ({cr.likes_count})</a>
                                             </>}
                                             {cr.likes && cr.likes.length > 0 && <> <a href="javascript:void(0)" class="dislike" onClick={(e) => { this.LikeForumPost(e, cr.id) }}><img src="/admin/dist/images/dislike.png" class="like-img" />Dislike ({cr.dislikes_count})</a>
                                             </>}
                                          </div>
                                          <div class="col-md-12">
                                             <div class="row">
                                                <div class="likes-sec">
                                                   <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt="" /> <span>You and 20 Others</span></div>
                                                   <div class="comment-sec"><a href="javascript:void(0)">{cr.comments_count} Comments</a></div>
                                                   <hr></hr>
                                                   <div class="like-share-sec">
                                                      <span><a href="javascript:void(0)" onClick={(e) => { this.LikeForumPost(e, cr.id) }}><img src="/admin/dist/images/like.png" alt="" /> Like</a></span>
                                                      <span
                                                         onClick={() => this.commentdata(cr.id)} >
                                                         <img src="/admin/dist/images/comment.png" alt="" /> Comment</span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt="" /> Share</a></span>
                                                      <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt="" /> Send</a></span>
                                                   </div>
                                                   {this.state.showComment ?
                                                      cr.id === this.state.idcomment &&
                                                      <div className="d-grid comment_div">
                                                         <textarea className="comment_textarea form-control" id="exampleFormControlTextarea1" rows="3" cols="30" ></textarea>
                                                         <button onClick={() => this.setState({ showComment: false })} className="comment_btn btn">Add Comment</button>
                                                      </div>
                                                      : ''
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    }
                                    {/* <div class="werehiring-post-sec">
                           <div class="row">
                              <div class="col-md-9">
                                 <div class="user-img">
                                    <img src="/admin/dist/images/pro.png" alt=""/>
                                 </div>
                                 <div class="user-details-sec">
                                    <h5>Alina Johnsan</h5>
                                    <p>Sr. UX Designer</p>
                                    <p>10h Ago</p>
                                 </div>
                              </div>
                              <div class="col-md-3">
                                 <div class="dotted-sec"><a href="javascript:void(0)"><img src="/admin/dist/images/dots.png" alt=""/></a></div>
                              </div>
                           </div>
                           <div id="summary5">
                              <p class="collapse" id="collapseSummary5" >
                                 Want to design solutions to innovate and work with the smartest technologists for
                                 large...
                              </p>
                              <a class="collapsed" data-toggle="collapse" href="#collapseSummary5" aria-expanded="false" aria-controls="collapseSummary5"></a>
                           </div>
                              <div class="row">
                                 <div class="post-image"><img src="/admin/dist/images/post2.png"/></div>
                              </div>
                              <div class="col-md-12">
                                 <div class="row">
                                    <div class="likes-sec">
                                       <div class="users_imgs"><img src="/admin/dist/images/user1.png" alt=""/> <span>You and 20 Others</span></div>
                                       <div class="comment-sec"><a href="javascript:void(0)">10 Comments</a></div>
                                     <hr></hr>
                                       <div class="like-share-sec">
                                          <span><a href="javascript:void(0)"><img src="/admin/dist/images/save-grey.png" alt=""/> Save</a></span>
                                          <span><a href="javascript:void(0)"><img src="/admin/dist/images/comment.png" alt=""/> Comment</a></span>
                                          <span><a href="javascript:void(0)"><img src="/admin/dist/images/share.png" alt=""/> Share</a></span>
                                          <span><a href="javascript:void(0)"><img src="/admin/dist/images/send.png" alt=""/> Send</a></span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                        </div> */}
                                 })}
                              </>}
                           </div>
                           <Sidebar />
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <Modal
               show={this.state.show_modal}
               onHide={() => this.showModal(false)}
               dialogClassName="modal-90w"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Create Post</h4>
               </Modal.Header>
               <Modal.Body>
                  <div>
                     <img class="imgPost" src={this.state.userDetail.image} /> <span>{this.state.userDetail.name}</span>
                  </div>
                  <TextInput cols="45" rows="5" placeholder="What do you want to talk about?" className="text_post" onChange={evt => this.updateInputValue(evt)} trigger={"#"} options={{ "#": this.state.options }} />
                  <div className="green">
                     {this.state.HasTagValue}
                  </div>
                  {/* {this.state.HasTagValue
                     <>
                        {this.state.HasTagValue.map((item) => {
                           <div className="green">
                              {item}
                           </div>
                        })}
                     </>
                  } */}
                  {this.state.previewUrl && <>
                     <span class="remove-btn">
                        <a href="javascript::void(0)" onClick={(e) => { this.RemoveImage(e) }} class="colse-btn">X</a>
                     </span>
                     <div>
                        <ReactTinyLink
                           cardSize="large"
                           showGraphic={true}
                           maxLine={2}
                           minLine={1}
                           url={this.state.previewUrl}
                        />
                     </div>
                  </>}
                  {/* {this.state.image && <>
                     <span class="remove-btn">
                        <a href="javascript::void(0)" onClick={(e) => { this.RemoveImage(e) }} class="colse-btn">X</a>
                     </span>
                     <img class="img-post-popup" src={this.state.image[0]} />
                  </>} */}
                  {this.state.fileArray &&
                     <Slider {...settings}>
                        {this.state.fileArray.map((url, index) => {
                           return (
                              <div className="image_section" >
                                 <span class="remove-btn" key={index} >
                                    <a href="javascript::void(0)" onClick={(e) => { this.RemoveImage(e, index) }} class="colse-btn">X</a>
                                 </span>
                                 <img class="img-post-popup" src={url} alt="..." />
                              </div>
                           )
                        })}
                     </Slider>
                  }
                  {/* <div><a href="javascript:void(0)" onClick={(e) => { this.AddHashProject(e) }}>Add Hastag</a></div> */}
                  <div class="row boder-line">
                     <div class="col-md-6">
                        <div class="chooseifiles" >
                           <input type="file" multiple id="fileToUpload" onChange={this.onChange} />
                           <span class="up-img" id="app-title">
                              <img src="/admin/dist/img/upload-imgss.png" class="select-img" alt="" />
                              Add Images
                           </span>
                           <ReactTooltip
                              anchorId="app-title"
                              place="top"
                              content="Add images"
                           />
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="post-btn">
                           <a href="javascript:void(0)" onClick={(e) => { this.AddPost(e) }}>Post</a>
                        </div>
                     </div>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
               </Modal.Footer>
            </Modal>




            <Modal
               show={this.state.show_modal_project}
               onHide={() => this.showModalProject(false)}
               dialogClassName="modal-90w"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Create Project</h4>
               </Modal.Header>

               <Modal.Body>

                  {this.state.step == 1 && <> <div class="lets-start">
                     <label>Let's Start With Strong Title.</label>
                     <input type="text" clas="form-control" name="project_title" value={this.state.project_title} onChange={e => { this.handleInput(e) }} placeholder="Title"></input>
                  </div>
                     <div class="lets-start">
                        <label>Job Category</label>
                        <ul>
                           {this.state.allCategory.length > 0 && <>
                              {this.state.allCategory.map((cr, index) => {
                                 return <li>
                                    <input type="radio" name="category_id" onChange={e => { this.handleInput(e) }} value={cr.id} />{cr.name}
                                 </li>
                              })}
                           </>}
                        </ul>
                     </div>

                     {this.state.allSkill && this.state.allSkill.length > 0 && <>
                        <div className="lets-start1">
                           <label>Skills</label>
                           <ul>
                              {this.state.allSkill.map((cr, index) => {
                                 return <li>
                                    <input type="checkbox" name="skill_id" onChange={e => { this.handleCheckboxChange(e) }} value={cr.id} />{cr.name}
                                 </li>
                              })}
                           </ul>
                        </div>
                     </>}

                  </>}

                  {this.state.step == 2 && <>
                     <div class="level">
                        What level of experience will it need?
                     </div>
                     <div>
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="level" value="entry" /> Entry
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="level" value="intermediate" /> Intermediate
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="level" value="expert" /> Expert
                     </div>
                     <div class="tell-us">
                        Tell us about your budget.
                     </div>
                     <div>
                        <input type="radio" onChange={e => { this.handleInput(e) }} value="hourly_based" name="budget" /> Hourly rate
                        <input type="radio" onChange={e => { this.handleInput(e) }} value="project_based" name="budget" /> Project rate
                     </div>

                     {this.state.budget == 'hourly_based' && <> <div>
                        <label>From /Hour</label>
                        <input type="text" class="form-control" onChange={e => { this.handleInput(e) }} value={this.state.min_price} name="min_price" />
                        <label>To /Hour</label>
                        <input type="text" class="form-control" onChange={e => { this.handleInput(e) }} value={this.state.max_price} name="max_price" />
                     </div>
                     </>}

                     {this.state.budget == 'project_based' && <> <div class="tell-us">
                        <label>Maximum Project Budget (USD)</label>
                        <select class="form-control" name="project_price" onChange={e => { this.handleInput(e) }} value={this.state.project_price}>
                           <option value="">Choose Budget</option>
                           <option value="0-500">0-500</option>
                           <option value="501-1000">501-1000</option>
                           <option value="1001-3000">1001-3000</option>
                           <option value="3001-5000">3001-5000</option>
                        </select>
                     </div>
                     </>}
                  </>}
                  {this.state.step == 3 && <>
                     <div class="tell-us">
                        <label>Project Description </label>
                        <TextInput cols="45" rows="5" placeholder="What do you want to talk about?" onChange={evt => this.updateInputValue(evt)} trigger={"#"} options={{ "#": this.state.options }}
                        />
                     </div>
                  </>}
                  <div>
                     {this.state.step < 3 && <> <a href="javascript:void(0)" onClick={(e) => { this.NextStep(this.state.step) }}>Next</a> </>}
                     {this.state.step > 1 && <> <a href="javascript:void(0)" onClick={(e) => { this.BackStep(this.state.step) }}>Back</a> </>}
                     {this.state.step == 3 && <> <a href="javascript:void(0)" onClick={(e) => { this.PostProject(this.state.step) }}>Post Project</a> </>}
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
               </Modal.Footer>
            </Modal>




            <Modal
               show={this.state.show_modal_salary}
               onHide={() => this.showModalSalary(false)}
               dialogClassName="modal-90w"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Add Salary</h4>
               </Modal.Header>

               <Modal.Body>

                  {this.state.salaryStep == 1 && <>  <div>
                     <label>Search Company</label>
                     <div className="add_salary">
                        <ReactSearchAutocomplete
                           items={this.state.CompanyName}
                           onSearch={handleOnSearch}
                           onHover={handleOnHover}
                           onSelect={handleOnSelect}
                           onFocus={handleOnFocus}
                           autoFocus
                        // formatResult={formatResult}
                        />
                     </div>
                  </div>
                  </>}
                  {this.state.salaryStep === 2 && <>
                     <div class="img-salary">
                        <img src={this.state.url + '/uploads/' + this.state.CompanyInfo.logo} />
                     </div>
                     <p className="companyname">{this.state.CompanyInfo.company_name}</p>
                     <input
                        placeholder="Enter Base Pay"
                        type="number"
                        name="salary"
                        onChange={e => { this.handleInput(e) }}
                        class="form-control" />
                     <lable>
                        Choose Salary Type
                     </lable>
                     <input type="radio" style={{ visibility: 'hidden' }} onChange={e => { this.handleInput(e) }} id='yearly' name="salary_type" value="yearly" />
                     <label htmlFor="yearly" className={`input_button_employee ${this.state.yearly ? "btnactive" : ""}`}
                        onClick={(e) => this.setState({
                           yearly: !this.state.yearly,
                           hourly: false,
                           monthly: false,
                        })}>
                        Yearly
                     </label>
                     <input type="radio" style={{ visibility: 'hidden' }} onChange={e => { this.handleInput(e) }} id='hourly' name="salary_type" value="hourly" />
                     <label htmlFor="hourly" className={`input_button_employee ml-1 ${this.state.hourly ? "btnactive" : ""}`}
                        onClick={(e) => this.setState({
                           hourly: !this.state.hourly,
                           yearly: false,
                           monthly: false,
                        })}>
                        Hourly
                     </label>
                     <input type="radio" style={{ visibility: 'hidden' }} onChange={e => { this.handleInput(e) }} id='monthly' name="salary_type" value="monthly" />
                     <label htmlFor="monthly" className={`input_button_employee ml-1 ${this.state.monthly ? "btnactive" : ""}`}
                        onClick={(e) => this.setState({
                           monthly: !this.state.monthly,
                           hourly: false,
                           yearly: false,
                        })}>
                        monthly
                     </label>
                     {/* <input type="radio" onChange={e => { this.handleInput(e) }} name="salary_type" value="yearly" />Yearly
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="salary_type" value="hourly" />Hourly
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="salary_type" value="monthly" />Monthly */}
                     <div>
                        <ReactSearchAutocomplete
                           placeholder="Job Title"
                           items={this.state.Title}
                           onSearch={handleOnSearch}
                           onHover={handleOnHover}
                           onSelect={handleOnSelectTitle}
                           onFocus={handleOnFocus}
                           autoFocus
                           styling={
                              {
                                 position: 'absolute',
                                 zIndex: '5',
                              }
                           }
                        // formatResult={formatResult}
                        />
                     </div>
                     <div class="enterlocation">
                        <Autocomplete
                           apiKey="AIzaSyABHQkemy2kHyDvto_e0IW4hNRafNlsoQw"
                           onPlaceSelected={onPlaceSelected}
                           placeholder={'Enter Location'}
                        />
                     </div>
                     <div class="radio-btns">
                        <input type="radio" style={{ visibility: 'hidden' }} onChange={e => { this.handleInput(e) }} name="employer_type" id='currently' value="currently" />
                        <label htmlFor="currently" className={`input_button_employee1 ${this.state.current ? "btnactive" : ""}`}
                           onClick={(e) => this.setState({
                              current: true,
                              former: false
                           })} >
                           Currently Working
                        </label>
                        <input type="radio" style={{ visibility: 'hidden' }} onChange={e => { this.handleInput(e) }} id='old' name="employer_type" value="old" />
                        <label htmlFor="old" className={`input_button_employee ml-1 ${this.state.former ? "btnactive" : ""}`}
                           onClick={(e) => this.setState({
                              former: true,
                              current: false
                           })}>
                           Former Employee
                        </label>
                        {/* <input type="radio" onChange={e => { this.handleInput(e) }} name="employer_type" value="currently" />Currently Working
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="employer_type" value="old" /> Former Employee */}
                     </div>
                     <div>
                        <ReactSearchAutocomplete
                           placeholder="Job Type"
                           items={this.state.Type}
                           onSearch={handleOnSearch}
                           onHover={handleOnHover}
                           onSelect={handleOnSelectType}
                           onFocus={handleOnFocus}
                           autoFocus
                           styling={{
                              position: 'absolute',
                              zIndex: '0',
                           }}
                        // formatResult={formatResult}
                        />
                     </div>
                     <div>
                        <select onChange={e => { this.handleInput(e) }} class="form-control" name="experience">
                           {arrayoption.map((e, i) => {
                              return <option value={e.value}>{e.value}</option>
                              // <li key={i}>{i}</li>
                           })}
                        </select>
                     </div>
                     <div className="d-flex justify-content-between border-top mt-5">
                        <div class="cancel-salary">
                           <button onClick={() => this.showModalSalary(false)}>Cancel</button>
                        </div>
                        <div class="post-salary">
                           <a href="javascript:void(0)" onClick={(e) => { this.AddSalary(e) }}>Add Salary</a>
                        </div>
                     </div>
                  </>}

               </Modal.Body>
               <Modal.Footer>
                  {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
               </Modal.Footer>
            </Modal>





            <Modal
               show={this.state.show_modal_review}
               onHide={() => this.showModalReview(false)}
               dialogClassName="modal-90w"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Add Review</h4>
               </Modal.Header>

               <Modal.Body>

                  {this.state.reviewStep == 1 && <>  <div>
                     <label>Search Company</label>
                     <ReactSearchAutocomplete
                        items={this.state.CompanyName}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                     // formatResult={formatResult}
                     />
                  </div>
                  </>}

                  {this.state.reviewStep === 2 && <>
                     <div class="img-salary">
                        <img src={this.state.url + '/uploads/' + this.state.CompanyInfo.logo} />
                     </div>
                     <p style={{ textAlign: 'center' }}>{this.state.CompanyInfo.company_name}</p>
                     <p className="rating_text">
                        Choose Rating
                     </p>
                     <ReactStars
                        classNames='react_star'
                        count={5}
                        isHalf={true}
                        onChange={ratingChanged}
                        size={24}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                     />
                     <div style={{ margin: '1rem 0' }}>
                        <ReactSearchAutocomplete
                           placeholder="Job Title"
                           items={this.state.Title}
                           onSearch={handleOnSearch}
                           onHover={handleOnHover}
                           onSelect={handleOnSelectTitle}
                           onFocus={handleOnFocus}
                           autoFocus
                           styling={
                              {
                                 position: 'absolute',
                                 zIndex: '5',
                              }
                           }
                        // formatResult={formatResult}
                        />
                     </div>

                     <div>
                        <ReactSearchAutocomplete
                           placeholder="Job Type"
                           items={this.state.Type}
                           onSearch={handleOnSearch}
                           onHover={handleOnHover}
                           onSelect={handleOnSelectType}
                           onFocus={handleOnFocus}
                           autoFocus
                           styling={{
                              position: 'absolute',
                              zIndex: '0',
                           }}
                        // formatResult={formatResult}
                        />
                     </div>
                     <div class="radio-btns">
                        <p style={{ fontSize: '14px' }}>
                           Choose Employee Type
                        </p>
                        <input type="radio" style={{ visibility: 'hidden' }} onChange={e => { this.handleInput(e) }} name="employer_type" id='currently' value="currently" />
                        <label htmlFor="currently" className={`input_button_employee ${this.state.current ? "btnactive" : ""}`}
                           onClick={(e) => this.setState({
                              current: true,
                              former: false
                           })} >
                           Currently Working
                        </label>
                        <input type="radio" style={{ visibility: 'hidden' }} onChange={e => { this.handleInput(e) }} id='old' name="employer_type" value="old" />
                        <label htmlFor="old" className={`input_button_employee ml-1 ${this.state.former ? "btnactive" : ""}`}
                           onClick={(e) => this.setState({
                              former: true,
                              current: false
                           })}>
                           Former Employee
                        </label>
                     </div>
                     <div>
                        <input type="text" placeholder="Review Title" name="reviewTitle" class="form-control" onChange={e => { this.handleInput(e) }} />
                     </div>
                     <div className="procon_text">
                        <textarea cols="10" placeholder="Pros" onChange={e => { this.handleInput(e) }} name="pros" rows="5"></textarea>
                     </div>
                     <div className="procon_text" >
                        <textarea cols="10" placeholder="Cons" onChange={e => { this.handleInput(e) }} name="cons" rows="5"></textarea>
                     </div>
                     <div className="d-flex justify-content-between border-top mt-5">
                        <div class="cancel-salary">
                           <button onClick={() => this.showModalReview(false)}>Cancel</button>
                        </div>
                        <div class="post-salary">
                           <a href="javascript:void(0)" onClick={(e) => { this.AddReview(e) }}>Add Review</a>
                        </div>
                     </div>
                  </>}
               </Modal.Body>
               <Modal.Footer>
                  {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.show_modal_interview}
               onHide={() => this.showModalInterview(false)}
               dialogClassName="modal-90w"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Add Interview</h4>
               </Modal.Header>

               <Modal.Body>

                  {this.state.interviewStep == 1 && <>  <div>
                     <label>Search Company</label>
                     <ReactSearchAutocomplete
                        items={this.state.CompanyName}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                     // formatResult={formatResult}
                     />

                  </div>
                  </>}

                  {this.state.interviewStep === 2 && <>
                     <div class="img-salary">
                        <img src={this.state.url + '/uploads/' + this.state.CompanyInfo.logo} />
                     </div>
                     <p className="companyname">{this.state.CompanyInfo.company_name}</p>

                     {/* <div class="radio-btns">
                        <lable>
                           Your Interview is completely anonymous
                        </lable>
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="type" value="positive" />Positive
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="type" value="neutral" />Neutral
                        <input type="radio" onChange={e => { this.handleInput(e) }} name="type" value="negative" />Negative
                     </div> */}
                     <div>
                        <ReactSearchAutocomplete
                           placeholder="Job Title"
                           items={this.state.Title}
                           onSearch={handleOnSearch}
                           onHover={handleOnHover}
                           onSelect={handleOnSelectTitle}
                           onFocus={handleOnFocus}
                           autoFocus
                        // formatResult={formatResult}
                        />
                     </div>
                     <div class="enterlocation">
                        <input
                           placeholder="Hiring and interview process?"
                           type="text"
                           class="form-control"
                           onChange={e => { this.handleInput(e) }}
                           name="description" />
                     </div>

                     <div class="enterlocation">
                        <input
                           placeholder="Interview Outcome"
                           type="text"
                           class="form-control"
                           onChange={e => { this.handleInput(e) }}
                           name="interview_outcome" />
                     </div>
                     <div class="enterlocation">
                        <lable>
                           Interview Question
                        </lable>
                        {this.state.formValues.map((element, index) => (
                           <div className="form-inline" key={index}>
                              <input type="text" placeholder="Question"
                                 class="form-control" name="question" value={element.question || ""} onChange={e => this.handleChange(index, e)} />
                              {
                                 index ?
                                    <div class="cl-btn">  <button type="button" className="button remove" onClick={() => this.removeFormFields(index)} class="colse-btn"><i class="fa fa-times" aria-hidden="true"></i></button></div>
                                    : null
                              }
                              <input type="text" placeholder="Answer"
                                 class="form-control" name="answer" value={element.answer || ""} onChange={e => this.handleChange(index, e)} />
                           </div>
                        ))}
                        <button className="button add-new" type="button" onClick={() => this.addFormFields()}>
                           Add Questions
                        </button>
                     </div>
                     <div className="d-flex justify-content-between border-top mt-5">
                        <div class="cancel-salary">
                           <button onClick={() => this.showModalInterview(false)}>Cancel</button>
                        </div>
                        <div class="post-salary">
                           <a href="javascript:void(0)" onClick={(e) => { this.AddInterview(e) }}>Submit</a>
                        </div>
                     </div>
                  </>}

               </Modal.Body>
               <Modal.Footer>
                  {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
               </Modal.Footer>
            </Modal>

            <Modal
               show={this.state.show_modal_topic}
               onHide={() => this.showModalTopic(false)}
               dialogClassName="modal-90w createtopic"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Create Topic</h4>
               </Modal.Header>

               <Modal.Body>
                  {this.state.topicStep == 1 && <>   <div class="topic-details">
                     <img class="" src={this.state.userDetail.image} /> <span>{this.state.userDetail.name}</span>
                  </div>

                     <lablel>Search</lablel>
                     <ReactSearchAutocomplete
                        items={this.state.Topic}
                        onSearch={handleOnSearchTopic}
                        onHover={handleOnHover}
                        onSelect={handleOnSelectTopic}
                        onFocus={handleOnFocus}
                        autoFocus
                     // formatResult={formatResult}
                     />
                     <div class="latest-top">
                        <lable>Latest Topic</lable>
                        <div class="row">
                           {this.state.JobTopic && this.state.JobTopic.length > 0 && <>
                              {this.state.JobTopic.map((cr, index) => {
                                 if (index <= 4) {
                                    return <a href="javascript:void()" onClick={(e) => { this.SingleTopic(e, cr.id, cr.topic) }} class="latest-post-block">
                                       <div class="gfds">
                                          <div class="post-data">
                                             <img src={this.state.url + '/uploads/' + cr.image} alt="" />
                                             <p>{cr.topic}</p>
                                          </div>
                                       </div>
                                    </a>
                                 }
                              })}
                           </>}
                        </div>
                     </div>
                     <div>
                        <lable>Trending Question</lable>
                        {this.state.JobPosts && this.state.JobPosts.length > 0 && <>
                           {this.state.JobPosts.map((cr, index) => {
                              if (index <= 5) {
                                 return <a href="javascript:void(0);" onClick={(e) => { this.SinglePost(e, cr.id) }} class="post-tr-blk">
                                    <div class="treading-post">
                                       <img src={this.state.url + cr.image} alt="" />
                                       <p>{cr.title}</p>
                                       <p>{cr.description}</p>
                                    </div>
                                 </a>
                              }
                           })}
                        </>}
                     </div>
                     <div class="add-query">
                        <a href="javascript:void(0);" onClick={(e) => { this.TopicNext(e) }}>Add a Query</a>
                     </div>
                  </>}
                  {this.state.topicStep == 2 && <>
                     <form onSubmit={this.onFormSubmit}>
                        <div class="back-arrow">
                           <a href="javascript:void(0);" onClick={(e) => { this.BackTopic(e) }}><i class="fa fa-long-arrow-left" aria-hidden="true"></i></a>
                        </div>
                        <div class="" topicss>
                           <lable>Topic</lable>
                           <ReactSearchAutocomplete
                              items={this.state.Topic}
                              onSearch={handleOnSearchTopic}
                              onHover={handleOnHover}
                              onSelect={handleOnSelectTopic}
                              onFocus={handleOnFocus}
                              autoFocus
                           // formatResult={formatResult}
                           />
                        </div>
                        <div>
                           <label> Company</label>
                           <ReactSearchAutocomplete

                              items={this.state.CompanyName}
                              onSearch={handleOnSearch}
                              onHover={handleOnHover}
                              onSelect={handleOnSelect}
                              onFocus={handleOnFocus}
                              autoFocus
                           // formatResult={formatResult}
                           />
                        </div>
                        <div>
                           <lable>Job Title</lable>
                           <ReactSearchAutocomplete
                              items={this.state.Title}
                              onSearch={handleOnSearch}
                              onHover={handleOnHover}
                              onSelect={handleOnSelectTitle}
                              onFocus={handleOnFocus}
                              autoFocus
                           // formatResult={formatResult}
                           />
                        </div>
                        <div>

                           <input type="text" class="form-control" placeholder="Enter Title" onChange={e => { this.handleInput(e) }} name="topic_title" />
                        </div>
                        <div>


                           <textarea cols="10" onChange={e => { this.handleInput(e) }} placeholder="Description" name="topic_description" rows="5"></textarea>
                        </div>
                        <div>
                           <label>Choose Image</label>
                           <input type="file" id="myfile" name="topic_image" onChange={this.onChange} />
                        </div>
                        <div class="add-query">
                           <input type="submit" value="Submit" />
                        </div>
                     </form>
                  </>}

                  {this.state.topicStep === 3 && <>
                     <div class="back-arrow">
                        <a href="javascript:void(0);" onClick={(e) => { this.BackTopic(e) }}><i class="fa fa-long-arrow-left" aria-hidden="true"></i></a>
                     </div>
                     <div>
                        <lable>{this.state.topicName}</lable>
                        {this.state.TopicPosts && this.state.TopicPosts.length > 0 && <>
                           {this.state.TopicPosts.map((cr, index) => {
                              if (index <= 20) {
                                 return <a href="javascript:void(0);" class="post-tr-blk">
                                    <div class="treading-post">
                                       <img src={this.state.url + cr.image} alt="" />
                                       <p>{cr.title}</p>
                                       <p>{cr.description}</p>
                                       <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                    </div>
                                 </a>
                              }
                           })}
                        </>}
                     </div>


                  </>}

                  {this.state.topicStep === 4 && <>
                     <div class="topic-detailss">
                        <img src={this.state.url + this.state.SinglePostData.image} alt="" />
                        <h3>{this.state.SinglePostData.title}</h3>
                        <p>{this.state.SinglePostData.description}</p>
                        <p>{moment.utc(this.state.SinglePostData.created_at).local().startOf('seconds').fromNow()}</p>
                     </div>

                     {this.state.CommentData && this.state.CommentData.length > 0 && <>
                        {this.state.CommentData.map((cr, index) => {
                           return <div class="topic-detailss">
                              <img src={cr.user.image} alt="" />
                              <h3>{cr.user.name}</h3>
                              <p>{cr.message}</p>
                              <p>
                                 {cr.image != null && <> <img src={this.state.url + cr.image} alt="" /> </>}
                              </p>
                              <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                           </div>
                        })}
                     </>}

                     <form onSubmit={this.onFormSubmitComment}>
                        <div>
                           <input type="text" name="comment" value={this.state.comment} class="form-control" onChange={e => { this.handleInput(e) }} />
                        </div>
                        <div>
                           <label>Choose Image</label>
                           <input type="file" id="myfile" name="comment_image" onChange={this.onChange} />
                        </div>
                        <div class="add-query">
                           <input type="submit" value="Submit" />
                        </div>
                     </form>

                  </>}




               </Modal.Body>
               <Modal.Footer>
               </Modal.Footer>
            </Modal>


            <Modal
               show={this.state.show_modal_job}
               onHide={() => this.showModalJob(false)}
               dialogClassName="modal-90w"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Apply Job</h4>
               </Modal.Header>

               <Modal.Body>
                  <h2>Upload resume</h2>
                  <form onSubmit={this.onFormSubmitJob}>
                     <div class="form-group">
                        <input type="text" class="form-control" id="name" onChange={e => { this.handleInput(e) }} name="name" placeholder="Enter Name" />
                     </div>
                     <div class="form-group">
                        <input type="text" class="form-control" id="email" onChange={e => { this.handleInput(e) }} name="email" placeholder="Enter email" />
                     </div>
                     <div class="form-group">
                        <input type="text" class="form-control" id="phone" onChange={e => { this.handleInput(e) }} name="phone" placeholder="phone" />
                     </div>
                     {this.state.resume_id && <>
                        <a href="javascript:void()" onClick={(e) => { this.NewResume(e) }}>Upload New Resume</a>
                     </>}
                     {!this.state.resume_id && <>
                        <div class="form-group">
                           {this.state.imgUrl && <>
                              <img src={this.state.imgUrl} />
                              <a href="javascript:void()" onClick={(e) => { this.RemoveImage(e) }} class="colse-btn"><i class="fa fa-times" aria-hidden="true"></i></a>
                           </>}
                           {this.state.imgUrl == "" && <> <div class="uploadresume">

                              <input type="file" id="myfile" name="image" onChange={this.onChangeJob} />

                              <img src="/admin/dist/images/uploadfile.png" />
                           </div>
                           </>}

                           <p>Please select a CV/Resume. CV Must be.PDF
                              or .DOC file.</p>
                        </div>
                     </>}

                     <div class="upload-btn">
                        <button type="submit" class="btn btn-primary">Submit</button>
                     </div>
                  </form>
                  <div class="my-resume-sec">
                     <h2>My Resume</h2>
                     <div class="resume_maine">
                        {this.state.allResumes && this.state.allResumes.length > 0 && <>
                           {this.state.allResumes.map((cr, index) => {
                              return <div class="resume-innersec">
                                 <div class="row">
                                    <input type="radio" disabled={this.state.imgUrl} name="resume_id" onChange={e => { this.handleInput(e) }} value={cr.id} />
                                    <div class="col-md-10">
                                       <div class="tabs-data">
                                          <div class="left-side-sec">
                                             <img src="/admin/dist/images/resumes.png" />
                                          </div>
                                          <div class="right-side-sec toppadd">
                                             <h4>{cr.name}</h4>
                                             <p>{moment(cr.created_at).format('DD-MMM-YYYY')}</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           })}
                        </>}
                     </div>
                  </div>
               </Modal.Body>
               <Modal.Footer>
               </Modal.Footer>
            </Modal>
         </>
      )
   }
}

export default Dashboard;
import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal } from "react-bootstrap";
import StarRatings from 'react-star-ratings';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      name: "",
      url: process.env.MIX_REACT_API_URL,
      allReveiw: [],
      allSalary: [],
      title: "",
      pros: "",
      cons: "",
      salary: "",
      salary_type: "",
      interview_outcome: "",
      type: "",
      description: "",
      experience: "",
      allJobTitle: [],
      location: "",
      rating: "",
      allInterview: [],
      allQuestion: [],
      show_edit_Review: false,
      show_edit_salary: false,
      show_edit_interview: false,
      edit_question: false,
      allUsers: [],
      allCompany: [],
      selected_Review: [],
      pendingCompany: [],
      company_modal: false,
      selected_company: null,
      edit_company_details: false,
      allIndustry: [],
      company_status: "active",
      company_email: "",
      founded: "",
      size: "",
      last_name: "",
      company_name: "",
      headqarter: "",
      website: "",
      ceo_first_name: "",
      industry_id: ""
    }
  }
  componentDidMount() {
    if(!sessionStorage.getItem("_access")){
        window.location.href = '/admin/login';
    }
    this.getPendingReview();
    this.getPendingSalary();
    this.getPendingInterview();
    this.getAllUsers();
    this.getAllCompany();
    this.getTitle()
    this.getPendingCompany();
    this.getIndustry();
  }

  showReviewModal(show, e, index) {
    if (show === false) {
      this.setState({
        selected_Review: [],
        show_review_modal: false,
      })
    } else {
      var selected = this.state.allReveiw[index];
      this.setState({
        show_review_modal: show,
        id: selected.id,
        company_name: selected.company_name,
        rating: selected.rating,
        cons: selected.cons,
        logo: selected.logo,
        image: selected.image,
        pros: selected.pros,
        status: selected.status,
        user_name: selected.user_name,
        title: selected.title
      })
    }
  }
  showSalaryModal(show, e, index) {
    if (show === false) {
      this.setState({
        selected_Review: [],
        show_salary_modal: false,
        show_edit_salary: false
      })
    } else {
      var selected = this.state.allSalary[index];
      this.setState({
        show_salary_modal: show,
        id: selected.id,
        company_name: selected.company_name,
        logo: selected.logo,
        job_title_id: selected.job_title_id,
        job_title: selected.job_title,
        employer_type: selected.employer_type,
        image: selected.image,
        salary_type: selected.salary_type,
        salary: selected.salary,
        experience: selected.experience,
        status: selected.status,
        user_name: selected.user_name,
        location: selected.location
      })
    }
  }

  showInterviewModal(show, e, index) {
    if (show === false) {
      this.setState({
        selected_Review: [],
        show_interview_modal: false,
      })
    } else {
      var selected = this.state.allInterview[index];
      this.setState({
        index: index,
      })
      this.getInverviewQuestion(index, show)

    }
  }
  showCompanyModal = (e = null, company = null) => {
    if (e) e.preventDefault();
    console.log(company)
    this.setState({
      company_modal: !this.state.company_modal,
      selected_company: company
    })
  }

  getInverviewQuestion = (index, show) => {
    var selected = this.state.allInterview[index];
    Axios.post(`${this.state.url}/get/interview-question`, { id: selected.id }, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status == true) {
        this.setState({
          allQuestion: res.data.data,
          show_interview_modal: show,
          id: selected.id,
          company_name: selected.company_name,
          type: selected.type,
          logo: selected.logo,
          image: selected.image,
          description: selected.description,
          interview_outcome: selected.interview_outcome,
          status: selected.status,
          user_name: selected.user_name,
          job_title: selected.job_title
        })
      }
    })
  }

  getPendingReview = (e) => {
    Axios.get(`${this.state.url}/get/pending/review`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        allReveiw: res.data.data
      })
      console.log("this.state.allReveiw", this.state.allReveiw);
    });
  }

  getPendingSalary = (e) => {
    Axios.get(`${this.state.url}/get/pending/salary`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        allSalary: res.data.data
      })
    });
  }

  getPendingInterview = (e) => {
    Axios.get(`${this.state.url}/get/pending/interview`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        allInterview: res.data.data
      })
    });

  }

  getPendingCompany = (e) => {
    Axios.get(`${this.state.url}/get/pending/company`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        pendingCompany: res.data.data
      })
    });

  }
  getAllUsers = (e) => {
    Axios.get(`${this.state.url}/get/all/users`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        allUsers: res.data.data
      })
    });
  }

  getAllCompany = (e) => {
    Axios.get(`${this.state.url}/get/all/company`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        allCompany: res.data.data
      })
    });
  }

  editReview = (e, id) => {
    this.setState({
      show_edit_Review: true
    })
  }
  handleInput(e) {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.value })
  }
  updateReview = (e) => {
    e.preventDefault();
    console.log("this.state.title", this.state.title)
    if (this.state.title === "") {
      NotificationManager.error("error", "Please enter title.");
      return false;
    }
    if (this.state.pros === "") {
      NotificationManager.error("error", "Please enter pros.");
      return false;
    }
    if (this.state.cons === "") {
      NotificationManager.error("error", "Please enter cons.");
      return false;
    }
    if (this.state.rating === "") {
      NotificationManager.error("error", "Please enter rating.");
      return false;
    }
    if (this.state.rating > 5) {
      NotificationManager.error("error", "Rating should not be greated than 5.");
      return false;
    }
    var data = {
      title: this.state.title,
      status: this.state.status,
      id: this.state.id ? this.state.id : "",
      cons: this.state.cons,
      pros: this.state.pros,
      rating: this.state.rating,
    }
    Axios.post(`${this.state.url}/update/review`, data, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status == true) {
        this.getPendingReview()
        this.setState({
          show_edit_Review: false,
          show_review_modal: false

        })
        NotificationManager.success("success", "Review successfully updated");
        this.getDepartment()
      } else {
        NotificationManager.error("error", "Name Already Exist");
      }
    })
  }

  editSalary = (e, id) => {
    this.setState({
      show_edit_salary: true
    })
  }

  updateSalary = (e) => {
    e.preventDefault();
    console.log("this.state.title", this.state.title)
    if (this.state.salary === "") {
      NotificationManager.error("error", "Please enter salary.");
      return false;
    }
    if (this.state.salary_type === "") {
      NotificationManager.error("error", "Please select Salary Type.");
      return false;
    }
    if (this.state.experience === "") {
      NotificationManager.error("error", "Please enter experience.");
      return false;
    }
    if (this.state.location === "") {
      NotificationManager.error("error", "Please enter location.");
      return false;
    }

    var data = {
      salary: this.state.salary,
      status: this.state.status,
      id: this.state.id ? this.state.id : "",
      salary_type: this.state.salary_type,
      experience: this.state.experience,
      employer_type: this.state.employer_type,
      job_title: this.state.job_title,
      location: this.state.location,
    }
    Axios.post(`${this.state.url}/update/salary`, data, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status == true) {
        this.getPendingSalary()
        this.setState({
          show_edit_salary: false,
          show_salary_modal: false

        })
        NotificationManager.success("success", "Salary successfully updated");
        this.getDepartment()
      } else {
        NotificationManager.error("error", "Name Already Exist");
      }
    })
  }

  editInterview = (e, id) => {
    this.setState({
      show_edit_interview: true
    })
  }

  editQuestion = (e, index, id) => {
    var selected = this.state.allQuestion[index];
    this.setState({
      edit_question: true,
      question: selected.question,
      answer: selected.answer,
      id: selected.id,
      status: selected.status
    })
  }

  backQuestion = (e) => {
    this.setState({
      edit_question: false
    })
  }

  removeQuestion = (e, id, show) => {
    e.preventDefault()
    Axios.post(`${this.state.url}/remove/question`, { id: id }, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status == true) {
        this.getInverviewQuestion(this.state.index, show);
        NotificationManager.success("success", "Question successfully Removed");
      } else {
        NotificationManager.error("error", "Name Already Exist");
      }
    })
  }

  updateQuestion = (e, id) => {
    var data = {
      question: this.state.question,
      answer: this.state.answer,
      id: this.state.id
    }
    e.preventDefault()
    Axios.post(`${this.state.url}/update/question`, data, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status == true) {
        this.setState({
          edit_question: false
        })
        this.getInverviewQuestion(this.state.index, true);
        NotificationManager.success("success", "Question successfully Updated");
      } else {
        NotificationManager.error("error", "Name Already Exist");
      }
    })
  }

  updateInterview = (e, id) => {
    var data = {
      type: this.state.type,
      interview_outcome: this.state.interview_outcome,
      status: this.state.status,
      description: this.state.description,
      id: this.state.id
    }
    e.preventDefault()
    Axios.post(`${this.state.url}/update/interview`, data, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res.data)
      if (res.data.status == true) {
        this.setState({
          show_interview_modal: false,
          show_edit_interview: false
        })
        this.getPendingInterview();
        NotificationManager.success("success", "Interview successfully Updated");
      } else {
        NotificationManager.error("error", "Name Already Exist");
      }
    })
  }

  handleRadio = (e) => {
    if (e.target.checked) {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  getTitle = (e) => {
    Axios.get(`${this.state.url}/get/title`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      this.setState({
        allJobTitle: res.data.data,
      })
    });
  }
  editCompany = e => {
    e.preventDefault();
    this.setState({
      edit_company_details: !this.state.edit_company_details,
      company_status: "active",
      company_email: this.state.selected_company.company_email,
      founded: this.state.selected_company.founded,
      size: this.state.selected_company.size,
      last_name: this.state.selected_company.last_name,
      company_name: this.state.selected_company.company_name,
      headqarter: this.state.selected_company.headqarter,
      website: this.state.selected_company.website,
      first_name: this.state.selected_company.first_name,
      industry_id: this.state.selected_company.industry.id
    })
  }
  render() {
    return (<>
      <NotificationContainer />
      <Navbar page="Admin Dashboard" />
      <Sidebar />
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Dashboard</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  {/* <li class="breadcrumb-item active">Dashboard v1</li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{this.state.allUsers ? this.state.allUsers.length : 0}</h3>

                    <p> All Users</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{this.state.allCompany.length}</h3>

                    <p>All Companies</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>44</h3>

                    <p>User Registrations</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>65</h3>

                    <p>Unique Visitors</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
            </div>
            <div class="row first-block">
              <div class="col-md-4">
                <div class="block-sec">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="3">Review</th>
                        </tr>
                      </thead>
                      <div class="maine-block-height">
                        <tbody>
                          {this.state.allReveiw.length > 0 && <>
                            {this.state.allReveiw.map((list, index) => {
                              return <tr>
                                <td>{list.user_name} added new review </td>
                                <td><a href="javascript::void()" onClick={(e) => { this.showReviewModal(true, e, index) }} class="">View</a></td>
                              </tr>
                            })}
                          </>}
                        </tbody>
                      </div>
                      {/* <tfoot>
                    <tr>
                      <td colspan="2">Sum</td>
                      <td>$180</td>
                    </tr>
                  </tfoot> */}
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="block-sec">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="3">Salary</th>
                        </tr>
                      </thead>
                      <div class="maine-block-height">
                        <tbody>
                          {this.state.allSalary.length > 0 && <>
                            {this.state.allSalary.map((list, index) => {
                              return <tr>
                                <td>{list.user_name} added new salary </td>
                                <td><a href="javascript::void()" onClick={(e) => { this.showSalaryModal(true, e, index) }} class="">View</a></td>
                              </tr>
                            })}
                          </>}
                        </tbody>
                      </div>
                      {/* <tfoot>
                    <tr>
                      <td colspan="2">Sum</td>
                      <td>$180</td>
                    </tr>
                  </tfoot> */}
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="block-sec">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="3">Interview</th>
                        </tr>
                      </thead>
                      <div class="maine-block-height">
                        <tbody>
                          {this.state.allInterview.length > 0 && <>
                            {this.state.allInterview.map((list, index) => {
                              return <tr>
                                <td>{list.user_name} added new interview </td>
                                <td><a href="javascript::void()" onClick={(e) => { this.showInterviewModal(true, e, index) }} class="">View</a></td>
                              </tr>
                            })}
                          </>}
                        </tbody>
                      </div>
                      {/* <tfoot>
                    <tr>
                      <td colspan="2">Sum</td>
                      <td>$180</td>
                    </tr>
                  </tfoot> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              {/* <div class="col-md-6">
                <div class="block-sec">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="3">Job</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="2">John</td>
                          <td>Doe</td>
                        </tr>

                      </tbody>

                    </table>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-md-6">
                <div class="block-sec">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="3">Company</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.pendingCompany.length > 0 && <>
                          {this.state.pendingCompany.map((list, index) => {
                            return <tr key={index}>
                              <td>{list.user_name} added new company </td>
                              <td><a href="" onClick={(e) => { this.showCompanyModal(e, list) }} class="">View</a></td>
                            </tr>
                          })}
                        </>}
                      </tbody>

                    </table>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </section>
      </div>

      <Modal
        show={this.state.show_interview_modal}
        onHide={() => this.showInterviewModal(false)}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <h4 class="title-headingsec">Interiew Detail</h4>
        </Modal.Header>

        <Modal.Body>
          <div class="form-title text-center">

          </div>
          <div class="d-flex flex-column text-center">
            <div class="col-md-12">
              <div class="block-sec">
                <div class="table-responsive">

                  <h4>Question Detail</h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.edit_question === false && <>
                        {this.state.allQuestion.length > 0 && <>
                          {this.state.allQuestion.map((list, index) => {
                            return <tr>
                              <td>{index + 1}</td>
                              <td>{list.question}</td>
                              <td>{list.answer}</td>
                              <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                              <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editQuestion(e, index, list._id) }}></i></a> || <a href="javascript:void(0)"><i class="fa fa-times" onClick={(e) => { if (window.confirm('Are you sure you want to remove question?')) this.removeQuestion(e, list.id, true) }}></i></a></td>
                            </tr>
                          })}
                        </>}
                      </>}

                      {this.state.edit_question && <>
                        <tr>
                          <td>{this.state.id}</td>
                          <td><input type="text" value={this.state.question} name="question" onChange={(e) => { this.handleInput(e) }} class="form-control" /></td>
                          <td><input type="text" value={this.state.answer} name="answer" onChange={(e) => { this.handleInput(e) }} class="form-control" /></td>
                          <td>{this.state.status}</td>
                          <td class="edit-icon"><a href="javascript::void(0);" onClick={(e) => { this.updateQuestion(e, this.state.id) }}>Update</a> | <a href="javascript::void(0);" onClick={(e) => { this.backQuestion(e, this.state.id) }}>Back</a></td>
                        </tr>

                      </>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
          <div class="review-user-details">
            <div class="col-md-12">
              <div class="row">

                <div class="col-md-12">
                  <div class="user-details new-add-interview">
                    {/* <h2 class="reviewby">Add Interview</h2> */}
                    <div class="user-image">
                      {this.state.image == "" && <><img src="dist/img/user8-128x128.jpg" alt="" class="user-image" /> </>}
                      {this.state.image != "" && <><img src={this.state.url + '/uploads/' + this.state.image} alt="" class="user-image" /> </>}
                    </div>
                    <div class="user-name-sec">
                      {/* <h3>{this.state.user_name}</h3> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row reviewby-sec ineterviewadd">
                <div class="col-md-4">
                  <div class="company-details addnnewdetails">
                  <a href="javascript::void(0);"><i class="far fa-edit" onClick={(e) => { this.editInterview(e, this.state.id) }}></i></a>
                    <div class="comapny-image">
                      <img src={this.state.url + '/uploads/' + this.state.logo} alt="Logo" />

                    </div>
                    <div class="rating-se">
                      <span class="job-type"><b>{this.state.company_name}</b></span>
                      {/* <ul><li>Information Technology Company</li>
                          </ul> */}
                    </div>
                  </div>

                </div>
                {this.state.show_edit_interview === false && <> <div class="col-md-8">

                  <div class="row">
                    <div class="col-md-6">
                      <div class="user-details-inner">
                      <span class="job-title"><b>User Name</b>{this.state.user_name}</span>
                        <span class="job-title"><b>Interview Type</b>{this.state.type}</span>

                        <span class="job-title"><b>Interview Outcomes</b>{this.state.interview_outcome}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Job Title</b>{this.state.job_title}</span>
                        <span class="job-type"><b>Description</b>{this.state.description}</span>
                      </div>
                    </div>
                  </div>
                </div>
                </>}

                {this.state.show_edit_interview && <> <div class="col-md-8">

                  <div class="row">
                    <div class="col-md-6">
                      <div class="user-details-inner">
                      <span class="job-title"><b>User Name</b>{this.state.user_name}</span>
                        <span class="job-title"><b>Interview Type</b>
                        <select class="custom-select" value={this.state.type} onChange={(e) => { this.handleInput(e) }} name="type">
                            <option value="">Select Type</option>
                            <option selected={this.state.type === 'neutral'} value="neutral">Neutral</option>
                            <option selected={this.state.type === 'positive'} value="positive">Positive</option>
                            <option selected={this.state.type === 'negative'} value="negative">Negative</option>
                          </select>
                        </span>

                        <span class="job-title"><b>Interview Outcomes</b>
                        <select class="custom-select" value={this.state.interview_outcome} onChange={(e) => { this.handleInput(e) }} name="interview_outcome">
                            <option value="">Select Outcomes</option>
                            <option selected={this.state.interview_outcome === 'accepted'} value="accepted">Accepted</option>
                            <option selected={this.state.interview_outcome === 'declined'} value="declined">Declined</option>
                            <option selected={this.state.interview_outcome === 'no offer'} value="no offer">No Offer</option>
                          </select>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Description</b>
                        <input type="text" value={this.state.description} name="description" onChange={(e) => { this.handleInput(e) }} class="form-control" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                </>}
              </div>
              <div class="row btn-section">
                <div class="col-md-6">
                  <div class="updatebtn">
                    <a href="javascript:void(0)" onClick={(e) => { this.updateInterview(e, this.state.id) }}>Approve</a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="radio">
                    <input id="radio-1" name="status" checked={this.state.status === 'active'} onClick={(e) => { this.handleRadio(e) }} value="active" type="radio" />
                    <label htmlFor="radio-1" class="radio-label">Active</label>
                  </div>

                  <div class="radio">
                    <input id="radio-2" checked={this.state.status === 'inactive'} name="status" onClick={(e) => { this.handleRadio(e) }} value="inactive" type="radio" />
                    <label htmlFor="radio-2" class="radio-label">Inactive</label>
                  </div>
                </div>
              </div>

            </div>
          </div>



          {/* <div class="review-user-details">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="user-details new-add-interview">
                    <h2 class="reviewby">Add Job</h2>
                    <div class="user-image">
                      <img src="dist/img/user8-128x128.jpg" alt="" class="user-image" />
                    </div>
                    <div class="user-name-sec">
                      <h3>Sudesh Kumar</h3>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="col-md-12">
              <div class="row reviewby-sec ineterviewadd">

                <div class="col-md-12">

                  <div class="row">
                    <div class="col-md-1">
                    </div>
                    <div class="col-md-5">
                      <div class="user-details-inner">
                        <span class="job-title"><b>Company</b>Lorem Ipsum is simply</span>
                        <span class="job-title"><b>Job Title</b>Lorem Ipsum is simply </span>
                        <span class="job-title"><b>Location</b>Lorem Ipsum is simply</span>
                        <span class="job-title"><b>Range</b>Lorem Ipsum is simply</span>
                        <span class="job-title"><b>Overview</b>Lorem Ipsum is simply</span>
                        <span class="job-title"><b>Expiry Date</b>14-01-2021</span>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Department</b>Android</span>
                        <span class="job-type"><b>Job Type</b>Lorem Ipsum is simply</span>
                        <span class="job-title"><b>Remote</b>Yes</span>
                        <span class="job-title"><b>Description</b>Lorem Ipsum is simply</span>
                        <span class="job-title"><b>Requirements</b>Lorem Ipsum is simply</span>
                        <span class="job-title"><b>Banner image</b><div class="banner-img"><img src="dist/img/photo1.png" alt="" /></div></span>
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row btn-section">
                <div class="col-md-6">
                  <div class="updatebtn">
                    <a href="javascript:void(0)">Approved</a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="radio">
                    <input id="radio-1" name="radio" type="radio" defaultChecked />
                    <label htmlFor="radio-1" class="radio-label">Active</label>
                  </div>

                  <div class="radio">
                    <input id="radio-2" name="radio" type="radio" />
                    <label htmlFor="radio-2" class="radio-label">Inactive</label>
                  </div>
                </div>
              </div>

            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={this.state.show_salary_modal}
        onHide={() => this.showSalaryModal(false)}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <h4 class="title-headingsec">Salary Detail</h4>
        </Modal.Header>
        <Modal.Body>
          <div class="form-title text-center">

          </div>
          <div class="d-flex flex-column text-center jjjj">


          </div>
          <div class="review-user-details">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="user-details">
                    <h5>Add Salary <a href="javascript::void(0);"><i class="far fa-edit" onClick={(e) => { this.editSalary(e, this.state.id) }}></i></a></h5>
                    <div class="user-image">
                      {this.state.image == "" && <><img src="dist/img/user8-128x128.jpg" alt="" class="user-image" /> </>}
                      {this.state.image != "" && <><img src={this.state.url + '/uploads/' + this.state.image} alt="" class="user-image" /> </>}
                    </div>
                    <div class="user-name-sec">
                      <h3>{this.state.user_name}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="company-details mani-company">
                    <div class="comapny-image">
                      <img src={this.state.url + '/uploads/' + this.state.logo} alt="Logo" />
                    </div>
                    <div class="rating-se">
                      <span class="job-type"><b>{this.state.company_name}</b></span>
                      {/* <ul><li>Information Technology Company</li>
                          </ul> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row reviewby-sec">

                {this.state.show_edit_salary === false && <> <div class="col-md-10 mx-auto">

                  <div class="row">
                    <div class="col-md-7">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Salary Type</b>{this.state.salary_type}</span>
                        <span class="job-type"><b>Location</b>{this.state.location}</span>

                        <span class="job-title"><b>Salary</b>{this.state.salary}</span>

                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Job Title</b>{this.state.job_title}</span>
                        <span class="job-title"><b>Employer Type</b>{this.state.employer_type}</span>
                        <span class="job-title"><b>Experience</b>{this.state.experience}</span>

                      </div>
                    </div>
                  </div>
                </div> </>}

                {this.state.show_edit_salary && <> <div class="col-md-10 mx-auto">

                  <div class="row">
                    <div class="col-md-7">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Salary Type</b>
                          <select class="custom-select" value={this.state.salary_type} onChange={(e) => { this.handleInput(e) }} name="salary_type">
                            <option value="">Select Salary Type</option>
                            <option selected={this.state.salary_type === 'monthly'} value="monthly">Monthly</option>
                            <option selected={this.state.salary_type === 'yearly'} value="yearly">Yearly</option>
                            <option selected={this.state.salary_type === 'hourly'} value="hourly">Hourly</option>
                          </select>
                        </span>
                        <span class="job-type"><b>Location</b>
                          <input type="text" value={this.state.location} name="location" onChange={(e) => { this.handleInput(e) }} class="form-control" />
                        </span>

                        <span class="job-title"><b>Salary</b>
                          <input type="number" value={this.state.salary} name="salary" onChange={(e) => { this.handleInput(e) }} class="form-control" />
                        </span>

                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Job Title</b>
                          <select value={this.state.job_title} className="custom-select" name="job_title" onChange={(e) => { this.handleInput(e) }}>
                            <option value="">Select Job Title</option>
                            {this.state.allJobTitle.length > 0 && <>
                              {this.state.allJobTitle.map((cr, index) => {
                                return <option selected={this.state.job_title == cr.value} value={cr.value} data={index}>{cr.name}</option>;
                              })}
                            </>}
                          </select>
                        </span>
                        <span class="job-title"><b>Employer Type</b>
                          <select class="custom-select" value={this.state.employer_type} onChange={(e) => { this.handleInput(e) }} name="employer_type">
                            <option value="">Select Employer Type</option>
                            <option selected={this.state.employer_type === 'old'} value="old">Old</option>
                            <option selected={this.state.employer_type === 'current'} value="current">Current</option>
                          </select>
                        </span>
                        <span class="job-title"><b>Experience</b>
                          <input type="text" value={this.state.experience} name="experience" onChange={(e) => { this.handleInput(e) }} class="form-control" />
                        </span>

                      </div>
                    </div>
                  </div>
                </div>
                </>}
              </div>
              <div class="row btn-section">
                <div class="col-md-6">
                  <div class="updatebtn">
                    {this.state.show_edit_salary === false && <> <a onClick={(e) => { this.updateSalary(e, this.state.id) }} href="javascript:void(0)">Approved</a> </>}
                    {this.state.show_edit_salary && <> <a href="javascript::void(0);" onClick={(e) => { this.updateSalary(e, this.state.id) }}>Update</a> </>}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="radio">
                    <input id="radio-1" name="status" checked={this.state.status === 'active'} onClick={(e) => { this.handleRadio(e) }} value="active" type="radio" />
                    <label htmlFor="radio-1" class="radio-label">Active</label>
                  </div>

                  <div class="radio">
                    <input id="radio-2" checked={this.state.status === 'inactive'} name="status" onClick={(e) => { this.handleRadio(e) }} value="inactive" type="radio" />
                    <label htmlFor="radio-2" class="radio-label">Inactive</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={this.state.show_review_modal}
        onHide={() => this.showReviewModal(false)}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <h4 class="title-headingsec">Review Detail</h4>
        </Modal.Header>
        <Modal.Body>
          <div class="form-title text-center">

          </div>

          <div class="review-user-details">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-9">

                  <div class="company-details">
                    <div class="comapny-image">
                      <img src={this.state.url + '/uploads/' + this.state.logo} alt="Logo" />
                    </div>

                    <div class="rating-se">
                      <span class="job-type"><b>{this.state.company_name}</b></span>
                      <ul><li>{this.state.rating}</li>
                        <StarRatings
                          rating={this.state.rating}
                          starRatedColor="yellow"
                          // changeRating={this.changeRating}
                          numberOfStars={5}
                          name='rating'
                        />
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row reviewby-sec">
                <div class="col-md-3">
                  <div class="user-details">
                    <h2 class="reviewby">Review By {this.state.show_edit_Review === false && <> <a href="javascript::void(0);"><i class="far fa-edit" onClick={(e) => { this.editReview(e, this.state.id) }}></i></a> </>}</h2>

                    <div class="user-image">
                      {this.state.image == "" && <><img src="dist/img/user8-128x128.jpg" alt="" class="user-image" /> </>}
                      {this.state.image != "" && <><img src={this.state.url + '/uploads/' + this.state.image} alt="" class="user-image" /> </>}

                    </div>
                    <div class="user-name-sec">
                      <h3>{this.state.user_name}</h3>
                    </div>
                  </div>
                </div>
                {this.state.show_edit_Review === false && <> <div class="col-md-9">

                  <div class="row">
                    <div class="col-md-6">
                      <div class="user-details-inner">

                        <span class="job-title"><b>Job Title</b>{this.state.title}</span>
                        <span class="job-title"><b>Pros</b>{this.state.pros}</span>


                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Job Type</b>Full Time</span>

                        <span class="job-type"><b>Cons</b>{this.state.cons}</span>
                      </div>
                    </div>
                  </div>

                </div>
                </>}

                {this.state.show_edit_Review && <> <div class="col-md-9">

                  <div class="row">
                    <div class="col-md-6">
                      <div class="user-details-inner">

                        <span class="job-title"><b>Job Title</b><input type="text" value={this.state.title} name="title" onChange={(e) => { this.handleInput(e) }} class="form-control" /></span>
                        <span class="job-title"><b>Pros</b><input type="text" value={this.state.pros} name="pros" onChange={(e) => { this.handleInput(e) }} class="form-control" /></span>


                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="user-details-inner">
                        <span class="job-type"><b>Rating</b><input type="number" value={this.state.rating} name="rating" onChange={(e) => { this.handleInput(e) }} class="form-control" /></span>

                        <span class="job-type"><b>Cons</b><input type="text" value={this.state.cons} name="cons" onChange={(e) => { this.handleInput(e) }} class="form-control" /></span>
                      </div>
                    </div>
                  </div>

                </div>
                </>}


              </div>
              <div class="row btn-section">
                <div class="col-md-6">
                  <div class="updatebtn">
                    {this.state.show_edit_Review === false && <> <a onClick={(e) => { this.updateReview(e, this.state.id) }} href="javascript:void(0)">Approved</a> </>}
                    {this.state.show_edit_Review && <> <a href="javascript::void(0);" onClick={(e) => { this.updateReview(e, this.state.id) }}>Update</a> </>}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="radio">
                    <input id="radio-1" name="status" checked={this.state.status === 'active'} onClick={(e) => { this.handleRadio(e) }} value="active" type="radio" />
                    <label htmlFor="radio-1" class="radio-label">Active</label>
                  </div>

                  <div class="radio">
                    <input id="radio-2" checked={this.state.status === 'inactive'} name="status" onClick={(e) => { this.handleRadio(e) }} value="inactive" type="radio" />
                    <label htmlFor="radio-2" class="radio-label">Inactive</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ****************************************************************************************************** */}
      {/* ################################### COMPANY MODAL #################################################### */}
      {/* ****************************************************************************************************** */}
      <Modal
        show={this.state.company_modal}
        onHide={() => this.showCompanyModal()}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <h4 class="title-headingsec">Company Details</h4>
        </Modal.Header>

        <Modal.Body>

          <div class="review-user-details">
            {this.state.selected_company && this.state.selected_company.user && <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="user-details">
                    {/* <h2 class="reviewby">Add Company</h2> */}
                    <div class="user-image">
                      <img src={this.state.selected_company.user.image} alt="" class="user-image" />
                    </div>
                    <div class="user-name-sec">
                      <h3>{this.state.selected_company.user.name}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="company-details mani-company">
                    <div class="comapny-image">
                      <img src={this.state.url + "/uploads/" + this.state.selected_company.logo} alt="Logo" />
                    </div>
                    <div class="rating-se">
                      <span class="job-type"><b>{this.state.selected_company.company_name}</b></span>
                      <ul><li>{this.state.selected_company.industry.name}</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>}
            {this.state.selected_company && <div class="col-md-12">
              <div class="row reviewby-sec ineterviewadd">

                <div class="col-md-12">
                  {!this.state.edit_company_details && <a href="" onClick={(e) => { this.editCompany(e) }} style={{ float: "right" }}><i class="far fa-edit"></i></a>}
                  {this.state.edit_company_details && <a href="" onClick={(e) => { this.editCompany(e) }} style={{ float: "right" }}>Cancel</a>}

                  <div class="row">
                    <div class="col-md-1">
                    </div>
                    <div class="col-md-5">
                      {!this.state.edit_company_details && <div class="user-details-inner">
                        {this.state.selected_company.industry && <span class="job-title"><b>Industry</b>{this.state.selected_company.industry.name}</span>}
                        <span class="job-title"><b>CEO First Name</b>{this.state.selected_company.first_name} </span>
                        <span class="job-title"><b>Website</b><a href={!/^(?:f|ht)tps?\:\/\//.test(this.state.selected_company.website) ? "http://" + this.state.selected_company.website : this.state.selected_company.website} target="_blank">{this.state.selected_company.website}</a></span>
                        <span class="job-title"><b>Headquarters</b>{this.state.selected_company.headqarter}</span>
                        <span class="job-title"><b>Banner</b><div class="banner-img"><img src={this.state.url + "/uploads/" + this.state.selected_company.banner} alt="" /></div></span>
                      </div>}
                      {this.state.edit_company_details && <div class="user-details-inner">
                        {this.state.selected_company.industry && <span class="job-title"><b>Industry</b>
                          <select className="form-control" name="industry_id" onChange={(e) => { this.handleInput(e) }} value={this.state.selected_company.industry.id}>
                            <option value="">Select</option>
                            {this.state.allIndustry.map((ind, key) => {
                              return <option value={ind.id} key={key}>{ind.name}</option>
                            })}
                          </select>
                        </span>}
                        <span class="job-title">
                          <b>CEO First Name</b>
                          <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                        <span class="job-title"><b>Website</b>
                          <input type="text" className="form-control" name="website" value={this.state.website} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                        <span class="job-title"><b>Headquarters</b>
                          <input type="text" className="form-control" name="headqarter" value={this.state.headqarter} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                      </div>}
                    </div>
                    <div class="col-md-5">
                      {!this.state.edit_company_details && <div class="user-details-inner">
                        <span class="job-type"><b>Company</b>{this.state.selected_company.company_name}</span>
                        <span class="job-type"><b>CEO Last Name</b>{this.state.selected_company.last_name}</span>
                        <span class="job-title"><b>Company Size</b>{this.state.selected_company.size}</span>
                        <span class="job-title"><b>Founded</b>{this.state.selected_company.founded}</span>
                        <span class="job-title"><b>Email Address</b>{this.state.selected_company.company_email}</span>
                      </div>}
                      {this.state.edit_company_details && <div class="user-details-inner">
                        <span class="job-type"><b>Company</b>
                          <input type="text" className="form-control" name="company_name" value={this.state.company_name} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                        <span class="job-type"><b>CEO Last Name</b>
                          <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                        <span class="job-title"><b>Company Size</b>
                          <input type="text" className="form-control" name="size" value={this.state.size} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                        <span class="job-title"><b>Founded</b>
                          <input type="text" className="form-control" name="founded" value={this.state.founded} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                        <span class="job-title"><b>Email Address</b>
                          <input type="text" className="form-control" name="company_email" value={this.state.company_email} onChange={(e) => { this.handleInput(e) }} />
                        </span>
                      </div>}
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row btn-section">
                <div class="col-md-6">
                  <div class="updatebtn">
                    {!this.state.edit_company_details && <a href="" onClick={e => { this.approveCompany(e) }}>Approve</a>}
                    {this.state.edit_company_details && <a href="" onClick={e => { this.updateAndApproveCompany(e) }}>Update</a>}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="radio">
                    <input id="radio-1" name="company_status" type="radio" defaultChecked onClick={() => { this.companyStatus('active') }} />
                    <label htmlFor="radio-1" class="radio-label">Active</label>
                  </div>

                  <div class="radio">
                    <input id="radio-2" name="company_status" type="radio" onClick={() => { this.companyStatus('inactive') }} />
                    <label htmlFor="radio-2" class="radio-label">Inactive</label>
                  </div>
                </div>
              </div>

            </div>}
          </div>
        </Modal.Body>
      </Modal>
    </>

    );
  }
  companyStatus = e => {
    this.setState({
      company_status: e
    })
  }
  approveCompany = e => {
    e.preventDefault();
    Axios.post(`${this.state.url}/api/approve/company`,
      {
        company_id: this.state.selected_company.id,
        company_status: this.state.company_status
      },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("_access")
        }
      }).then(res => {
        if (res.data.success) {
          NotificationManager.success("success", res.data.message);
          this.setState({
            company_status: "active",
            company_modal: false,
            selected_company: null
          });
          this.getPendingCompany();
        }
        else {
          NotificationManager.error("error", res.data.message);
        }
      })
  }
  updateAndApproveCompany = e => {
    e.preventDefault();
    if (this.state.industry_id === "") {
      NotificationManager.error("error", "Please select company industry.");
      return false;
    }
    if (this.state.company_name === "") {
      NotificationManager.error("error", "Please enter company name.");
      return false;
    }
    if (this.state.website === "") {
      NotificationManager.error("error", "Please enter company website.");
      return false;
    }
    if (this.state.first_name === "") {
      NotificationManager.error("error", "Please enter CEO first name.");
      return false;
    }
    if (this.state.last_name === "") {
      NotificationManager.error("error", "Please enter CEO last name.");
      return false;
    }
    if (this.state.website === "") {
      NotificationManager.error("error", "Please enter company website.");
      return false;
    }
    if (this.state.size === "") {
      NotificationManager.error("error", "Please enter company size.");
      return false;
    }
    if (this.state.headqarter === "") {
      NotificationManager.error("error", "Please enter company headquarter.");
      return false;
    }
    console.log(this.state.founded.length, !isNaN(+this.state.founded))
    if (this.state.founded.length !== 4 || isNaN(+this.state.founded)) {
      NotificationManager.error("error", "Please enter a valid founded year.");
      return false;
    }
    if (this.state.company_email === "") {
      NotificationManager.error("error", "Please enter a valid Email Id.");
      return false;
    }
    var data = {
      company_status: this.state.company_status,
      company_email: this.state.company_email,
      founded: this.state.founded,
      size: this.state.size,
      last_name: this.state.last_name,
      company_name: this.state.company_name,
      headqarter: this.state.headqarter,
      website: this.state.website,
      first_name: this.state.first_name,
      industry_id: this.state.industry_id,
      company_id: this.state.selected_company.id
    }

    Axios.post(`${this.state.url}/api/update/company/details`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("_access")
      }
    }).then(res => {
      console.log(res)
      if (res.data.success) {
        NotificationManager.success("success", res.data.message);
        this.setState({
          company_status: "active",
          company_email: "",
          founded: "",
          size: "",
          last_name: "",
          company_name: "",
          headqarter: "",
          website: "",
          ceo_first_name: "",
          industry_id: "",
          edit_company_details: false,
          company_modal: false,
          selected_company: null
        });
        this.getPendingCompany();

      }
      else {
        NotificationManager.error("error", res.data.message);
      }
    })
  }
  getIndustry = (e) => {
    Axios.get(`${this.state.url}/get/industry`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        allIndustry: res.data.data
      })
    });
  }
}
export default Dashboard;

import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Header from "./Headerseller";
import Footer from "./Footerseller";
class Seller extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA"
        }
    }

    componentDidMount() {
        this.checkLanguage();
    }

    checkLanguage() {
        this.setState({
           "language":sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "arabic"
        })
    }

    render() {
        return <>

            <Header/>
<section class="banner_sec" >
  <div class="container">
    <div class="row banner_cnt">
      <div class="col-md-6">
        <h2>Build a Better Brand</h2>
        <p>Showcase your brand, manage employee reviews and get insights related to your company page.</p>
        <a class="page-primary-btn " href="/employer/claim-account" >Claim Account</a> </div>
      <div class="col-md-6">
        <div class="banner_img"> <img src="../sellers/img/working-on-computer.png" alt="kaziom banner" /> </div>
      </div>
    </div>
  </div>
</section>
<section class="business_sec" >
  <div class="container">
    <div class="sec_head">
      <h2 class="page-main-heading">Here’s what Kaziom helps your business with</h2>
      <img src="../sellers/img/line.png" alt="" /> </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="steps_cnt">
          <div class="subsec_head">
            <h4>Showcase your brand</h4>
            <img src="../sellers/img/line.png" alt="" /> </div>
          <p>Tell your story and provide authentic information about your company to your prospective employees. With a unique story, you can put forward a brand that reveals your authenticity.  Kaziom helps you create a pleasing and concise profile of your brand, making you stand out from the crowd. </p>

        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="setp_img"> <img src="../sellers/img/banner1.png" alt=""/> </div>
      </div>
    </div>
    <div class="row col_rev">
      <div class="col-lg-6 col-md-6">
        <div class="setp_img"> <img src="../sellers/img/banner1.png" alt=""/> </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="steps_cnt">
          <div class="subsec_head">
            <h4>Manage employee reviews</h4>
            <img src="../sellers/img/line.png" alt="" /> </div>
          <p>Strengthen your employer brand by responding to reviews and identifying issues. At Kaziom, we have built a database of numerous company reviews, management ratings, benefit and salary reports, and workplace environment. Our professionals carefully filter employee reviews from best to worst.</p>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="steps_cnt">
          <div class="subsec_head">
            <h4>Get actionable insights</h4>
            <img src="../sellers/img/line.png" alt="" /> </div>
          <p>Get meaningful insights across job profiles and locations to grow your business. We will provide you with an excellent marketing plan, growth strategies and much more to boost your business productivity and propel it to bigger success. By taking advantage of our insights, you can: </p>

        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="setp_img"> <img src="../sellers/img/banner1.png" alt=""/> </div>
      </div>
    </div>
  </div>
</section>
<section class="company_brands">
  <div class="container">
    <div class="sec_head">
      <h2 class="page-main-heading">Join 1000+ businesses growing with Kaziom</h2>
      <img src="../sellers/img/line.png" alt="" /> </div>
    <div class="logo_slider">
      <section class="customer-logos slider">
        <div class="slide"><img src="../sellers/img/asgardia.png" alt=""/></div>
        <div class="slide"><img src="../sellers/img/aven.png" alt=""/></div>
        <div class="slide"><img src="../sellers/img/codelab.png" alt=""/></div>
        <div class="slide"><img src="../sellers/img/earth.png" alt=""/></div>
        <div class="slide"><img src="../sellers/img/hexa.png" alt=""/></div>
        <div class="slide"><img src="../sellers/img/kyan.png" alt=""/></div>
        <div class="slide"><img src="../sellers/img/lightai.png" alt=""/></div>
        <div class="slide"><img src="../sellers/img/solaytic.png" alt=""/></div>
      </section>
    </div>
  </div>
</section>
<section class="work_setps">
  <div class="container">
    <div class="sec_head">
      <h2 class="page-main-heading">How it works</h2>
      <img src="../sellers/img/line.png" alt=""/> </div>
    <div class="row work_row">
      <div class="col-md-3 col-sm-6 col-lg-3">
        <div class="work_aside"> <img src="../sellers/img/add-user.png" alt="" />
          <h5>Create account</h5>
          <p>Sign up with your official email id</p>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3">
        <div class="work_aside"> <img src="../sellers/img/claim.png" alt="" />
          <h5>Claim company page</h5>
          <p>Verify yourself as an authorized person on behalf of the company by providing your details</p>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3">
        <div class="work_aside"> <img src="../sellers/img/post-job.png" alt="" />
          <h5>Post Jobs in your Company</h5>
          <p>Start posting job on your company profile, and get condidates response.</p>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3">
        <div class="work_aside"> <img src="../sellers/img/short-list.png" alt="" />
          <h5>Short List Condidates</h5>
          <p>Short list uyou condidates now</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="get_started">
<div class="container">
<div class="sec_head">
  <h2 class="page-main-heading">Get started today</h2>
  <img src="../sellers/img/line.png" alt="" /> </div>
<div class="sec_head">
  <p>Get onboarded on a journey towards building a better brand.</p>
  <a class="page-primary-btn " href="#">Claim Account</a> </div>
	</div>
</section>
      <Footer/>
        </>;
    }
}

export default Seller;

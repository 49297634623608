import React from "react";
import { Link } from "react-router-dom";

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import Axios from "axios";
import NotificationAlert from "react-notification-alert";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: process.env.MIX_REACT_API_URL,
      active:"dashboard"
    }
  }
  componentDidMount = (e) => {
    let params = window.location.href
    
    setTimeout(() => {
      this.setState({
        active: params.split("/")[4]
      })
      console.log("param", this.state.url)
    }, 100);

  }
  logout (e) {
    Axios.get(`${this.state.url}/logout`, {
      headers: {
        token: sessionStorage.getItem('_access')
      }
    }).then(res => {
      sessionStorage.removeItem("_access");
      sessionStorage.removeItem("data");
      window.location.href="/employer/login"
    });
  }
  render() {
    return (
      <>

<div class="sidebar"> <i class="bx bx-menu"></i>
    <div class="logo-details"><i class="logo_icon">
      <img src="../sellers/img/logo-icon.png" alt="" /></i> 
      <span class="logo_name"><img src="../sellers/img/logo-name.png" alt=""/></span> 
      </div>
     <ul class="nav-links">
       <li class= {this.state.active === 'dashboard' ? 'active' : null } > <a href="/employer/dashboard"> <i class="bx bx-grid-alt" ></i> <span class="link_name">Home</span> </a></li>
       <li class={this.state.active === 'profile' ? 'active' : null } > <a href="/employer/profile"> <i class="bx bx-buildings" ></i> <span class="link_name">Company Page</span> </a></li>
       <li class={this.state.active === 'jobs' ? 'active' : null }> <a href="/employer/jobs"> <i class="fa fa-users" aria-hidden="true"></i> <span class="link_name">Jobs</span> </a></li>
       
       <li class={this.state.active === 'apply-candidates-list' ? 'active' : null } > <a href="/employer/apply-candidates-list"> <i class="fa fa-users" aria-hidden="true"></i> <span class="link_name">Job Applicants List</span> </a></li>
       {/* <li class=""> <a href="#"> <i class="fa fa-star-o" aria-hidden="true"></i> <span class="link_name">Reviews</span> </a></li>
       <li class=""> <a href="#"> <i class="fa fa-phone" aria-hidden="true"></i><span class="link_name">Contact Us</span> </a></li> */}
       <li class=""> <a href="javascript:void(0)" onClick={e => { this.logout(e) }} > <i class="fa fa-sign-out" aria-hidden="true"></i><span class="link_name">Logout</span> </a></li>
     
       
    </ul>
  </div>
      </>
    )
  }
}

export default Sidebar;
import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import readXlsxFile from 'read-excel-file';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Pagination from "../Common/Pagination";

class Skill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            url: process.env.MIX_REACT_API_URL,
            name: "",
            add_new: false,
            edit_mode: false,
            upload_data: [],
            show_back: false,
            allTitle: [],
            category_id: "",
            allCategory: [],
            pagination_data: {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": null,
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "next_page_url": null,
                "path": "",
                "per_page": 0,
                "prev_page_url": null,
                "to": 0,
                "total": 0
            },
        }
    }
    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
        this.getSkill();
        this.getCategory()

    }
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    AddSkill = (e) => {
        e.preventDefault();
        if (this.state.category_id === "") {
            NotificationManager.error("error", "Please Choose Category.");
            return false;
        }

        if (this.state.name === "") {
            NotificationManager.error("error", "Please enter name.");
            return false;
        }
        if (this.state.status === "") {
            NotificationManager.error("error", "Please select status.");
            return false;
        }
        var data = {
            name: this.state.name,
            status: this.state.status,
            category_id: this.state.category_id,
            id: this.state.id ? this.state.id : ""
        }
        if (!this.state.edit_mode) {
            Axios.post(`${this.state.url}/add/skill`, data, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {

                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false
                    })
                    NotificationManager.success("success", "Skill successfully added");
                    this.getSkill()
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        } else {
            Axios.post(`${this.state.url}/update/skill`, data, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false,
                        edit_mode: false
                    })
                    NotificationManager.success("success", "Skill updated successfully");
                    this.getSkill()
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        }
    }

    getSkill = (page = 1) => {
        Axios.get(`${this.state.url}/get/skill?page=${page}`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allTitle: res.data.data.data,
                pagination_data: res.data.data

            })
            console.log("this.state.allTitle", this.state.allTitle);
        });
    }


    getCategory = (page = 1) => {
        Axios.get(`${this.state.url}/get/category?page=${page}`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allCategory: res.data.data.data,

            })
            console.log("this.state.allTitle", this.state.allCategory);
        });
    }


    AddNew = (e) => {
        setTimeout(() => {
            this.setState({
                add_new: true,
                show_back: true,
                edit_mode: false,
                name: "",
                status: ""
            })
            console.log(this.state.edit_mode);
        }, 100);

    }

    cancelForm = (e) => {
        this.setState({
            add_new: false,
            show_back: false,
            edit_mode: false,
            name: "",
            status: ""
        })
    }

    editType = (e, index, id) => {
        e.preventDefault();
        var data = [];
        var selected = this.state.allTitle[index];

        this.setState({
            show_back: true,
            id: selected.id,
            category_id: selected.category.id,
            name: selected.name,
            status: selected.status,
            edit_mode: true,
            show_list: false
        })
        console.log("this.state.edit_mode", selected);
    }

    UploadFile = (e) => {
        var data = []
        readXlsxFile(e.target.files[0]).then((rows) => {
            console.log(rows)
            if (rows) {
                for (let k of rows) {
                    data.push({ "category_id": k[0], "category": k[1], "skill": k[2] })
                    // data.push({"company_name":k[1]})
                }
            }
            this.setState({
                upload_data: data.slice(1)
            })

            console.log(this.state.upload_data)
        })
    }

    SendData = (e) => {
        Axios.post(`${this.state.url}/add/skill/excel`, { data: this.state.upload_data }, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            NotificationManager.success("success", "Skill successfully added");
            setTimeout(() => {
                window.location.reload()
            }, 500);
        })
    }

    donwnLoad = (e) => {
        Axios.get(`${this.state.url}/file/export`, {
            responseType: "blob",
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "add-skill.xls");
            document.body.appendChild(link);
            link.click();
            NotificationManager.success("success", "Skill successfully added");
        })
    }

    render() {
        return (<>
            <NotificationContainer />
            <Navbar page="Admin Dashboard" />
            <Sidebar />
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-12">
                                <h1 class="m-0">Skill</h1>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-6 mt-3"  >
                                {!this.state.show_back && <> <div style={{ textAlign: 'left' }} class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.AddNew(e) }}>Add New</a></div> </>}
                                {this.state.show_back && <> <div style={{ textAlign: 'left' }} class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>}
                            </div>
                            <div class={`col-md-6 mt-3 d-flex align-items-center`}>
                                <input type="file" className="col-sm-5" id="input" onChange={(e) => { this.UploadFile(e) }} />
                                <div className="col-sm-2 ml-2 px-0"> Upload File </div>
                                <div style={{ display: 'flex', alignItems: 'center' }} className='col-sm-5'>
                                    <button type="button" style={{ marginRight: '5px', backgroundColor: '#047768', borderRadius: '2rem' }} class="btn btn-block text-white admin_upload_btn" onClick={(e) => { this.SendData(e) }}>Upload</button>
                                    <button type="button" style={{ marginLeft: '5px', backgroundColor: '#047768', borderRadius: '2rem' }} class="btn mt-0 text-white btn-block" onClick={(e) => { this.donwnLoad(e) }}>Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.edit_mode || this.state.add_new) && <> <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card card-primary">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <h3 class="card-title">Add New Skill</h3>
                                            </div>
                                            <div class="col-md-3">

                                            </div>
                                        </div>
                                    </div>
                                    <form>

                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="last_name">Choose Category</label>
                                                        <select value={this.state.category_id} className="custom-select" name="category_id" onChange={(e) => { this.handleInput(e) }}>
                                                            <option value="">Select Category</option>
                                                            {this.state.allCategory.length > 0 && <>
                                                                {this.state.allCategory.map((cr, index) => {
                                                                    {
                                                                        cr.status === 'active'
                                                                        return <option value={cr.id} data={index}>{cr.name}</option>;
                                                                    }
                                                                })}
                                                            </>}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Name</label>
                                                        <input type="name" value={this.state.name} class="form-control" name="name" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter Name" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Status</label>
                                                        <select class="custom-select" value={this.state.status} onChange={(e) => { this.handleInput(e) }} name="status">
                                                            <option value="">Select Status</option>
                                                            <option selected={this.state.status === 'active' || this.state.status === 'active'} value="active">Active</option>
                                                            <option selected={this.state.status === 'inactive' || this.state.status === 'inactive'} value="inactive">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer submit-btn">
                                            <button type="button" onClick={e => { this.AddSkill(e) }} class="btn btn-primary">Submit</button>
                                            <button type="button" onClick={e => { this.cancelForm(e) }} class="btn btn-primary">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}

                {!this.state.add_new && !this.state.edit_mode && <> <section class="table-sec">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <div class="start-table-sec">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>Category</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.allTitle.length > 0 && <>
                                                {this.state.allTitle.map((list, index) => {
                                                    return <tr key={index}>
                                                        <td>{(this.state.pagination_data.per_page * (this.state.pagination_data.current_page - 1)) + index + 1}</td>
                                                        <td>{list.category.name}</td>
                                                        <td>{list.name}</td>
                                                        <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                                                        <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editType(e, index, list._id) }}></i></a></td>
                                                    </tr>
                                                })}
                                            </>}

                                        </tbody>
                                        <Pagination data={this.state.pagination_data} getData={this.getSkill} />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}
            </div>
        </>
        );
    }
}
export default Skill;
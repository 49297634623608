import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import LinkedIn from "linkedin-login-for-react";

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
         url: process.env.MIX_REACT_API_URL,
            show_modal: false,
            message: "AAAA",
            language:sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "english"
        }
    }

    componentDidMount() {
      //   this.test();
    }
    changeLanguage = (e, language) => {
       this.setState({
          "language":language
       })
        sessionStorage.setItem("language",language)
      window.location.reload()
    }


    LoginModal = (e) => {
      if (e) e.preventDefault();
      this.setState({
          show_modal: true
      })
   }
   showModal = e => {
      this.setState({
          show_modal: false
      })
  }

  notify(type, message) {
   if (type === "error") type = "danger";
   var options = {};
   options = {
       place: "tr",
       message: (
           <div>
               <div>
                   <b>{message}</b>
               </div>
           </div>
       ),
       type: type,
       icon: "nc-icon nc-bell-55",
       autoDismiss: 7
   };
   this.notificationAlert.current.notificationAlert(options);
}

  gmailLogin = (data) => {
        Axios.post(`${this.state.url}/api/social/login`,{name:data.name, social_unique_id:data.social_unique_id, email:data.email, avatar:data.avatar, login_by:"google",type:"google",device_type:'web'})
              .then((resp) => {
               //  console.log("resp.status",resp.data.user); return false
                  if (resp.data.status === true) {
                    this.setState({
                      loggedIn:resp.data.access_token
                    })
                    sessionStorage.setItem("userDetail", JSON.stringify(resp.data.user));
                     sessionStorage.setItem("_access", resp.data.access_token);
                     // this.notify("success", "User logged successfully");
                     if(resp.data.user.profile_percentage < 80){
                        window.location.href = '/user/onboarding';
                     }else{
                        window.location.href = '/user/dashboard';
                     }


                  } else {
                      this.notify("error", resp.message);
                  }
              }, err => {
                  this.setState({
                      loading: false
                  })
              })
      }
      facebookLogin = (data) => {
         Axios.post(`${this.state.url}/api/social/login`,{name:data.name, social_unique_id:data.social_unique_id,  avatar:data.avatar, login_by:data.login_by,type:data.type,device_type:'web'})
               .then((resp) => {
                //  console.log("resp.status",resp.data.user); return false
                   if (resp.data.status === true) {
                     this.setState({
                       loggedIn:resp.data.access_token
                     })
                     sessionStorage.setItem("userDetail", JSON.stringify(resp.data.user));
                      sessionStorage.setItem("_access", resp.data.access_token);
                      // this.notify("success", "User logged successfully");
                      window.location.href = '/user/dashboard';

                   } else {
                       this.notify("error", resp.message);
                   }
               }, err => {
                   this.setState({
                       loading: false
                   })
               })
       }

       linkdinLogin = (data) => {

         Axios.post(`${this.state.url}/api/social/login`,{name:data.name, social_unique_id:data.id,  avatar:data.avatar, login_by:data.login_by,type:data.type,device_type:'web'})
               .then((resp) => {
                //  console.log("resp.status",resp.data.user); return false
                   if (resp.data.status === true) {
                     this.setState({
                       loggedIn:resp.data.access_token
                     })
                     sessionStorage.setItem("userDetail", JSON.stringify(resp.data.user));
                      sessionStorage.setItem("_access", resp.data.access_token);
                      // this.notify("success", "User logged successfully");
                      window.location.href = '/user/dashboard';

                   } else {
                       this.notify("error", resp.message);
                   }
               }, err => {
                   this.setState({
                       loading: false
                   })
               })
       }
      //  getaccesstoken = (code,url) => {









      //    Axios.post(`https://www.linkedin.com/oauth/v2/accessToken` , data,{

      //          }).then((resp) => {
      //              console.log("resp.getaccesstoken",resp);

      //          }, err => {
      //             console.log("resp.getaccesstokenerr",err);
      //          })
      //  }

    render() {
      const responseGoogle = (response) => {
         console.log("gmail responsasdfsdfe",response);
         var data = {
           name: response['profileObj'].givenName+' '+response['profileObj'].familyName,
           social_unique_id:response['profileObj'].googleId,
           avatar:response['profileObj'].imageUrl ? response['profileObj'].imageUrl : null,
           email:response['profileObj'].email ? response['profileObj'].email : null,
           type:"google",
           login_by:"google",
           device_type:"web"


       }
       console.log(data)
       if(response['profileObj'].googleId){
         this.gmailLogin(data);
       }

       }
       const responseFacebook = (response) => {
         console.log("facebook responsasdfsdfe",response);
         var data = {
            name: response.name,
            social_unique_id:response.userID,
            avatar:response.picture.data.url ? response.picture.data.url : null,
          //  email:response['profileObj'].email ? response['profileObj'].email : null,
            type:"facebook",
            login_by:"facebook",
            device_type:"web"
       }
       console.log(data)
       if(response.userID){
         this.facebookLogin(data);
       }

    }
    const callbackLinkedIn = (error, code, redirectUri) => {
      if (error) {
        // signin failed
        console.log("link eror",error);
      } else {
         console.log("link success",code);
         console.log("link success",redirectUri);
       //  this.getaccesstoken(code,redirectUri);

       Axios.post(`${this.state.url}/api/linkdin/login/token`,{
         code:code,
         redirect_uri:redirectUri,
         client_id:'86vx5rsi2yyclk',
         client_secret:'z0pheiwFlk48lQRA'
      })
       .then((resp) => {
         if (resp.data.status === true) {

            var data = {
               name: resp.data.name,
               social_unique_id:resp.data.id,
               avatar: null,

               type:"linkdin",
               login_by:"linkdin",
               device_type:"web"
          }
            this.linkdinLogin(data);


          } else {
              this.notify("error", resp.message);
          }
         console.log("link auth",resp.data.name)
       })



      }
    };

        return <>
         <NotificationAlert ref={this.notificationAlert} />
      <header className="header-section" >
         <nav className="navbar navbar-default navbar-fixed-top" id="myScrollspy">
            <div className="container">
               <div className="col-md-12">
               <div className="row">
                  <div className="col-md-1">
                     <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="javascript:void(0)"><img src="admin/dist/img/white-logo.png" className="normal-logo" alt=""/></a>
                        <a className="navbar-brand" href="javascript:void(0)"><img src="admin/dist/img/small-logo.png" className="small-logo" alt=""/></a>
                     </div>
                  </div>
                  <div className="col-md-11">
                     <div id="navbar" className="navbar-collapse collapse">
                        <ul className="nav navbar-nav navbar-right">
                           <li><a href="#home">Home</a></li>
                           <li>
                           {/* <GoogleLogin
                              clientId="656125226788-9lo0va7486ucqk1d7658vn841qff0dj8.apps.googleusercontent.com"
                              buttonText="Login"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              // cookiePolicy={'single_host_origin'}
                           />
                           </li>
                           <li>
                           <FacebookLogin
                              appId="538576001322360"
                              autoLoad={true}
                              fields="name,email,picture"
                              callback={responseFacebook}
                              cssclassName="my-facebook-button-class login_btnhome"
                              icon="fa-facebook"
                           />
                           </li>
                           <li className="linked-btn">
                           <LinkedIn
                                 clientId="86vx5rsi2yyclk"
                                 callback={callbackLinkedIn}

                                 scope={["r_liteprofile","r_emailaddress"]}
                                 text="Login With LinkedIn"
                              /> */}


                           </li>

                           {/* <li><a href="javascript:void()" onClick={(e) => { this.LoginModal(e) }}>Login</a></li> */}
                           <li><a href="#about_us">About Us</a></li>
                           <li><a href="#features_sec">Features</a></li>
                           {/* <li><a href="#testimonial_sec">Testimonial</a></li> */}
                           {/* <li><a href="#faq_sec">FAQ</a></li> */}
                           {/* <li><a href="#blog_sec">Blog</a></li> */}
                           <li><a href="https://kaziom.com/blog/">Blog</a></li>
                           <li><a href="#contact-us">Contact Us</a></li>
                           <li>
                              <div className="nav-wrapper">
                                 <div className="sl-nav">
                                    <ul>
                                       <li>
                                          {this.state.language === 'english' && <> <img src="admin/dist/img/flag1.png" className="selectimage" alt=""/> <i className="fa fa-angle-down" aria-hidden="true"></i>
                                          <div className="triangle"></div> </>}
                                          {this.state.language === 'arabic' && <> <img src="admin/dist/img/flag2.png" className="selectimage" alt=""/> <i className="fa fa-angle-down" aria-hidden="true"></i>
                                          <div className="triangle"></div> </>}
                                          <ul>
                                             <li onClick={(e) => { this.changeLanguage(e,'english') }}><img src="admin/dist/img/flag1.png" className="flag-icons" alt=""/><span className="active"> English</span></li>
                                             <li onClick={(e) => { this.changeLanguage(e,'arabic') }}><img src="admin/dist/img/flag2.png" className="flag-icons" alt=""/><span> عربي</span></li>
                                          </ul>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </nav>

      </header>

      <Modal
                show={this.state.show_modal}
                onHide={() => this.showModal(false)}
                dialogClassName="modal-90w"
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <h4 className="title-headingsec">Send Notification</h4>
                </Modal.Header>

                <Modal.Body>
                    <h2>To whom you wish to send?</h2>


                </Modal.Body>
                <Modal.Footer>
                    {/* <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button> */}
                </Modal.Footer>
            </Modal>

        </>;
    }
}

export default Header;
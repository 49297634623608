import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Modal } from "react-bootstrap";
import Pagination from "../Common/Pagination";

class Customnotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.MIX_REACT_API_URL,
            add_new: false,
            edit_mode: false,
            title: "",
            description: "",
            image: null,
            
            all_notifications: [],
            selected_notification: null,
            show_modal: false,
            active_users: "",
            table_data: {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": null,
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "next_page_url": null,
                "path": "",
                "per_page": 0,
                "prev_page_url": null,
                "to": 0,
                "total": 0
            },
        }
    }
    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
        this.getNotifications();
    }
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleImage = e => {
        this.setState({
            image: e.target.files[0]
        })
    }
    getNotifications = (page = null) => {
        page = page ? page : this.state.table_data.current_page;
        Axios.get(`${this.state.url}/api/custom-notification/all?page=${page}`, {
            headers: {
                Authorization: " Bearer " + sessionStorage.getItem("_access"),
            }
        }).then(res => {
            console.log(res)
            this.setState({
                table_data: res.data.data.notifications,
                all_notifications: res.data.data.notifications.data
            })
            console.log("all response", res)
        })
    }
    AddNotification = e => {
        if (this.state.title === "") {
            NotificationManager.error("error", "Please enter title.");
            return false;
        }
        if (this.state.description === "") {
            NotificationManager.error("error", "Please enter description.");
            return false;
        }
        const formData = new FormData();
        formData.append('title', this.state.title);
        formData.append('image', this.state.image);
        formData.append('description', this.state.description);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        Axios.post(`${this.state.url}/api/custom-notification/add`, formData, {
            headers: {
                Authorization: " Bearer " + sessionStorage.getItem("_access"),
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            this.setState({
                table_data: res.data.data.notifications,
                all_notifications: res.data.data.notifications.data,
                title: "",
                description: "",
                image: null,
                add_new: false,
            });
            NotificationManager.success("success", "Notificaation added successfully.");
        })
    }
    AddNew = (e) => {
        this.setState({
            add_new: true,
            edit_mode: false
        });
        // this.cancelForm();
    }
    cancelForm = e => {
        this.setState({
            title: "",
            description: "",
            image: null,
            add_new: false,
            edit_mode: false
        });
    }
    sendNotiModal = (e = null, list) => {
        if (e) e.preventDefault();
        console.log(list)
        this.setState({
            selected_notification: list,
            show_modal: true
        })
    }
    showModal = e => {
        this.setState({
            show_modal: e
        })
    }
    sendNotifications = e => {
        if (e) e.preventDefault();
        if (this.state.active_users === "") {
            NotificationManager.error("error", "Please select users list");
            return false;
        }
        var data = {
            notification_id: this.state.selected_notification.id,
            send_to: this.state.active_users
        }
        Axios.post(`${this.state.url}/api/custom-notification/send`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log(res)
            if (res.data.success) {
                NotificationManager.success("success", "Notifications sent successfully.");
            }
            else {
                NotificationManager.error("error", "Something went wrong.");
            }
            this.setState({
                title: "",
                description: "",
                image: null,
                add_new: false,
                show_modal: false
            });
        })
    }
    render() {
        return (<>
            <NotificationContainer />
            <Navbar page="Admin Dashboard" />
            <Sidebar />

            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-9">
                                <h1 class="m-0">Custom Notifications</h1>
                            </div>
                            <div class="col-sm-3">
                                {!this.state.add_new && !this.state.edit_mode && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.AddNew(e) }}>Add New</a></div> </>}
                                {(this.state.add_new || this.state.edit_mode) && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>}
                            </div>

                        </div>
                    </div>
                </div>
                {(this.state.edit_mode || this.state.add_new) && <> <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card card-primary">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <h3 class="card-title">Add New Notification</h3>
                                            </div>
                                            <div class="col-md-3">

                                            </div>
                                        </div>
                                    </div>
                                    <form>

                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Title</label>
                                                        <input type="text" value={this.state.title} class="form-control" name="title" onChange={e => { this.handleInput(e) }} placeholder="Enter Title" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Description</label>
                                                        <textarea type="text" defaultValue={this.state.description} class="form-control" name="description" onChange={e => { this.handleInput(e) }} placeholder="Enter Description" ></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Image</label>
                                                        <input type="file" accept="image/*" class="form-control" name="image" onChange={e => { this.handleImage(e) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer submit-btn">
                                            <button type="button" onClick={e => { this.AddNotification(e) }} class="btn btn-primary">Submit</button>
                                            <button type="button" onClick={e => { this.cancelForm(e) }} class="btn btn-primary">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}

                {!this.state.add_new && !this.state.edit_mode && <> <section class="table-sec">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <div class="start-table-sec">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Image</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.all_notifications.length > 0 && <>
                                                {this.state.all_notifications.map((list, index) => {
                                                    return <tr key={index}>
                                                        <td>{(this.state.table_data.per_page * (this.state.table_data.current_page - 1)) + index + 1}</td>
                                                        <td>{list.title}</td>
                                                        <td>{list.description}</td>
                                                        <td>
                                                            <img src={this.state.url + "/uploads/cns/" + list.image} alt="" style={{ width: "100px", height: "80px" }} />
                                                        </td>
                                                        <td>
                                                            <a href="" onClick={(e) => { this.sendNotiModal(e, list) }}>
                                                                <i className="fa fa-paper-plane"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                })}
                                            </>}

                                        </tbody>
                                        {/* ################################ PAGINATION############################# */}
                                        <Pagination data={this.state.table_data} getData={this.getNotifications}/>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}
            </div>
            <Modal
                show={this.state.show_modal}
                onHide={() => this.showModal(false)}
                dialogClassName="modal-90w"
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <h4 class="title-headingsec">Send Notification</h4>
                </Modal.Header>

                <Modal.Body>
                    <h2>To whom you wish to send?</h2>
                    <div className="form-group">
                        <label>
                            <input name="active_users" type="radio" onClick={(e) => { this.handleInput(e) }} value="24hours" />
                        &nbsp;&nbsp; Active Users in Last 24 Hours
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <input name="active_users" type="radio" onClick={(e) => { this.handleInput(e) }} value="1week" />
                        &nbsp;&nbsp; Active Users in Last 1 Week
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <input name="active_users" type="radio" onClick={(e) => { this.handleInput(e) }} value="15days" />
                        &nbsp;&nbsp; Active Users in Last 15 Days
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <input name="active_users" type="radio" onClick={(e) => { this.handleInput(e) }} value="1month" />
                        &nbsp;&nbsp; Active Users in Last 1 month
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <input name="active_users" type="radio" onClick={(e) => { this.handleInput(e) }} value="all" />
                        &nbsp;&nbsp; All Users
                        </label>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={e => { this.sendNotifications(e) }}>Send&nbsp;<i className="fa fa-paper-plane"></i></button>
                </Modal.Footer>
            </Modal>
        </>
        );
    }
}
export default Customnotifications;

import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
class Footerseller extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA",
            date: new Date()
        }
    }

    componentDidMount() {
    }
    

    render() {
        return <>
<footer id="contact-us" class="landing_page" >
  <div class="container" >
    <div class="row">
      <div class="col-md-3">
        <div class="footer-logo"><img src="../sellers/img/white-logo.png" alt="" /></div>
        <div class="soical-icons">
          <ul>
            <li><a href="https://www.facebook.com/kazi.oman/" ><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="https://instagram.com/kazi.oman?igshid=1cbsam19t6sfn" ><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li><a href="https://twitter.com/kazi_oman?s=11" ><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
            <li><a href="https://www.linkedin.com/company/kazi-oman" ><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-9">
        <div class="footer-menu">
          <ul class="navbar-right">
            <li><a href="#home" >Home</a></li>
            <li><a href="#about_us" >About Us</a></li>
            <li><a href="#features_sec" >Features</a></li>
            <li><a href="https://kaziom.com/blog/" >Blog</a></li>
            <li><a href="/terms-conditions">Term &amp; Condition</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
          <div class="play-btna-sec">
            <a href="https://play.google.com/store/apps/details?id=com.app.kaziom" target="_blank">
                <img src="../sellers/img/available-soon-apps.png" alt="" />
                </a>
            <a href="https://apps.apple.com/us/app/kaziom/id1562348010" target="_blank">
                <img src="../sellers/img/play_store_en2.png" alt="" /></a>
             </div>
        </div>
      </div>
    </div>
    <hr class="footer-line"></hr>
    <div class="row">
      <div class="col-md-6">
        <div class="copyright-sec">
          <p>© {this.state.date?.getFullYear() } Kaziom - All rights Reserved</p>
        </div>
      </div>
      {/* <div class="col-md-6">
        <div class="development-sec">
          <p class="right-sec">Design &amp; Development By<a href="https://www.ganeshawebtech.com/" target="_blank" rel="noopener noreferrer"><img src="../sellers/img/gws_logo.webp" alt="ganeshawebtech" /></a></p>
        </div>
      </div> */}
    </div>
  </div>
</footer>

        </>;
    }
}

export default Footerseller;
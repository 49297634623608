import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import ReactTextareaAutocomplete from 'react-textarea-autocomplete'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import { NotificationContainer, NotificationManager } from 'react-notifications';
class Forum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.MIX_REACT_API_URL,
            show: false,
            search: '',
            latestTopics: [],
        }
        this.searchHandle = this.searchHandle.bind(this);
    }

    searchHandle(e) {
        this.setState({
            search: e.target.value,
        })
    }

    componentDidMount = (e) => {
        this.setState({
            "userDetail": JSON.parse(sessionStorage.getItem("userDetail"))
        })

        setTimeout(() => {
            this.setState({
                designation: this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
                show: true
            })
            console.log("sidebar", this.state.userDetail.image)
        }, 100);
        this.getLatestTopics()
    }

    getLatestTopics = () => {
        Axios.get(`${this.state.url}/api/get/topic`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res, 55)
            this.setState({
                latestTopics: res.data.data
            })
            console.log("resumes", this.state.latestTopics)
        });
    }
    render() {
        return (
            <>
                <NotificationContainer />
                <Header />
                <Navbar />
                <section class="sidebar_menu">
                    <div id="page-content-wrapper" class="top-job-page">
                        <div class="container-fluid">
                            <div class="col-md-12 pd0">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div className="row">
                                            <div class="col-md-3 forums">
                                                <div className="tabs_details mt-0">
                                                    <ul class="nav nav-tabs">
                                                        <li class="nav-item">
                                                            <a class="nav-link active" data-toggle="tab" href="#explore">Explore Topics</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" data-toggle="tab" href="#mytopic">My Topics</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" data-toggle="tab" href="#myanswer">My Answers</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9 tab-content">
                                                <div id="explore" class="tab-pane active">
                                                    <div className="latest">
                                                        <h3>Latest Topics</h3>
                                                        <div className="topic_img">
                                                            <div className="jobs_topics">
                                                                <div className="img_topics">
                                                                    <img src="/uploads/jobs.png" alt="jobs" />
                                                                    <div className="text_topics">Jobs</div>
                                                                </div>
                                                            </div>
                                                            <div className="jobs_topics ml-2">
                                                                <div className="img_topics">
                                                                    <img src="/uploads/internship.png" alt="jobs" />
                                                                    <div className="text_topics">Internships</div>
                                                                </div>
                                                            </div>
                                                            <div className="jobs_topics ml-2">
                                                                <div className="img_topics">
                                                                    <img src="/uploads/job_status.png" alt="jobs" />
                                                                    <div className="text_topics">Jobs Status</div>
                                                                </div>
                                                            </div>
                                                            <div className="jobs_topics ml-2">
                                                                <div className="img_topics">
                                                                    <img src="/uploads/salary.png" alt="jobs" />
                                                                    <div className="text_topics">Salaries</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h3 className="my-3">Trending Questions</h3>
                                                        <div className="trending">
                                                            {this.state.latestTopics && this.state.latestTopics.length > 0 && <>
                                                                {this.state.latestTopics.map((cr, index) => {
                                                                    return (
                                                                        <Link to={`/user/forum/` + cr.id} activeClassName="active">
                                                                            <div class="desp-details mt-3">
                                                                                <div class="img-pro">
                                                                                    <img src='/uploads/1608634769238season.jpg' alt="" />
                                                                                </div>
                                                                                <div class="name-details-time">
                                                                                    <h4>{cr.topic}</h4>
                                                                                    <p>
                                                                                        Ankit Mehra
                                                                                        {/* {cr.user.name} */}
                                                                                        <span class="dot-points pl-2">{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <p class="des_forum description_topics">
                                                                                    So, i've been using c## for a whole decade now, if you guys know how to break the boring feeling...
                                                                                </p>
                                                                                <div className="vote_upvote mt-3">
                                                                                    <div className="vote">
                                                                                        <img src="/uploads/vote.png" width={20} alt="vote" />
                                                                                        <div className="vote_text">10 Vote</div>
                                                                                    </div>
                                                                                    <div className="vote ml-3">
                                                                                        <img src="/uploads/upvote.png" width={20} alt="upvote" />
                                                                                        <div className="vote_text">20 Upvote</div>
                                                                                    </div>
                                                                                    <div className="vote ml-3">
                                                                                        <img src="/admin/dist/images/comment.png" width={20} alt="comment" />
                                                                                        <div className="vote_text">30 Comment</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    )
                                                                })}
                                                            </>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="mytopic" class="tab-pane">
                                                    My Topics
                                                </div>
                                                <div id="myanswer" class="tab-pane">
                                                    My Answers
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-md-none d-lg-block  col-lg-3">
                                        <div class="forum-sec">
                                            <h3>Latest Topics</h3>
                                            <Link to={`/user/forums`} activeClassName="active">View All</Link>
                                            {this.state.latestTopics && this.state.latestTopics.length > 0 && <>
                                                {this.state.latestTopics.map((cr, index) => {
                                                    return (
                                                        <div class="desp-details">
                                                            <div class="img-pro">
                                                                <Link to={`/user/forum/` + cr.id} activeClassName="active">
                                                                    <img src='/uploads/1608634769238season.jpg' alt="" />
                                                                </Link>
                                                            </div>
                                                            <div class="name-details-time">
                                                                <h4>{cr.topic}</h4>
                                                                <p>
                                                                    Ankit Mehra
                                                                    {/* {cr.user.name} */}
                                                                    <span class="dot-points pl-2">{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</span>
                                                                </p>
                                                            </div>
                                                            <p class="des_forum">{cr.description}</p>
                                                        </div>
                                                    )
                                                })}
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Forum;   
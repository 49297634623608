import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Header from "../User/Header";
import { NotificationContainer, NotificationManager } from 'react-notifications';

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: process.env.MIX_REACT_API_URL,
      show:false,
      allData:[],
      company_id:"",
      company:"",
      jobs:[],
      name:"",
      phone:"",
      email:"",
      education:"",
      experience:"",
      watchList:"",
      JobTitle:[],
      Title:[],
      location:"",
      latitude:"",
      longitude:""
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount = (e) => {



     setTimeout(() => {
        this.setState({
           show:true,

        })
        console.log("sidebar",this.state.userDetail.image)
     }, 100);
     this.getTitle()
     this.getUserDetail()
  }

  getUserDetail = () => {
    Axios.get(`${this.state.url}/api/get/profile` , {
       headers: {
          "Authorization": "Bearer "+sessionStorage.getItem('_access')
        }
    }).then(res => {
        console.log(res)
        this.setState({
          name:res.data.data.name,
          email:res.data.data.email,
          phone:res.data.data.phone,
          experience:res.data.data.experience,
          education:res.data.data.eduction,
          designation:res.data.data.designation,
          image:res.data.data.image,
          job_title_id:res.data.data.designation,
          location:res.data.data.location

        })
        console.log("homedata",res.data.data)
    });
}

  getTitle = (page=1) => {
    var dd=[];
    Axios.post(`${this.state.url}/api/title`, page, {
       headers: {
          "Authorization": "Bearer "+sessionStorage.getItem('_access')
        }
    }).then(res => {
        console.log(res)
        this.setState({
          JobTitle: res.data.data,

        })
        if(this.state.JobTitle){
          for(let k of this.state.JobTitle){
             dd.push({"id":k.id, "name":k.name});
          }
          this.setState({
             Title:dd
          })
        }
        console.log("CompanyName",this.state.Title)
    });
}
 onChange(e) {
//    this.setState({
//       "image": evt.target.files[0],
//   });
   if (e.target.files) {
       let file = e.target.files[0];
       var reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onloadend = function (e) {
         this.setState({
             image: [reader.result],
             "image":file,
         })
       }.bind(this);
       setTimeout(() => {
         console.log(this.state.image[0])
       }, 200);

   }
}

handleInput(e) {

    this.setState({ [e.target.name]: e.target.value })
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.state.name == "") {
       NotificationManager.error("error", "Please enter name.");
       return false;
    }
    if (this.state.email == "") {
       NotificationManager.error("error", "Please enter email.");
       return false;
    }
    if (this.state.phone == "") {
       NotificationManager.error("error", "Please enter phone number.");
       return false;
    }
    if (this.state.job_title_id == "") {
       NotificationManager.error("error", "Please choose job title.");
       return false;
    }
    if (this.state.experience == "") {
       NotificationManager.error("error", "Please enter experience.");
       return false;
    }
    if (this.state.location == "") {
       NotificationManager.error("error", "Please enter location  .");
       return false;
    }
    if (this.state.education == "") {
      NotificationManager.error("error", "Please enter education  .");
      return false;
   }
    const formData = new FormData();
    if(this.state.image){
      formData.append('image',this.state.image);
    }
    formData.append('name',this.state.name);
    formData.append('email',this.state.email);
    formData.append('phone',this.state.phone);
    formData.append('experience',this.state.experience);
    formData.append('title_id',this.state.job_title_id);
    formData.append('location',this.state.location);
    formData.append('latitude',this.state.latitude);
    formData.append('longitude',this.state.longitude);
    formData.append('education',this.state.education);
    const config = {
       headers: {
           'content-type': 'multipart/form-data',
           "Authorization": "Bearer "+sessionStorage.getItem('_access')
       }
   };
    Axios.post(`${this.state.url}/api/update/profile`, formData,config, {
   }).then(res => {
    this.getUserDetail()
    NotificationManager.success("success", "Profile Updated Successfully");
    setTimeout(() => {
      window.location.href = '/user/dashboard';
    }, 400);
   })
  }

  onSelect = (e) => {
    console.log("address", e);
    setTimeout(() => {
        var lastEl = e.value.terms.slice(-2)[0];
        this.setState({
            location: e.value.description,
            city: lastEl.value
        })
        console.log("asdfsdf", this.state.city)
        this.getLatLng(this.state.location)
    }, 100);

}

 getLatLng = () => {
  //  setTimeout(() => {
  geocodeByAddress(this.state.location)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({
          latitude: lat,
          longitude: lng
      }))
      .catch(error => console.error('Error', error));
  //  }, 200);
  setTimeout(() => {
      console.log(this.state.longitude)
  }, 200);



}


  render() {
    const handleOnSelectTitle = (item) => {
        var tt = []
        for(let k of this.state.JobTitle){
           if(k.id === item.id){
             tt = k;
           }
        }
        setTimeout(() => {
          this.setState({
             job_title_id:item.id
           })
           console.log('this.state.CompanyInfo',this.state.job_title_id)
        }, 100);
       // the item selected
    }
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
      }

      const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
      }
      const handleOnFocus = () => {
        console.log('Focused')
      }



    return (
      <>
      <NotificationContainer/>
    <Header/>
    {/* <Navbar/> */}

    <section class="sidebar_menu">
    <div id="page-content-wrapper" class="onborarding-sec">
        <div class="container-fluid">
         <div class="col-md-12 pd0">
            <div class="row">
            <div class="col-md-8 mx-auto">

            <form onSubmit={this.onFormSubmit}>
                    <div>

                    {this.state.image && <> <img src={this.state.image} alt=""/> </>}
            { this.state.image == null && <> <img src="/admin/dist/images/profile-top.png" alt=""/> </>}
                        <input type="file" onChange= {this.onChange}/>
                    </div>
                    <div>
                        <lable>Name</lable>
                        <input type="text" class="form-control" onChange={e => { this.handleInput (e) }} name="name" value={this.state.name}></input>
                    </div>
                    <div>
                        <lable>Email</lable>
                        <input type="text" class="form-control" onChange={e => { this.handleInput (e) }} name="email" value={this.state.email}></input>
                    </div>
                    <div>
                        <lable>Mobile No.</lable>
                        <input type="text" class="form-control" onChange={e => { this.handleInput (e) }} name="phone" value={this.state.phone}></input>
                    </div>
                    <div>
                        <lable>Job Title</lable>
                        <ReactSearchAutocomplete
                            items={this.state.Title}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelectTitle}
                            onFocus={handleOnFocus}
                            autoFocus
                            // formatResult={formatResult}
                        />
                    </div>
                    <div>
                        <lable>Experience</lable>
                        <input type="text" class="form-control" onChange={e => { this.handleInput (e) }} name="experience" value={this.state.experience}></input>
                    </div>
                    <div>
                        <lable>Location</lable>
                        <GooglePlacesAutocomplete
                            value={this.state.address}
                            apiKey="AIzaSyABHQkemy2kHyDvto_e0IW4hNRafNlsoQw"
                            selectProps={{ onChange: (e) => this.onSelect(e) }}

                        />

                    </div>
                    <div>
                        <lable>Education</lable>
                        <input type="text" class="form-control" onChange={e => { this.handleInput (e) }} name="education" value={this.state.education}></input>
                    </div>
                    <div class="sub-buttonsec">
                    <input type="submit" value="Submit" />
                    </div>
                </form>

                  </div>





        {/* <Sidebar/> */}
        </div>
        </div>
        </div>
        </div>

        </section>


      </>
    )
  }
}

export default Onboarding;
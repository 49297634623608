import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import ReactTextareaAutocomplete from 'react-textarea-autocomplete'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import { NotificationContainer, NotificationManager } from 'react-notifications';
class Company extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      url: process.env.MIX_REACT_API_URL,
      show: false,
      search: '',
      allCompany: [],
    }
    this.searchvalue = this.searchvalue.bind(this);
  }

  searchvalue(e) {
    this.setState({
      search: e.target.value,
    })
  }

  componentDidMount = (e) => {
    this.myRef.current.scrollTo(0, 0);
    this.setState({
      "userDetail": JSON.parse(sessionStorage.getItem("userDetail"))
    })

    setTimeout(() => {
      this.setState({
        designation: this.state.userDetail.designation.name,
        show: true
      })
      console.log("sidebar", this.state.userDetail.image)
    }, 100);
    this.getAllCompany()
  }

  getAllCompany = (page = 1) => {
    Axios.post(`${this.state.url}/api/get/all/company`, page, {
      headers: {
        "Authorization": "Bearer " + sessionStorage.getItem('_access')
      }
    }).then(res => {
      console.log(res)
      this.setState({
        allCompany: res.data.data,
      })
      console.log("homedata", this.state.allCompany)
    });
  }
  render() {
    return (
      <>
        <div ref={this.myRef}></div>
        <NotificationContainer />
        <Header />
        <Navbar />
        <section class="sidebar_menu">
          <div id="page-content-wrapper" class="top-job-page">
            <div class="container-fluid">
              <div class="col-md-12 pd0">
                <div class="row">
                  <div class="col-md-9">
                    <div class="top-jobs-blocks">
                      <h3>Top Jobs</h3>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <div class="form-header">
                          <form>
                            <input
                              type="text"
                              placeholder="Search"
                              value={this.state.search}
                              onChange={this.searchvalue}
                              name="search" />
                            <button type="submit"><img src="images/search.png" alt=""/></button>
                          </form>
                        </div>
                      </div>
                      <div class="col-md-7">
                        <div class="maine-select-form">
                          <div class="refresh_icon"><a href="javascript:void(0)"><img src="images/refresh.png" alt="" /></a></div>
                          <div class="all-select-field">
                            <form>
                              <select name="entry-level" id="level" class="selectvalue">
                                <option>Tech Company</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                              </select>
                              <select name="today" id="today">
                                <option>India</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                              </select>
                              <select name="full-time" id="time">
                                <option>Gujarat</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                              </select>
                              <select name="full-time" id="time">
                                <option>Baroda</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                              </select>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 padd0">
                        {this.state.allCompany && this.state.allCompany.length > 0 && <>
                          {this.state.allCompany.filter((item) => {
                            return this.state.search.toLocaleLowerCase() === '' ? item : item.company_name.toLocaleLowerCase().includes(this.state.search)
                          }).map((cr, index) => {
                            return <Link to={`/user/company/` + cr.id} activeClassName="active">
                              <div class="topjob-compaines">
                                <div class="topjob-img">
                                  {cr.logo && <> <img src={this.state.url + '/uploads/' + cr.logo} alt="" /> </>}
                                  {cr.logo == null && <> <img src="/admin/dist/images/google.png" alt="" /> </>}
                                </div>
                                <div class="jobdesp">
                                  <h4>{cr.company_name}</h4>
                                  <p><span class="sales-img"><img src="/admin/dist/images/location.png" /></span>San {cr.headqarter} </p>
                                  <p><span class="sales-img"><img src="/admin/dist/images/bag.png" /></span>{cr.jobs.length} Job Open</p>
                                </div>
                              </div>
                            </Link>
                          })}
                        </>}
                      </div>
                    </div>
                  </div>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Company;
import React from "react";
import CKEditor from "react-ckeditor-component";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import ModernDatepicker from 'react-modern-datepicker';


class Jobform extends React.Component {


    onSelect = (e) => {
        console.log("address", e);
        setTimeout(() => {
            var lastEl = e.value.terms.slice(-2)[0];
            this.setState({
                address: e.value.description,
                city: lastEl.value
            })
            console.log("asdfsdf", this.state.city)
            this.getLatLng(this.state.address)
        }, 100);

    }
    handleChange = address => {
        console.log("Adsdf")
        this.setState({ address });
    };
    handleInput(e) {
        console.log(e.target.name)
        // setTimeout(() => {
        if (e.target.name === 'range_type') {
            if (e.target.value === 'range') {
                this.setState({
                    show_range: true,
                    show_fixed: false
                })
            } else {
                this.setState({
                    show_fixed: true,
                    show_range: false
                })
            }
            console.log(this.state.show_fixed)
        }
        // }, 100);

        this.setState({ [e.target.name]: e.target.value })
    }
    onChange(evt) {
        this.setState({
            "banner": evt.target.files[0],
        });

    }
    onChange1(evt) {

        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            description: newContent
        })
    }
    onChange2(evt) {
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            overview: newContent
        })
    }
    onChange3(evt) {
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            requirement: newContent
        })
    }
    handelDate = (date) => {
        this.setState({
            expiry_date: date
        })
    }
    render() {
        return <>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <h3 class="card-title">Add New Job</h3>
                                        </div>
                                        <div class="col-md-3">

                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={this.props.onFormSubmit}>

                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="last_name">Company</label>
                                                    <select value={this.props.state.company_id} className="custom-select" name="company_id" onChange={(e) => { this.props.handleInput(e) }}>
                                                        <option value="">Select Company</option>
                                                        {this.props.state.allCompany.length > 0 && <>
                                                            {this.props.state.allCompany.map((cr, index) => {
                                                                {
                                                                    cr.status === 'active'
                                                                    return <option value={cr.id} data={index}>{cr.company_name}</option>;
                                                                }
                                                            })}
                                                        </>}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="last_name">Department</label>
                                                    <select value={this.props.state.department_id} className="custom-select" name="department_id" onChange={(e) => { this.props.handleInput(e) }}>
                                                        <option value="">Select Department</option>
                                                        {this.props.state.allDepartment.length > 0 && <>
                                                            {this.props.state.allDepartment.map((cr, index) => {
                                                                {
                                                                    cr.status === 'active'
                                                                    return <option value={cr.id} data={index}>{cr.name}</option>;
                                                                }
                                                            })}
                                                        </>}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Job Title</label>
                                                    <select value={this.props.state.job_title} className="custom-select" name="job_title" onChange={(e) => { this.props.handleInput(e) }}>
                                                        <option value="">Select Job Title</option>
                                                        {this.props.state.allJobTitle.length > 0 && <>
                                                            {this.props.state.allJobTitle.map((cr, index) => {
                                                                return <option value={cr.id} data={index}>{cr.name}</option>;
                                                            })}
                                                        </>}
                                                    </select>
                                                    {/* <SuggestionInputSearch
                        onSubmitFunction={this.props.handleOnSubmit}
                        recentSearches={recentSearches}
                        placeholder={placeholder}
                        inputPosition={inputPosition}
                        // inputName={job_title}
                        /> */}

                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="last_name">Job Type</label>
                                                    <select value={this.props.state.job_type} className="custom-select" name="job_type" onChange={(e) => { this.props.handleInput(e) }}>
                                                        <option value="">Select Job Type</option>
                                                        {this.props.state.allJobType.length > 0 && <>
                                                            {this.props.state.allJobType.map((cr, index) => {
                                                                return <option value={cr.id} data={index}>{cr.name}</option>;
                                                            })}
                                                        </>}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmai54l1">Contact Number</label>
                                                    <input type="text" value={this.props.state.contact} class="form-control" name="contact" onChange={e => { this.props.handleInput(e) }} id="exampleInputEmai54l1" placeholder="Enter Contact Number" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Email</label>
                                                    <input type="text" value={this.props.state.email} class="form-control" name="email" onChange={e => { this.props.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter Email" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="last_name">Location</label>
                                                    <GooglePlacesAutocomplete
                                                        value={this.props.state.address}
                                                        apiKey="AIzaSyAER3cqDaeDGIFpTwqMOZA-72A11XOiaTA"
                                                        selectProps={{ onChange: (e) => this.onSelect(e) }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Remote</label>
                                                    <input type="radio" checked={this.props.state.remote_type === 'yes'} name="remote_type" value="yes" onChange={(e) => { this.props.handleInput(e) }} class="radio-sec" class="" />  Yes
                        <input type="radio" checked={this.props.state.remote_type === 'no'} name="remote_type" value="no" onChange={(e) => { this.props.handleInput(e) }} class="radio-sec" class="rediopadding" />  No
                        <input type="radio" checked={this.props.state.remote_type === 'temporary'} name="remote_type" value="temporary" onChange={(e) => { this.props.handleInput(e) }} class="radio-sec" class="rediopadding" />  Temporary
                    </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Salary</label>
                                                    <select class="custom-select" value={this.props.state.range_type} onChange={(e) => { this.props.handleInput(e) }} name="range_type">
                                                        <option value="">Select Salary Type</option>
                                                        <option selected={this.props.state.range_type === 'range' || this.props.state.range_type === 'range'} value="range">Range</option>
                                                        <option selected={this.props.state.range_type === 'fixed' || this.props.state.range_type === 'fixed'} value="fixed">Fixed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {this.props.state.show_range && <> <div class="col-md-2">
                                                <div class="form-group description-sec">
                                                    <label >Rs.</label>
                                                    <input type="number" value={this.props.state.salary_from} name="salary_from" onChange={(e) => { this.props.handleInput(e) }} class="form-control" id="exampleInputEmail1" placeholder="From" />
                                                </div>
                                            </div>

                                                <div class="col-md-2">
                                                    <div class="form-group description-sec">
                                                        <label >To</label>
                                                        <input type="number" value={this.props.state.salary_to} name="salary_to" onChange={(e) => { this.props.handleInput(e) }} class="form-control" id="exampleInputEmail1" placeholder="To" />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group description-sec">
                                                        <label >Type</label>
                                                        <select class="custom-select" value={this.props.state.salary_type} onChange={(e) => { this.props.handleInput(e) }} name="salary_type">
                                                            <option value="">Select Type</option>
                                                            <option selected={this.props.state.salary_type === 'monthly' || this.props.state.salary_type === 'monthly'} value="monthly">Monthly</option>
                                                            <option selected={this.props.state.salary_type === 'yearly' || this.props.state.salary_type === 'yearly'} value="yearly">Yearly</option>
                                                            <option selected={this.props.state.salary_type === 'hourly' || this.props.state.salary_type === 'hourly'} value="hourly">Hourly</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>}
                                            {this.props.state.show_fixed && <> <div class="col-md-2">
                                                <div class="form-group description-sec">
                                                    <label >To</label>
                                                    <input type="number" value={this.props.state.salary_to} name="salary_to" onChange={(e) => { this.props.handleInput(e) }} class="form-control" id="exampleInputEmail1" placeholder="To" />
                                                </div>
                                            </div>
                                                <div class="col-md-2">
                                                    <div class="form-group description-sec">
                                                        <label >Type</label>
                                                        <select class="custom-select" value={this.props.state.salary_type} onChange={(e) => { this.props.handleInput(e) }} name="salary_type">
                                                            <option value="">Select Type</option>
                                                            <option selected={this.props.state.salary_type === 'monthly' || this.props.state.salary_type === 'monthly'} value="monthly">Monthly</option>
                                                            <option selected={this.props.state.salary_type === 'yearly' || this.props.state.salary_type === 'yearly'} value="yearly">Yearly</option>
                                                            <option selected={this.props.state.salary_type === 'hourly' || this.props.state.salary_type === 'hourly'} value="hourly">Hourly</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>}
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Description</label>
                                                    <CKEditor
                                                        activeClass="p10"
                                                        content={this.props.state.description}
                                                        events={{
                                                            "blur": this.props.onBlur,
                                                            "afterPaste": this.props.afterPaste,
                                                            "change": this.props.onChange1
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Overview</label>
                                                    <CKEditor
                                                        activeClass="p10"
                                                        content={this.props.state.overview}
                                                        events={{
                                                            "blur": this.props.onBlur,
                                                            "afterPaste": this.props.afterPaste,
                                                            "change": this.props.onChange2
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Requirement</label>
                                                    <CKEditor
                                                        activeClass="p10"
                                                        content={this.props.state.requirement}
                                                        events={{
                                                            "blur": this.props.onBlur,
                                                            "afterPaste": this.props.afterPaste,
                                                            "change": this.props.onChange3
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Status</label>
                                                    <select class="custom-select" value={this.props.state.status} onChange={(e) => { this.props.handleInput(e) }} name="status">
                                                        <option value="">Select Status</option>
                                                        <option selected={this.props.state.status === 'active' || this.props.state.status === 'active'} value="active">Active</option>
                                                        <option selected={this.props.state.status === 'inactive' || this.props.state.status === 'inactive'} value="inactive">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="company_title">Banner Image</label>
                                                    <input type="file" name="banner" onChange={this.props.onChange} class="form-control"></input>
                                                    {this.props.state.edit_mode === true &&
                                                        <img src={this.props.state.url + '/uploads/' + this.props.state.banner} class="bannerimage" alt="" ></img>
                                                    }
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="company_title">Expiry Date</label>
                                                    <ModernDatepicker
                                                        date={this.props.state.expiry_date}
                                                        format={'YYYY-MM-DD'}
                                                        showBorder
                                                        onChange={date => this.props.handelDate(date, 'expiry_date')}
                                                        placeholder={' Expiry Date'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer submit-btn">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <button type="button" onClick={e => { this.props.cancelForm(e) }} class="btn btn-primary">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>;
    }

}

export default Jobform

import React from "react";
import { Link } from "react-router-dom";
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      show:false
    }
  }
  componentDidMount = (e) => {
    this.setState({
        "userDetail":JSON.parse(sessionStorage.getItem("userDetail"))
     })

     setTimeout(() => {
        this.setState({
           designation:this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
           show:true
        })
        console.log("sidebar",this.state.userDetail.image)
     }, 100);
  }
  render() {
    return (
      <>

       {this.state.show && <>

        <header class="new-header">
               <div class="container-fluid">
                  <div class="col-md-12">
                     <div class="row">
                        <div class="col-md-3">
                           <a class="navbar-brand" href="/user/dashboard"><img src="/admin/dist/images/logo.png" alt=""/></a>
                        </div>
                        <div class="col-md-6">
                           <div class="form-header">
                              <form>
                                 <input type="text" placeholder="Search" name="search"/>
                                 <button type="submit"><img src="/admin/dist/images/search.png" alt=""/></button>
                              </form>
                           </div>
                        </div>
                        <div class="col-md-3">
                           <div class="notification-sec">
                              <ul>
                                 <li><a href="#"><img src="/admin/dist/images/notification.png" alt=""/></a><span class="not-msg">1</span></li>
                                 <li><a href="#"><img src="/admin/dist/images/msg.png" alt=""/></a></li>
                                 <li><a href="#" class="profile-img-header">

                                 {this.state.userDetail.image && <> <img src={this.state.userDetail.image} alt=""/> </>}
                                 {this.state.userDetail.image == null && <> <img src="/admin/dist/images/profile-img.png" alt=""/> </>}



                                    </a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </header>

        </>}


      </>
    )
  }
}

export default Sidebar;
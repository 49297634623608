import React from "react";
import { Link } from "react-router-dom";
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      show: false
    }
  }
  componentDidMount = (e) => {
    this.setState({
      "userDetail": JSON.parse(sessionStorage.getItem("userDetail"))
    })

    setTimeout(() => {
      this.setState({
        designation: this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
        show: true
      })
      console.log("sidebar", this.state.userDetail.image)
    }, 100);
  }
  render() {
    return (
      <>

        {this.state.show && <> <div class="d-md-none d-lg-block  col-lg-3">
          <div class="profile_maine_section">
            <div class="use_details_block">
              {this.state.userDetail.image && <> <img src={this.state.userDetail.image} alt="" /> </>}
              {this.state.userDetail.image == null && <> <img src="/admin/dist/images/profile-top.png" alt="" /> </>}

              <h3>{this.state.userDetail.name}</h3>
              <p>{this.state.designation}
              </p>
            </div>
            <div class="following-sec">
              <p><span class="heart"><img src="/admin/dist/images/heart.png" /></span>130 people followed you</p>
            </div>
            <hr></hr>
            <div class="services_block">
              <a href="javascript:void(0)"><span><img src="/admin/dist/images/network.png" />My Network</span></a>
              <a href="javascript:void(0)"><span><img src="/admin/dist/images/pending.png" />Pending Requests <b>(10)</b></span></a>
              <Link to={`/user/settings`} activeClassName="active"><span><img src="/admin/dist/images/resume.png" /> My Resumes</span></Link>
              <a href="javascript:void(0)"><span><img src="/admin/dist/images/account.png" />Manage Account</span></a>
              <Link to={`/user/settings`} activeClassName="active"><span><img src="/admin/dist/images/jobss.png" />Applied Jobs</span></Link>
            </div>
            <hr></hr>
            <div class="mypost_block">
              <a href="javascript:void(0)"><span><img src="/admin/dist/images/wifi.png" />My Post</span></a>
              <a href="javascript:void(0)"><span><img src="/admin/dist/images/gws.png" />Ganesha Tech</span></a>
            </div>
          </div>
          <div class="folio_maine_section">
            <div class="folio_inner_section">
              <h3>Folio</h3>
              <a href="javascript:void(0)" class="add_user"><img src="/admin/dist/images/pluse.png" alt="" /></a>
            </div>
            <div class="active_user">
              <ul>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/2.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/2.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
                <li><img src="/admin/dist/images/bond.png" alt="" /><span class="active_user"></span></li>
              </ul>
              <a href="javascript:void(0)" class="viewall_btn">View All</a>
            </div>
          </div>
        </div>
        </>}


      </>
    )
  }
}

export default Sidebar;
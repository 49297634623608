import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Pagination from "../Common/Pagination";


class Department extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            name: "",
            show_back: false,
            url: process.env.MIX_REACT_API_URL,
            add_new: false,
            edit_mode: false,
            allDepartment: [],
            pagination_data: {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": null,
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "next_page_url": null,
                "path": "",
                "per_page": 0,
                "prev_page_url": null,
                "to": 0,
                "total": 0
            },
        }
    }
    componentDidMount = (e) => {
        console.log(this.state.url)
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
        this.getDepartment()
    }
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    AddDepartment = (e) => {
        e.preventDefault();
        if (this.state.name === "") {
            NotificationManager.error("error", "Please enter name.");
            return false;
        }
        if (this.state.status === "") {
            NotificationManager.error("error", "Please select status.");
            return false;
        }
        var data = {
            name: this.state.name,
            status: this.state.status,
            id: this.state.id ? this.state.id : ""
        }
        if (!this.state.edit_mode) {
            Axios.post(`${this.state.url}/add/department`, data, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false
                    })
                    NotificationManager.success("success", "Department successfully added");
                    this.getDepartment()
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        } else {
            Axios.post(`${this.state.url}/update/department`, data, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false,
                        edit_mode: false
                    })
                    NotificationManager.success("success", "Department updated successfully");
                    this.getDepartment()
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        }
    }

    getDepartment = (page = 1) => {
        Axios.get(`${this.state.url}/get/department?page=${page}`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allDepartment: res.data.data.data,
                pagination_data: res.data.data
            })
        });
    }


    AddNew = (e) => {
        setTimeout(() => {
            this.setState({
                add_new: true,
                show_back: true,
                edit_mode: false,
                name: "",
                status: ""
            })
            console.log(this.state.edit_mode);
        }, 100);

    }

    cancelForm = (e) => {
        this.setState({
            show_back: false,
            add_new: false,
            edit_mode: false,
            name: "",
            status: ""
        })
    }

    editType = (e, index, id) => {
        e.preventDefault();
        var data = [];
        var selected = this.state.allDepartment[index];

        this.setState({
            show_back: true,
            id: selected.id,
            name: selected.name,
            status: selected.status,
            edit_mode: true,
            show_list: false
        })
        console.log("this.state.edit_mode", selected);
    }

    render() {
        return (<>
            <NotificationContainer />
            <Navbar page="Admin Dashboard" />
            <Sidebar />

            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-9">
                                <h1 class="m-0">Department </h1>
                            </div>
                            <div class="col-sm-3">
                                {!this.state.show_back && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.AddNew(e) }}>Add New</a></div> </>}
                                {this.state.show_back && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>}
                            </div>

                        </div>
                    </div>
                </div>
                {(this.state.edit_mode || this.state.add_new) && <> <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card card-primary">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <h3 class="card-title">Add New Department</h3>
                                            </div>
                                            <div class="col-md-3">

                                            </div>
                                        </div>
                                    </div>
                                    <form>

                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Name</label>
                                                        <input type="name" value={this.state.name} class="form-control" name="name" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter Name" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Status</label>
                                                        <select class="custom-select" value={this.state.status} onChange={(e) => { this.handleInput(e) }} name="status">
                                                            <option value="">Select Status</option>
                                                            <option selected={this.state.status === 'active' || this.state.status === 'active'} value="active">Active</option>
                                                            <option selected={this.state.status === 'inactive' || this.state.status === 'inactive'} value="inactive">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer submit-btn">
                                            <button type="button" onClick={e => { this.AddDepartment(e) }} class="btn btn-primary">Submit</button>
                                            <button type="button" onClick={e => { this.cancelForm(e) }} class="btn btn-primary">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}

                {!this.state.add_new && !this.state.edit_mode && <> <section class="table-sec">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <div class="start-table-sec">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.allDepartment.length > 0 && <>
                                                {this.state.allDepartment.map((list, index) => {
                                                    return <tr key={index}>
                                                        <td>{(this.state.pagination_data.per_page * (this.state.pagination_data.current_page - 1)) + index + 1}</td>
                                                        <td>{list.name}</td>
                                                        <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                                                        <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editType(e, index, list._id) }}></i></a></td>
                                                    </tr>
                                                })}
                                            </>}

                                        </tbody>
                                        <Pagination data={this.state.pagination_data} getData={this.getDepartment} />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}
            </div>
        </>

        );
    }
}
export default Department;
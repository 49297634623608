import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
  } from "reactstrap";
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import 'react-notifications/lib/notifications.css';

import Axios from "axios";
import NotificationAlert from "react-notification-alert";
class Claimaccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loading:false,
            email: "",
            password: "",
            url: process.env.MIX_REACT_API_URL,
        }
    }
    
    // notificationAlert = React.createRef();
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    loginSubmit(e) {
      this.setState({
        loading:true
    })
        var data = {
            email:this.state.email,
           
        }
        console.log(this.state.url);
        Axios.post(`${this.state.url}/api/claim-seller-account`, data).then(res => {
            console.log(res.data)
           // alert(res.data); 
           this.setState({
            loading:false
        })
            if(res.data.status == true){
                NotificationManager.success("success", res.data.message);
            }else{
                NotificationManager.error('error', res.data.message);
            }
        })
    }
   
    render() {
        return <>
            <NotificationContainer/>
            <header class="page_header">
  <div class="mhead_sec">
    <div class="containr">
      <div class="head_menu">
        <div class="logo_img"> <a class="navbar-logo" href="/employer/home" ><img src="../sellers/img/kaziom-logo.png" alt="kaziom logo" /></a> </div>
      </div>
    </div>
  </div>
</header>
<section class="signin-up_page">
  <div class="signin_form form_area">
	  <div class="form_feilds">
    <h4>Claim your employer account</h4>
    <form>
      <label>Offcial E-mail</label>
      <input placeholder="john@gmail.com" type="email" name="email" onChange={e => { this.handleInput(e) }} />
      {/* <label>Password</label>
      <input name="password" placeholder="Enter password" type="password" onChange={e => { this.handleInput(e) }} /> */}
     <button  type="button" onClick={e => { this.loginSubmit(e) }} disabled={this.state.loading}  >
     {this.state.loading  && <>
            <i class="fa fa-refresh fa-spin"></i>
         </>}
         {!this.state.loading  && <>
          Send E-mail
         </>}  
       
       </button>

     
    </form>
    <p> <a href="/employer/login">Login</a></p>
		  </div>
  </div>
</section>
        </>;
    }
}

export default Claimaccount;
import React from "react";
import { Link } from "react-router-dom";
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    }
  }
  componentDidMount = (e) => {
    let params = window.location.href
    setTimeout(() => {
      this.setState({
        url: params.split("/")[4]
      })
      console.log("param", this.state.url)
    }, 100);

  }
  render() {
    return (
      <>

        <aside class="main-sidebar sidebar-dark-primary elevation-4">
          <Link to="index3.html" class="brand-link">
            <img src="dist/img/logo.png" alt="Logo" class="brand-image img-circle elevation-3 large-logo" />
            <img src="dist/img/small-logo.png" alt="Logo" class="small-logo" />

          </Link>

          <div class="sidebar" style={{overflow: 'scroll'}}>
            {/* <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"/>
        </div>
        <div class="info">
          <Link to="#" class="d-block">Alexander Pierce</Link>
        </div>
      </div> */}
            <nav class="mt-2">
              <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                  <Link to="/admin/dashboard" className={"nav-link " + (this.state.url === 'dashboard' ? 'active' : "")}>
                    <i class="nav-icon fas fa-tachometer-alt"></i>
                    <p>
                      Dashboard
                      <i class="right fas fa-angle-left"></i>
                    </p>
                  </Link>
                  {/* <ul class="nav nav-treeview">
              <li class="nav-item">
                <Link to="./index.html" class="nav-link active">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Dashboard v1</p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to="./index2.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Dashboard v2</p>
                </Link>
              </li>

            </ul> */}
                </li>
                <li class="nav-item">
                  <Link to="/admin/users" className={"nav-link " + (this.state.url === 'users' ? 'active' : "")}>
                    <img src="dist/img/user.png" alt="" />
                    <p>
                      Users
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/industry" className={"nav-link " + (this.state.url === 'industry' ? 'active' : "")}>
                    <img src="dist/img/industry.png" alt="" />
                    <p>
                      Industry
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/department" className={"nav-link " + (this.state.url === 'department' ? 'active' : "")}>
                    <img src="dist/img/depat.png" alt="" />
                    <p>
                      Department
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/title" className={"nav-link " + (this.state.url === 'title' ? 'active' : "")}>
                    <img src="dist/img/tilte.png" alt="" />
                    <p>
                      Title

                    </p>
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/admin/category" className={"nav-link " + (this.state.url === 'category' ? 'active' : "")}>
                    <img src="dist/img/tilte.png" alt="" />
                    <p>
                      Category

                    </p>
                  </Link>
                </li>


                <li class="nav-item">
                  <Link to="/admin/skill" className={"nav-link " + (this.state.url === 'skill' ? 'active' : "")}>
                    <img src="dist/img/tilte.png" alt="" />
                    <p>
                      Skill

                    </p>
                  </Link>
                </li>


                <li class="nav-item">
                  <Link to="/admin/company" className={"nav-link " + (this.state.url === 'company' ? 'active' : "")}>
                    <img src="dist/img/company-icon.png" alt="" />
                    <p>
                      Company
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/jobs" className={"nav-link " + (this.state.url === 'jobs' ? 'active' : "")}>
                    <img src="dist/img/job-icon.png" alt="" />
                    <p>
                      Jobs
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/topic" className={"nav-link " + (this.state.url === 'topic' ? 'active' : "")}>
                    <img src="dist/img/topic.png" alt="" />
                    <p>
                      Topic
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/post" className={"nav-link " + (this.state.url === 'post' ? 'active' : "")}>
                    <img src="dist/img/topic.png" alt="" />
                    <p>
                      Forum Question
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/custom-notifications" className={"nav-link " + (this.state.url === 'custom-notifications' ? 'active' : "")}>
                    <img src="dist/img/notification.png" alt="" />
                    <p>
                      Custom Notifications
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/admin/customer-chats" className={"nav-link " + (this.state.url === 'customer-chats' ? 'active' : "")}>
                    {/* <img src="dist/img/notification.png" alt=""/> */}
                    <i class="fas fa-comments"></i> &nbsp;&nbsp;
                    <p>
                      Customer Chats
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/admin/report-user" className={"nav-link " + (this.state.url === 'report-user' ? 'active' : "")}>
                    {/* <img src="dist/img/notification.png" alt=""/> */}
                    <i class="fas fa-comments"></i> &nbsp;&nbsp;
                    <p>
                      Reported User
                      <i class="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                </li>
                {/* <li class="nav-item">
            <Link to="#" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Layout Options
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </Link>
            <ul class="nav nav-treeview">
             <li class="nav-item">
                <Link to="pages/layout/collapsed-sidebar.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Collapsed Sidebar</p>
                </Link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <Link to="#" class="nav-link">
              <i class="nav-icon fas fa-chart-pie"></i>
              <p>
                Charts
                <i class="right fas fa-angle-left"></i>
              </p>
            </Link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <Link to="pages/charts/chartjs.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>ChartJS</p>
                </Link>
              </li>

            </ul>
          </li>

          <li class="nav-item">
            <Link to="#" class="nav-link">
              <i class="nav-icon fas fa-edit"></i>
              <p>
                Forms
                <i class="fas fa-angle-left right"></i>
              </p>
            </Link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <Link to="pages/forms/general.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>General Elements</p>
                </Link>
              </li>



            </ul>
          </li>
          <li class="nav-item">
            <Link to="#" class="nav-link">
              <i class="nav-icon fas fa-table"></i>
              <p>
                Tables
                <i class="fas fa-angle-left right"></i>
              </p>
            </Link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <Link to="pages/tables/simple.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Simple Tables</p>
                </Link>
              </li>

            </ul>
          </li> */}










              </ul>
            </nav>
          </div>
        </aside>
      </>
    )
  }
}

export default Sidebar;
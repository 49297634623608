import React from "react";
import Axios from "axios";
const cu = sessionStorage.getItem('data') ? JSON.parse(sessionStorage.getItem("data")) : null;
const company_data = sessionStorage.getItem('comapny_data') ? JSON.parse(sessionStorage.getItem("comapny_data")) : null;

class Navbar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        url: process.env.MIX_REACT_API_URL,
      }
    }
    Capitalize(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
      }
    logout (e) {
        Axios.get(`${this.state.url}/logout`, {
          headers: {
            token: sessionStorage.getItem('_access')
          }
        }).then(res => {
          sessionStorage.removeItem("_access");
          sessionStorage.removeItem("data");
          window.location.href="/employer/login"
        });
      }


      render() {
        return (<>
        <header class="page_header" >
      <div class="dash_header" >
        <div class="mhead_sec">
          <div class="containr">
            <div class="head_menu">
              <div class="nav_bar">
                <div class="profile-details">
                  <div class="profile-content"> 
                  
                   {company_data.logo !== null && 
                     <img src={this.state.url+'/uploads/'+company_data.logo} alt="profileImg" /> 
                   }
                   {company_data.logo === null && 
                   <img src="../sellers/img/dummy-user.png" alt="profileImg" /> 
                   }
                   
                  </div>
                  <div class="name-job">
                    <div class="profile_name">{cu.name ? this.Capitalize(cu.name) : null}</div>
                  </div>
                  <i class="bx bx-log-out" onClick={e => { this.logout(e) }} ></i> </div>
        
              </div>
            </div>
          </div>
        </div>
      </div>
</header>
        </>

);
}
}

export default Navbar;
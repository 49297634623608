import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Breadcrumb, Boxes } from "./Parts";
import CKEditor from "react-ckeditor-component";
class Topic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            first_name: "",
            last_name: "",
            company_email: "",
            url: process.env.MIX_REACT_API_URL,
            add_new: false,
            contact_number: "",
            show_back: false,
            topic: "",
            image: "",
            status: "",
            edit_mode: false,
            allTopic: [],
            allIndustry: []
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        // this.onChange = this.onChange2.bind(this);
    }
    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
        this.getTopic();
    }
    onChange1(evt) {

        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            description: newContent
        })
    }


    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChange(e) {
        this.setState({
            "image": e.target.files[0],
        });

    }

    onChange2(e) {
        this.setState({
            "banner": e.target.files[0],
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        if (this.state.topic === "") {
            NotificationManager.error("error", "Please enter English topic name.");
            return false;
        }
        if (this.state.arabic_topic === "") {
            NotificationManager.error("error", "Please enter Arabic topic name.");
            return false;
        }
        if (this.state.image === "") {
            NotificationManager.error("error", "Please Choose image.");
            return false;
        }
        if (this.state.status === "") {
            NotificationManager.error("error", "Please select status.");
            return false;
        }

        const formData = new FormData();
        formData.append('topic', this.state.topic);
        formData.append('arabic_topic', this.state.arabic_topic);
        formData.append('image', this.state.image);
        formData.append('status', this.state.status);
        formData.append("id", this.state.id ? this.state.id : "");
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        if (!this.state.edit_mode) {
            Axios.post(`${this.state.url}/add/topic`, formData, config, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false
                    })
                    NotificationManager.success("success", "Topic successfully added");
                    this.getTopic()
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        } else {
            Axios.post(`${this.state.url}/update/topic`, formData, config, {
                headers: {
                    token: sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false,
                        edit_mode: false
                    })
                    NotificationManager.success("success", "Topic updated successfully");
                    this.getTopic()
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        }
    }

    getTopic = (e) => {
        Axios.get(`${this.state.url}/get/topic`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allTopic: res.data.data
            })
            console.log("this.state.allTopic", this.state.allTopic);
        });
    }


    AddNew = (e) => {
        setTimeout(() => {
            this.setState({
                show_back: true,
                add_new: true,
                edit_mode: false,
                status: "",
                topic: "",
                arabic_topic: "",
                image: "",
            })
            console.log(this.state.show_back);
        }, 100);

    }

    cancelForm = (e) => {
        this.setState({
            show_back: false,
            add_new: false,
            edit_mode: false,
            status: "",
            arabic_topic: "",
            image: "",
            topic: ""

        })
    }

    editType = (e, index, id) => {
        e.preventDefault();
        var data = [];
        var selected = this.state.allTopic[index];

        this.setState({
            show_back: true,
            id: selected.id,
            topic: selected.topic,
            arabic_topic: selected.arabic_topic,
            image: selected.image,
            status: selected.status,
            edit_mode: true,
            show_list: false
        })
        console.log("this.state.edit_mode", selected);
    }

    render() {
        return (<>
            <NotificationContainer />
            <Navbar page="Admin Dashboard" />
            <Sidebar />

            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-3">
                                <h1 class="m-0">Topic</h1>
                                {/* <input type="search" className="form-control"></input> */}
                            </div>
                            <div class="col-md-7">
                                {/* <div class="row">
                        <div class="col-md-10">
                            <div class="search-bar">
                                <form class="form-inline search-datesec">
                                    <div class="search-form">
                                        <div class="form-group">
                                            <input type="text" name="search" id="myInputTextField" placeholder="Search Record" class="form-control" />
                                                <span>
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-2">
                        <div class="import-button">
                        <a href="javascript:void(0)"><i class="far fa-file-pdf"></i></a>
                        </div>
                        </div>
                    </div> */}
                            </div>
                            <div class="col-sm-2">
                                {!this.state.show_back && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.AddNew(e) }}>Add New</a></div> </>}
                                {this.state.show_back && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>}
                            </div>

                        </div>
                    </div>
                </div>
                {(this.state.edit_mode || this.state.add_new) && <> <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card card-primary">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <h3 class="card-title">Add New Topic</h3>
                                            </div>
                                            <div class="col-md-3">

                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={this.onFormSubmit}>

                                        <div class="card-body">
                                            <div class="row">

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_name">English Topic</label>
                                                        <input type="text" value={this.state.topic} class="form-control" name="topic" onChange={e => { this.handleInput(e) }} id="topic" placeholder="Enter Topic Name" />
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_name">Arabic Topic</label>
                                                        <input type="text" value={this.state.arabic_topic} class="form-control" name="arabic_topic" onChange={e => { this.handleInput(e) }} id="arabic_topic" placeholder="Enter Arabic Topic Name" />
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="company_title">Image</label>
                                                        <input type="file" name="image" onChange={this.onChange} class="form-control"></input>
                                                        {this.state.edit_mode === true &&
                                                            <img src={this.state.url + '/uploads/' + this.state.image} class="bannerimage" alt=""></img>
                                                        }
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1"> Status</label>
                                                        <select class="custom-select" value={this.state.status} onChange={(e) => { this.handleInput(e) }} name="status">
                                                            <option value="">Select Company Status</option>
                                                            <option selected={this.state.status === 'active'} value="active">Active</option>
                                                            <option selected={this.state.status === 'inactive'} value="inactive">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer submit-btn">
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                            <button type="button" onClick={e => { this.cancelForm(e) }} class="btn btn-primary">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}

                {!this.state.add_new && !this.state.edit_mode && <> <section class="table-sec">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <div class="start-table-sec">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>English Topic</th>
                                                <th>Arabic Topic</th>
                                                <th>Logo</th>
                                                <th>Created By</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.allTopic.length > 0 && <>
                                                {this.state.allTopic.map((list, index) => {
                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{list.topic}</td>
                                                        <td>{list.arabic_topic}</td>
                                                        <td><img style={{ width: "20px", height: "20px", objectFit: 'cover' }} src={this.state.url + '/uploads/' + list.image}></img></td>
                                                        <td>{list.created_by}</td>
                                                        <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                                                        <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editType(e, index, list._id) }}></i></a></td>
                                                    </tr>
                                                })}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}
            </div>
        </>

        );
    }
}
export default Topic;
import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import ReactTextareaAutocomplete from 'react-textarea-autocomplete'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import { NotificationContainer, NotificationManager } from 'react-notifications';
class Job extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         url: process.env.MIX_REACT_API_URL,
         show: false,
         search: '',
         allJobs: [],
      }
      this.searchHandle = this.searchHandle.bind(this);
   }

   searchHandle(e) {
      this.setState({
         search: e.target.value,
      })
   }

   componentDidMount = (e) => {
      this.setState({
         "userDetail": JSON.parse(sessionStorage.getItem("userDetail"))
      })

      setTimeout(() => {
         this.setState({
            designation: this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
            show: true
         })
         console.log("sidebar", this.state.userDetail.image)
      }, 100);
      this.getAllJobs()
   }

   getAllJobs = (page = 1) => {
      Axios.post(`${this.state.url}/api/get/all/job`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allJobs: res.data.data,
         })
         console.log("homedata", this.state.allJobs)
      });
   }
   render() {
      return (
         <>
            <NotificationContainer />
            <Header />
            <Navbar />
            <section class="sidebar_menu">
               <div id="page-content-wrapper" class="top-job-page">
                  <div class="container-fluid">
                     <div class="col-md-12 pd0">
                        <div class="row">
                           <div class="col-md-9">
                              <div class="top-jobs-blocks">
                                 <h3>Top Jobs</h3>
                              </div>
                              <div class="row">
                                 <div class="col-md-5">
                                    <div class="form-header">
                                       <form>
                                          <input
                                             type="text"
                                             placeholder="Search"
                                             name="search"
                                             value={this.state.search}
                                             onChange={this.searchHandle}
                                          />
                                          <button type="submit"><img src="images/search.png" alt=""></img></button>
                                       </form>
                                    </div>
                                 </div>
                                 <div class="col-md-7">
                                    <div class="maine-select-form">
                                       <div class="refresh_icon"><a href="javascript:void(0)"><img src="images/refresh.png" alt="" /></a></div>
                                       <div class="all-select-field">
                                          <form>
                                             <select name="entry-level" id="level" class="selectvalue">
                                                <option>Entry Leval</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                             </select>
                                             <select name="today" id="today">
                                                <option>Today</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                             </select>
                                             <select name="full-time" id="time">
                                                <option>Full Time</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                             </select>
                                             <select name="full-time" id="time">
                                                <option>Full Time</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                             </select>
                                          </form>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="row">
                                 {this.state.allJobs && this.state.allJobs.length > 0 && <>
                                    {this.state.allJobs.filter((item) => {
                                       return this.state.search.toLocaleLowerCase() === '' ? item : item.department.toLocaleLowerCase().includes(this.state.search)
                                    }).map((cr, index) => {
                                       return <Link to={`/user/job/` + cr.id} class="col-sm-6 col-lg-4 col-xl-3">
                                          <div class="topjob-blocks">
                                             <div class="topjob-img">
                                                <img src={this.state.url + '/uploads/' + cr.banner} alt="" />
                                             </div>
                                             <div class="save-img">
                                                <img src="/admin/dist/images/save.png" alt="" />
                                             </div>
                                             <div class="jobdesp">
                                                <h4>{cr.department}</h4>
                                                <p><span class="sales-img">
                                                   <img src={this.state.url + '/uploads/' + cr.company_banner} alt="" />
                                                </span>{cr.company_name}</p>
                                                <p class="addresscr">{cr.address} ({cr.job_type})</p>
                                                <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                             </div>
                                             <div class="btn-jobs"><a href="javascript:void(0)" class="remote-btn">Remote</a> <a href="javascript:void(0)" class="apply-job">Apply Job</a></div>
                                          </div>
                                       </Link>
                                    })}
                                 </>}
                              </div>
                           </div>
                           <Sidebar />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </>
      )
   }
}

export default Job;
import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import readXlsxFile from 'read-excel-file';
import {NotificationContainer, NotificationManager} from 'react-notifications';
  import 'react-notifications/lib/notifications.css';
import { Breadcrumb, Boxes } from "./Parts";
import CKEditor from "react-ckeditor-component";
import Pagination from "../Common/Pagination";
import { CSVLink } from "react-csv";
import * as moment from "moment";
const input = document.getElementById('input');

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            first_name: "",
            last_name:"",
            company_email:"",
            url: process.env.MIX_REACT_API_URL,
            company_name:"",
            company_title:"",
            description:"",
            banner:"",
            logo:"",
            keyword:"",
            keyword1:"",
            industry_id:"",
            add_new:false,
            contact_number:"",
            show_back:false,
            size:"",
            upload_data:[],
            company_status:"",
            website:"",
            founded:"",
            headqarter:"",
            edit_mode:false,
            allCompany:[],
            allIndustry:[],
            sugIndustry:[],
            getjob:[],
            company_id:null,
            pagination_data: {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": null,
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "next_page_url": null,
                "path": "",
                "per_page": 0,
                "prev_page_url": null,
                "to": 0,
                "total": 0
            },
            excel_data: [],
            excel_headers: [
                { label: "Company", key: "company_name" },
                { label: "Title", key: "company_title" },
                { label: "Email", key: "company_email" },
                { label: "Website", key: "website" },
                { label: "CEO Name", key: "first_name" },
                { label: "Headqarter", key: "headqarter" },
                { label: "Founded", key: "founded" },
                { label: "Size", key: "size" },
                { label: "Status", key: "status" }
            ]
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        // this.onChange = this.onChange2.bind(this);
    }
    
    componentDidMount = (e) => {
        if(!sessionStorage.getItem("_access")){
            window.location.href = '/admin/login';
        } 
        this.getCompany();
        this.getIndustry()
    }
    onChange1(evt){
      
        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            description:newContent
        }) 
          }

    getExcelData = () => {
    var param = new URLSearchParams(this.props.location.search).get("status");
    Axios.get(`${this.state.url}/get/excel/company`, {
        headers: {
            token: sessionStorage.getItem('_access')
        }
    }).then(res => {
        
        if (res.data.data.length > 0) {
            
            var x = [];
            res.data.data.forEach(val => {
                
                var c = {
                company_name: val.company_name,
                company_title: val.company_title,
                company_email: val.company_email,
                website: val.website,
                first_name: val.first_name,
                headqarter:val.headqarter,
                size:val.size,
                founded:val.founded,
                status:val.status
                }
                c.created_at = moment(val.created_at).format("YYYY-MM-DD");
                
                x.push(c);
            })
            
            this.setState({
                excel_data: x
            })
            // setTimeout(() => {
                console.log("res.data.data.data",res.data.data)
            // }, 500);
            
            }
        
    });
}

    searchRecords = e => {
        var val = e.target.value;
        console.log(val, e.keyCode)
        if (val.length > 2 || (e.keyCode === 8 && val.length === 0) || e.keyCode === 13) {
            this.setState({
                keyword: val,
                page: 1
            })
            setTimeout(() => {
                console.log(this.state.keyword)
                this.getCompany();
            }, 100);
        }
    }
    searchIndustry = (e) => {
        Axios.post(`${this.state.url}/admin/search-industry` ,{ keyword: this.state.keyword1 }, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                sugIndustry: res.data.data
            })
          //  console.log("this.state.allDepartment", this.state.allDepartment);
        });
    }
    getIndustry = (e) => {
        Axios.get(`${this.state.url}/admin/get/industry`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allIndustry:res.data.data
            })
        });
    }
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === "keyword1") {
            this.setState({
                keyword1: e.target.value
            })
            //console.log("message",this.state.keyword);
            this.searchIndustry();
        }
    }

    onChange(e) {
        if(e.target.name === 'image'){
            this.setState({
                "logo":e.target.files[0],
            });
        }else{
            this.setState({
                "banner":e.target.files[0],
            });
        }
    }

    onChange2(e) {
        this.setState({
            "banner":e.target.files[0],
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        
        if (this.state.industry_id === "") {
            NotificationManager.error("error", "Please select Industry .");
            return false;
        }
        if (this.state.company_name === "") {
            NotificationManager.error("error", "Please enter Company Name.");
            return false;
        }
        // if (this.state.first_name === "") {
        //     NotificationManager.error("error", "Please enter First Name.");
        //     return false;
        // }
        // if (this.state.last_name === "") {
        //     NotificationManager.error("error", "Please enter Last Name.");
        //     return false;
        // }
        if (this.state.website === "") {
            NotificationManager.error("error", "Please enter Company Website.");
            return false;
        }
        if (this.state.size === "") {
            NotificationManager.error("error", "Please enter Company Size.");
            return false;
        }
        if (this.state.headqarter === "") {
            NotificationManager.error("error", "Please enter Company Headquarter.");
            return false;
        }
        if (this.state.founded === "") {
            NotificationManager.error("error", "Please select founded.");
            return false;
        }
        // if (this.state.company_title === "") {
        //     NotificationManager.error("error", "Please enter Company Title.");
        //     return false;
        // }
        // if (this.state.company_email === "") {
        //     NotificationManager.error("error", "Please enter Company Email.");
        //     return false;
        // }
        if (this.state.contact_number === "") {
            NotificationManager.error("error", "Please enter Contact Number.");
            return false;
        }
        if (this.state.logo === "") {
            NotificationManager.error("error", "Please Choose company logo.");
            return false;
        }
        if (this.state.banner === "") {
            NotificationManager.error("error", "Please Choose company banner.");
            return false;
        }
        if (this.state.description === "") {
            NotificationManager.error("error", "Please Enter Description.");
            return false;
        }
        if (this.state.company_status === "") {
            NotificationManager.error("error", "Please Select Company Status.");
            return false;
        }
        const formData = new FormData();
        formData.append('first_name',this.state.first_name);
        formData.append('logo',this.state.logo);
        formData.append('banner',this.state.banner);
        formData.append('last_name',this.state.last_name);
        formData.append('company_name',this.state.company_name);
        formData.append('contact_number',this.state.contact_number);
        formData.append('company_title',this.state.company_title);
        formData.append('company_email',this.state.company_email);
        formData.append('website',this.state.website);
        formData.append('size',this.state.size);
        formData.append('founded',this.state.founded);
        formData.append('headqarter',this.state.headqarter);
        formData.append('industry_id',this.state.industry_id);
        formData.append('company_status',this.state.company_status);
        formData.append('status',this.state.status);
        formData.append('description',this.state.description);
        formData.append("id",this.state.id ? this.state.id : "");
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        if(!this.state.edit_mode){
            Axios.post(`${this.state.url}/add/company`, formData,config, {
                    headers: {
                        token: sessionStorage.getItem('_access')
                    }
                }).then(res => {
                console.log(res.data)
                if(res.data.status == true){
                    this.setState({
                        add_new:false
                    })
                    NotificationManager.success("success", "Company successfully added");
                    this.getCompany()
                }else{
                    NotificationManager.error("error", "Email Already Exist");
                }
            })
        }else{
            Axios.post(`${this.state.url}/update/company`, formData,config, {
                    headers: {
                        token: sessionStorage.getItem('_access')
                    }
                }).then(res => {
                console.log(res.data)
                if(res.data.status == true){
                    this.setState({
                        add_new:false,
                        edit_mode:false,
                        show_back:false
                    })
                    NotificationManager.success("success", "Company updated successfully");
                    this.getCompany()
                }else{
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        }
    }
    
    getjob = (page=1,id) => {
        
        Axios.get(`${this.state.url}/get/compjob?page=${page}&id=${this.state.company_id}`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {

            this.setState({
                getjob: res.data.data.data,
                pagination_data: res.data.data,
                edit_mode:true,
            })
         
                console.log("this.state.allIndustry", this.state.pagination_data);
        });
    }
    getCompany = (page = 1) => {
        Axios.get(`${this.state.url}/get/jobCompany?page=${page}&keyword=${this.state.keyword}`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allCompany:res.data.data.data,
                pagination_data: res.data.data
            })
            this.getExcelData()
            console.log("this.state.allCompany",this.state.allCompany);
        });
    }
    

    AddNew = (e,id) => {
          console.log('id',id);
        setTimeout(() => {
            this.setState({
              //  show_back:true,
                company_id:id
              
            })
            this.getjob();
            console.log(this.state.show_back);
        }, 100);
     
        
    }

    cancelForm = (e) => {
        this.setState({
            show_back:false,
            add_new:false,
            edit_mode:false,
            company_id:null 
        })
    }
    
    editType = (e, index, id) => {
        e.preventDefault();
        var data = [];
        var selected = this.state.allCompany[index];

        this.setState({
            show_back:true,
            id: selected.id,
            first_name: selected.first_name ? selected.first_name : "",
            last_name:selected.last_name ? selected.last_name : "",
            company_email:selected.company_email ? selected.company_email : "",
            logo:selected.logo,
            
            keyword1:selected.industry ? selected.industry.name : "",
            banner:selected.banner,
            company_status:selected.company_status,
            company_name:selected.company_name,
            description:selected.description,
            contact_number:selected.contact_number,
            company_title:selected.company_title,
            website:selected.website,
            headqarter:selected.headqarter,
            founded:selected.founded,
            size:selected.size,
            industry_id:selected.industry_id,
            status: selected.status,
            edit_mode: true,
            show_list: false
        })
        console.log("this.state.edit_mode", selected);
    }

    UploadFile = (e) => {
        var data=[]
        readXlsxFile(e.target.files[0]).then((rows) => {
            console.log(rows)
            if(rows){
                for(let k of rows){
                    data.push({"industry_id":k[0], "company_name":k[1], "first_name":k[2],"last_name":k[3],"website":k[4],"size":k[5],"headqarter":k[6],"founded":k[7],"company_title":k[8],"company_email":k[9],"contact_number":k[10],"status":"inactive","description":k[12],"company_status":k[13]})
                    // data.push({"company_name":k[1]})
                }
            }
            this.setState({
                upload_data:data.slice(1)
            })

            console.log(this.state.upload_data)
        })
    }
    SendData = (e) => {
        Axios.post(`${this.state.url}/add/company/excel`, {data:this.state.upload_data}, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            NotificationManager.success("success", "Company successfully added");
    })
    }
    handleInput1 = (e = null, data) => {
        this.setState({
            industry_id: data.id,
            keyword1:data.name,
            sugIndustry: []
        })
    }
    render() {
        return (<>
         <NotificationContainer/>
            <Navbar page="Admin Dashboard" />
            <Sidebar />
            
            <div class="content-wrapper">
                <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-3">
                    {!this.state.edit_mode &&  <>    <h1 class="m-0">Company</h1>  </> }
                    {this.state.edit_mode &&  <>    <h1 class="m-0">Jobs</h1>  </> }
                        {/* <input type="search" className="form-control"></input> */}
                    </div>
                   
                    <div class="col-sm-2">
               
                    {this.state.edit_mode && <> <div class="add-new"><a href="javascript:void(0)"  onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>}
                    </div> 
                   
                    </div>
                </div>
                </div>
                {this.state.edit_mode &&  <> <section class="table-sec">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <div class="start-table-sec">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>Company</th>
                                                <th>Department</th>
                                                <th>Job Title</th>
                                                <th>Job Type</th>
                                                <th>Address</th>
                                                <th>Remote Type</th>
                                                <th>Salary</th>
                                                <th>Banner</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.getjob.length > 0 && <>
                                                {this.state.getjob.map((list, index) => {
                                                    return <tr>
                                                         <td>{(this.state.pagination_data.per_page * (this.state.pagination_data.current_page - 1)) + index + 1}</td>
                                                        <td>{list.company_name}</td>
                                                        <td>{list.department}</td>
                                                        <td>{list.title_name}</td>
                                                        <td>{list.job_type}</td>
                                                        <td>{list.address}</td>
                                                        <td>{list.remote_type}</td>
                                                        <td>{list.range_type === 'fixed' ? list.salary_to : list.salary_from + "-" + list.salary_to}</td>
                                                        <td><img width="20px;" height="20px" src={this.state.url + '/uploads/' + list.banner}></img></td>
                                                        <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                                                        {/* <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editType(e, index, list._id) }}></i></a></td> */}
                                                    </tr>
                                                })}
                                            </>}

                                        </tbody>
                                        <Pagination data={this.state.pagination_data} getData={this.getAllJobs} />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}

                           {!this.state.add_new && !this.state.edit_mode && <> <section class="table-sec">
                                <div class="container-fluid">
                                    <div class="col-md-12">
                                        <div class="start-table-sec">
                                            <div class="table-responsive">          
                                            <table class="table">
                                                <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>Company Name</th> 
                                                    <th>Company Email</th>
                                                    {/* <th>CEO First Name</th> */}
                                                    {/* <th>CEO Last Name</th> */}
                                                    <th>Website</th>
                                                    <th>Headquarter</th>
                                                    <th>Company Status</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.allCompany.length > 0 && <>
                                                    {this.state.allCompany.map((list, index) => {
                                                    return<tr>
                                                    <td>{(this.state.pagination_data.per_page * (this.state.pagination_data.current_page - 1)) + index + 1}</td>
                                                    <td>{list.company_name}</td>
                                                  
                                                    <td>{list.company_email}</td>
                                                    {/* <td>{list.first_name}</td> */}
                                                    {/* <td>{list.last_name}</td> */}
                                                    <td>{list.website}</td>
                                                    <td>{list.headqarter}</td>
                                                   <td>{list.company_status}</td>
                                              
                                                    <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                                                    <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-eye" onClick={(e) => { this.AddNew(e, list.id) }}></i></a></td>
                                                </tr>
                                                })}
                                                </>}
                                                
                                                </tbody>
                                                <Pagination data={this.state.pagination_data} getData={this.getCompany} />
                                            </table>
                                            </div>
                                </div>
                                </div>
                        </div>
                            </section>
                            </>}
                    </div>
        </>

        );
    }
}
export default Company;
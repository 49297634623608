import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Breadcrumb, Boxes } from "./Parts";
import { CSVLink } from "react-csv";
import * as moment from "moment";
import Pagination from "../Common/Pagination";

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                links: [
                    {
                        url: null,
                        label: "&laquo; Previous",
                        active: false,
                    },
                    {
                        url: null,
                        label: "Next &raquo;",
                        active: false,
                    },
                ],
                next_page_url: null,
                path: "",
                per_page: 0,
                prev_page_url: null,
                to: 0,
                total: 0,
            },
            excel_data: [],
            excel_headers: [
                { label: "Name", key: "name" },
                { label: "Email", key: "email" },
                { label: "Phone", key: "phone" },
                { label: "Location", key: "location" },
                { label: "Notify Me", key: "nofity_me" },
                { label: "Status", key: "status" },
            ],
            keyword: "",
            status: "",
            name: "",
            show_back: false,
            url: process.env.MIX_REACT_API_URL,
            add_new: false,
            edit_mode: false,
            allUsers: [],
        };
    }
    componentDidMount = (e) => {
        console.log(this.state.url);
        if (!sessionStorage.getItem("_access")) {
            window.location.href = "/admin/login";
        }
        this.getAllUsers();
    };
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    searchRecords = (e) => {
        var val = e.target.value;
        console.log(val, e.keyCode);
        if (
            val.length > 2 ||
            (e.keyCode === 8 && val.length === 0) ||
            e.keyCode === 13
        ) {
            this.setState({
                keyword: val,
                page: 1,
            });
            setTimeout(() => {
                console.log(this.state.keyword);
                this.getAllUsers();
            }, 100);
        }
    };

    getExcelData = () => {
        var param = new URLSearchParams(this.props.location.search).get(
            "status"
        );
        Axios.get(`${this.state.url}/get/excel/users`, {
            headers: {
                token: sessionStorage.getItem("_access"),
            },
        }).then((res) => {
            if (res.data.data.length > 0) {
                var x = [];
                res.data.data.forEach((val) => {
                    var c = {
                        name: val.name,
                        email: val.email,
                        phone: val.phone,
                        location: val.location,
                        notify_me: val.notify_me,
                        status: val.status,
                    };
                    c.created_at = moment(val.created_at).format("YYYY-MM-DD");

                    x.push(c);
                });

                this.setState({
                    excel_data: x,
                });
                // setTimeout(() => {
                console.log("res.data.data.data", res.data.data);
                // }, 500);
            }
        });
    };

    getAllUsers = (page = 1) => {
        Axios.post(
            `${this.state.url}/get/users?page=${page}&keyword=${this.state.keyword}`,
            {
                headers: {
                    token: sessionStorage.getItem("_access"),
                },
            }
        ).then((res) => {
            console.log(res);
            this.setState({
                allUsers: res.data.data.data,
                pagination_data: res.data.data,
            });
            this.getExcelData();
            console.log("this.state.allUsers", this.state.pagination_data);
        });
    };

    toggleStatus = (e, userId, currentStatus) => {
        e.preventDefault();
        const newStatus = currentStatus === "active" ? "inactive" : "active";

        Axios.post(
            `${this.state.url}/toggle/user/status`,
            {
                user_id: userId,
                status: newStatus,
            },
            {
                headers: {
                    token: sessionStorage.getItem("_access"),
                },
            }
        )
            .then((res) => {
                // Debugging line
                console.log("Response from toggle status:", res.data);

                if (res.data.status) {
                    this.getAllUsers();
                    NotificationManager.success(
                        "success",
                        "Status updated successfully"
                    );
                } else {
                    NotificationManager.error(
                        "error",
                        "Could not update status"
                    );
                }
            })
            .catch((error) => {
                // Debugging line
                console.log("An error occurred:", error);

                NotificationManager.error("error", "An error occurred");
            });
    };

    AddNew = (e) => {
        setTimeout(() => {
            this.setState({
                add_new: true,
                show_back: true,
                edit_mode: false,
                name: "",
                status: "",
            });
            console.log(this.state.edit_mode);
        }, 100);
    };

    toggleCanCreatePosts = (e, userId, currentStatus) => {
        e.preventDefault();
        const newStatus = currentStatus === "active" ? "inactive" : "active";

        Axios.post(
            `${this.state.url}/toggle-create-posts`,
            {
                user_id: userId,
                status: newStatus,
            },
            {
                headers: {
                    token: sessionStorage.getItem("_access"),
                },
            }
        )
            .then((res) => {
                // Debugging line
                console.log("Response from toggle status:", res.data);

                if (res.data.status) {
                    this.getAllUsers();
                    NotificationManager.success(
                        "success",
                        "Status updated successfully"
                    );
                } else {
                    NotificationManager.error(
                        "error",
                        "Could not update status"
                    );
                }
            })
            .catch((error) => {
                // Debugging line
                console.log("An error occurred:", error);

                NotificationManager.error("error", "An error occurred");
            });
    };

    cancelForm = (e) => {
        this.setState({
            show_back: false,
            add_new: false,
            edit_mode: false,
            name: "",
            status: "",
        });
    };

    editType = (e, index, id) => {
        e.preventDefault();
        var data = [];
        var selected = this.state.allUsers[index];

        this.setState({
            show_back: true,
            id: selected.id,
            name: selected.name,
            status: selected.status,
            edit_mode: true,
            show_list: false,
        });
        console.log("this.state.edit_mode", selected);
    };

    render() {
        return (
            <>
                <NotificationContainer />
                <Navbar page="Admin Dashboard" />
                <Sidebar />

                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-4">
                                    <h1 class="m-0">Users </h1>
                                </div>
                                <div class="col-sm-8">
                                    {/* {!this.state.show_back && <> <div class="add-new"><a href="javascript:void(0)"  onClick={(e) => { this.AddNew(e) }}>Add New</a></div> </>}
                    {this.state.show_back && <> <div class="add-new"><a href="javascript:void(0)"  onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>} */}
                                    <div class="row">
                                        <div class="col-md-10">
                                            <div class="search-bar">
                                                <form class="form-inline search-datesec">
                                                    <div class="search-form">
                                                        <div class="form-group">
                                                            <input
                                                                type="text"
                                                                onKeyUp={(
                                                                    e
                                                                ) => {
                                                                    this.searchRecords(
                                                                        e
                                                                    );
                                                                }}
                                                                name="search"
                                                                id="myInputTextField"
                                                                placeholder="Search Record"
                                                                class="form-control"
                                                            />
                                                            <span>
                                                                <i
                                                                    class="fa fa-search"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="import-button">
                                                <CSVLink
                                                    data={this.state.excel_data}
                                                    headers={
                                                        this.state.excel_headers
                                                    }
                                                    id="typesTableExport"
                                                    filename={
                                                        "Users-list-" +
                                                        moment().format(
                                                            "YYYY-MM-DD-h-mm-ss-a"
                                                        ) +
                                                        ".csv"
                                                    }
                                                    asyncOnClick={true}
                                                    onClick={(event) => {
                                                        this.getExcelData();
                                                    }}
                                                >
                                                    Excel
                                                </CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.state.edit_mode || this.state.add_new) && (
                        <>
                            <section class="content">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card card-primary">
                                                <div class="card-header">
                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            {/* <h3 class="card-title">Add New Department</h3> */}
                                                        </div>
                                                        <div class="col-md-3"></div>
                                                    </div>
                                                </div>
                                                <form>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        type="name"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .name
                                                                        }
                                                                        class="form-control"
                                                                        name="name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.handleInput(
                                                                                e
                                                                            );
                                                                        }}
                                                                        id="exampleInputEmail1"
                                                                        placeholder="Enter Name"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label for="exampleInputPassword1">
                                                                        Status
                                                                    </label>
                                                                    <select
                                                                        class="custom-select"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .status
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.handleInput(
                                                                                e
                                                                            );
                                                                        }}
                                                                        name="status"
                                                                    >
                                                                        <option value="">
                                                                            Select
                                                                            Status
                                                                        </option>
                                                                        <option
                                                                            selected={
                                                                                this
                                                                                    .state
                                                                                    .status ===
                                                                                    "active" ||
                                                                                this
                                                                                    .state
                                                                                    .status ===
                                                                                    "active"
                                                                            }
                                                                            value="active"
                                                                        >
                                                                            Active
                                                                        </option>
                                                                        <option
                                                                            selected={
                                                                                this
                                                                                    .state
                                                                                    .status ===
                                                                                    "inactive" ||
                                                                                this
                                                                                    .state
                                                                                    .status ===
                                                                                    "inactive"
                                                                            }
                                                                            value="inactive"
                                                                        >
                                                                            Inactive
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-footer submit-btn">
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                this.AddDepartment(
                                                                    e
                                                                );
                                                            }}
                                                            class="btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                this.cancelForm(
                                                                    e
                                                                );
                                                            }}
                                                            class="btn btn-primary"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}

                    {!this.state.add_new && !this.state.edit_mode && (
                        <>
                            <section class="table-sec">
                                <div class="container-fluid">
                                    <div class="col-md-12">
                                        <div class="start-table-sec">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>NO</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone No</th>
                                                            {/* <th>Image</th> */}
                                                            <th>Location</th>
                                                            <th>Designation</th>
                                                            <th>Notify Me</th>
                                                            <th>Status</th>
                                                            <th>
                                                                Allowed to Post
                                                            </th>
                                                            {/* <th>Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.allUsers
                                                            .length > 0 && (
                                                            <>
                                                                {this.state.allUsers.map(
                                                                    (
                                                                        list,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {this
                                                                                        .state
                                                                                        .pagination_data
                                                                                        .per_page *
                                                                                        (this
                                                                                            .state
                                                                                            .pagination_data
                                                                                            .current_page -
                                                                                            1) +
                                                                                        index +
                                                                                        1}
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        list.name
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        list.email
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        list.phone
                                                                                    }
                                                                                </td>
                                                                                {/* <td>{list.image}</td> */}
                                                                                <td>
                                                                                    {
                                                                                        list.location
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        list.title
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {list.notify_me ==
                                                                                    "no"
                                                                                        ? "No"
                                                                                        : "Yes"}
                                                                                </td>

                                                                                <td
                                                                                    class="edit-icon d-flex justify-content-between align-items-center gap-7"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        this.toggleStatus(
                                                                                            e,
                                                                                            list.id,
                                                                                            list.status
                                                                                        );
                                                                                    }}
                                                                                    style={{
                                                                                        gap: 15,
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        {list.status ==
                                                                                        "active"
                                                                                            ? "Active"
                                                                                            : "Inactive"}
                                                                                    </div>
                                                                                    <div>
                                                                                        <a href="">
                                                                                            {list.status ===
                                                                                                "active" && (
                                                                                                <>
                                                                                                    <i
                                                                                                        class="fas fa-times"
                                                                                                        title="Inactive Post"
                                                                                                    ></i>
                                                                                                </>
                                                                                            )}
                                                                                            {list.status ===
                                                                                                "inactive" && (
                                                                                                <>
                                                                                                    <i
                                                                                                        class="fas fa-check"
                                                                                                        title="Active Post"
                                                                                                    ></i>
                                                                                                </>
                                                                                            )}
                                                                                        </a>
                                                                                    </div>
                                                                                </td>

                                                                                <td>
                                                                                    <div
                                                                                        class="edit-icon d-flex justify-content-between align-items-center gap-7"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.toggleCanCreatePosts(
                                                                                                e,
                                                                                                list.id,
                                                                                                list.can_create_posts
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <div>
                                                                                            {list.can_create_posts ==
                                                                                            true
                                                                                                ? "Allowed"
                                                                                                : "Not Allowed"}
                                                                                        </div>
                                                                                        {/* <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                list.can_create_posts
                                                                                            }
                                                                                            
                                                                                        />
                                                                                        <span class="slider round"></span> */}

                                                                                        <div>
                                                                                            <a href="">
                                                                                                {list.can_create_posts ===
                                                                                                    true && (
                                                                                                    <>
                                                                                                        <i
                                                                                                            class="fas fa-times"
                                                                                                            title="Inactive Post"
                                                                                                        ></i>
                                                                                                    </>
                                                                                                )}
                                                                                                {list.can_create_posts ===
                                                                                                    false && (
                                                                                                    <>
                                                                                                        <i
                                                                                                            class="fas fa-check"
                                                                                                            title="Active Post"
                                                                                                        ></i>
                                                                                                    </>
                                                                                                )}
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                {/* <td class="edit-icon"><a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editType(e, index, list._id) }}></i></a></td> */}
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </>
                                                        )}
                                                    </tbody>
                                                    <Pagination
                                                        data={
                                                            this.state
                                                                .pagination_data
                                                        }
                                                        getData={
                                                            this.getAllUsers
                                                        }
                                                    />
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </div>
            </>
        );
    }
}
export default User;

import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Modal } from "react-bootstrap";
import Pagination from "../Common/Pagination";

class Reportuser extends React.Component {
    // debugger;
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            url: process.env.MIX_REACT_API_URL,
            name: "",
            show_back: false,
            add_new: false,
            edit_mode: false,
            allReports: [],
            singledetail: [],
            comments: [],
            /// selected_notification: [],
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                links: [
                    {
                        url: null,
                        label: "&laquo; Previous",
                        active: false,
                    },
                    {
                        url: null,
                        label: "Next &raquo;",
                        active: false,
                    },
                ],
                next_page_url: null,
                path: "",
                per_page: 0,
                prev_page_url: null,
                to: 0,
                total: 0,
            },
        };
    }

    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = "/admin/login";
        }
        this.getReports();
    };

    /**
    handleInput is a function that sets the state of the component.
    @param {object} e - The event object containing the target name and value.
    */
    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    /**
    getReports is a function that retrieves all reports for the current user from the server.
    @param {number} page - The page number to retrieve the reports from. Default is 1.
    */
    getReports = (page = 1) => {
        Axios.post(`${this.state.url}/get/report-users?page=${page}`, {
            headers: {
                token: sessionStorage.getItem("_access"),
            },
        })
        .then((res) => {
            console.log({res});
            this.setState({
                allReports: res.data.data.data,
                pagination_data: res.data.data,
            });
            console.log("this.state.allReports", this.state.allReports);
        });
    };

    /**
    This function is used to block a user.
    @param {Object} e - The event object.
    @param {Number} id - The ID of the user to be blocked.
     */
    blockuser = (e, id) => {
        Axios.post(`${this.state.url}/block-user?id=${id}`, {
            headers: {
                token: sessionStorage.getItem("_access"),
            },
        }).then((res) => {
            console.log(res);

            NotificationManager.success("success", res.data.message);
            this.getReports();
        });
    };

    /**
    sendNotiModal is a function that fetches report detail from an API and sets the state with the response data.
    @param {Object} e - The event object.
    @param {String} list - The list of reports.
     */
    sendNotiModal = (e = null, list) => {
        if (e) e.preventDefault();

        Axios.get(`${this.state.url}/get/report-detail?id=${list}`, {
            headers: {
                token: sessionStorage.getItem("_access"),
            },
        }).then((res) => {
            setTimeout(() => {
                this.setState({
                    singledetail: res.data?.data,
                    title: res.data.data.post?.title,
                    description: res.data.data.post?.description,
                    post: res.data.data?.post,
                    show_modal: true,
                });
            }, 200);
            console.log("this.state.allReports65465456", this.state.comments);
        });
    };

    /**
    showModal() is a function that sets the state of show_modal to the value of the parameter e.
    @param {any} e - The value to set show_modal to.
    */
    showModal = (e) => {
        this.setState({
            show_modal: e,
        });
    };

    render() {
        return (
            <>
                <NotificationContainer></NotificationContainer>
                <Navbar page="Admin Dashboard" /> <Sidebar />
                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-9">
                                    <h1 class="m-0">Report Users </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="table-sec">
                        <div class="container-fluid">
                            <div class="col-md-12">
                                <div class="start-table-sec">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>Reported User</th>
                                                    <th>Post Title</th>
                                                    <th>User Name</th>
                                                    <th>Comment </th>
                                                    <th>Sub Comment</th>
                                                    <th>Message</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.allReports.length >
                                                    0 && (
                                                    <>
                                                        {this.state.allReports.map(
                                                            (list, index) => {
                                                                return (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td>
                                                                            {this
                                                                                .state
                                                                                .pagination_data
                                                                                .per_page *
                                                                                (this
                                                                                    .state
                                                                                    .pagination_data
                                                                                    .current_page -
                                                                                    1) +
                                                                                index +
                                                                                1}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list
                                                                                    .reporteduser
                                                                                    ?.name
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list
                                                                                    .post
                                                                                    ?.title
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list
                                                                                    .user
                                                                                    ?.name
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list
                                                                                    .comments
                                                                                    ?.message
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list
                                                                                    .subcomments
                                                                                    ?.message
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list?.message
                                                                            }
                                                                        </td>
                                                                        <td class="edit-icon">
                                                                            {list
                                                                                .user
                                                                                ?.block ==
                                                                                0 && (
                                                                                <a
                                                                                    href="javascript:void(0)"
                                                                                    title="Block User"
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-ban"
                                                                                        aria-hidden="true"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            this.blockuser(
                                                                                                e,
                                                                                                list
                                                                                                    .user
                                                                                                    ?.id
                                                                                            );
                                                                                        }}
                                                                                    ></i>
                                                                                </a>
                                                                            )}
                                                                            {list
                                                                                .user
                                                                                ?.block ==
                                                                                1 && (
                                                                                <a
                                                                                    href="javascript:void(0)"
                                                                                    class="text-danger"
                                                                                    title="UnBlock User"
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-ban"
                                                                                        aria-hidden="true"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            this.blockuser(
                                                                                                e,
                                                                                                list
                                                                                                    .user
                                                                                                    .id
                                                                                            );
                                                                                        }}
                                                                                    ></i>
                                                                                </a>
                                                                            )}
                                                                            &nbsp;&nbsp;
                                                                            <a
                                                                                href=""
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    this.sendNotiModal(
                                                                                        e,
                                                                                        list.id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-paper-plane"></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                            <Pagination
                                                data={
                                                    this.state.pagination_data
                                                }
                                                getData={this.getReports}
                                            />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Modal
                        show={this.state.show_modal}
                        onHide={() => this.showModal(false)}
                        dialogClassName="modal-90w"
                        size="lg"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <h4 class="title-headingsec">View Report Detail</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>{this.state.title ? this.state.title : ""}</h4>
                            <div className="form-group">
                                <label>
                                    {this.state?.description
                                        ? this.state.description
                                        : ""}
                                </label>
                            </div>
                            {this.state.post?.comments.length > 0 && (
                                <>
                                    <h2>All Chat</h2>
                                    {this.state.post?.comments.map((val, key) => {
                                        return (
                                            <div
                                                className="form-group"
                                                key={key}
                                            >
                                                <label>{val.message}</label>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        );
    }
}
export default Reportuser;

import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
class Faq extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA"
        }
    }

    componentDidMount() {
        this.test();
    }
    test() {
        Axios.get("http://localhost:8000/api/test").then(res => {
            this.setState({message:res.data.message})
        })
    }

    render() {
        return <>
         <Header/>
        <section class="frequently-asked-quetions" id="faq-sec-maine">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="faq-inner-sec">
                     <div class="row">
                        <div class="col-md-9 mx-auto">
                           <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingOne">
                                    <h4 class="panel-title">
                                       <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.le VHS.</div>
                                 </div>
                              </div>
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingTwo">
                                    <h4 class="panel-title">
                                       <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</div>
                                 </div>
                              </div>
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingThree">
                                    <h4 class="panel-title">
                                       <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                 </div>
                              </div>
                              <div class="panel panel-default">
                                 <div class="panel-heading" role="tab" id="headingFour">
                                    <h4 class="panel-title">
                                       <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                       Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                       </a>
                                    </h4>
                                 </div>
                                 <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                    <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                 </div>
                              </div>
                              <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingFive">
                                   <h4 class="panel-title">
                                      <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                      </a>
                                   </h4>
                                </div>
                                <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                                   <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                </div>
                             </div>
                             <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingSix">
                                   <h4 class="panel-title">
                                      <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                      </a>
                                   </h4>
                                </div>
                                <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                                   <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                </div>
                             </div>
                             <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingSeven">
                                   <h4 class="panel-title">
                                      <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                      </a>
                                   </h4>
                                </div>
                                <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                                   <div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                </div>
                             </div>
                           
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <Footer/>
        </>;
    }
}

export default Faq;
import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
  } from "reactstrap";
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import 'react-notifications/lib/notifications.css';

import Axios from "axios";
import NotificationAlert from "react-notification-alert";
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            url: process.env.MIX_REACT_API_URL,
        }
        console.log(process.env.MIX_REACT_SOCKET_ENDPOINT)
    }

    


    // notificationAlert = React.createRef();
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    loginSubmit(e) {
        var data = {
            email:this.state.email,
            password:this.state.password
        }
        console.log(this.state.url);
        Axios.post(`${this.state.url}/admin/login`, data).then(res => {
            console.log(res.data)
           // alert(res.data);
            if(res.data.status == true){
                sessionStorage.setItem("_access", res.data.data.access_token);
                NotificationManager.success("success", "User logged successfully");
                window.location.href = '/admin/dashboard';
            }else{
                NotificationManager.error('error', 'Invalid Credential');
            }
        })
    }

    render() {
        return <>
            <NotificationContainer/>
            <div className="login-page">
                <div className="login-box">
                    <div className="login-logo">
                    <img src="dist/img/logo.png" alt="Logo" className="login-logo"/>
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign in to start your session</p>

                            <form method="post">
                                <div className="input-group mb-3">
                                    <input type="text" name="email" className="form-control" placeholder="Email" onChange={e => { this.handleInput(e) }} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" name="password" className="form-control" placeholder="Password" onChange={e => { this.handleInput(e) }} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="">
                                        {/* <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label for="remember">
                                                Remember Me
                        </label>
                                        </div> */}
                                    </div>
                                    <div className="col-12">
                                        <div className="login-button">
                                        <button type="button" className="btn btn-primary btn-block" onClick={e => { this.loginSubmit(e) }}>Sign In</button>
                                    </div>
                                    </div>
                                </div>
                            </form>

                            {/* <div className="social-auth-links text-center mb-3">
                                <p>- OR -</p>
                                <a href="#" className="btn btn-block btn-primary">
                                    <i className="fab fa-facebook mr-2"></i> Sign in using Facebook
                  </a>
                                <a href="#" className="btn btn-block btn-danger">
                                    <i className="fab fa-google-plus mr-2"></i> Sign in using Google+
                  </a>
                            </div> */}

                            {/* <p className="mb-1">
                                <a href="forgot-password.html">I forgot my password</a>
                            </p> */}
                            {/* <p className="mb-0">
                                <a href="register.html" className="text-center">Register a new membership</a>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default Login;
import React from "react";
// import { Link } from "react-router-dom";
// import Axios from "axios";
class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA",
            date: new Date()
        }
    }

    componentDidMount() {
    }
    

    render() {
        return <>
<footer id="contact-us">
         <div className="container">
            <div className="row">
               <div className="col-md-3">
                  <div className="footer-logo">
                     <img src="admin/dist/img/white-logo.png" alt=""/>
                  </div>
                  <div className="soical-icons">
                     <ul>
                        <li><a href="https://www.facebook.com/kazi.oman/"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a href="https://instagram.com/kazi.oman?igshid=1cbsam19t6sfn"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a href="https://twitter.com/kazi_oman?s=11"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/kazi-oman"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        {/* <li><a href="javascript:void(0)"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li> */}
                     </ul>
                  </div>
               </div>
               <div className="col-md-9">
                  <div className="footer-menu">
                     <ul className="navbar-right">
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about_us">About Us</a></li>
                        <li><a href="#features_sec">Features</a></li>
                        <li><a href="https://kaziom.com/blog/">Blog</a></li>
                        {/* <li><a href="#testimonial_sec">Testimonial</a></li> */}
                        {/* <li><a href="#faq_sec">FAQ</a></li> */}
                        {/* <li><a href="#blog_sec">Blog</a></li> */}
                        <li><a href="/terms-conditions">Term & Condition</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/employer/home">Employer</a></li>
                     </ul>
                     <div className="play-btna-sec">
                     <a href="https://play.google.com/store/apps/details?id=com.app.kaziom" target="_blank"><img src="admin/dist/img/available-soon-apps.png" alt=""/></a>
                           <a href="https://apps.apple.com/us/app/kaziom/id1562348010" target="_blank"><img src="admin/dist/img/play_store_en2.png" alt=""/></a>
                     </div>
                  </div>
               </div>
            </div>
            <hr className="footer-line"/>
            <div className="row">
               <div className="col-md-6">
                  <div className="copyright-sec">
                     <p>© {this.state.date?.getFullYear() } Kaziom - All rights Reserved</p>
                  </div>
               </div>
               {/* <div className="col-md-6">
                  <div className="development-sec">
                  <p className="right-sec">Design &amp; Development By<a href="https://www.ganeshawebtech.com/" target="_blank" rel="noopener noreferrer">
                     <img src="admin/dist/img/gws_logo.webp" alt="ganeshawebtech" /></a></p>
                  </div>
               </div> */}
            </div>
         </div>
      </footer>
        </>;
    }
}

export default Footer;
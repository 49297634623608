import React from "react";
import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal } from "react-bootstrap";
import StarRatings from 'react-star-ratings';
import Pagination from "../Common/Pagination";
const company_data = sessionStorage.getItem('comapny_data') ? JSON.parse(sessionStorage.getItem("comapny_data")) : null;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      name: "",
      url: process.env.MIX_REACT_API_URL,
      allJob:[],
      dashboard_data:[],
      keyword:"",
      avg_rating:0,
      pagination_data: {
        "current_page": 1,
        "data": [],
        "first_page_url": "",
        "from": 1,
        "last_page": 1,
        "last_page_url": "",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": null,
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": null,
        "path": "",
        "per_page": 0,
        "prev_page_url": null,
        "to": 0,
        "total": 0
    },
    }
  }
  componentDidMount() {

    if(!sessionStorage.getItem("_access")){
        window.location.href = '/employer/login';
    }
    this.getAllJobs(); 
    this.dashbaord();

  }

    getAllJobs = (page=1) => {
    //  alert(sessionStorage.getItem('_access'));
      Axios.get(`${this.state.url}/api/get-companyjobs?page=${page}&keyword=${this.state.keyword}`, {
          headers: {
              Authorization: "Bearer " + sessionStorage.getItem('_access')
          }
      }).then(res => {

          this.setState({
              allJob: res.data.data.data,
              pagination_data: res.data.data
          })

      });
    }
   dashbaord = (e) => {
      //  alert(sessionStorage.getItem('_access'));
        Axios.get(`${this.state.url}/api/comapny-dashboard`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
  
            this.setState({
                dashboard_data: res.data.data,
                avg_rating:res.data.data['review']
            })
           
        });
      }
  render() {
    return (<>
      <NotificationContainer />

     <Navbar page="Seller Dashboard" />
   


<div class="dashboard_content" >
   <Sidebar /> 
 
  <section class="home-section" >
    <div class="home-content">
      <div class="company_page">
        <div class="company_name">
          <div class="name_rating">
            <h2>{company_data ? company_data.company_name : null}</h2>
            <div class="rating">
             
             {this.state.avg_rating === 5 && <> 
              <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>

              </>}
              {this.state.avg_rating === 4 && <> 
              <span class="fa fa-star "></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>

              </>}
              {this.state.avg_rating === 3 && <> 
              <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>

              </>}
              {this.state.avg_rating === 2 && <> 
              <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>

              </>}
              {this.state.avg_rating === 1 && <> 
              <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star checked"></span>

              </>}
              {this.state.avg_rating === 0 && <> 
              <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>
            <span class="fa fa-star "></span>

              </>}
            </div>
          </div>
          <p>You can manage your company page, respond to reviews and update your company information</p>
        </div>
        <div class="company_details">
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="bg_white">
                    <h5>Total Reviews</h5>
                    <h2><strong>{this.state.dashboard_data ? this.state.dashboard_data['review'] : "0"}</strong></h2>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="bg_white">
                    <h5>Overall Rating</h5>
                    <h2><strong>{this.state.dashboard_data ? this.state.dashboard_data['avg_rating'] : "0"}</strong></h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="bg_white">
                    <h5>Total Followers</h5>
                    <h2><strong>{this.state.dashboard_data ? this.state.dashboard_data['followers'] : "0"}</strong></h2>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="bg_white">
                    <h5>Total Jobs</h5>
                    <h2><strong>{this.state.allJob.length}</strong></h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="bg_white">
                    <h5>Total Reviews</h5>
                    <h2><strong>{this.state.dashboard_data ? this.state.dashboard_data['review'] : "0"}</strong></h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="bg_white">
                <div class="profile_build">
                  <h4>Build your Company Profile</h4>
                 
                  <p>Over 4.5 million job seekers visit<br/>
                    Kaziom to learn about companies and make an informed career decision.</p>
                    <h4>Profile Completion: <span>30%</span></h4>
                  <div class="progress">
                  
                    <div class="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div class="creat_brand">
                  <h4>Create Your Brand</h4>
                  <ul>
                    <li><a href="#">Lorem ipsum dolor sit amet,</a></li>
                    <li><a href="#">Lorem ipsum dolor sit amet,</a></li>
                    <li><a href="#">Lorem ipsum dolor sit amet,</a></li>
                  </ul>
                </div>
                <div class="creat_brand">
                  <h4>Create Your Brand</h4>
                  <ul>
                    <li><a href="#">Lorem ipsum dolor sit amet,</a></li>
                    <li><a href="#">Lorem ipsum dolor sit amet,</a></li>
                    <li><a href="#">Lorem ipsum dolor sit amet,</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="review_details">
            <h4>Ratings</h4>
            <p>Reviews are provided by current and former employees.</p>
            <div class="tabel_head"> <span>Showing 1 Location</span>
          
            </div>
            <table class="table table-bordered table-hover">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Location</th>
                  <th scope="col">Company Culture</th>
                  <th scope="col">Job Security</th>
                  <th scope="col">Career Growth</th>
                  <th scope="col">Work Satisfaction</th>
                  <th scope="col">Salary & Benefit</th>
                  <th scope="col">Work Life Balance</th>
                  <th scope="col">Skill Development</th>
                  <th scope="col">Overall Rating</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Panchkula (1)</td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                </tr>
                <tr>
                  <td scope="row">Panchkula (1)</td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                </tr>
                <tr>
                  <td scope="row">Panchkula (1)</td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                  <td class="">5.00 <i class="fa fa-star" aria-hidden="true"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="review_details">
            <h4>Posted Jobs</h4>
            <p>Reviews are provided by current and former employees.</p>
            <div class="tabel_head"> <span>Showing 1 Location</span>
             
            </div>
            <table class="table table-bordered table-hover">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Location</th>
                  <th scope="col">Department</th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Job type</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>

              {this.state.allJob.length > 0 && <>
                     {this.state.allJob.map((list, index) => {
                       return <tr>
                        <td scope="row">{list.address}</td>
                        <td class="">{list.department}</td>
                        <td class="">{list.title_name}</td>
                        <td class="">{list.job_type}</td>
                        <td class=""> {list.contact}</td>
                        <td class=""><div class={list.status === 'active' ? "job_active" : "job_inactive"}>{list.status}</div></td>
                        </tr>
                         })}
                 </>}
               
               
               
              </tbody>
            </table>
            <Pagination data={this.state.pagination_data} getData={this.getAllJobs} />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
    </>

    );
  }
  
}
export default Dashboard;

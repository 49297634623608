import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import ReactTextareaAutocomplete from 'react-textarea-autocomplete'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Slider from "react-slick";

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: process.env.MIX_REACT_API_URL,
      show:false,
      allData:[],
      company_id:"",
      company:"",
      jobs:[],
      watchList:[],
      showType:'jobs',
      appliedJobs:[],
      image:"",
      imgUrl:"",
      resume:"",
      name:"",
      allResumes:[]
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount = (e) => {

    this.setState({
        "userDetail":JSON.parse(sessionStorage.getItem("userDetail")),
     })

     setTimeout(() => {
        this.setState({
           designation:this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
           show:true
        })
        console.log("sidebar",this.state.userDetail.image)
     }, 100);
     this.getAppliedJobs()
     this.getResume()
     this.getWatchlist()
  }

  ChangeType = (type) => {
      this.setState({
        showType:type
      })
  }

  getAppliedJobs = (id) => {
    Axios.get(`${this.state.url}/api/apply-job-list`, {
       headers: {
          "Authorization": "Bearer "+sessionStorage.getItem('_access')
        }
    }).then(res => {
        console.log(res)
        this.setState({
            appliedJobs:res.data.data.data

        })
        console.log("java.png",this.state.appliedJobs)
    });
}

getWatchlist = (id) => {
   Axios.get(`${this.state.url}/api/watchlist/list`, {
      headers: {
         "Authorization": "Bearer "+sessionStorage.getItem('_access')
       }
   }).then(res => {
       console.log(res)
       this.setState({
           WatchList:res.data.data.watchlist.data

       })
       console.log("watchlist data",this.state.WatchList)
   });
}

getResume = () => {
   Axios.get(`${this.state.url}/api/get-resumes`, {
      headers: {
         "Authorization": "Bearer "+sessionStorage.getItem('_access')
       }
   }).then(res => {
       console.log(res)
       this.setState({
           allResumes:res.data.resume

       })
       console.log("resumes",this.state.allResumes)
   });
}
handleInput(e) {

  this.setState({ [e.target.name]: e.target.value })
}

onFormSubmit = (e) => {
   e.preventDefault();
   if (this.state.name === "") {
      NotificationManager.error("error", "Please enter resume name.");
      return false;
  }
  if (this.state.resume === "") {
      NotificationManager.error("error", "Please choose image.");
      return false;
   }
   const formData = new FormData();
   formData.append('name',this.state.name);
   formData.append('resume',this.state.resume);
   const config = {
      headers: {
          'content-type': 'multipart/form-data',
          "Authorization": "Bearer "+sessionStorage.getItem('_access')
      }
  };
   Axios.post(`${this.state.url}/api/upload-resume`, formData,config, {
      // headers: {
      //    "Authorization": "Bearer "+sessionStorage.getItem('_access')
      //  }
   }).then(res => {
       console.log(res)
       this.setState({
         name:"",
         resume:"",
         imgUrl:"",
         image:""

       })
       this.getResume()
       NotificationManager.success("success", "Resume Uploaded Successfully");

   });
}

onChange(e) {

    const filesFormats = [".doc", ".docx", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", ];

       if (e.target.files) {
           let file = e.target.files[0];
           console.log(file.type)
           const isRightFormat = filesFormats.includes(file.type);
           if (!isRightFormat) {
            NotificationManager.error("error", "Please enter valid document only pdf or doc.");
            return;
          }
           var reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onloadend = function (e) {
             this.setState({
               imgUrl: file.type == "application/pdf" ? "/admin/dist/images/pdf.jpeg" : "/admin/dist/images/doc.png",  //[reader.result]
               //   image : [reader.result]
               resume:file
             })
           }.bind(this);
           setTimeout(() => {
             console.log(this.state.image[0])
           }, 200);

       }
    }

    RemoveImage = e => {
        this.setState({
            image: ""
        })
     }

   RemoveWatchlist = (id) => {
      Axios.post(`${this.state.url}/api/watchlist/remove`, {job_id:id}, {
         headers: {
            "Authorization": "Bearer "+sessionStorage.getItem('_access')
          }
      }).then(res => {
         NotificationManager.success("success", "Watchlist Remove Successfully");
         this.getWatchlist()
      });
  }
  render() {

    return (
      <>
      <NotificationContainer/>
    <Header/>
    <Navbar/>

    <section class="sidebar_menu">
    <div id="page-content-wrapper" class="top-job-page">
        <div class="container-fluid">
        <div class="col-md-12 pd0">
                  <div class="row">
                     <div class="col-md-9">
                        <div class="row">
                           <div class="col-md-4 mb-3">
                              <div class="left-side-tabs">
                                 <h3>Settings</h3>
                                 <ul class="nav nav-pills flex-column tabs-left" id="myTab" role="tablist">
                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('jobs') }} class="nav-link active" id="appliedjob-tab" data-toggle="tab" href="#appliedjob" role="tab"><img src="/admin/dist/images/applied-job.png"/> Applied Jobs</a>
                                    </li>
                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('resume') }} class="nav-link" id="resume-tab" data-toggle="tab" href="#resume" role="tab" aria-controls="resume" aria-selected="false"><img src="/admin/dist/images/upload.png"/> Upload Resume</a>
                                    </li>
                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('watchlist') }} class="nav-link" id="watchlist-tab" data-toggle="tab" href="#watchlist" role="tab"  aria-selected="false"><img src="/admin/dist/images/watch.png"/>Watchlist</a>
                                    </li>
                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('notification') }} class="nav-link" id="notification-tab" data-toggle="tab" href="#notification" role="tab"><img src="/admin/dist/images/not.png"/> Notification</a>
                                    </li>
                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('preference') }} class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><img src="/admin/dist/images/preferences.png"/> Preferences</a>
                                    </li>

                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('help') }} class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><img src="/admin/dist/images/support.png"/> Help Center or FAQ</a>
                                    </li>
                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('privacy') }} class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"  aria-selected="false"><img src="/admin/dist/images/policy.png"/>Privacy Policy</a>
                                    </li>
                                    <li class="nav-item">
                                       <a onClick={(e) => { this.ChangeType('terms') }} class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"  aria-selected="false"><img src="/admin/dist/images/term.png"/>Terms & Condition</a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <div class="col-md-8">
                              <div class="tab-content" id="myTabContent">
                                 {this.state.showType === 'jobs' && <> <div class="active" id="appliedjob" >
                                    <h2>Applied Jobs</h2>
                                    <div class="col-md-12">
                                    {this.state.appliedJobs && this.state.appliedJobs.length > 0 && <>
                                    {this.state.appliedJobs.map((cr, index) => {
                                       return <div class="tabs_maine">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="tabs-data">
                                                   <div class="left-side-sec">
                                                   {cr.banner && <> <img src={this.state.url + '/uploads/' + cr.banner} alt=""/> </>}
                                                   {cr.banner == null && <> <img src="/admin/dist/images/java.png" alt=""/> </>}

                                                   </div>
                                                   <div class="right-side-sec">
                                                      <h4>{cr.job_title}</h4>
                                                      <p> <img src="/admin/dist/images/twittersmall.png" class="small-img"/> Twitter</p>
                                                      <p>{cr.job.address} {cr.job.remote_type === 'yes' ? "(Remote)" : ""}</p>
                                                      <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="pending-data">
                                                   <a href="javascript:void(0)">{cr.status}</a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       })}
                                       </>}

                                    </div>
                                 </div>
                                 </>}
                                 {this.state.showType === 'resume' && <> <div class="" id="resume" >
                                    <h2>Upload resume</h2>
                                    <form onSubmit={this.onFormSubmit}>
                                       <div class="form-group">
                                          <input type="text" class="form-control" id="name" onChange={e => { this.handleInput(e) }} name="name" placeholder="Enter CV Name"/>
                                       </div>
                                       <div class="form-group">
                                           {this.state.imgUrl && <>
                                            <img src={this.state.imgUrl} />
                                            <a href="javascript:void()" onClick={(e) => { this.RemoveImage(e) }}>Remove</a>
                                            </>}
                                          {this.state.imgUrl == "" && <> <div class="uploadresume">

                                             <input type="file" id="myfile" name="image" onChange= {this.onChange}/>

                                             <img src="/admin/dist/images/uploadfile.png"/>
                                          </div>
                                          </>}

                                          <p>Please select a CV/Resume. CV Must be.PDF or .DOC file.</p>
                                       </div>
                                       <div class="upload-btn">
                                       <button type="submit" class="btn btn-primary">Upload</button>
                                       </div>
                                    </form>
                                    <div class="my-resume-sec">
                                       <h2>My Resume</h2>
                                       <div class="resume_maine">
                                       {this.state.allResumes && this.state.allResumes.length > 0 && <>
                                          {this.state.allResumes.map((cr, index) => {
                                          return <div class="resume-innersec">
                                             <div class="row">
                                                <div class="col-md-10">
                                                   <div class="tabs-data">
                                                      <div class="left-side-sec">
                                                         <img src="/admin/dist/images/resumes.png" />
                                                      </div>
                                                      <div class="right-side-sec toppadd">
                                                         <h4>{cr.name}</h4>
                                                         <p>{moment(cr.created_at).format('DD-MMM-YYYY')}</p>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-2">
                                                   <div class="angle-icon">
                                                      <a href="javascript:void(0)"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                         })}
                                         </>}
                                       </div>
                                    </div>
                                 </div>
                                 </>}
                                 {this.state.showType === 'watchlist' && <> <div class="" id="watchlist" >
                                    <h2>WatchList</h2>
                                    <div class="col-md-12">
                                    {this.state.WatchList && this.state.WatchList.length > 0 && <>
                                    {this.state.WatchList.map((cr, index) => {
                                       return <div class="tabs_maine">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="tabs-data">
                                                   <div class="left-side-sec">
                                                   {cr.job.banner && <> <img src={this.state.url + '/uploads/' + cr.job.banner} alt=""/> </>}
                                                   {cr.job.banner == null && <> <img src="/admin/dist/images/java.png" alt=""/> </>}
                                                   </div>
                                                   <div class="right-side-sec">
                                                      <h4>{cr.job.title.name}</h4>
                                                      <p> <img src="/admin/dist/images/twittersmall.png" class="small-img" /> Twitter</p>
                                                      <p>{cr.job.address} {cr.job.remote_type === 'yes' ? "(Remote)" : ""}</p>
                                                      <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="delete-data">
                                                   <a href="javascript:void(0)" onClick={() => {if(window.confirm('Are you sure you want to remove watchlist?')){this.RemoveWatchlist(cr.job_id)};}}>Delete</a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       })}
                                       </>}

                                    </div>
                                 </div>
                                 </>}
                                 {this.state.showType === 'notification' && <> <div class="" id="notification" >
                                    <h2>Notification</h2>
                                    <div class="col-md-12">
                                       <div class="tabs_maine">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="watchlist-img-sec">
                                                   <img src="/admin/dist/images/watchlistq.png"/>
                                                </div>
                                                <div class="watchlist-side-sec">
                                                   <h5>Watchlist Notification</h5>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="toggle-switch">
                                                   <div>
                                                      <label class="switch">
                                                      <input type="checkbox"/>
                                                      <span class="slider"></span>
                                                      </label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="tabs_maine">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="watchlist-img-sec">
                                                   <img src="/admin/dist/images/noti.png"/>
                                                </div>
                                                <div class="watchlist-side-sec">
                                                   <h5>Job Alert Notification</h5>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="toggle-switch">
                                                   <div>
                                                      <label class="switch">
                                                      <input type="checkbox"/>
                                                      <span class="slider"></span>
                                                      </label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="tabs_maine">
                                          <div class="row">
                                             <div class="col-md-9">
                                                <div class="watchlist-img-sec">
                                                   <img src="/admin/dist/images/watchlistq.png"/>
                                                </div>
                                                <div class="watchlist-side-sec">
                                                   <h5>Comment Notification</h5>
                                                </div>
                                             </div>
                                             <div class="col-md-3">
                                                <div class="toggle-switch">
                                                   <div>
                                                      <label class="switch">
                                                      <input type="checkbox"/>
                                                      <span class="slider"></span>
                                                      </label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 </>}
                              </div>
                           </div>
                        </div>
                     </div>
                     <Sidebar/>
                     </div>
               </div>
        </div>
        </div>

        </section>


      </>
    )
  }
}

export default Setting;
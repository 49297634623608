import React from "react";
import { Link } from "react-router-dom";
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      show: false
    }
  }
  componentDidMount = (e) => {

  }
  render() {
    return (
      <>

        <div id="sidebar-wrapper" className="sticky">
          <nav className="main-menu">
            <ul>
              <li className="active">
                <a href="#">
                  <span className="nav-icon"> <img src="/admin/dist/images/home.png" alt="" /> </span>
                  <span className="nav-text">Create Post
                  </span>
                </a>
              </li>
              <li className="has-subnav">
                <Link to={`/user/companies`} activeClassName="active">
                  <span className="nav-icon"><img src="/admin/dist/images/companies.png" alt="" /></span>
                  <span className="nav-text">Companies
                  </span>

                </Link>
              </li>

              <li className="has-subnav">
                <Link to={`/user/jobs`} activeClassName="active">
                  <span className="nav-icon"><img src="/admin/dist/images/job.png" alt="" /></span>
                  <span className="nav-text">Job
                  </span>
                </Link>
              </li>
              <li className="has-subnav">
                <Link to={`/user/forums`} activeClassName="active">
                  <span className="nav-icon"><img src="/admin/dist/images/forum.png" alt="" /></span>
                  <span className="nav-text">Forum
                  </span>
                </Link>
              </li>
              <span className="line-menu">
                <hr></hr>
              </span>
              <li>
                <a href="#">
                  <span className="nav-icon"><img src="/admin/dist/images/create-post.png" alt="" /></span>
                  <span className="nav-text">Create Post
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="nav-icon"><img src="/admin/dist/images/create-posts.png" alt="" /></span>
                  <span className="nav-text">Create Project
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="nav-icon"><img src="/admin/dist/images/add-ralary.png" alt="" /></span>
                  <span className="nav-text">Add Salary
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="nav-icon"><img src="/admin/dist/images/reviews.png" alt="" /></span>
                  <span className="nav-text">Add Reviews
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="nav-icon"><img src="/admin/dist/images/interviews.png" alt="" /></span>
                  <span className="nav-text">Add Interviews
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="nav-icon"><img src="/admin/dist/images/topics.png" alt="" /></span>
                  <span className="nav-text">Add Topics
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>


      </>
    )
  }
}

export default Navbar;
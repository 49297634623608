import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
class ContactUs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA"
        }
    }

    componentDidMount() {
        this.test();
    }
    test() {
        Axios.get("http://localhost:8000/api/test").then(res => {
            this.setState({message:res.data.message})
        })
    }

    render() {
        return <>
         <Header/>
         <section class="contact-form" id="contact-us">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <div class="contact-address">
                     <span><i class="fa fa-paper-plane" aria-hidden="true"></i>125 Lorem Ipsum is simply, dummy text of
                     the printing and is typesetting .</span>
                     <span><a class="first-no" href="mailto:lorumipsum@gmail.com" target="_blank" rel="noopener"><i class="fa fa-envelope" aria-hidden="true"></i> lorumipsum@gmail.com</a></span>
                     <span class="addresicon call-icom"><a class="first-no" href="tel:+0123654789" target="_blank" rel="noopener"><i class="fa fa-mobile" aria-hidden="true"></i>+0123654789</a></span>
                     <span class="addresicon call-icom"><a class="first-no" href="tel:+0123654789" target="_blank" rel="noopener"><i class="fa fa-phone" aria-hidden="true"></i>+0123654789</a></span>
                  </div>
               </div>
               <div class="col-md-7">
                  <div class="map-image">
                     <img src="images/map.png" alt=""/>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="what-we-more">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="inner-aboutus">
                     <h3>What to know More?</h3>
                     <img src="images/line2.png" alt=""/>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                     </p>
                  </div>
               </div>
            </div>
            <div class="col-md-12">
               <div class="contactus-form">
                  <form>
                     <div class="col-md-6">
                        <div class="form-group">
                           <input type="text" class="form-control" placeholder="First Name*" id="name"/>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <input type="text" class="form-control" placeholder="Last Name*" id="name"/>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <input type="text" class="form-control" placeholder="Email*" id="email"/>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <input type="text" class="form-control" placeholder="Phone No.*" id="number"/>
                        </div>
                     </div>
                     <div class="col-md-12">
                        <div class="form-group">
                           <textarea class="form-control" rows="2" id="comment" placeholder="Your Message"></textarea>
                        </div>
                     </div>
                     <div class="submit-btn">
                        <button type="submit" class="btn">Submit</button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </section>
      <Footer/>
        </>;
    }
}

export default ContactUs;
import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import CKEditor from "react-ckeditor-component";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import ModernDatepicker from 'react-modern-datepicker';
import Jobform from "../Common/Jobform";
import * as moment from "moment";
import Pagination from "../Common/Pagination";
import { CSVLink } from "react-csv";
const urlRegEx ='[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}(.[a-z]{2,4})?\b(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?';
// const $ = window.jQuery;
class Jobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            name: "",
            job_type: "",
            description: "",
            range_type: "",
            keyword:"",
            expiry_date: "",
            allJobTitle: [],
            edit_location:false,
            remote_type: "",
            contact: "",
            email: "",
            show_fixed: true,
            show_back: false,
            salary_type: "",
            salary_from: "",
            overview: "",
            requirement: "",
            latitude: "",
            longitude: "",
            city: "",
            banner: "",
            job_title: "",
            address: "",
            url: process.env.MIX_REACT_API_URL,
            add_new: false,
            department_id: "",
            edit_mode: false,
            company_id: "",
            keyword:"",
            keyword1:"",
            keyword2:"",
            web_url:"",
            show_fixed: false,
            allIndustry: [],
            filterData: [],
            allJob: [],
            allJobType: [],
            allCompany: [],
            sugCompany:[],
            allDepartment: [],
            sugDepartment: [],
            sugJob: [],
            pagination_data: {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": null,
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "next_page_url": null,
                "path": "",
                "per_page": 0,
                "prev_page_url": null,
                "to": 0,
                "total": 0
            },
            excel_data: [],
            excel_headers: [
                { label: "Company", key: "company_name" },
                { label: "Department", key: "department" },
                { label: "Job Title", key: "title_name" },
                { label: "Job Type", key: "job_type" },
                { label: "Address", key: "address" },
                { label: "Salary", key: "salary" },
                { label: "Status", key: "status" }
            ]
        }
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        this.onChange2 = this.onChange2.bind(this);
        this.onChange3 = this.onChange3.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
        // const gmapScriptEl = document.createElement(`script`)
        // gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAER3cqDaeDGIFpTwqMOZA-72A11XOiaTA&libraries=places`
        // document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
        this.getTitle();
        this.getJobType()
        this.getCompany();
        this.getDepartment();
        this.getAllJobs()
    }
    onSelect = (e) => {
        console.log("address", e);
        setTimeout(() => {
            var lastEl = e.value.terms.slice(-2)[0];
            this.setState({
                address: e.value.description,
                city: lastEl.value
            })
            console.log("asdfsdf", this.state.city)
            this.getLatLng(this.state.address)
        }, 100);

    }
    getExcelData = () => {
        var param = new URLSearchParams(this.props.location.search).get("status");
        Axios.get(`${this.state.url}/api/get/ExcelJob`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            
            if (res.data.data.length > 0) {
               
                var x = [];
                res.data.data.forEach(val => {
                    
                   var c = {
                    company_name: val.company_name,
                    department: val.department,
                    title_name: val.title_name,
                    job_type: val.job_type,
                    address: val.address,
                    status:val.status
                   }
                   c.created_at = moment(val.created_at).format("YYYY-MM-DD");
                   c.salary = val.range_type === 'fixed' ? val.salary_to : val.salary_from + "-" + val.salary_to
                 
                   x.push(c);
                })
                
                this.setState({
                   excel_data: x
                })
                // setTimeout(() => {
                    console.log("res.data.data.data",res.data.data)
                // }, 500);
                
             }
            
        });
    }

    getLatLng = () => {
        //  setTimeout(() => {
          
        geocodeByAddress(this.state.address)
      
            .then(results => getLatLng(results[0]))

            .then(({ lat, lng }) => this.setState({
                latitude: lat,
                longitude: lng
            }))
          
            .catch(error => console.error('Error', error));
        //  }, 200);
        setTimeout(() => {
            console.log(this.state.longitude)
        }, 200);



    }
    handleChange = address => {
        console.log("Adsdf")
        this.setState({ address });
    };
    handleInput(e) {
        console.log(e.target.name)
        // setTimeout(() => {
        if (e.target.name === 'range_type') {
            if (e.target.value === 'range') {
                this.setState({
                    show_range: true,
                    show_fixed: false
                })
            } else {
                this.setState({
                    show_fixed: true,
                    show_range: false
                })
            }
            console.log(this.state.show_fixed)
        }
        // }, 100);

        this.setState({ [e.target.name]: e.target.value })

        if (e.target.name === "keyword") {
            this.setState({
                keyword: e.target.value
            })
            //console.log("message",this.state.keyword);
            this.searchCompany();
        }

        if (e.target.name === "keyword1") {
            this.setState({
                keyword1: e.target.value
            })
            //console.log("message",this.state.keyword);
            this.searchDepartment();
        }
        if (e.target.name === "keyword2") {
            this.setState({
                keyword2: e.target.value
            })
            //console.log("message",this.state.keyword);
            this.searchJob();
        }
        
    }
    
    onFormSubmit = (e) => { 
        e.preventDefault();
        console.log(this.state.address)
        // if (this.state.company_id === "") {
        //     NotificationManager.error("error", "Please select company.");
        //     return false;
        // }
    //     if (this.state.url !== "") {
        
    //         if(!new RegExp(urlRegEx).test(String(this.state.url).toLowerCase())){
    //           NotificationManager.error("error", "Enter valid Url.");
    //           return false;
    //         }
         
    //   }
        if (this.state.department_id === "") {
            NotificationManager.error("error", "Please select department.");
            return false;
        }
        if (this.state.job_title === "") {
            NotificationManager.error("error", "Please select job title.");
            return false;
        }
        if (this.state.job_type === "") {
            NotificationManager.error("error", "Please select job type.");
            return false;
        }
        if (this.state.contact === "") {
            NotificationManager.error("error", "Please enter contact number.");
            return false;
        }
        if (this.state.contact.length < 10) {
            NotificationManager.error("error", "Please enter correct contact number.");
            return false;
        }
        if (this.state.email === "") {
            NotificationManager.error("error", "Please enter email.");
            return false;
        }

        if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.email)) {
            NotificationManager.error("error", "Please enter valid email.");
            return false;
        }   


        
      
        if (this.state.remote_type === "") {
            NotificationManager.error("error", "Please choose remote type.");
            return false;
        }

        if (this.state.range_type === "") {
            NotificationManager.error("error", "Please select range type.");
            return false;
        }
        if (this.state.range_type === "range") {
            if (this.state.salary_from === "") {
                NotificationManager.error("error", "Please enter salary from amount.");
                return false;
            }
        }
        if (this.state.salary_to === "") {
            NotificationManager.error("error", "Please enter salary to amount.");
            return false;
        }
        
        if (this.state.salary_type === "") {
            NotificationManager.error("error", "Please select salary type.");
            return false;
        }
        if (this.state.status === "") {
            NotificationManager.error("error", "Please select status.");
            return false;
        }
        if (this.state.description === "") {
            NotificationManager.error("error", "Please enter description.");
            return false;
        }
        if (this.state.overview === "") {
            NotificationManager.error("error", "Please enter overview.");
            return false;
        }
        if (this.state.requirement === "") {
            NotificationManager.error("error", "Please enter requirment.");
            return false;
        }
        if (this.state.status === "") {
            NotificationManager.error("error", "Please select status.");
            return false;
        }
        if (this.state.banner === "") {
            NotificationManager.error("error", "Please choose banner image.");
            return false;
        }
        if (this.state.expiry_date === "") {
            NotificationManager.error("error", "Please select expiry date.");
            return false;
        }

        const formData = new FormData();
        formData.append('job_type', this.state.job_type);
       // formData.append('company_id', this.state.company_id);
        formData.append('department_id', this.state.department_id);
        formData.append('job_title', this.state.job_title);
        formData.append('address', this.state.address);
        formData.append('latitude', this.state.latitude);
        formData.append('longitude', this.state.longitude);
        formData.append('remote_type', this.state.remote_type);
        formData.append('range_type', this.state.range_type);
        formData.append('salary_from', this.state.salary_from);
        formData.append('salary_to', this.state.salary_to);
        formData.append('salary_type', this.state.salary_type);
        formData.append('description', this.state.description);
        formData.append('overview', this.state.overview);
        formData.append('requirement', this.state.requirement);
        formData.append('banner', this.state.banner);
        formData.append('expiry_date', this.state.expiry_date);
        formData.append('status', this.state.status);
        formData.append('contact', this.state.contact);
        formData.append('email', this.state.email);
        formData.append('city', this.state.city);
        formData.append('url', this.state.web_url);
        formData.append("id", this.state.id ? this.state.id : "");
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                 'Authorization': "Bearer " + sessionStorage.getItem('_access')
            }
        };
        if (!this.state.edit_mode) {
            Axios.post(`${this.state.url}/api/addJob`, formData, config, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false
                    })
                    NotificationManager.success("success", "Job successfully added");
                    this.getAllJobs()
                } else {
                    NotificationManager.error("error", res.data.message);
                }
            })
        } else {
            Axios.post(`${this.state.url}/api/updateJob`, formData, config, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        add_new: false,
                        edit_mode: false,
                        show_back:false,
                        edit_location:false,
                    })
                    NotificationManager.success("success", "Job updated successfully");
                    this.getAllJobs()
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            })
        }
    }

    getTitle = (e) => {
        Axios.get(`${this.state.url}/admin/get/title`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            var filterDataArr = [];
            for (let k of res.data.data) {
                filterDataArr.push(k.name)
            }
            this.setState({
                allJobTitle: res.data.data,
                filterData: filterDataArr
            })
            console.log("this.state.allIndustry", this.state.filterData);
        });
    }
    getJobType = (e) => {
        Axios.get(`${this.state.url}/get/job-type`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allJobType: res.data.data,
            })
        });
    }

    getAllJobs = (page=1) => {
         // alert(sessionStorage.getItem('_access'));
        Axios.get(`${this.state.url}/api/get-companyjobs?page=${page}&keyword=${this.state.keyword}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {

            this.setState({
                allJob: res.data.data.data,
                pagination_data: res.data.data
            })
            this.getExcelData()
                console.log("this.state.allIndustry", this.state.pagination_data);
        });
    }

    searchRecords = e => {
        var val = e.target.value;
        console.log(val, e.keyCode)
        if (val.length > 2 || (e.keyCode === 8 && val.length === 0) || e.keyCode === 13) {
           this.setState({
              keyword: val,
              page: 1
           })
           setTimeout(() => {
              console.log(this.state.keyword)
              this.getAllJobs();
           }, 100);
        }
     }


    AddNew = (e) => {
        setTimeout(() => {
            this.setState({
                add_new: true,
                edit_mode: false,
                company_id: "",
                department_id: "",
                job_title: "",
                job_type: "",
                show_back: true,
                city: "",
                address: "",
                remote_type: "",
                range_type: "",
                salary_from: "",
                salary_to: "",
                contact: "",
                email: "",
                salary_type: "",
                description: "",
                overview: "",
                requirement: "",
                banner: "",
                expiry_date: "",
                latitude: "",
                longitude: "",
                status: "",
                keyword:"",
                keyword1:"",
                keyword2:""
            })
            console.log(this.state.edit_mode);
        }, 100);

    }

    cancelForm = (e) => {
        this.setState({
            add_new: false,
            edit_mode: false,
            company_id: "",
            department_id: "",
            job_title: "",
            job_type: "",
            city: "",
            address: "",
            show_back: false,
            remote_type: "",
            range_type: "",
            salary_from: "",
            salary_to: "",
            salary_type: "",
            description: "",
            overview: "",
            requirement: "",
            contact: "",
            email: "",
            banner: "",
            expiry_date: "",
            latitude: "",
            longitude: "",
            status: "",
            keyword:"",
            keyword1:"",
            keyword2:""
        })
    }

    editType = (e, index, id) => {
        e.preventDefault();
        var data = [];
        var selected = this.state.allJob[index];
        if (selected.range_type === 'range') {
            this.setState({
                show_range: true,
                show_fixed: false
            })
        } else {
            this.setState({
                show_fixed: true,
                show_range: false
            })
        }

        
     //   this.state.expiry_date < moment(new Date()).format("YYYY-MM-DD") ?   moment(new Date()).format("YYYY-MM-DD") : this.state.expiry_date
        this.setState({
            show_back: true,
            id: selected.id,
            company_id: selected.company_id,
            keyword: selected.company_name,
            keyword1: selected.department,
            keyword2: selected.title_name, 
            department_id: selected.department_id,
            job_title: selected.job_title_id,
            job_type: selected.job_type_id,
            contact: selected.contact,
            city: selected.city,
            email: selected.email,
            address: selected.address,
            remote_type: selected.remote_type,
            range_type: selected.range_type, 
            salary_from: selected.salary_from,
            salary_to: selected.salary_to,
            salary_type: selected.salary_type,
            description: selected.description,
            overview: selected.overview,
            requirement: selected.requirement,
            banner: selected.banner,
            expiry_date:  selected.expiry_date,
            latitude: selected.latitude,
            longitude: selected.longitude,
            status: selected.status,
            edit_mode: true,
            show_list: false
        })

       
        console.log("this.state.edit_mode", selected);
     //   this.getCompanyname();
       // this.getDepartmentname();
       // this.getJobname();
    }
    handleOnSubmit = (term) => {
        setTimeout(() => {
            this.setState({
                job_title: term
            })
        }, 100);

    }

    editLocation = (e) => {
        this.setState({
            edit_location:true
        })
    }
    
    getCompany = (e) => {
        Axios.get(`${this.state.url}/admin/get/company`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allCompany: res.data.data
            })
            console.log("this.state.allCompany", this.state.allCompany);
        });
    }
    
    

    searchJob = (e = null) => {

        //   this.setState({ keyword: keyword })

        Axios.post(`${this.state.url}/admin/search-job`, { keyword: this.state.keyword2 }, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
           // console.log("temp", res.data.data);
            this.setState({

                sugJob: res.data.data
            })

        })
    }
  
    searchCompany = (e = null) => {

        //   this.setState({ keyword: keyword })

        Axios.post(`${this.state.url}/admin/search-company`, { keyword: this.state.keyword }, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log("temp", res.data.data);
            this.setState({

                sugCompany: res.data.data
            })

        })
    }
   

    searchDepartment = (e) => {
        Axios.post(`${this.state.url}/admin/search-department` ,{ keyword: this.state.keyword1 }, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                sugDepartment: res.data.data
            })
          //  console.log("this.state.allDepartment", this.state.allDepartment);
        });
    }
    getDepartment = (e) => {
        Axios.get(`${this.state.url}/admin/get/department`, {
            headers: {
                token: sessionStorage.getItem('_access')
            }
        }).then(res => {
            console.log(res)
            this.setState({
                allDepartment: res.data.data
            })
            console.log("this.state.allDepartment", this.state.allDepartment);
        });
    }
    onChange(evt) {
        this.setState({
            "banner": evt.target.files[0],
        });

    }
    onChange1(evt) {

        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            description: newContent
        })
    }
    onChange2(evt) {
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            overview: newContent
        })
    }
    onChange3(evt) {
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            requirement: newContent
        })
    }
    handelDate = (date) => {
       //  alert(date);
         if(date !== 'Invalid Date'){
            this.setState({
                expiry_date: date
            })
         }
        
    }
   
    handleInput1 = (e = null, company) => {
        this.setState({
            company_id: company.id,
            keyword:company.company_name,
            sugCompany: []
        })
    }
    handleInput2 = (e = null, department) => {
        this.setState({
            department_id: department.id,
            keyword1:department.name,
            sugDepartment: []
        })
    }
    handleInput3 = (e = null, job) => {
        this.setState({
            job_title: job.id,
            keyword2:job.name,
            sugJob: []
        })
    }
    
    
    render() {
        const recentSearches = this.state.filterData;
        const placeholder = 'Search Job Type...';
        const job_type = "job_type"
        const inputPosition = 'center';
        return (<>
            <NotificationContainer />
            <Navbar page="Seller Jobs" />
            
            <div class="dashboard_content" >
   <Sidebar />
   <section class="home-section" >
      <div class="home-content">
         {(this.state.edit_mode || this.state.add_new) && <>
         <div class="add_job">
            <div class="bg_white">
               <h4>Add Job</h4>
               <p>Add job here</p>
               <div class="form_inputs">
                  <form accept-charset="UTF-8" onSubmit={this.onFormSubmit}  autocomplete="off"   method="POST" enctype="multipart/form-data" target="_blank">
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <label required="required">Department</label>
                              <input value={this.state.keyword1} autoComplete="off"  type="text" class="form-control" name="keyword1"   onChange={(e) => { this.handleInput(e) }} placeholder="Enter Department" />
                              {this.state.sugDepartment.length > 0 && <>
                              {this.state.sugDepartment.map((temp, key) => {
                              //  return 
                              <p key={key} className="chat-template"  >{temp.company_name}</p>
                              return   <option key={key} onClick={(e) => { this.handleInput2(e, temp) }} >{temp.name}</option>
                              })
                              }
                              </>}
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label >Job Title</label>
                              <input value={this.state.keyword2} autoComplete="off" className="form-control custom-select"  placeholder="Search job title" name="keyword2" onChange={(e) => { this.handleInput(e) }} />
                              {this.state.sugJob.length > 0 && <>
                              {this.state.sugJob.map((temp, key) => {
                              //  return 
                              <p key={key} className="chat-template"  >{temp.company_name}</p>
                              return  <option key={key} onClick={(e) => { this.handleInput3(e, temp) }} >{temp.name}</option>
                              })
                              }
                              </>}
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <label required="required">Job Type</label>
                              <select value={this.state.job_type} className="custom-select form-control form-control-lg" name="job_type" onChange={(e) =>
                                 { this.handleInput(e) }}>
                                 <option selected  value=" ">Select Job Type</option>
                                 {this.state.allJobType.length > 0 && <>
                                 {this.state.allJobType.map((cr, index) => {
                                 return  <option value={cr.id} data={index}>{cr.name}</option>
                                 ;
                                 })}
                                 </>}
                              </select>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>URL</label>
                              <input type="text" value={this.state.web_url} class="form-control" name="web_url" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter URL" />
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>Contact Number</label>
                              <input type="number" value={this.state.contact} class="form-control" name="contact" onChange={e => { this.handleInput(e) }} id="exampleInputEmai54l1" placeholder="Enter Contact Number" />
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>E-mail</label>
                              <input type="text" value={this.state.email} class="form-control" name="email" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter Email" />
                           </div>
                        </div>
                        
                     </div>
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>Address</label>
                              {this.state.edit_mode && <>  <a href="javascript:void(0)"><i class="far fa-edit" onClick={(e) => { this.editLocation(e) }}></i></a></>}
                              {this.state.edit_location == true && this.state.edit_mode === true && <>
                              <GooglePlacesAutocomplete
                              value={this.state.address}
                              className="form-control"
                              apiKey="AIzaSyABHQkemy2kHyDvto_e0IW4hNRafNlsoQw"
                              selectProps={{ onChange: (e) => this.onSelect(e) }}
                              />
                              </>}
                              {this.state.edit_location == false && this.state.edit_mode === true && <>
                              <input type="text" value={this.state.address} class="form-control" name="location" disabled />
                              </>}
                              {this.state.add_new && <>  <GooglePlacesAutocomplete
                              value={this.state.address}
                              className="form-control"
                              apiKey="AIzaSyABHQkemy2kHyDvto_e0IW4hNRafNlsoQw"
                              selectProps={{ onChange: (e) => this.onSelect(e) }}
                              /></>}
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group radio_select">
                              <label>Remote</label>
                              
                              <input type="radio" checked={this.state.remote_type === 'yes'} name="remote_type" value="yes" onChange={(e) => { this.handleInput(e) }} class="radio-sec" class="" /> <span>Yes</span> 
                              <input type="radio" checked={this.state.remote_type === 'no'} name="remote_type" value="no" onChange={(e) => { this.handleInput(e) }} class="radio-sec" class="rediopadding" />  <span>No</span>
                              <input type="radio" checked={this.state.remote_type === 'temporary'} name="remote_type" value="temporary" onChange={(e) => { this.handleInput(e) }} class="radio-sec" class="rediopadding" />  <span>Temporary</span>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <label for="exampleInputPassword1">Salary</label>
                              <select class="custom-select form-control" value={this.state.range_type} onChange={(e) =>
                                 { this.handleInput(e) }} name="range_type">
                                 <option value="">Select Salary Type</option>
                                 <option selected={this.state.range_type === 'range' || this.state.range_type === 'range'} value="range">Range</option>
                                 <option selected={this.state.range_type === 'fixed' || this.state.range_type === 'fixed'} value="fixed">Fixed</option>
                              </select>
                           </div>
                        </div>
                        {this.state.show_range && <> 
                        <div class="col-md-2">
                           <div class="form-group description-sec">
                              <label >Rs.</label>
                              <input type="number" value={this.state.salary_from} name="salary_from" onChange={(e) => { this.handleInput(e) }} class="form-control" id="exampleInputEmail1" placeholder="From" />
                           </div>
                        </div>
                        <div class="col-md-2">
                           <div class="form-group description-sec">
                              <label >To</label>
                              <input type="number" value={this.state.salary_to} name="salary_to" onChange={(e) => { this.handleInput(e) }} class="form-control" id="exampleInputEmail1" placeholder="To" />
                           </div>
                        </div>
                        <div class="col-md-2">
                           <div class="form-group description-sec">
                              <label >Type</label>
                              <select class="custom-select form-control" value={this.state.salary_type} onChange={(e) =>
                                 { this.handleInput(e) }} name="salary_type">
                                 <option value="">Select Type</option>
                                 <option selected={this.state.salary_type === 'monthly' || this.state.salary_type === 'monthly'} value="monthly">Monthly</option>
                                 <option selected={this.state.salary_type === 'yearly' || this.state.salary_type === 'yearly'} value="yearly">Yearly</option>
                                 <option selected={this.state.salary_type === 'hourly' || this.state.salary_type === 'hourly'} value="hourly">Hourly</option>
                              </select>
                           </div>
                        </div>
                        </>}
                        {this.state.show_fixed && <> 
                        <div class="col-md-2">
                           <div class="form-group description-sec">
                              <label >To</label>
                              <input type="number" value={this.state.salary_to} name="salary_to" onChange={(e) => { this.handleInput(e) }} class="form-control" id="exampleInputEmail1" placeholder="To" />
                           </div>
                        </div>
                        <div class="col-md-2">
                           <div class="form-group description-sec">
                              <label >Type</label>
                              <select class="custom-select form-control" value={this.state.salary_type} onChange={(e) =>
                                 { this.handleInput(e) }} name="salary_type">
                                 <option value="">Select Type</option>
                                 <option selected={this.state.salary_type === 'monthly' || this.state.salary_type === 'monthly'} value="monthly">Monthly</option>
                                 <option selected={this.state.salary_type === 'yearly' || this.state.salary_type === 'yearly'} value="yearly">Yearly</option>
                                 <option selected={this.state.salary_type === 'hourly' || this.state.salary_type === 'hourly'} value="hourly">Hourly</option>
                              </select>
                           </div>
                        </div>
                        </>}
                     </div>
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <label for="exampleInputPassword1">Status</label>
                              <select class="form-control form-control-lg" value={this.state.status} onChange={(e) =>
                                 { this.handleInput(e) }} name="status">
                                 <option value="" >Select Status</option>
                                 <option selected={this.state.status === 'active' || this.state.status === 'active'} value="active">Active</option>
                                 <option selected={this.state.status === 'inactive' || this.state.status === 'inactive'} value="inactive">Inactive</option>
                              </select>
                           </div>
                        </div>
                       
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <div class="form-group">
                              <label for="exampleInputPassword1">Description</label>
                              <CKEditor
                              activeClass="p10"
                              content={this.state.description}
                              events={{
                              "blur": this.onBlur,
                              "afterPaste": this.afterPaste,
                              "change": this.onChange1
                              }}
                              />
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <label for="exampleInputPassword1">Overview</label>
                              <CKEditor
                              activeClass="p10"
                              content={this.state.overview}
                              events={{
                              "blur": this.onBlur,
                              "afterPaste": this.afterPaste,
                              "change": this.onChange2
                              }}
                              />
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <label for="exampleInputPassword1">Requirement</label>
                              <CKEditor
                              activeClass="p10"
                              content={this.state.requirement}
                              events={{
                              "blur": this.onBlur,
                              "afterPaste": this.afterPaste,
                              "change": this.onChange3
                              }}
                              />
                           </div>
                        </div>
                     </div>
                     <div class="row">
                     <div class="col-md-6">
                           <div class="form-group file_upload">
                              <label class="mr-4">Banner Image:</label>
                              <label for="file" class="sr-only">File</label>
                              <div class="input-group">
                             
                                     <input type="file" class="form-control"  onChange={this.onChange} name="banner"  />

                                     {this.state.banner && this.state.edit_mode  &&
                                        <img src ={this.state.url+'/uploads/'+this.state.banner}  style={{width: "33px"}} class="bannerimage" alt=""></img>
                                     }
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label for="company_title">Expiry Date</label>
                              <ModernDatepicker
                                 className={'form-control'}
                                 date={ moment(this.state.expiry_date).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") ? moment(new Date()).format("YYYY-MM-DD") : this.state.expiry_date}
                             //    value={this.state.expiry_date}
                                 format={'YYYY-MM-DD'}
                                 showBorder
                                 minDate={new Date()}
                                 onChange={date =>
                              this.handelDate(date, 'expiry_date')}
                              placeholder={' Expiry Date'}
                              />
                           </div>
                        </div>
                        
                        </div>
                      <div class="sub_btn">
                        <button type="submit" class="save_btn page-primary-btn">Save</button>
                        <button type="button" class="cancel_btn page-primary-btn"  onClick={(e) => { this.cancelForm(e) }} >Cancel</button> 
                     </div>
                  </form>
               </div>
            </div>
         </div>
         </>
         }
         {!this.state.add_new && !this.state.edit_mode && <> 
         <div class="bg_white">
            <div class="job_details">
               <h4>Posted Jobs</h4>
               <div class="row">
                  <div class="col-md-10">
                     <div class="search-bar">
                        <form class="form-inline search-datesec">
                           <div class="search-form">
                              <div class="form-group">
                                 {/* <input type="text" onKeyUp={(e) => { this.searchRecords(e) }} name="search" id="myInputTextField" placeholder="Search Record" class="form-control" /> */}
                                 {/* <span>
                                 <i class="fa fa-search" aria-hidden="true"></i>
                                 </span> */}
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
                  <div class="col-md-2">
                     <div class="import-button">
                        <CSVLink data={this.state.excel_data} headers={this.state.excel_headers} id="typesTableExport" filename={"Job-list-" + moment().format('YYYY-MM-DD-h-mm-ss-a') + ".csv"} asyncOnClick={true} onClick={(event) => { this.getExcelData() }}>
                          Excel
                        </CSVLink>
                     </div>
                  </div>
               </div>
               <div class="tabel_head">
                  <span>Showing 1 Location</span>
                  <div class="filter_view"> <button type="button" class="save_btn page-primary-btn"  onClick={(e) => { this.AddNew(e) }}>Add New Job</button></div>
               </div>
               <table class="table table-bordered table-hover">
                  <thead class="">
                     <tr>
                        <th scope="col">NO</th>
                        <th scope="col">Department</th>
                        <th scope="col">Job Title</th>
                        <th scope="col">Job Type</th>
                        <th scope="col">Address</th>
                        <th scope="col">Remote Type</th>
                        <th scope="col" >Salary</th>
                        {/* <th scope="col" >Banner</th> */}
                        <th scope="col" >Status</th>
                        <th scope="col" >Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {this.state.allJob.length > 0 && <>
                     {this.state.allJob.map((list, index) => {
                     return <tr>
                         <td>{(this.state.pagination_data.per_page * (this.state.pagination_data.current_page - 1)) + index + 1}</td>
                        <td scope="row" >{list.department}</td>
                        <td>{list.title_name}</td>
                        <td>{list.job_type}</td>
                        <td>{list.address}</td>
                        <td>{list.remote_type}</td>
                        <td>{list.range_type === 'fixed' ? list.salary_to : list.salary_from + "-" + list.salary_to}</td>
                        {/* <td><img width="20px;" height="20px" src={this.state.url + '/uploads/' + list.banner}></img></td> */}
                        <td>{list.status == 'active' ? "Active" : "Inactive"}</td>
                        <td class=""><a href="javascript:void(0)" class="edit_sec" onClick={(e) => { this.editType(e, index, list._id) }} >Edit</a></td>
                     </tr>
                     })}
                     </>}
                  </tbody>
               </table>
               <Pagination data={this.state.pagination_data} getData={this.getAllJobs} />
            </div>
         </div>
         </>}
      </div>
   </section>
</div>

       </>

        );
    }
}
export default Jobs;
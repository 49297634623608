import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Axios from "axios";
import * as moment from "moment";
// import socketIOClient from "socket.io-client";
// const ENDPOINT = process.env.MIX_REACT_SOCKET_ENDPOINT;
// const socket = socketIOClient(ENDPOINT);

class Customerchats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.MIX_REACT_API_URL,
            users: [],
            selected_user: null,
            single_chat: [],
            current_user: null
        }
    }

    componentDidMount = () => {
        this.getUsers();
        var self = this;
        // socket.on("fox_2", data => {
        //     console.log("fox_2", data)
        // })
        // socket.on("new-message", data => {
        //     if (self.state.users.length > 0) {
        //         self.getUsers();
        //         if (self.state.selected_user && [data.data.sender.id, data.data.receiver.id].includes(self.state.selected_user.id)) {
        //             var newth = data.data;
        //             var wth = self.state.single_chat;
        //             wth.push(newth);
        //             console.log(wth)
        //             self.setState({ single_chat: wth });
        //             self.scrollTop();
        //         }
        //         if (data.data.receiver.id === self.state.current_user.id) {
        //             var beep_one = document.getElementById("beep_one");
        //             beep_one.play();
        //         }
        //     }
        // });
    }

    getChat = (e = null, user) => {
        if (e) {
            this.getUsers();
            e.preventDefault();
        }
        this.setState({ selected_user: user })
        Axios.post(`${this.state.url}/api/chat/single`, { user_id: user.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                single_chat: res.data.data.history
            })
            this.scrollTop();
        })
    }

    getUsers = () => {
        Axios.get(`${this.state.url}/api/chat/users`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                users: res.data.data.users,
                current_user: res.data.data.current_user,
            })
        })
    }
    scrollTop = () => {
        setTimeout(() => {
            var chElem = document.getElementById('message_div_' + this.state.single_chat.length);
            chElem.scrollIntoView();
        }, 300);
    }
    sendMessage = e => {
        var data = {
            message: this.state.message,
            user_id: this.state.selected_user.id
        }
        Axios.post(`${this.state.url}/api/chat/send/message`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({ message: "", })
            var x = document.getElementById("message");
            x.value = "";
            this.scrollTop();
        })
    }
    handleInput = e => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            this.sendMessage();
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }
    cancelChat = e => {
        var x = document.getElementById("message");
        x.value = "";
        this.setState({
            message: "",
            selected_user: null,
            single_chat: [],
        });
    }
    render() {
        return <>
            <NotificationContainer />
            <Navbar page="Admin Dashboard" />
            <Sidebar />
            <audio id="beep_one" controls preload="auto" style={{ display: "none" }}>
                <source src="../../../../sound/message.mp3" controls></source>
            </audio>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-9">
                                <h1 class="m-0">Customer Chats</h1>
                            </div>
                            <div class="col-sm-3">

                            </div>

                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4">
                            {/* <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Users</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <ul class="nav nav-pills flex-column">
                                        <li class="nav-item active">
                                            <a href="#" class="nav-link">
                                                <i class="fas fa-inbox"></i>
                                                Inbox
                                                <span class="badge bg-primary float-right">12</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div> */}
                            <div className="bg-dark">
                                <ul className="contacts-list">
                                    {this.state.users.map((chat, key) => {
                                        return <User chat={chat} current_user={this.state.current_user} key={key} getChat={this.getChat} />
                                    })
                                    }
                                </ul>

                            </div>
                        </div>
                        <div class="col-md-8">
                            {this.state.single_chat.length > 0 && <div className="card direct-chat direct-chat-primary">
                                <div className="card-header ui-sortable-handle" style={{ "cursor": "move" }}>
                                    <h3 className="card-title">{this.state.selected_user.name}</h3>
                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="remove" onClick={e => { this.cancelChat(e) }}>
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body" id="chat_box">

                                    <Directchat chat={this.state.single_chat} current_user={this.state.current_user} />




                                </div>

                                <div className="card-footer">
                                    {/* <form action="#" method="post"> */}
                                    <div className="input-group">
                                        <input type="text" name="message" id="message" placeholder="Type Message ..." className="form-control" onChange={e => { this.handleInput(e) }} onKeyPress={e => { this.handleInput(e) }} />
                                        <span className="input-group-append">
                                            <button type="button" className="btn btn-primary" onClick={e => { this.sendMessage(e) }}>Send</button>
                                        </span>
                                    </div>
                                    {/* </form> */}
                                </div>

                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        </>;
    }
}
export default Customerchats;
function User(props) {
    if (props.current_user.id === props.chat.sender.id) {
        return <><li>
            <a href="" onClick={e => { props.getChat(e, props.chat.receiver) }}>
                <img className="contacts-list-img" src={props.chat.receiver.image} alt="User Avatar" />
                <div className="contacts-list-info">
                    <span className="contacts-list-name">
                        {props.chat.receiver.name} &nbsp;&nbsp;{props.chat.count_messages > 0 && <span className="badge badge-sm badge-danger">{props.chat.count_messages}</span>}
                        <small className="contacts-list-date float-right">{moment(props.chat.created_at).format("YYYY-MM-DD h:mm:ss a")}</small>
                    </span>
                    <span className="contacts-list-msg">{props.chat.message}</span>
                </div>
            </a>
        </li></>;
    }
    else {
        return <><li>
            <a href="" onClick={e => { props.getChat(e, props.chat.sender) }}>
                <img className="contacts-list-img" src={props.chat.sender.image} alt="User Avatar" />
                <div className="contacts-list-info">
                    <span className="contacts-list-name">
                        {props.chat.sender.name}&nbsp;&nbsp;{props.chat.count_messages > 0 && <span className="badge badge-sm badge-danger">{props.chat.count_messages}</span>}
                        <small className="contacts-list-date float-right">{moment(props.chat.created_at).format("YYYY-MM-DD h:mm:ss a")}</small>
                    </span>
                    <span className="contacts-list-msg">{props.chat.message}</span>
                </div>
            </a>
        </li></>;
    }
}

function Directchat(props) {
    return <><div className="direct-chat-messages">

        {props.chat.map((msg, key) => {
            var id = "message_div_" + (key + 1);
            if (msg.sender.id !== props.current_user.id) {
                return <div className="direct-chat-msg" key={key} id={id}>
                    <div className="direct-chat-infos clearfix">
                        {/* <span className="direct-chat-name float-left">{msg.sender.name}</span> */}
                        <span className="direct-chat-timestamp float-right">{moment(msg.created_at).format("YYYY-MM-DD h:mm:ss a")}</span>
                    </div>
                    <img className="direct-chat-img" src={msg.sender.image} alt="message user image" />
                    <div className="direct-chat-text">
                        {msg.message}
                    </div>
                </div>
            }
            else {
                return <div className="direct-chat-msg right" key={key} id={id}>
                    <div className="direct-chat-infos clearfix">
                        {/* <span className="direct-chat-name float-right">{msg.sender.name}</span> */}
                        <span className="direct-chat-timestamp float-left">{moment(msg.created_at).format("YYYY-MM-DD h:mm:ss a")}</span>
                    </div>
                    <img className="direct-chat-img" src={msg.sender.image} alt="message user image" />
                    <div className="direct-chat-text">
                        {msg.message}
                    </div>
                </div>
            }
        })}
    </div >
    </>
}
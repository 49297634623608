import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import GoogleLogin from 'react-google-login';
import ReactTextareaAutocomplete from 'react-textarea-autocomplete'
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import * as moment from "moment";
import Sidebar from "../User/Sidebar";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Slider from "react-slick";

class Company extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         url: process.env.MIX_REACT_API_URL,
         show: false,
         allData: [],
         company_id: "",
         company: "",
         jobs: [],
         watchList: "",
         related_jobs: [],
         allResumes: [],
         allJobs: [],
         allCompany: [],
         image: "",
         name: "",
         email: "",
         phone: "",
         resume: "",
         imgUrl: "",
         resume_id: "",
         company_Logo: '',
         company_Address: '',
      }
      this.onFormSubmitJob = this.onFormSubmitJob.bind(this);
      this.onChangeJob = this.onChangeJob.bind(this);
      this.readmoreless = this.readmoreless.bind(this);
   }
   componentDidMount = (e) => {
      var company_id = window.location.href.split("/").pop()
      this.setState({
         "userDetail": JSON.parse(sessionStorage.getItem("userDetail")),
         company_id: window.location.href.split("/").pop()
      })

      setTimeout(() => {
         this.setState({
            designation: this.state.userDetail.designation ? this.state.userDetail.designation.name : "",
            show: true
         })
         console.log("sidebar", this.state.userDetail.image)
      }, 100);
      this.getSingleJOb(company_id);
      this.getAllJobs();
      this.getAllCompany();
   }

   getAllCompany = (page = 1) => {
      var dd = [];
      Axios.post(`${this.state.url}/api/get/all/company`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allCompany: res.data.data,
         })
         if (this.state.allCompany) {
            for (let k of this.state.allCompany) {
               dd.push({ "id": k.id, "name": k.company_name });
            }
            this.setState({
               CompanyName: dd
            })
         }
         console.log("CompanyName", this.state.CompanyName)
      });
   }


   getAllJobs = (page = 1) => {
      Axios.post(`${this.state.url}/api/get/all/job`, page, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allJobs: res.data.data,
         })
         console.log("homedata", this.state.allJobs)
      });
   }

   getSingleJOb = (id) => {
      Axios.post(`${this.state.url}/api/single/job`, { id: id }, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.setState({
            watchList: res.data.data.added_to_watchlist,
            company: res.data.data.company,
            jobs: res.data.data.job,
            related_jobs: res.data.data.related_jobs,
            company_Logo: res.data.data.company.logo,
            company_Address: res.data.data.related_jobs.address,
         })
         console.log("homedata", res.data.data)
      });
   }
   SingleJob = (e, id) => {
      window.location.href = "/user/job/" + id
   }

   readmoreless() {
      console.log(this.state.company_Address, 84);
   }

   AddWatchlist = (e, id) => {
      e.preventDefault()
      Axios.post(`${this.state.url}/api/watchlist/add`, { job_id: id }, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         NotificationManager.success("success", "Watchlist Added Successfully");
         this.getSingleJOb(this.state.company_id)
      });
   }

   RemoveWatchlist = (id) => {
      Axios.post(`${this.state.url}/api/watchlist/remove`, { job_id: id }, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         NotificationManager.success("success", "Watchlist Remove Successfully");
         this.getSingleJOb(this.state.company_id)
      });
   }

   getResume = () => {
      Axios.get(`${this.state.url}/api/get-resumes`, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         this.setState({
            allResumes: res.data.resume
         })
         console.log("resumes", this.state.allResumes)
      });
   }
   handleInput(e) {

      this.setState({ [e.target.name]: e.target.value })
   }

   ApplyJob = (e, id) => {
      e.preventDefault()
      var aa = []
      aa.push(id)
      this.getResume()
      this.setState({
         apply_job_id: aa,
         show_modal_job: true
      })


   }
   showModalJob = e => {
      this.setState({
         show_modal_job: false,
         topicStep: 1
      })
   }

   RemoveImage = e => {
      this.setState({
         image: "",
         resume: "",
         imgUrl: "",
         resume_id: ""
      })
   }

   NewResume = e => {
      this.setState({
         resume_id: ""
      })
   }

   onFormSubmitJob = (e) => {
      e.preventDefault();
      if (this.state.name === "") {
         NotificationManager.error("error", "Please enter name.");
         return false;
      }
      if (this.state.email === "") {
         NotificationManager.error("error", "Please enter email.");
         return false;
      }
      if (this.state.phone === "") {
         NotificationManager.error("error", "Please enter phone.");
         return false;
      }
      if (this.state.resume_id) {
         this.applyNewJob(this.state.resume_id, this.state.name, this.state.email, this.state.phone);
      } else {
         if (this.state.resume === "") {
            NotificationManager.error("error", "Please choose image.");
            return false;
         }
         const formData = new FormData();
         formData.append('name', this.state.name);
         formData.append('resume', this.state.resume);
         const config = {
            headers: {
               'content-type': 'multipart/form-data',
               "Authorization": "Bearer " + sessionStorage.getItem('_access')
            }
         };
         Axios.post(`${this.state.url}/api/upload-resume`, formData, config, {
            // headers: {
            //    "Authorization": "Bearer "+sessionStorage.getItem('_access')
            //  }
         }).then(res => {
            this.applyNewJob(res.data.resume.id, this.state.name, this.state.email, this.state.phone);
         });
      }

   }


   applyNewJob = (resume_id, name, email, phone) => {
      var data = {
         resume_id: resume_id,
         name: name,
         email: email,
         phone: phone,
         job_id: this.state.apply_job_id
      }
      // console.log(data); return false;
      Axios.post(`${this.state.url}/api/apply-job`, data, {
         headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         console.log(res)
         NotificationManager.success("success", "Job Applied Successfully");
         $('.type_def_chk').prop("checked", false);
         this.setState({
            name: "",
            resume: "",
            imgUrl: "",
            image: "",
            apply_job_id: "",
            resume_id: "",
            aa: "",
            show_modal_job: false
         })
      });
   }

   onChangeJob(e) {
      const filesFormats = [".doc", ".docx", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];
      if (e.target.files) {
         let file = e.target.files[0];
         console.log(file.type)
         const isRightFormat = filesFormats.includes(file.type);
         if (!isRightFormat) {
            NotificationManager.error("error", "Please enter valid document only pdf or doc.");
            return;
         }
         var reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onloadend = function (e) {
            this.setState({
               imgUrl: file.type == "application/pdf" ? "/admin/dist/images/pdf.jpeg" : "/admin/dist/images/doc.png",  //[reader.result]
               //   image : [reader.result]
               resume: file
            })
         }.bind(this);
         setTimeout(() => {
            console.log(this.state.image[0])
         }, 200);
      }
   }
   render() {
      var settings = {
         className: "slider coupon",
         speed: 500,
         slidesToShow: 4,
         slidesToScroll: 1,
         autoplay: false,
         autoplaySpeed: 2000,
         dots: false,
         variableWidth: true,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 992,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 767,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 576,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 320,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }
         ]
      };
      return (
         <>
            <NotificationContainer />
            <Header />
            <Navbar />
            <section class="sidebar_menu">
               <div id="page-content-wrapper" class="top-job-page">
                  <div class="container-fluid">
                     <div class="col-md-12 pd0">
                        <div class="row">
                           <div class="col-md-9">
                              <div class="jobss-details">
                                 <div class="row">
                                    <div class="col-md-6">
                                       <div class="job-profile-img">
                                          <img src={this.state.url + '/uploads/' + this.state.jobs.banner} alt=""></img>
                                       </div>
                                       <div class="job-details-dsp">
                                          <h4>{this.state.jobs.job_title}</h4>
                                          <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt="" /></span>{this.state.jobs.company_name}</p>
                                          <p><b>{this.state.jobs.address}</b></p>
                                       </div>
                                    </div>
                                    <div class="col-md-6">
                                       <div class="post-date">
                                          <p>Job Posted Time: {moment.utc(this.state.jobs.created_at).local().startOf('seconds').fromNow()}</p>
                                          {this.state.jobs.range_type === 'fixed' && <> <span>${this.state.jobs.salary_to}/{this.state.jobs.salary_type}</span> </>}
                                          {this.state.jobs.range_type !== 'fixed' && <> <span>From : {this.state.jobs.salary_from} - To: {this.state.salary_to}/{this.state.jobs.salary_type}</span> </>}
                                          <span><a href="javascript:void(0)" class="apply-job" onClick={(e) => { this.ApplyJob(e, this.state.jobs.id) }}>Apply Job</a></span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-8">
                                       <div class="post-fiels-sec">
                                          <a href="javascript:void(0)">{this.state.jobs.job_title}</a>
                                          <a href="javascript:void(0)">{this.state.jobs.job_type}/{this.state.jobs.remote_type}</a>
                                          <a href="javascript:void(0)">Junior</a>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="save-list">
                                          {!this.state.watchList && <> <a href="javascript:void(0)" onClick={(e) => { this.AddWatchlist(e, this.state.jobs.id) }}><span>Add To Watchlist <img src="/admin/dist/images/save.png" alt="" /></span></a>
                                          </>}
                                          {this.state.watchList && <> <a href="javascript:void(0)" onClick={(e) => { this.RemoveWatchlist(this.state.jobs.id) }}><span>Remove To Watchlist <img src="/admin/dist/images/save.png" alt="" /></span></a>
                                          </>}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="tabs_details">
                                 <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                       <a class="nav-link active" data-toggle="tab" href="#home">Overview</a>
                                    </li>
                                    <li class="nav-item">
                                       <a class="nav-link" data-toggle="tab" href="#menu1">Company</a>
                                    </li>
                                    <li class="nav-item">
                                       <a class="nav-link" data-toggle="tab" href="#job_responsibility">Job Responsibility</a>
                                    </li>
                                    <li class="nav-item">
                                       <a class="nav-link" data-toggle="tab" href="#requirement">Requirements</a>
                                    </li>
                                    <li class="nav-item">
                                       <a class="nav-link" data-toggle="tab" href="#review">Review</a>
                                    </li>
                                 </ul>
                                 <div class="tab-content job-tabs">
                                    <div id="home" class="tab-pane active"><br></br>
                                       <p dangerouslySetInnerHTML={{ __html: this.state.jobs.description }}></p>
                                       <div className="row">
                                          <div className="col-6 row">
                                             <h3 className="col-4"> Salary Range </h3>
                                             <p className="col-8">{this.state.jobs.salary_from}{'  to  '}{this.state.jobs.salary_to}{' / '} {this.state.jobs.salary_type}</p>
                                          </div>
                                          <div className="col-6 row">
                                             <div className="col-4">
                                                <h3>Job Title</h3>
                                             </div>
                                             <div className="col-8">
                                                <p>{this.state.jobs.job_title}</p>
                                             </div>
                                          </div>
                                          <div className="col-6 row">
                                             <div className="col-4">
                                                <h3>Job Type</h3>
                                             </div>
                                             <div className="col-8">
                                                <p>{this.state.jobs.job_type}</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div id="menu1" class="tab-pane">
                                       <br></br>
                                       <p className="overview_paragraph" dangerouslySetInnerHTML={{ __html: this.state.company.description }} ></p>
                                       <div className='row'>
                                          <div className="col-md-12 col-lg-5">
                                             <div className="row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Company</h3>
                                                <p className="col-md-12 col-lg-8" >{this.state.company.company_name}</p>
                                             </div>
                                             <div className="row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Website</h3>
                                                <p className="col-md-12 col-lg-8">{this.state.company.website}</p>
                                             </div>
                                             <div className="row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Size</h3>
                                                <p className="col-md-12 col-lg-8">{this.state.company.size}</p>
                                             </div>
                                             <div className="row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Founded</h3>
                                                <p className="col-md-12 col-lg-8">{this.state.company.founded}</p>
                                             </div>
                                          </div>
                                          <div className="col-md-12 col-lg-7">
                                             <div className=" row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Headquarters</h3>
                                                <p className="col-md-12 col-lg-8">{this.state.company.headqarter}</p>
                                             </div>
                                             <div className="row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Email Address</h3>
                                                <p className="col-md-12 col-lg-8">{this.state.company.company_email}</p>
                                             </div>
                                             <div className="row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Status</h3>
                                                <p className="col-md-12 col-lg-8">{this.state.company.status}</p>
                                             </div>
                                             <div className="row overview_section">
                                                <h3 className="col-md-12 col-lg-4">Contact No.</h3>
                                                <p className="col-md-12 col-lg-8">{this.state.company.contact_number}</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div id="job_responsibility" className="tab-pane">
                                       <br />
                                       <div>
                                          <h3>Job Responsibility</h3>
                                          <p dangerouslySetInnerHTML={{ __html: this.state.jobs.description }}></p>
                                       </div>
                                    </div>
                                    <div id="requirement" className="tab-pane">
                                       <br />
                                       <div>
                                          <h3>Job Requirement</h3>
                                          <p dangerouslySetInnerHTML={{ __html: this.state.jobs.requirement }}></p>
                                       </div>
                                    </div>
                                    <div id="review" class="tab-pane employee_review">
                                       <br />
                                       <h3>Review</h3>
                                    </div>
                                 </div>
                              </div>
                              <div class="suggestion-sec">
                                 <div class="suggested-sec">
                                    <h3>Jobs</h3>
                                    <Link to={`/user/jobs`} activeClassName="active">
                                       <a>View All</a>
                                    </Link>
                                 </div>
                                 <div class="suggestedjob-slider">
                                    <Slider {...settings}>
                                       {this.state.related_jobs.map((slide, index) => {
                                          return (
                                             <div class="card suggested">
                                                <div class="card-body suggested_body">
                                                   <div class="card-content suggested">
                                                      <div class="card-text suggested_text">
                                                         <div onClick={(e) => { this.SingleJob(e, slide.id) }} activeClassName="active">
                                                            <img src={this.state.url + '/uploads/' + slide.banner} alt="" />
                                                            <h5>{slide.job_title}</h5>
                                                            <p><span class="sales-img"><img src={this.state.url + '/uploads/' + this.state.company_Logo} alt="" /></span>{slide.company_name}</p>
                                                            <p id="suggested_tab"> {slide.address}
                                                            </p>
                                                            <p> ({slide.remote_type}) </p>
                                                            <p>{moment.utc(this.state.jobs.created_at).local().startOf('seconds').fromNow()}</p>
                                                         </div>
                                                         <a href="javascript:void(0)" onClick={(e) => { this.ApplyJob(e, this.state.jobs.id) }} class="apply_btn">Apply</a>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       })}
                                    </Slider>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-3 company">
                              <div class="top-job">
                                 <h3>Related Jobs </h3>
                                 <Link to={`/user/jobs`} activeClassName="active">View All</Link>
                                 {this.state.allJobs && this.state.allJobs.length > 0 && <>
                                    {this.state.allJobs.map((cr, index) => {
                                       if (index <= 4) {
                                          return <div class="job-details-block">
                                             <div class="row">
                                                <div class="col-md-8 pr-0">
                                                   <Link to={`/user/job/` + cr.id} activeClassName="active ">
                                                      <div className="related_job">
                                                         <div class="job-profile">
                                                            {cr.banner && <> <img src={this.state.url + '/uploads/' + cr.banner} alt="" /> </>}
                                                            {cr.banner == null && <> <img src="/admin/dist/images/ui.png" alt="" /> </>}
                                                         </div>
                                                         <div class="job-details">
                                                            <h4>{cr.department}</h4>
                                                            <p><span class="sales-img"><img src="/admin/dist/images/salesforce.png" alt="" /></span>{cr.company_name}</p>
                                                            <p id="suggested_tab">{cr.address}</p>
                                                            <p>{moment.utc(cr.created_at).local().startOf('seconds').fromNow()}</p>
                                                         </div>
                                                      </div>
                                                   </Link>
                                                </div>
                                                <div class="col-md-4">
                                                   <div class="apply_btn"><a onClick={(e) => { this.ApplyJob(e, cr.id) }} href="javascript:void(0)">Apply</a></div>
                                                </div>
                                             </div>
                                          </div>
                                       }
                                    })}
                                 </>}
                              </div>
                              <div class="top-companies" id="sticky">
                                 <h3>Top Companies</h3>
                                 <Link to={`/user/companies`} activeClassName="active">View All</Link>
                                 {this.state.allCompany && this.state.allCompany.length > 0 && <>
                                    {this.state.allCompany.map((cr, index) => {
                                       if (index <= 4) {
                                          return <Link to={`/user/company/` + cr.id} activeClassName="active">
                                             <div class="companies-details-block">
                                                <div class="company-profile">
                                                   {cr.logo && <> <img src={this.state.url + '/uploads/' + cr.logo} alt="" /> </>}
                                                   {cr.logo == null && <> <img src="/admin/dist/images/google.png" alt="" /> </>}
                                                </div>
                                                <div class="job-details">
                                                   <h4>{cr.company_name}</h4>
                                                   <p><span class="sales-img"><img src="/admin/dist/images/location.png" alt="" /></span>{cr.headqarter}</p>
                                                   <p><span class="sales-img"><img src="/admin/dist/images/bag.png" alt="" /></span>{cr.jobs.length} Job Open</p>
                                                </div>
                                             </div>
                                          </Link>
                                       }
                                    })}
                                 </>}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <Modal
               show={this.state.show_modal_job}
               onHide={() => this.showModalJob(false)}
               dialogClassName="modal-90w"
               //  size="lg"
               aria-labelledby="example-custom-modal-styling-title"
            >
               <Modal.Header closeButton>
                  <h4 class="title-headingsec">Apply Job</h4>
               </Modal.Header>
               <Modal.Body>
                  <h2>Upload resume</h2>
                  <form onSubmit={this.onFormSubmitJob}>
                     <div class="form-group">
                        <input type="text" class="form-control" id="name" onChange={e => { this.handleInput(e) }} name="name" placeholder="Enter Name" />
                     </div>
                     <div class="form-group">
                        <input type="text" class="form-control" id="email" onChange={e => { this.handleInput(e) }} name="email" placeholder="Enter email" />
                     </div>
                     <div class="form-group">
                        <input type="text" class="form-control" id="phone" onChange={e => { this.handleInput(e) }} name="phone" placeholder="phone" />
                     </div>
                     {this.state.resume_id && <>
                        <a href="javascript:void()" onClick={(e) => { this.NewResume(e) }}>Upload New Resume</a>
                     </>}
                     {!this.state.resume_id && <>
                        <div class="form-group">
                           {this.state.imgUrl && <>
                              <img src={this.state.imgUrl} />
                              <a href="javascript:void()" onClick={(e) => { this.RemoveImage(e) }} class="colse-btn"><i class="fa fa-times" aria-hidden="true"></i></a>
                           </>}
                           {this.state.imgUrl == "" && <> <div class="uploadresume">
                              <input type="file" id="myfile" name="image" onChange={this.onChangeJob} />
                              <img src="/admin/dist/images/uploadfile.png" />
                           </div>
                           </>}
                           <p>Please select a CV/Resume. CV Must be.PDF
                              or .DOC file.</p>
                        </div>
                     </>}
                     <div class="upload-btn">
                        <button type="submit" class="btn btn-primary">Submit</button>
                     </div>
                  </form>
                  <div class="my-resume-sec">
                     <h2>My Resume</h2>
                     <div class="resume_maine">
                        {this.state.allResumes && this.state.allResumes.length > 0 && <>
                           {this.state.allResumes.map((cr, index) => {
                              return <div class="resume-innersec">
                                 <div class="row">
                                    <input type="radio" disabled={this.state.imgUrl} name="resume_id" onChange={e => { this.handleInput(e) }} value={cr.id} />
                                    <div class="col-md-10">
                                       <div class="tabs-data">
                                          <div class="left-side-sec">
                                             <img src="/admin/dist/images/resumes.png" />
                                          </div>
                                          <div class="right-side-sec toppadd">
                                             <h4>{cr.name}</h4>
                                             <p>{moment(cr.created_at).format('DD-MMM-YYYY')}</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           })}
                        </>}
                     </div>
                  </div>
               </Modal.Body>
               <Modal.Footer>
               </Modal.Footer>
            </Modal>
         </>
      )
   }
}

export default Company;
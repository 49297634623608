import React from "react";

class Pagination extends React.Component {

    render() {
        return <tfoot>
            <tr>
                <td colSpan="100%" align="center">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            {
                                this.props.data.links.map((link, key) => {
                                    if (key === 0) {
                                        if (!link.url) {
                                            return <li class="page-item"><a class="page-link" href="javascript:void(0)">Previous</a></li>;
                                        }
                                        else {
                                            return <li class="page-item"><a class="page-link" href="javascript:void(0)" onClick={(e) => { this.props.getData(this.props.data.current_page - 1) }}>Previous</a></li>
                                        }
                                    }
                                    else if (key !== (this.props.data.links.length - 1)) {
                                        if (this.props.data.current_page === link.label) {
                                            return <li class={"page-item active"}><a class="page-link" href="javascript:void(0)" >{link.label}</a></li>
                                        }
                                        else {
                                            return <li class={"page-item"}><a class="page-link" href="javascript:void(0)" onClick={(e) => { this.props.getData(link.label) }}>{link.label}</a></li>
                                        }
                                    } else {
                                        if (!link.url) {
                                            return <li class="page-item"><a class="page-link" href="javascript:void(0)">Next</a></li>;
                                        }
                                        else {
                                            return <li class="page-item"><a class="page-link" href="javascript:void(0)" onClick={(e) => { this.props.getData(this.props.data.current_page + 1) }}>Next</a></li>
                                        }
                                    }
                                })
                            }
                        </ul>
                    </nav>
                </td>
            </tr>
        </tfoot>;
    }
}
export default Pagination;
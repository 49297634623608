import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
  } from "reactstrap";
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import 'react-notifications/lib/notifications.css';

import Axios from "axios";
import NotificationAlert from "react-notification-alert";
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading:false,
            url: process.env.MIX_REACT_API_URL,
            first_name:"",
            last_name:"",
            company_name:"",
            company_title:"",
            company_email:"",
            contact_number:"",
       
        }
    }
    
    // notificationAlert = React.createRef();
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    registerSubmit(e) {
      this.setState({
          loading:true
      })
      if (this.state.first_name === "") {
        NotificationManager.error("error", "Please enter first name.");
        return false;
      }
     if (this.state.last_name === "") {
          NotificationManager.error("error", "Please enter last name.");
          return false;
      }
      if (this.state.company_name === "") {
        NotificationManager.error("error", "Please enter company name.");
        return false;
      }
      if (this.state.company_title === "") {
      NotificationManager.error("error", "Please enter company title.");
      return false;
      }
      if (this.state.company_email === "") {
      NotificationManager.error("error", "Please enter company email.");
      return false;
      }
      if (this.state.contact_number === "") {
        NotificationManager.error("error", "Please enter mobile number.");
        return false;
      }
      if (this.state.password === "") {
        NotificationManager.error("error", "Please enter password.");
        return false;
      }
        var data = {
           first_name:this.state.first_name,
           last_name:this.state.last_name,
           company_name:this.state.company_name,
           company_title:this.state.company_title,
           company_email:this.state.company_email,
           contact_number:this.state.contact_number,
           password:this.state.password,
         
        }
        console.log(this.state.url);
        Axios.post(`${this.state.url}/api/company-register`, data).then(res => {
            console.log(res.data)
           // alert(res.data);
           this.setState({
            loading:false
        })
            if(res.data.status == true){
              this.setState({
                first_name:"",
                last_name:"",
                company_name:"",
                company_title:"",
                company_email:"",
                contact_number:"",
                password:"",
            })
                NotificationManager.success("success", res.data.message);
         //       window.location.href = '/employer/dashboard';
            }else{
                NotificationManager.error('error', res.data.message);
            }
        })
    }
   
    render() {
        return <>
            <NotificationContainer/>
            <header class="page_header" >
  <div class="mhead_sec">
    <div class="containr">
      <div class="head_menu">
        <div class="logo_img"> <a class="navbar-logo" href="/employer/home" ><img src="../sellers/img/kaziom-logo.png" alt="kaziom logo" /></a> </div>
      </div>
    </div>
  </div>
</header>
<section class="signin-up_page" style={{height: "110vh"}} >
  <div class="signin_form form_area" >
    <div class="form_feilds">
      <h4>Sign Up to Kaziom</h4>
      <form>
      <div class="form-group">
          <label for="first_name">First Name</label>
          <input type="text" class="form-control" name="first_name" id="first_name"  placeholder="Enter your first name." required onChange={e => { this.handleInput(e) }}  />
        </div>
        <div class="form-group">
          <label for="last_name">Last Name</label>
          <input type="text" class="form-control" name="last_name" id="last_name"  placeholder="Enter your last name." required  onChange={e => { this.handleInput(e) }} />
        </div>
        <div class="form-group">
          <label for="name-f">Company Name</label>
          <input type="text" class="form-control" name="company_name"  placeholder="Enter your first name." required onChange={e => { this.handleInput(e) }} />
        </div>
        <div class="form-group">
          <label for="name-l">Company Title</label>
          <input type="text" class="form-control" name="company_title" id="company_title" placeholder="Enter your last name." required onChange={e => { this.handleInput(e) }} />
        </div>
        <div class="form-group">
          <label for="name-l">Company E-mail</label>
          <input type="text" class="form-control" name="company_email" id="company_email" placeholder="Enter your company email." required onChange={e => { this.handleInput(e) }} />
        </div>
        <div class="form-group">
			     <label for="name-l">Your Mobile Number</label>
           <input type="tel" name="contact_number" id="phone" required="required" placeholder="Your Number" onChange={e => { this.handleInput(e) }} />
          <div class="invalid-feedback"> You must enter a valid mobile number </div>
        </div>
        <div class="form-group">
			     <label for="name-l">Password</label>
           <input type="password" name="password" id="password" required="required" placeholder="Enter password" onChange={e => { this.handleInput(e) }} />
          <div class="invalid-feedback"> You must enter a valid mobile number </div>
        </div>
        {/* <div class="form-group">
          <label for="Designnation">Your Designation</label>
          <select class="form-select" aria-label="Default select example">
            <option selected value="Please Select">Please Select</option>
            <option value="CEO">CEO</option>
            <option value="Director">Director</option>
            <option value="Founder">Algeria</option>
            <option value="HR Manager">HR Manager</option>
            <option value="Marketing Manager">Marketing Manager</option>
            <option value="Marketing Head">Marketing Head</option>
            <option value="Owner">Owner</option>
            <option value="Antarctica">Other</option>
          </select>	
        </div> */}
		  <button type="button" onClick={e => { this.registerSubmit(e) }}  disabled={this.state.loading}  >
      {this.state.loading  && <>
            <i class="fa fa-refresh fa-spin"></i>
         </>}
         {!this.state.loading  && <>
          Register
         </>}
        
        </button>
      </form>
      <a href="/employer/login">Login</a>
    </div>
  </div>
</section>
        </>;
    }
}

export default Register;
import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
class ArabicHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "AAAA",
            language:sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "arabic"
        }
    }

    componentDidMount() {
      //   this.test();
    }
    changeLanguage = (e, language) => {
       this.setState({
          "language":language
       })
        sessionStorage.setItem("language",language)
       window.location.reload()
    }

    render() {
        return <>
        
      <header class="header-section arabic-header">
         <nav class="navbar navbar-default navbar-fixed-top" id="myScrollspy">
            <div class="container">
               <div class="col-md-12">
               <div class="row">
                 
                  <div class="col-md-10">
                     <div id="navbar" class="navbar-collapse collapse">
                        <ul class="nav navbar-nav navbar-right nav-left">
                        
                         
                         
                           {/* <li><a href="#testimonial_sec">Testimonial</a></li> */}  
                           {/* <li><a href="#faq_sec">FAQ</a></li> */}
                           {/* <li><a href="#blog_sec">Blog</a></li> */}
                           <li>
                              <div class="nav-wrapper">
                                 <div class="sl-nav">
                                    <ul>
                                       <li>
                                          {this.state.language === 'english' && <> <img src="admin/dist/img/flag1.png" class="selectimage" alt=""/> <i class="fa fa-angle-down" aria-hidden="true"></i>
                                          <div class="triangle"></div> </>}
                                          {this.state.language === 'arabic' && <> <img src="admin/dist/img/flag2.png" class="selectimage" alt=""/> <i class="fa fa-angle-down" aria-hidden="true"></i>
                                          <div class="triangle"></div> </>}
                                          <ul>
                                             <li onClick={(e) => { this.changeLanguage(e,'english') }}><img src="admin/dist/img/flag1.png" class="flag-icons" alt=""/><span class="active"> English</span></li>
                                             <li onClick={(e) => { this.changeLanguage(e,'arabic') }}><img src="admin/dist/img/flag2.png" class="flag-icons" alt=""/><span> عربي</span></li>
                                          </ul>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </li>
                           <li><a href="#contact-us">اتصل بنا</a></li>
                           <li><a href="https://kaziom.com/blog/">مدونة او مذكرة</a></li>
                           <li><a href="#features_sec">سمات</a></li>
                           <li><a href="#about_us">معلومات عنا</a></li>
                           <li><a href="#home">الصفحة الرئيسية</a></li>
                       
                        </ul>
                     </div>
                  </div>
                  <div class="col-md-2">
                     <div class="navbar-header right-logo-side">
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        </button>
                        <a class="navbar-brand" href="javascript:void(0)"><img src="admin/dist/img/logo-arabic.png" class="normal-logo arabic-logo" alt=""/></a>
                        <a class="navbar-brand" href="javascript:void(0)"><img src="admin/dist/img/small-logo.png" class="small-logo arabic-smalllog" alt=""/></a>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </nav>
       
      </header>
      
        </>;
    }
}

export default ArabicHeader;

import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
  } from "reactstrap";
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import 'react-notifications/lib/notifications.css';

import Axios from "axios";
import NotificationAlert from "react-notification-alert";
const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get('token');

console.log('token', token);
class Restpassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirm_password: "",
        
            url: process.env.MIX_REACT_API_URL,
        }
    }
    
    // notificationAlert = React.createRef();
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    loginSubmit(e) {
      if (this.state.password === "") {
        NotificationManager.error("error", "Please Enter  Password.");
        return false;
    }
   
    if (this.state.password !== this.state.confirm_password) {
        NotificationManager.error("error", "Please  Password And Confirm password Same.");
        return false;
    }

        var data = {
          token:token,
          password:this.state.password,
          confirm_password:this.state.confirm_password,
           
        }
        console.log(this.state.url);
        Axios.post(`${this.state.url}/api/reset-password`, data).then(res => {
            console.log(res.data)
           // alert(res.data); 
            if(res.data.status == true){
              this.setState({
                  password:"",
                  confirm_password:"",
              })
                NotificationManager.success("success", res.data.message);
            }else{
                NotificationManager.error('error', res.data.message);
            }
        })
    }
   
    render() {
        return <>
            <NotificationContainer/>
            <header class="page_header">
  <div class="mhead_sec">
    <div class="containr">
      <div class="head_menu">
        <div class="logo_img"> <a class="navbar-logo" href="/employer/home" ><img src="../sellers/img/kaziom-logo.png" alt="kaziom logo" /></a> </div>
      </div>
    </div>
  </div>
</header>
<section class="signin-up_page">
  <div class="signin_form form_area">
	  <div class="form_feilds">
    <h4>Reset Password</h4>
    <form>
       <label>Password</label>
      <input name="password" placeholder="Enter password" type="password" onChange={e => { this.handleInput(e) }} /> 
  
      <label>Confirm Password</label>
      <input name="confirm_password" placeholder="Enter confirm password" type="password" onChange={e => { this.handleInput(e) }} /> 
  
        
       <button  type="button" onClick={e => { this.loginSubmit(e) }} >Submit</button>
    </form>
    <p> <a href="/employer/login">Login</a></p>
		  </div>
  </div>
</section>
        </>;
    }
}

export default Restpassword;
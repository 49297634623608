import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import dateFormat from 'dateformat';
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import CKEditor from "react-ckeditor-component";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import ModernDatepicker from 'react-modern-datepicker';
import Jobform from "../Common/Jobform";
import * as moment from "moment";
import Pagination from "../Common/Pagination";
import { CSVLink } from "react-csv";
// const $ = window.jQuery;
class Applyjoblist extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            loading:false,
            interview_date: "",
            interview_time: "",
            address: "",
            interviewer_name: "",
            about:"",
            url: process.env.MIX_REACT_API_URL,
            edit_mode: false,
            show_fixed: false,
            allIndustry: [],
            filterData: [],
            allJob: [],
            select_details:null,
            viewmode:false,
            pagination_data: {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": null,
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "next_page_url": null,
                "path": "",
                "per_page": 0,
                "prev_page_url": null,
                "to": 0,
                "total": 0
            },
            excel_data: [],
            excel_headers: [
                { label: "Company", key: "company_name" },
                { label: "Department", key: "department" },
                { label: "Job Title", key: "title_name" },
                { label: "Job Type", key: "job_type" },
                { label: "Address", key: "address" },
                { label: "Salary", key: "salary" },
                { label: "Status", key: "status" }
            ]
        }
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        this.onChange2 = this.onChange2.bind(this);
        this.onChange3 = this.onChange3.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
      
        this.getAllJobs()
    }
    onSelect = (e) => {
        console.log("address", e);
        setTimeout(() => {
            var lastEl = e.value.terms.slice(-2)[0];
            this.setState({
                address: e.value.description,
                city: lastEl.value
            })
            console.log("asdfsdf", this.state.city)
            this.getLatLng(this.state.address)
        }, 100);

    }
    getExcelData = () => {
        var param = new URLSearchParams(this.props.location.search).get("status");
        Axios.get(`${this.state.url}/api/get/ExcelJob`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            
            if (res.data.data.length > 0) {
               
                var x = [];
                res.data.data.forEach(val => {
                    
                   var c = {
                    company_name: val.company_name,
                    department: val.department,
                    title_name: val.title_name,
                    job_type: val.job_type,
                    address: val.address,
                    status:val.status
                   }
                   c.created_at = moment(val.created_at).format("YYYY-MM-DD");
                   c.salary = val.range_type === 'fixed' ? val.salary_to : val.salary_from + "-" + val.salary_to
                 
                   x.push(c);
                })
                
                this.setState({
                   excel_data: x
                })
                // setTimeout(() => {
                    console.log("res.data.data.data",res.data.data)
                // }, 500);
                
             }
            
        });
    }

    getLatLng = () => {
        //  setTimeout(() => {
        geocodeByAddress(this.state.address)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => this.setState({
                latitude: lat,
                longitude: lng
            }))
            .catch(error => console.error('Error', error));
        //  }, 200);
        setTimeout(() => {
            console.log(this.state.longitude)
        }, 200);



    }
    handleChange = address => {
        console.log("Adsdf")
        this.setState({ address });
    };
    handleInput(e) {
        console.log(e.target.name)
   
        if (e.target.name === 'status') {
            if (e.target.value === 'Accepted') {
                this.setState({
                    status: "Accepted",
                    
                })
            } else if(e.target.value === 'Rejected') {
                this.setState({
                    status: "Rejected",
                })
            }
            console.log(this.state.show_fixed)
        }else{
            this.setState({ [e.target.name]: e.target.value })

        }  
    }
    
    update_application_status = (e) => {
        e.preventDefault();
        console.log(this.state.address)
        // if (this.state.company_id === "") {
        //     NotificationManager.error("error", "Please select company.");
        //     return false;
        // }
        if (this.state.status === "") {
            NotificationManager.error("error", "Please select status.");
            return false;
        }
     
     
        const formData = new FormData();
        formData.append('status', this.state.status);
        formData.append('id', this.state.select_details.id);
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                 'Authorization': "Bearer " + sessionStorage.getItem('_access')
            }
        };
       
            Axios.post(`${this.state.url}/api/update-application-status`, formData, config, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status == true) {
                    this.setState({
                        select_details:res.data.data
                    })
                    NotificationManager.success("success", "Status Updated Successfully.");
                    this.getAllJobs()
                } else {
                    NotificationManager.error("error", res.data.message);
                }
            })
      
          
        
    }
    onFormSubmit = (e) => {
        e.preventDefault();
        console.log(this.state.address)
      
        this.setState({
            loading:true
        })
        if (this.state.interviewer_name === "") {
            NotificationManager.error("error", "Please enter interviewer name.");
            return false;
        }
        if (this.state.interview_date === "") {
            NotificationManager.error("error", "Please enter interview date.");
            return false;
        }
        if (this.state.interview_time === "") {
            NotificationManager.error("error", "Please enter interview time.");
            return false;
        }
        if (this.state.address === "") {
            NotificationManager.error("error", "Please enter address.");
            return false;
        }
      
      

        const formData = new FormData();
        formData.append('interviewer_name', this.state.interviewer_name);
        formData.append('interview_date', this.state.interview_date);
        formData.append('interview_time', this.state.interview_time);
        formData.append('address', this.state.address);
        formData.append('about', this.state.about);
        formData.append('apply_id', this.state.select_details.id);
        formData.append('job_id', this.state.select_details.job_id);
        formData.append('resume_id', this.state.select_details.resume_id);
        formData.append('user_id', this.state.select_details.user_id);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                 'Authorization': "Bearer " + sessionStorage.getItem('_access')
            }
        };
 
            Axios.post(`${this.state.url}/api/set-interview-schedule`, formData, config, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('_access')
                }
            }).then(res => {
                console.log(res.data)
                this.setState({
                    loading:false
                })
                if (res.data.status == true) {
                    this.setState({
                        add_new: false
                    })
                    NotificationManager.success("success", "Interview  schedule  added successfully!");
                    this.getAllJobs()
                } else {
                    NotificationManager.error("error",res.data.message);
                }
            })
  
          
        
    }

   
  

    getAllJobs = (page=1) => {
         // alert(sessionStorage.getItem('_access'));
        Axios.get(`${this.state.url}/api/apply-job-list?page=${page}&keyword=${this.state.keyword}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {

            this.setState({
                allJob: res.data.data.data,
                pagination_data: res.data.data
            })
            this.getExcelData()
                console.log("this.state.allIndustry", this.state.pagination_data);
        });
    }

    searchRecords = e => {
        var val = e.target.value;
        console.log(val, e.keyCode)
        if (val.length > 2 || (e.keyCode === 8 && val.length === 0) || e.keyCode === 13) {
           this.setState({
              keyword: val,
              page: 1
           })
           setTimeout(() => {
              console.log(this.state.keyword)
              this.getAllJobs();
           }, 100);
        }
     }

   

    view_details = (e = null, val) => {

        if (val.status === 'Accepted') {
            this.setState({
                status: "Accepted",
                
            })
        } else if(val.status === 'Rejected') {
            this.setState({
                status: "Rejected",
            })
        }
        this.setState({
            select_details: val, 
            viewmode:true 
        })
         
    }
    view_schedule = (e = null, val) => {

         if(val.interview_schedule){
            this.setState({
                select_details: val, 
                edit_mode:true,
                interview_date:val.interview_schedule.interview_date,
                interview_time:val.interview_schedule.interview_time,
                address:val.interview_schedule.address,
                interviewer_name:val.interview_schedule.interviewer_name,
                about:val.interview_schedule.about
            })
         }else{
            this.setState({
                select_details: val, 
                edit_mode:true,
                interview_date:"",
                interview_time:"",
                address:"",
                interviewer_name:"",
                about:""
            })
         }
       
         
    }
    
    cancel_view = (e = null) => {
        this.setState({
            select_details: null, 
            viewmode:false 
        })
         
    }
    cancel_sedual = (e = null) => {
        this.setState({
            select_details: null, 
            edit_mode:false,
            interviewer_name:"",
            interview_date:"",
            interview_time:"",
            address:"",
            about:""
        })
         
    }
 
    handleOnSubmit = (term) => {
        setTimeout(() => {
            this.setState({
                job_title: term
            })
        }, 100);

    }
    
    editLocation = (e) => {
        this.setState({
            edit_location:true
        })
    }
  
    onChange(evt) {
        this.setState({
            "banner": evt.target.files[0],
        });

    }
    onChange1(evt) {

        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            description: newContent
        })
    }
    onChange2(evt) {
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            overview: newContent
        })
    }
    onChange3(evt) {
        var newContent = evt.editor.getData();
        console.log(newContent)
        this.setState({
            requirement: newContent
        })
    }
    handelDate = (date) => {
        //  alert(date);
          if(date !== 'Invalid Date'){
             this.setState({
                interview_date: date
             })
          }
         
     }
   
   
    handleInput1 = (e = null, company) => {
        this.setState({
            company_id: company.id,
            keyword:company.company_name,
            sugCompany: []
        })
    }
    handleInput2 = (e = null, department) => {
        this.setState({
            department_id: department.id,
            keyword1:department.name,
            sugDepartment: []
        })
    }
    handleInput3 = (e = null, job) => {
        this.setState({
            job_title: job.id,
            keyword2:job.name,
            sugJob: []
        })
    }
    
    
    render() {
        const recentSearches = this.state.filterData;
        const placeholder = 'Search Job Type...';
        const job_type = "job_type"
        const inputPosition = 'center';
        return (<>
            <NotificationContainer />
            <Navbar page="Seller Jobs" />
            
            <div class="dashboard_content" >
   <Sidebar />
   <section class="home-section" >
      <div class="home-content">
         {this.state.edit_mode && <>
         <div class="add_job">
            <div class="bg_white">
               <h4>Set Interview Schedule</h4>
              
               <div class="form_inputs">
                  <form accept-charset="UTF-8"    method="POST" enctype="multipart/form-data" target="_blank">
                    <div class="row">
                    <div class="col-md-6">
                           <div class="form-group">
                              <label>Interviewer Name</label>
                              <input type="text" value={this.state.interviewer_name} class="form-control" name="interviewer_name" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter Interviewer Name" />
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>Address</label>
                              <input type="text" value={this.state.address} class="form-control" name="address" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter Address" />
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>Interview time</label>
                              <input type="text" value={this.state.interview_time} class="form-control" name="interview_time" onChange={e => { this.handleInput(e) }} id="exampleInputEmai54l1" placeholder="Enter Interview Time" />
                           </div>
                        </div>
                      
                       
                     
                        {/* <div class="col-md-6">
                           <div class="form-group">
                              <label>About</label>
                              <input type="text" value={this.state.about} class="form-control" name="about" onChange={e => { this.handleInput(e) }} id="exampleInputEmail1" placeholder="Enter About" />
                           </div>
                        </div> */}
                        <div class="col-md-6">
                           <div class="form-group">
                              <label for="company_title">Interview date</label>

                              <ModernDatepicker
                                 className={'form-control'}
                                 date={ moment(this.state.interview_date).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") ? moment(new Date()).format("YYYY-MM-DD") : this.state.interview_date}
                             //    value={this.state.expiry_date}
                                 format={'YYYY-MM-DD'}
                                 showBorder
                                 minDate={new Date()}
                                 onChange={date =>
                              this.handelDate(date, 'interview_date')}
                              placeholder={' Interview Date'}
                              />

                             
                           </div>
                           </div>
                     </div>
                  
                     
                    <div class="sub_btn">
                        <button type="submit" onClick={(e) => { this.onFormSubmit(e) }}  class="save_btn page-primary-btn" disabled={this.state.loading} >
                        {this.state.loading  && <>
                        <i class="fa fa-refresh fa-spin"></i>
                        </>}
                        {!this.state.loading  && <>
                           Save
                        </>}
                        </button>
                        <button href="#"  onClick={(e) => { this.cancel_sedual(e) }}  class="cancel_btn page-primary-btn">Cancel</button> 
                     </div>
                  </form>
               </div>
            </div>
         </div>
         </>
         }
        {this.state.viewmode && <>  
           <div class="more_about_cmpny">
                    <div class="bg_white">
                      <div class="head_sec">
                        <h3> {this.state.select_details ? this.state.select_details.name : null}</h3>
                         </div>
                         {/* <h3>Change Status Job Application</h3> */}
                           <div class="row act_status">
                           <div class="col-md-6">
                           <div class="form-group" style={{"font-weight": "700" }}>
                              <label>Application Status</label>
                              <input type="radio" checked={this.state.status === 'Accepted'} name="status" value="Accepted" onChange={(e) => { this.handleInput(e) }} class="radio-sec" class="" />  Accepted
                              <input type="radio" checked={this.state.status === 'Rejected'} name="status" value="Rejected" onChange={(e) => { this.handleInput(e) }} class="radio-sec" class="rediopadding" />  Rejected
                              
                           </div>
                        </div>
                         </div>
                      <div class="form_inputs">
                        <form>
                      
                          <div class="row">

                            <div class="col-md-3">
                              <div class="form-group form-outputs">
                                <label>Name</label>
                                <span class="add_value"> {this.state.select_details ? this.state.select_details.name : null}</span>
                                
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group form-outputs">
                                <label>E-mail</label>
                                <span class="add_value"> {this.state.select_details ? this.state.select_details.email : null}</span>
                                
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group form-outputs">
                                <label>Mobile</label>
                                <span class="add_value">{this.state.select_details ? this.state.select_details.phone : null}</span>
                                
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group form-outputs">
                                <label>Job Title</label>
                                <span class="add_value">{this.state.select_details ? this.state.select_details.job_title : null}</span>
                                
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group form-outputs">
                                <label>Status</label>
                                <span class="add_value">{this.state.select_details ? this.state.select_details.status.charAt(0).toUpperCase() + this.state.select_details.status.slice(1)   : null}</span>
                                
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group form-outputs">
                                <label>Apply Date</label>
                                <span class="add_value">{this.state.select_details ? dateFormat(this.state.select_details.created_at, 'yyyy/MM/dd') : null}</span>
                                
                              </div>
                            </div>
                           
                            <div class="col-md-3">
                              <div class="form-group form-outputs">
                                <label>Resume</label>
                                <span class="add_value"><a target="_blank" href= {this.state.url+'/'+this.state.select_details.resume.resume}  >View</a></span>
                                
                              </div>
                            </div>
                            
                           </div>
                          
							 <div class="sub_btn">
                             <button onClick={(e) => { this.update_application_status(e) }} class="save_btn page-primary-btn" >Submit</button>
                    
                            <button  onClick={(e) => { this.cancel_view(e) }}  class="cancel_btn page-primary-btn" >Cancel</button> </div>
                        </form>
                      </div>
                    </div>
                  </div>
             </>}

         {!this.state.viewmode && !this.state.edit_mode &&  <>
         <div class="bg_white">
            <div class="job_details">
               <h4>Job applicants list</h4>
               <div class="row">
                  <div class="col-md-10">
                     <div class="search-bar">
                        <form class="form-inline search-datesec">
                           <div class="search-form">
                              <div class="form-group">
                                 {/* <input type="text" onKeyUp={(e) => { this.searchRecords(e) }} name="search" id="myInputTextField" placeholder="Search Record" class="form-control" /> */}
                                 {/* <span>
                                 <i class="fa fa-search" aria-hidden="true"></i>
                                 </span> */}
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
                  {/* <div class="col-md-2">
                     <div class="import-button">
                        <CSVLink data={this.state.excel_data} headers={this.state.excel_headers} id="typesTableExport" filename={"Job-list-" + moment().format('YYYY-MM-DD-h-mm-ss-a') + ".csv"} asyncOnClick={true} onClick={(event) => { this.getExcelData() }}>
                          Excel
                        </CSVLink>
                     </div>
                  </div> */}
               </div>
               <div class="tabel_head">
                  <span>Showing 1 Location</span>
                  {/* <div class="filter_view"> <a href="#" onClick={(e) => { this.AddNew(e) }}>Add New Job</a></div> */}
               </div>
               <table class="table table-bordered table-hover">
                  <thead class="">
                     <tr>
                        <th scope="col">NO</th>
                        <th scope="col">Name</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Job Title</th>
                        <th scope="col">Status</th>
                        <th scope="col" >Schedule</th>
                        <th scope="col" >Action</th>
                     </tr>
                  </thead>
                  <tbody>
                   {this.state.allJob.length > 0 && <>
                     {this.state.allJob.map((list, index) => {
                     return <tr>
                        <td>{(this.state.pagination_data.per_page * (this.state.pagination_data.current_page - 1)) + index + 1}</td>
                        <td scope="row" >{list.name}</td>
                        <td>{list.email}</td>
                        <td>{list.phone}</td>
                        <td>{list.job_title}</td>
                        <td>{list.status }</td>
                        <td class=""> 
                              {list.status === "Accepted" && <> 
                              <a href="javascript:void(0)" class="edit_sec" onClick={(e) => { this.view_schedule(e, list) }} >Set</a>
                            </>}
                        </td>
                   
                        <td class=""><a href="javascript:void(0)" class="edit_sec" onClick={(e) => { this.view_details(e, list) }} >View Details</a></td>
                     </tr>
                     })}
                  </>}
                  </tbody>
               </table>
               <Pagination data={this.state.pagination_data} getData={this.getAllJobs} />
            </div>
         </div>
         </>}
      
      </div>
   </section>
</div>

       </>

        );
    }
}
export default Applyjoblist;
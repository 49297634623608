import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import Pagination from "../Common/Pagination";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Breadcrumb, Boxes } from "./Parts";
import CKEditor from "react-ckeditor-component";
class Question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            first_name: "",
            last_name: "",
            company_email: "",
            url: process.env.MIX_REACT_API_URL,
            add_new: false,
            contact_number: "",
            show_back: false,
            topic: "",
            image: "",
            status: "",
            edit_mode: false,
            allPost: [],
            allIndustry: [],
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                links: [
                    {
                        url: null,
                        label: "&laquo; Previous",
                        active: false,
                    },
                    {
                        url: null,
                        label: "Next &raquo;",
                        active: false,
                    },
                ],
                next_page_url: null,
                path: "",
                per_page: 0,
                prev_page_url: null,
                to: 0,
                total: 0,
            },
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        // this.onChange = this.onChange2.bind(this);
    }
    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = "/admin/login";
        }
        this.getPost();
    };

    onChange1(evt) {
        var newContent = evt.editor.getData();
        console.log(newContent);
        this.setState({
            description: newContent,
        });
    }

    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChange(e) {
        this.setState({
            image: e.target.files[0],
        });
    }

    onChange2(e) {
        this.setState({
            banner: e.target.files[0],
        });
    }

    statusChangepost = (e, id) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("id", id);

        Axios.post(`${this.state.url}/change/post-status`, formData, {
            headers: {
                token: sessionStorage.getItem("_access"),
            },
        }).then((res) => {
            console.log(res.data);

            this.setState({
                add_new: false,
            });
            NotificationManager.success(
                "success",
                "Status update successfully"
            );
            this.getPost();
        });
    };

    deletePost = (e, id) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("id", id);

        Axios.post(`${this.state.url}/admin/post/${id}`, formData, {
            headers: {
                token: sessionStorage.getItem("_access"),
            },
        })
            .then((res) => {

                this.getPost();

                // Show a success notification
                NotificationManager.success(
                    "success",
                    "Post deleted successfully"
                );
            })
            .catch((error) => {
                // Handle the error here
                console.log(error);

                // Show an error notification
                NotificationManager.error("error", "Failed to delete post");
            });
    };

    onFormSubmit = (e) => {
        e.preventDefault();

        if (this.state.topic === "") {
            NotificationManager.error(
                "error",
                "Please enter English topic name."
            );
            return false;
        }
        if (this.state.arabic_topic === "") {
            NotificationManager.error(
                "error",
                "Please enter Arabic topic name."
            );
            return false;
        }
        if (this.state.image === "") {
            NotificationManager.error("error", "Please Choose image.");
            return false;
        }
        if (this.state.status === "") {
            NotificationManager.error("error", "Please select status.");
            return false;
        }

        const formData = new FormData();
        formData.append("topic", this.state.topic);
        formData.append("arabic_topic", this.state.arabic_topic);
        formData.append("image", this.state.image);
        formData.append("status", this.state.status);
        formData.append("id", this.state.id ? this.state.id : "");
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        if (!this.state.edit_mode) {
            Axios.post(`${this.state.url}/add/topic`, formData, config, {
                headers: {
                    token: sessionStorage.getItem("_access"),
                },
            }).then((res) => {
                if (res.data.status == true) {
                    this.setState({
                        add_new: false,
                    });
                    NotificationManager.success(
                        "success",
                        "Topic successfully added"
                    );
                    this.getPost();
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            });
        } else {
            Axios.post(`${this.state.url}/update/topic`, formData, config, {
                headers: {
                    token: sessionStorage.getItem("_access"),
                },
            }).then((res) => {
                if (res.data.status == true) {
                    this.setState({
                        add_new: false,
                        edit_mode: false,
                    });
                    NotificationManager.success(
                        "success",
                        "Topic updated successfully"
                    );
                    this.getPost();
                } else {
                    NotificationManager.error("error", "Name Already Exist");
                }
            });
        }
    };

    getPost = (page = 1) => {
        Axios.get(`${this.state.url}/admin/get/all-post?page=${page}`, {
            headers: {
                token: sessionStorage.getItem("_access"),
            },
        }).then((res) => {
            this.setState({
                allPost: res.data.data.data,
                pagination_data: res.data.data,
            });
        });
    };

    render() {
        return (
            <>
                <NotificationContainer />
                <Navbar page="Admin Dashboard" />
                <Sidebar />

                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-3">
                                    <h1 class="m-0">Forum Question</h1>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                    </div>

                    <section class="table-sec">
                        <div class="container-fluid">
                            <div class="col-md-12">
                                <div class="start-table-sec">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>Created By</th>
                                                    <th>Topic</th>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.allPost.length >
                                                    0 && (
                                                    <>
                                                        {this.state.allPost.map(
                                                            (list, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {this
                                                                                .state
                                                                                .pagination_data
                                                                                .per_page *
                                                                                (this
                                                                                    .state
                                                                                    .pagination_data
                                                                                    .current_page -
                                                                                    1) +
                                                                                index +
                                                                                1}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list
                                                                                    .user
                                                                                    .name
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list
                                                                                    .topics
                                                                                    .topic
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list.title
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                list.description
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {list.status ==
                                                                            "active"
                                                                                ? "Active"
                                                                                : "Inactive"}
                                                                        </td>
                                                                        <td class="edit-icon d-flex justify-content-between align-items-center">
                                                                            <a
                                                                                href=""
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    this.statusChangepost(
                                                                                        e,
                                                                                        list.id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {list.status ===
                                                                                    "active" && (
                                                                                    <>
                                                                                        
                                                                                        <i
                                                                                            class="fas fa-times"
                                                                                            title="Inactive Post"
                                                                                        ></i>
                                                                                    </>
                                                                                )}
                                                                                {list.status ===
                                                                                    "inactive" && (
                                                                                    <>
                                                                                        
                                                                                        <i
                                                                                            class="fas fa-check"
                                                                                            title="Active Post"
                                                                                        ></i>
                                                                                    </>
                                                                                )}
                                                                            </a>
                                                                            <span role="button" tabindex="0" style={{cursor: 'pointer'}}>
                                                                                <i
                                                                                    class="fa fa-trash  text-danger cursor-pointer"
                                                                                    aria-hidden="true"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.deletePost(
                                                                                            e,
                                                                                            list.id
                                                                                        )
                                                                                    }
                                                                                ></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                            <Pagination
                                                data={
                                                    this.state.pagination_data
                                                }
                                                getData={this.getPost}
                                            />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}
export default Question;

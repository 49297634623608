import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
  } from "reactstrap";
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import 'react-notifications/lib/notifications.css';

import Axios from "axios";
import NotificationAlert from "react-notification-alert";
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            url: process.env.MIX_REACT_API_URL,
        }
    }
    
    // notificationAlert = React.createRef();
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    loginSubmit(e) {
        var data = {
            email:this.state.email,
            password:this.state.password
        }
        console.log(this.state.url);
        Axios.post(`${this.state.url}/api/company-login`, data).then(res => {
            console.log(res.data)
           // alert(res.data);
            if(res.data.status == true){
                sessionStorage.setItem("_access", res.data.data.access_token);
                
                sessionStorage.setItem("data",JSON.stringify(res.data.data.user_data))
                sessionStorage.setItem("comapny_data",JSON.stringify(res.data.data.company_data))
                NotificationManager.success("success", "User logged successfully");
                window.location.href = '/employer/dashboard';
            }else{
                NotificationManager.error('error', 'Invalid Credential');
            }
        })
    }
   
    render() {
        return <>
            <NotificationContainer/>
            <header class="page_header">
  <div class="mhead_sec">
    <div class="containr">
      <div class="head_menu">
        <div class="logo_img"> <a class="navbar-logo" href="/employer/home" ><img src="../sellers/img/kaziom-logo.png" alt="kaziom logo" /></a> </div>
      </div>
    </div>
  </div>
</header>
<section class="signin-up_page">
  <div class="signin_form form_area">
	  <div class="form_feilds">
    <h4>Sign in to Kaziom</h4>
    <form>
      <label>Offcial Email</label>
      <input placeholder="john@gmail.com" type="email" name="email" onChange={e => { this.handleInput(e) }} />
      <label>Password</label>
      <input name="password" placeholder="Enter password" type="password" onChange={e => { this.handleInput(e) }} />
     <button  type="button" onClick={e => { this.loginSubmit(e) }} >Login</button>
    </form>
    <p><a href="/employer/forgot-password">Forgot Password</a></p>
    <p>Don't have an account? <a href="/employer/register">Sign Up</a></p>
		  </div>
  </div>
</section>
        </>;
    }
}

export default Login;